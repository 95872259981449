import React, { useState, useEffect } from 'react';
import style from './stylesheets/content.css';

import LineChartTotal from "./LineChartTotal";
import TablePortfolio from "./TablePortfolio";


import ParentComponent from "./ParentComponent";
import TableComponent from "./Simulator";
import ResultSimulator from "./ReportSimulator";
import Symbols from "./Symbols";
import Market from "./Market";
import ProgressionBarChart from "./ProgressionBarBuySell";
import Bots from "./Bots";
import WhalesDetection from "./WhalesDetection";
import TradeWidget from "./Bots";

const Content = () => {
	/* Pull information from the database - users */
	const [UsersInfo, setUsersInfo] = useState([]);
	const [TradingviewSignalsInfo, setTradingviewSignalsInfo] = useState([]);
	const [CoinsInfo, setCoinsInfo] = useState([]);

	useEffect(() => {
	  fetch('/api/users')
		.then(res => res.json())
		.then(data => setUsersInfo(data))
		.catch(err => console.error(err));
	}, []);

	/* Pull information from the database - charts for coins */
	useEffect(() => {
		fetch('/api/coinhistory')
			.then(res => res.json())
			.then(data => setCoinsInfo(data))
			.catch(err => console.error(err));
	}, []);
  return (
    <div className="content col-12 border-style-left">
      <div className="col-12 row-20">
        <div className="container col-12 row-10">
            {UsersInfo.map(item => (


          <div className="card col-5 row-20 pad-left-10 pad-b-10-mobile_tablet">
              <div className="linechart_hidden col-12 row-7 pad-bottom-10 pad-right-10 border-radius">
                  <div className="card dashboard_text_3 col-12 row-20 border-radius">
                      <div className="dashboard_title_2 col-12 s10 border-radius-top">Portfolio overview</div>
                      <div className="s9-grey col-12" style={{ borderTop: '1px solid #fff',borderBottom: '1px solid #fff' }}>Total: {(item.spot_total+item.margin_total+item.future_total).toFixed(2)} $</div>
                      <div className="linechart col-12 row-15"> <LineChartTotal total={item.spot_total+item.margin_total+item.future_total} benefit={item.spot_benefits+item.margin_benefits+item.future_benefits}/></div>
                  </div>
              </div>
			<div className="linechart col-12 row-13 pad-right-10 border-radius">
				  <div className="dashboard_text_3 col-12 row-20 border-radius">
				  <div className="dashboard_title_2 col-12 s10 border-radius-top">Automated portfolio</div>
				    <TablePortfolio />
				  </div>
			</div>
          </div>
            ))}
            <div className="card col-4 row-20 pad-right-5 pad-b-10-mobile_tablet col-4-mobile">
                <div className="card col-12 row-20 border-radius">
                    <div className="linechart col-12 row-20 border-radius">
                        <div className="dashboard_text_3 col-12 row-20 border-radius">
                            <div className="dashboard_title_2 col-12 s10 border-radius-top">Market overview</div>
                            <div className="parentContainer">
                                <div className="linechart col-12 row-3 pad-top-10 border-radius"><Market/></div>
                                <div className="linechart col-12 row-15 pad-top-10 pad-bottom-10 border-radius"><Symbols/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-3 row-20 pad-left-5 pad-right-10 pad-r-b-10-mobile_tablet col-4-mobile">
                <div className="linechart col-12 row-20 border-radius">
                    <div className="dashboard_text_3 col-12 row-20 border-radius">
                        <div className="dashboard_title_2 col-12 s10 border-radius-top">Automated self services</div>
                        <TradeWidget/>
                    </div>
                </div>
            </div>
        </div>

          <div className="container col-12 row-13">
              <div className="card col-3 width-tablet-phone row-19 pad-left-10 pad-r-b-10-mobile_tablet">
                  <div className="card dashboard_text_3 col-12 row-20 border-radius">
                      <div className="dashboard_title_2 col-12 s10 border-radius-top">Simulator</div>
                      <TableComponent/>
                  </div>
              </div>

              <div className="card col-9 row-20 mobile_textsize pad-left-10">
                  <ParentComponent />
              </div>
          </div>
      </div>
    </div>
  );
};

export default Content;
