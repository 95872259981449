const indicatorNameMapSimulatorPremium = {
    //other
    "rec_rebond_s_r": "Rebound Support Resistance",
    "rec_breakout_up_down": "Breakout Up/Down",
    "change_15m_wf": "CHG 15m",
    "change_1h_wf": "CHG 1h",
    "change_1d_wf": "CHG 1d",
    "change_1w_wf": "CHG 1w",
    "change_1m_wf": "CHG 1m",
    "score_wf": "Score",
    "btc_score_wf": "BTC Score",
    "price_position_wf": "BTC Score",

    // 15m Indicators
    "rsi6_15m_wf": "RSI (6) 15m",
    "rec_rsi6_bull_15m_wf": "RSI 6 Bullish 15m",
    "rsi14_15m_wf": "RSI (14) 15m",
    "rec_rsi14_bull_15m_wf": "RSI 14 Bullish 15m",
    "rsi30_15m_wf": "RSI (30) 15m",
    "rec_rsi30_bull_15m_wf": "RSI 30 Bullish 15m",
    "rec_stochrsi6_15m_wf": "Stoch RSI (6) 15m",
    "rec_stochrsi14_15m_wf": "Stoch RSI (14) 15m",
    "rec_stochrsi30_15m_wf": "Stoch RSI (30) 15m",
    "rec_golden_cross_15m_wf": "Golden Cross 15m",
    "rec_cci_15m_wf": "CCI 15m",
    "rec_william_15m_wf": "William %R 15m",
    "rec_hma_15m_wf": "Hull Moving Average 15m",
    "BB_lower_15m_wf": "Bollinger Bands Low 15m",
    "BB_upper_15m_wf": "Bollinger Bands High 15m",
    "rec_atr_15m_wf": "Average True Range 15m",
    "rec_ichimoku_15m_wf": "Ichimoku Cloud 15m",
    "rec_death_cross_15m_wf": "Death Cross 15m",
    "rec_ema510_15m_wf": "EMA 0510 15m",
    "rec_ema1020_15m_wf": "EMA 1020 15m",
    "rec_ema1550_15m_wf": "EMA 1550 15m",
    "rec_ema50200_15m_wf": "EMA 50200 15m",
    "rec_ema719_15m_wf": "EMA 719 15m",
    "rec_ema921_15m_wf": "EMA 921 15m",
    "rec_ema725_15m_wf": "EMA 725 15m",
    "rec_ema2599_15m_wf": "EMA 2599 15m",
    "rec_doji_15m_wf": "Doji 15m",
    "rec_hammer_15m_wf": "Hammer 15m",
    "rec_hanging_man_15m_wf": "Hanging Man 15m",
    "rec_morning_star_15m_wf": "Morning Star 15m",
    "rec_evening_star_15m_wf": "Evening Star 15m",
    "rec_shooting_star_15m_wf": "Shooting Star 15m",
    "rec_inverted_hammer_15m_wf": "Inverted Hammer 15m",
    "rec_three_inside_up_15m_wf": "3 Inside Up 15m",
    "rec_three_inside_down_15m_wf": "3 Inside Down 15m",
    "rec_three_white_soldiers_15m_wf": "3 White Soldiers 15m",
    "rec_three_black_crows_15m_wf": "3 Black Crows 15m",
    "rec_bearish_engulfing_15m_wf": "Bearish Engulfing 15m",
    "rec_bullish_engulfing_15m_wf": "Bullish Engulfing 15m",
    "rec_vwma_14_15m_wf": "VWMA (14) 15m",
    "rec_macd_15m_wf": "MACD (12,26,9) 15m",
    "rec_psar_15m_wf": "PSAR 15m",
    "rec_kdj_15m_wf": "KDJ (9) 15m",
    "rec_dmi_14_15m_wf": "DMI (14) 15m",

    // 1h Indicators
    "rsi6_1h_wf": "RSI (6) 1h",
    "rec_rsi6_bull_1h_wf": "RSI 6 Bullish 1h",
    "rsi14_1h_wf": "RSI (14) 1h",
    "rec_rsi14_bull_1h_wf": "RSI 14 Bullish 1h",
    "rsi30_1h_wf": "RSI (30) 1h",
    "rec_rsi30_bull_1h_wf": "RSI 30 Bullish 1h",
    "rec_stochrsi6_1h_wf": "Stoch RSI (6) 1h",
    "rec_stochrsi14_1h_wf": "Stoch RSI (14) 1h",
    "rec_stochrsi30_1h_wf": "Stoch RSI (30) 1h",
    "rec_cci_1h_wf": "Commodity Channel Index 1h",
    "rec_william_1h_wf": "William %R 1h",
    "rec_hma_1h_wf": "Hull Moving Average 1h",
    "BB_lower_1h_wf": "Bollinger Bands Low 1h",
    "BB_upper_1h_wf": "Bollinger Bands High 1h",
    "rec_atr_1h_wf": "Average True Range 1h",
    "rec_ichimoku_1h_wf": "Ichimoku Cloud 1h",
    "rec_golden_cross_1h_wf": "Golden Cross 1h",
    "rec_death_cross_1h_wf": "Death Cross 1h",
    "rec_ema510_1h_wf": "EMA 0510 1h",
    "rec_ema1020_1h_wf": "EMA 1020 1h",
    "rec_ema1550_1h_wf": "EMA 1550 1h",
    "rec_ema50200_1h_wf": "EMA 50200 1h",
    "rec_ema719_1h_wf": "EMA 719 1h",
    "rec_ema921_1h_wf": "EMA 921 1h",
    "rec_ema725_1h_wf": "EMA 725 1h",
    "rec_ema2599_1h_wf": "EMA 2599 1h",
    "rec_doji_1h_wf": "Doji 1h",
    "rec_hammer_1h_wf": "Hammer 1h",
    "rec_hanging_man_1h_wf": "Hanging Man 1h",
    "rec_morning_star_1h_wf": "Morning Star 1h",
    "rec_evening_star_1h_wf": "Evening Star 1h",
    "rec_shooting_star_1h_wf": "Shooting Star 1h",
    "rec_inverted_hammer_1h_wf": "Inverted Hammer 1h",
    "rec_three_inside_up_1h_wf": "3 Inside Up 1h",
    "rec_three_inside_down_1h_wf": "3 Inside Down 1h",
    "rec_three_white_soldiers_1h_wf": "3 White Soldiers 1h",
    "rec_three_black_crows_1h_wf": "3 Black Crows 1h",
    "rec_bearish_engulfing_1h_wf": "Bearish Engulfing 1h",
    "rec_bullish_engulfing_1h_wf": "Bullish Engulfing 1h",
    "rec_vwma_14_1h_wf": "VWMA 14 1h",
    "rec_macd_1h_wf": "MACD (12,26,9) 1h",
    "rec_psar_1h_wf": "PSAR 1h",
    "rec_kdj_1h_wf": "KDJ (9) 1h",
    "rec_dmi_14_1h_wf": "DMI (14) 1h",

    // 1d Indicators
    "rsi6_1d_wf": "RSI (6) 1d",
    "rec_rsi6_bull_1d_wf": "RSI 6 Bullish 1d",
    "rsi14_1d_wf": "RSI (14) 1d",
    "rec_rsi14_bull_1d_wf": "RSI 14 Bullish 1d",
    "rsi30_1d_wf": "RSI (30) 1d",
    "rec_rsi30_bull_1d_wf": "RSI 30 Bullish 1d",
    "rec_stochrsi6_1d_wf": "Stoch RSI (6) 1d",
    "rec_stochrsi14_1d_wf": "Stoch RSI (14) 1d",
    "rec_stochrsi30_1d_wf": "Stoch RSI (30) 1d",
    "rec_cci_1d_wf": "Commodity Channel Index 1d",
    "rec_william_1d_wf": "William %R 1d",
    "rec_hma_1d_wf": "Hull Moving Average 1d",
    "BB_lower_1d_wf": "Bollinger Bands Low 1d",
    "BB_upper_1d_wf": "Bollinger Bands High 1d",
    "rec_atr_1d_wf": "Average True Range 1d",
    "rec_ichimoku_1d_wf": "Ichimoku Cloud 1d",
    "rec_golden_cross_1d_wf": "Golden Cross 1d",
    "rec_death_cross_1d_wf": "Death Cross 1d",
    "rec_ema510_1d_wf": "EMA 0510 1d",
    "rec_ema1020_1d_wf": "EMA 1020 1d",
    "rec_ema1550_1d_wf": "EMA 1550 1d",
    "rec_ema50200_1d_wf": "EMA 50200 1d",
    "rec_ema719_1d_wf": "EMA 719 1d",
    "rec_ema921_1d_wf": "EMA 921 1d",
    "rec_ema725_1d_wf": "EMA 725 1d",
    "rec_ema2599_1d_wf": "EMA 2599 1d",
    "rec_doji_1d_wf": "Doji 1d",
    "rec_hammer_1d_wf": "Hammer 1d",
    "rec_hanging_man_1d_wf": "Hanging Man 1d",
    "rec_morning_star_1d_wf": "Morning Star 1d",
    "rec_evening_star_1d_wf": "Evening Star 1d",
    "rec_shooting_star_1d_wf": "Shooting Star 1d",
    "rec_inverted_hammer_1d_wf": "Inverted Hammer 1d",
    "rec_three_inside_up_1d_wf": "3 Inside Up 1d",
    "rec_three_inside_down_1d_wf": "3 Inside Down 1d",
    "rec_three_white_soldiers_1d_wf": "3 White Soldiers 1d",
    "rec_three_black_crows_1d_wf": "3 Black Crows 1d",
    "rec_bearish_engulfing_1d_wf": "Bearish Engulfing 1d",
    "rec_bullish_engulfing_1d_wf": "Bullish Engulfing 1d",
    "rec_vwma_14_1d_wf": "VWMA (14) 1d",
    "rec_macd_1d_wf": "MACD (12,26,9) 1d",
    "rec_psar_1d_wf": "PSAR 1d",
    "rec_kdj_1d_wf": "KDJ (9) 1d",
    "rec_dmi_14_1d_wf": "DMI (14) 1d"
};
export default indicatorNameMapSimulatorPremium;