import React, { useState, useEffect } from 'react';
import { LineChart, Line, Tooltip, ResponsiveContainer } from 'recharts';

const LineChartTotal = ({total, benefit}) => {
	// State variables to store fetched data and the selected data type
	const [data, setData] = useState([]);
	const [selectedDataType, setSelectedDataType] = useState('total');

	// Fetch data from API based on selected data type
	useEffect(() => {
		fetchData(selectedDataType);
	}, [selectedDataType]);

	const fetchData = async (dataType) => {
		try {
			let response;
			switch (dataType) {
				case 'total':
					response = await fetch("/api/totalhistory");
					break;
				case 'benefit':
					response = await fetch("/api/benefithistory");
					break;
				default:
					break;
			}
			const responseData = await response.json();

			// Format the fetched data
			const formattedData = responseData.map((item) => ({
				username: item.username,
				data: item.data.map((d) => ({
					date: new Date(d.date),
					value: d.value,
				})),
			}));

			setData(formattedData); // Set data state
		} catch (error) {
			console.error(error);
		}
	};

	// Function to handle data type selection
	const handleDataTypeSelect = (dataType) => {
		setSelectedDataType(dataType);
	};

	// Render the line chart with fetched data
	return (
		<div>
			<ResponsiveContainer width="100%" height={128}>
				<LineChart>
					<Tooltip />

					{/* Render lines using fetched data */}
					{data.map((entry, index) => (
						<Line
							key={`data-${index}`}
							dataKey="value"
							name={entry.username}
							data={entry.data}
							stroke={
								selectedDataType === 'total' ? '#888' :
									selectedDataType === 'benefit' ? '#000' :
										'#f44336' // Default color
							}
							strokeWidth={2}
							dot={{ fill: '#FFF', strokeWidth: 0,r:0 }}
						/>
					))}
				</LineChart>
			</ResponsiveContainer>
			<ul className="data-type-list">
				{/* Render list of data types for selection */}
				<li className={selectedDataType === 'total' ? 'selected' : ''} onClick={() => handleDataTypeSelect('total')}>Total <span>({total})</span></li>
				<li className={selectedDataType === 'benefit' ? 'selected' : ''} onClick={() => handleDataTypeSelect('benefit')}>Benefits <span>({benefit})</span></li>
			</ul>
		</div>
	);
};

export default LineChartTotal;
