import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import ProgressionBarTechnical from "./ProgressionBarTechnical";
import TableComponent from "./Simulator";
import ResultSimulator from "./ReportSimulator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle} from '@fortawesome/free-solid-svg-icons';
const CoinHistory = ({ data, selectedCurrency, onCurrencyChange, currencyData }) => {
	const svgRef = useRef(null);
	const containerRef = useRef(null);
	const [lowestPrice, setLowestPrice] = useState(null);
	const [highestPrice, setHighestPrice] = useState(null);
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [technicalData, setTechnicalData] = useState(null); // State for technical data

	useEffect(() => {
		// Fetch currency data from the API endpoint connected to your database
		fetch("/api/coinhistory2?currency") // Replace with your actual API endpoint
			.then((response) => response.json())
			.then((data) => {
				if (Array.isArray(data)) {
					// Extract currency values from the data
					const currencies = data.map((currency) => currency.currency);
					currencies.slice().sort();

					// Set the currency options in state
					setCurrencyOptions(currencies);
				}
			})
			.catch((error) => {
				console.error("Error fetching currency data:", error);
			});
	}, []); // Fetch data only once when the component mounts
	useEffect(() => {
		// Fetch currency data from the API endpoint connected to your database
		fetch("/api/coinhistory") // Use the endpoint to fetch the list of currencies
			.then((response) => response.json())
			.then((data) => {
				if (Array.isArray(data)) {
					// Set the currency options in state
					setCurrencyOptions(data);
				}
			})
			.catch((error) => {
				console.error("Error fetching currency data:", error);
			});
	}, []);

	useEffect(() => {
		if (!containerRef.current) return;

		const svg = d3.select(svgRef.current);

		const containerWidth = containerRef.current.clientWidth;
		const containerHeight = containerRef.current.clientHeight;

		const width = containerWidth;
		const height = containerHeight;
		const margin = { top: 0, right: 0, bottom: 0, left: 0 };
		const innerWidth = width - margin.left - margin.right;
		const innerHeight = height - margin.top - margin.bottom;

		// Clear the old chart by removing the SVG element with the specified ID
		const existingSvg = document.getElementById("line-chart-svg");
		if (existingSvg) {
			existingSvg.remove();
		}

		const xScale = d3
			.scaleTime()
			.domain(d3.extent(data, (d) => d.date))
			.range([0, innerWidth]);

		const yScale = d3
			.scaleLinear()
			.domain([0, d3.max(data, (d) => d.value)])
			.nice()
			.range([innerHeight, 0]);

		const lineGenerator = d3
			.line()
			.x((d) => xScale(d.date))
			.y((d) => yScale(d.value))
			.curve(d3.curveLinear);

		svg
			.append("path")
			.datum(data)
			.attr("id", "line-chart-svg") // Add an ID to the new chart for future removal
			.attr("fill", "none")
			.attr("stroke", "#f44336")
			.attr("stroke-width", 2)
			.attr("d", lineGenerator);
		const valuesFloat = data.map((d) => d.value);
		setLowestPrice(d3.min(valuesFloat));
		setHighestPrice(d3.max(valuesFloat));
	}, [data]);
	useEffect(() => {
		// Fetch technical data from the API endpoint
		if (selectedCurrency) {
			fetch(`/api/coinhistory?currency=${selectedCurrency}`)
				.then((response) => response.json())
				.then((data) => {
					if (Array.isArray(data) && data.length > 0) {
						// Set the technical data in state
						setTechnicalData(data[0].technical);
					} else {
						// Handle the case where technical data is not available
						setTechnicalData(null);
					}
				})
				.catch((error) => {
					console.error("Error fetching technical data:", error);
					setTechnicalData(null);
				});
		} else {
			setTechnicalData(null); // Clear technical data when no currency is selected
		}
	}, [selectedCurrency]);

	const handleCurrencyChange = (event) => {
		onCurrencyChange(event.target.value);
	};


	/* Functions to change color*/
	function getColorRec(value) {
		if (value === 0) {
			return "recommendation-red-flag"; // Apply red color class for zero
		} else if (value === 1) {
			return "recommendation-green-flag"; // Apply green color class for one
		} else {
			return "recommendation-grey-flag"; // No specific color class for other values
		}
	}

	function RecommendationIcon({ value }) {
		const colorClass = getColorRec(value);
		let icon;

		switch (value) {
			case 0:
				icon = <FontAwesomeIcon icon={faCircle} className={colorClass} />;
				break;
			case 1:
				icon = <FontAwesomeIcon icon={faCircle} className={colorClass} />;
				break;
			default:
				icon = <FontAwesomeIcon icon={faCircle} className={colorClass} />;
				break;
		}

		return icon;
	}
	function getColorRecTotal(value) {
		const parsedValue = parseInt(value);

		if (parsedValue >= -1 && parsedValue < -0.5) {
			return "recommendation-darkred-flag"; // Apply red color class for zero
		} else if (parsedValue >= -0.5 && parsedValue < 0) {
			return "recommendation-red-flag"; // Apply green color class for one
		} else if (parsedValue > 0 && parsedValue <= 0.5) {
			return "recommendation-green-flag"; // Apply green color class for one
		} else if (parsedValue > 0.5 && parsedValue <= 1) {
			return "recommendation-darkgreen-flag"; // Apply green color class for one
		} else {
			return "recommendation-grey-flag"; // No specific color class for other values
		}
	}

	return (
			<div className="linechart col-12 row-12 pad-right-10 border-radius">
				<div className="dashboard_text_3 col-12 row-20 border-radius">
					<div className="dashboard_title_2 col-12 s10 border-radius-top">Technical analysis</div>
					<div className="dashboard_title_2 col-12 s10b3 center">
						<select
							value={selectedCurrency}
							onChange={handleCurrencyChange}
							style={{ width: "100px" }}
						>
							{currencyOptions.map((currency, index) => (
								<option key={index} value={currency}>
									{currency}
								</option>
							))}
						</select>

					</div>
					{technicalData && (
						<div className="linechart col-4 row-5" style={{ padding: '5px' }}>
							<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'center', fontSize: '14px',backgroundColor: '#FFF',color: '#555', fontWeight: 'bold' }}>
								15m
							</div>
							<div className="linechart col-12 row-5" style={{ display: 'flex', fontSize: '10px', flexWrap: 'wrap', padding: '10px 10px 19px 5px', borderRight: '1px solid black' }}>
								<div className="linechart col-12 row-5" >
									<ProgressionBarTechnical recommend_all={technicalData.Recommend_All_15m_wf} recommend_other={technicalData.Recommend_Other_15m_wf} recommend_sma={technicalData.rec_sma_15m_wf} recommend_ema={technicalData.rec_ema_15m_wf} />
									<div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px' }}>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Oscillators
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi6_15m_wf : ""} />
												RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi6_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi6_bull_15m_wf : ""} />
												Bullish.RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi6_bull_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box'}}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi14_15m_wf : ""} />
												RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi14_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi14_bull_15m_wf : ""} />
												Bullish.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi14_bull_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi30_15m_wf : ""} />
												RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi30_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi30_bull_15m_wf : ""} />
												Bullish.RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi30_bull_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_lower_15m_wf : ""} />
												Lower.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_lower_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_upper_15m_wf : ""} />
												Upper.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_upper_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_stochrsi14_15m_wf : ""} />
												Stoch.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_stochrsi14_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.CCI20_15m_wf : ""} />
												CCI.20
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.CCI20_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_dmi_30_15m_wf : ""} />
												DMI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_dmi_30_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_kdj_9_15m_wf : ""} />
												KDJ.9
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_kdj_9_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Candlesticks

										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_doji_15m_wf : ""} />
												Doji
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_doji_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hammer_15m_wf : ""} />
												Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hammer_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hanging_man_15m_wf : ""} />
												Hanging.Man
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hanging_man_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_morning_star_15m_wf : ""} />
												Morning.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_morning_star_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_evening_star_15m_wf : ""} />
												Evening.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_evening_star_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_shooting_star_15m_wf : ""} />
												Shooting.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_shooting_star_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_inverted_hammer_15m_wf : ""} />
												Inverted.Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_inverted_hammer_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_up_15m_wf : ""} />
												3.Inside.Up
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_up_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_down_15m_wf : ""} />
												3.Inside.Down
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_down_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_white_soldiers_15m_wf : ""} />
												3.White.Soldiers
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_white_soldiers_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_black_crows_15m_wf : ""} />
												3.Black.Crows
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_black_crows_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_engulfing_15m_wf : ""} />
												Engulfing
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_engulfing_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Supports & Resistances
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S3_15m_wf : ""} />
												S.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S3_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S2_15m_wf : ""} />
												S.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S2_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S1_15m_wf : ""} />
												S.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S1_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_Middle_15m_wf : ""} />
												Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_Middle_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_15m_wf : ""} />
												R.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R1_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R2_15m_wf : ""} />
												R.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R2_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R3_15m_wf : ""} />
												R.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R3_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_pivot_15m_wf : ""} />
												Rec.Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_pivot_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Other
										</div>

										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.change_15m_wf : ""} />
												Variation
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.change_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_15m_wf : ""} />
												Market.Cap
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.volatility_15m_wf : ""} />
												Volatility
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.volatility_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_vwma14_15m_wf : ""} />
												Rec.VWMA
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_vwma14_15m_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_psar_15m_wf : ""} />
												Rec.PSAR
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_psar_15m_wf : ""})
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}


					{technicalData && (
						<div className="linechart col-4 row-5" style={{ padding: '5px' }}>
							<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'center', fontSize: '14px',backgroundColor: '#FFF',color: '#555', fontWeight: 'bold' }}>
								1h
							</div>
							<div className="linechart col-12 row-5" style={{ display: 'flex', fontSize: '10px', flexWrap: 'wrap', padding: '10px 10px 0px 0px', borderRight: '1px solid black' }}>
								<div className="linechart col-12 row-5" >
									<ProgressionBarTechnical recommend_all={technicalData.Recommend_All_1h_wf} recommend_other={technicalData.Recommend_Other_1h_wf} recommend_sma={technicalData.rec_sma_1h_wf} recommend_ema={technicalData.rec_ema_1h_wf} />
									<div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px' }}>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Oscillators
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi6_1h_wf : ""} />
												RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi6_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi6_bull_1h_wf : ""} />
												Bullish.RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi6_bull_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi14_1h_wf : ""} />
												RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi14_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi14_bull_1h_wf : ""} />
												Bullish.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi14_bull_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi30_1h_wf : ""} />
												RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi30_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi30_bull_1h_wf : ""} />
												Bullish.RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi30_bull_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_lower_1h_wf : ""} />
												Lower.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_lower_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_upper_1h_wf : ""} />
												Upper.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_upper_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_stochrsi14_1h_wf : ""} />
												Stoch.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_stochrsi14_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.CCI20_1h_wf : ""} />
												CCI.20
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.CCI20_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_dmi_30_1h_wf : ""} />
												DMI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_dmi_30_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_kdj_9_1h_wf : ""} />
												KDJ.9
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_kdj_9_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Candlesticks

										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_doji_1h_wf : ""} />
												Doji
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_doji_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hammer_1h_wf : ""} />
												Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hammer_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hanging_man_1h_wf : ""} />
												Hanging.Man
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hanging_man_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_morning_star_1h_wf : ""} />
												Morning.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_morning_star_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_evening_star_1h_wf : ""} />
												Evening.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_evening_star_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_shooting_star_1h_wf : ""} />
												Shooting.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_shooting_star_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_inverted_hammer_1h_wf : ""} />
												Inverted.Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_inverted_hammer_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_up_1h_wf : ""} />
												3.Inside.Up
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_up_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_down_1h_wf : ""} />
												3.Inside.Down
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_down_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_white_soldiers_1h_wf : ""} />
												3.White.Soldiers
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_white_soldiers_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_black_crows_1h_wf : ""} />
												3.Black.Crows
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_black_crows_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_engulfing_1h_wf : ""} />
												Engulfing
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_engulfing_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Supports & Resistances
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S3_1h_wf : ""} />
												S.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S3_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S2_1h_wf : ""} />
												S.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S2_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S1_1h_wf : ""} />
												S.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S1_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_Middle_1h_wf : ""} />
												Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_Middle_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_1h_wf : ""} />
												R.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R1_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R2_1h_wf : ""} />
												R.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R2_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R3_1h_wf : ""} />
												R.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R3_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_pivot_1h_wf : ""} />
												Rec.Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_pivot_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Other
										</div>

										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.change_1h_wf : ""} />
												Variation
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.change_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_1h_wf : ""} />
												Market.Cap
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.volatility_1h_wf : ""} />
												Volatility
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.volatility_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_vwma14_1h_wf : ""} />
												Rec.VWMA
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_vwma14_1h_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_psar_1h_wf : ""} />
												Rec.PSAR
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_psar_1h_wf : ""})
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{technicalData && (
						<div className="linechart col-4 row-5" style={{ padding: '5px' }}>
							<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'center', fontSize: '14px',backgroundColor: '#FFF',color: '#555', fontWeight: 'bold' }}>
								1d
							</div>
							<div className="linechart col-12 row-5" style={{ display: 'flex', fontSize: '10px', flexWrap: 'wrap', padding: '10px 10px 0px 0px', borderRight: '1px solid black' }}>
								<div className="linechart col-12 row-5" >
									<ProgressionBarTechnical recommend_all={technicalData.Recommend_All_1d_wf} recommend_other={technicalData.Recommend_Other_1d_wf} recommend_sma={technicalData.rec_sma_1d_wf} recommend_ema={technicalData.rec_ema_1d_wf} />
									<div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '20px' }}>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Oscillators
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi6_1d_wf : ""} />
												RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi6_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi6_bull_1d_wf : ""} />
												Bullish.RSI.6
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi6_bull_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi14_1d_wf : ""} />
												RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi14_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi14_bull_1d_wf : ""} />
												Bullish.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi14_bull_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rsi30_1d_wf : ""} />
												RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rsi30_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_rsi30_bull_1d_wf : ""} />
												Bullish.RSI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_rsi30_bull_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_lower_1d_wf : ""} />
												Lower.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_lower_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.BB_upper_1d_wf : ""} />
												Upper.BB
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.BB_upper_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_stochrsi14_1d_wf : ""} />
												Stoch.RSI.14
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_stochrsi14_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.CCI20_1d_wf : ""} />
												CCI.20
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.CCI20_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_dmi_30_1d_wf : ""} />
												DMI.30
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_dmi_30_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_kdj_9_1d_wf : ""} />
												KDJ.9
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_kdj_9_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Candlesticks

										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_doji_1d_wf : ""} />
												Doji
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_doji_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hammer_1d_wf : ""} />
												Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hammer_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_hanging_man_1d_wf : ""} />
												Hanging.Man
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_hanging_man_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_morning_star_1d_wf : ""} />
												Morning.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_morning_star_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_evening_star_1d_wf : ""} />
												Evening.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_evening_star_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_shooting_star_1d_wf : ""} />
												Shooting.Star
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_shooting_star_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_inverted_hammer_1d_wf : ""} />
												Inverted.Hammer
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_inverted_hammer_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_up_1d_wf : ""} />
												3.Inside.Up
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_up_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_inside_down_1d_wf : ""} />
												3.Inside.Down
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_inside_down_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_white_soldiers_1d_wf : ""} />
												3.White.Soldiers
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_white_soldiers_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_three_black_crows_1d_wf : ""} />
												3.Black.Crows
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_three_black_crows_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_engulfing_1d_wf : ""} />
												Engulfing
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_engulfing_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Supports & Resistances
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S3_1d_wf : ""} />
												S.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S3_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S2_1d_wf : ""} />
												S.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S2_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_S1_1d_wf : ""} />
												S.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_S1_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_Middle_1d_wf : ""} />
												Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_Middle_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_1d_wf : ""} />
												R.1
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R1_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R2_1d_wf : ""} />
												R.2
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R2_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R3_1d_wf : ""} />
												R.3
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.Pivot_M_Classic_R3_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_pivot_1d_wf : ""} />
												Rec.Pivot
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_pivot_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '100%', boxSizing: 'border-box', float: 'left', fontSize: '10px',backgroundColor: '#DDD',color: '#555', fontWeight: 'bold' }}>
											Other
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.change_1d_wf : ""} />
												Variation
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.change_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.Pivot_M_Classic_R1_1d_wf : ""} />
												Market.Cap
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.volatility_1d_wf : ""} />
												Volatility
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.volatility_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_vwma14_1d_wf : ""} />
												Rec.VWMA
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_vwma14_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_psar_1d_wf : ""} />
												Rec.PSAR
												<span className="benefits" style={{ marginLeft: '5px' }}>
													({technicalData ? technicalData.rec_psar_1d_wf : ""})
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.mfm_1d_wf : ""} />
												M.F.M
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.mfv_1d_wf : ""} />
												M.F.V
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
										<div style={{ flexBasis: '50%', boxSizing: 'border-box' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<RecommendationIcon value={technicalData ? technicalData.rec_ad_line_1d_wf : ""} />
												AD.Line
												<span className="benefits" style={{ marginLeft: '5px' }}>
													()
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>

	);
};

export default CoinHistory;
