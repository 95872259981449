import React, { useState, useEffect } from "react";
import { ArrowForward } from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const Tutorial = ({ onClose }) => {
    const { t } = useTranslation();
    const [stepIndex, setStepIndex] = useState(0);
    const [currentElement, setCurrentElement] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
    const [tooltipAbove, setTooltipAbove] = useState(false); // New state to track position
    const tutorialSteps = [
        {selector: ".card-portfolio-overview",content: t("tutorial.step_1")},
        {selector: ".card-portfolio",content: t("tutorial.step_2") },
        {selector: ".card-market-overview",content: t("tutorial.step_3")},
        { selector: ".card-self-services",content: t("tutorial.step_4")},
        {selector: ".card-strategy-builder",content: t("tutorial.step_5")},
        {selector: ".card-technical-analysis",content: t("tutorial.step_6")}];
    // Function to update position dynamically
    const updatePosition = (element) => {
        if (!element) return;
        const rect = element.getBoundingClientRect(); // Get position relative to viewport

        const spaceBelow = window.innerHeight - rect.bottom; // Space below element
        const spaceAbove = rect.top; // Space above element

        // If not enough space below, move tooltip above
        setTooltipAbove(spaceBelow < 60 && spaceAbove > 60);

        setPosition({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height
        });
    };

    // Scroll to the element smoothly
    const scrollToElement = (element) => {
        if (!element) return;
        const rect = element.getBoundingClientRect();
        const absoluteTop = rect.top + window.scrollY - 100; // Adjust margin from top

        window.scrollTo({
            top: absoluteTop,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        document.body.style.overflow = "hidden"; // Disable scrolling

        return () => {
            document.body.style.overflow = "auto"; // Re-enable scrolling
        };
    }, []);

    useEffect(() => {
        const checkElement = () => {
            const step = tutorialSteps[stepIndex];
            const element = document.querySelector(step.selector);

            if (element) {
                setCurrentElement(element);
                updatePosition(element);
                scrollToElement(element);
            } else {
                handleNext(); // Skip if element not found
            }
        };

        checkElement();
    }, [stepIndex]);

    useEffect(() => {
        const update = () => {
            setCurrentElement((prevElement) => {
                if (prevElement) {
                    updatePosition(prevElement);
                }
                return prevElement;
            });
        };

        window.addEventListener("scroll", update);
        window.addEventListener("resize", update);

        return () => {
            window.removeEventListener("scroll", update);
            window.removeEventListener("resize", update);
        };
    }, [currentElement]);

    const handleNext = () => {
        if (stepIndex < tutorialSteps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            onClose();
        }
    };

    return currentElement ? (
        <div className="tutorial-overlay">
            {/* Highlight Box */}
            <div
                className="tutorial-box"
                style={{
                    position: "fixed",
                    top: position.top,
                    left: position.left,
                    width: position.width,
                    height: position.height,
                    background: "rgba(255, 255, 255, 0.3)",
                    borderRadius: "5px",
                    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                    border: "2px solid rgba(255, 255, 255, 0.7)",
                    zIndex: 999
                }}
            />

            {/* Tooltip Box (Now Adjusts Position) */}
            <div
                className="tooltip-box"
                style={{
                    position: "fixed",
                    top: tooltipAbove ? position.top - 50 : position.top + position.height + 10, // Adjust if too low
                    left: position.left,
                    width: position.width,
                    background: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "14px",
                    textAlign: "left",
                    zIndex: 1000
                }}
            >
                <p style={{ margin: 0, color: "#555" }}>{tutorialSteps[stepIndex].content}</p>
                <ArrowForward
                    onClick={handleNext}
                    style={{ cursor: "pointer", color: "#f44336" }}
                />
            </div>
        </div>
    ) : null;
};

export default Tutorial;
