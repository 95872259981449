import React, { useEffect, useState } from 'react';

const Element = ({ name, price, variation }) => {
	const isPositive = parseFloat(variation) >= 0;
	const variationColor = isPositive ? '#4CAF50' : '#f44336';

	return (
		<div style={{ ...styles.card }}>
			<div style={styles.symbol} title={name}>{name}</div>
			<div style={styles.lastprice}>{price}</div>
			<div style={{ ...styles.variation, color: variationColor }}>{variation}%</div>
		</div>
	);
};

const styles = {
	subtitle: {
		fontSize: '10px',
		fontWeight: 'bold',
		color: '#333',
		marginBottom: '10px',
		textAlign: 'center',
		textTransform: 'uppercase',
		letterSpacing: '1px',
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '3.9vw',
		height: '3.9vw',
		minWidth: '50px',
		minHeight: '50px',
		margin: '1px',
		borderRadius: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		textAlign: 'center',
	},
	symbol: {
		fontWeight: 'bold',
		fontSize: '10px',
		color: '#f0f0f0',
		textShadow: '0 1px 2px rgba(0, 0, 0, 0.4)',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '100%',
	},
	lastprice: {
		fontSize: '8px',
		color: '#777',
	},
	variation: {
		fontSize: '7px',
		fontWeight: '400',
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		borderRadius: '12px',
	},
};

const Market = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = () => {
			fetch('/api/market')
				.then(response => response.json())
				.then(data => {
					if (data.error) { // Handle session expiry or error message
						setData([]); // Reset data or handle the error
						console.error('Session expired or error:', data.error);
					} else if (Array.isArray(data)) {
						setData(data);  // Valid array response
					} else {
						setData([]);  // If data is not an array, reset or handle it
					}
				})
				.catch(error => {
					console.error('Error fetching market info:', error);
					setData([]);  // In case of network error, reset data
				});
		};

		fetchData();

		const intervalId = setInterval(fetchData, 60000);

		return () => clearInterval(intervalId);
	}, []);

	return (
		<div>
			{/* Metrics container */}
			<div style={styles.container}>
				{Array.isArray(data) && data.length > 0 ? (
					data.map((item, index) => (
						<Element
							key={index}
							name={item.symbol}
							price={item.lastprice}
							variation={item.variation}
						/>
					))
				) : (
					<p>No data available or loading...</p> // This message will display if data is empty or not loaded yet
				)}
			</div>
		</div>
	);
};

export default Market;
