import React, { useState, useEffect } from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';

const ProgressionBarPerformanceReview = ({ highest, lowest, stability, delay }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const calculateData = (recommendations) => {
            return recommendations.map(recommendation => ({
                name: recommendation.name,
                positive: (recommendation.value * 100).toFixed(2),  // Convert value between 0 and 1 to percentage (0-100)
                negative: (100 - (recommendation.value * 100)).toFixed(2)  // The complement to get the negative value
            }));
        };

        const recommendations = [
            { name: 'Highest', value: highest },
            { name: 'Lowest', value: lowest },
            { name: 'Stability', value: stability },
            { name: 'Delay', value: delay }
        ];

        setData(calculateData(recommendations));
    }, [highest, lowest, stability, delay]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#888', padding: '10px' }}>
                    {payload.map((entry, index) => (
                        <p key={index}>
                            <span style={{ color: entry.dataKey === 'positive' ? '#000' : '#FFF' }}>
                                {entry.dataKey === 'positive' ? 'Positive' : 'Negative'}: {typeof entry.value === 'number' ? entry.value.toFixed(2) + '%' : entry.value}
                            </span>
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={100}>
                <BarChart data={data} layout="vertical"> {/* Changed layout to vertical */}
                    <CartesianGrid horizontal={false} />
                    <XAxis type="number" hide /> {/* XAxis now is for numeric values */}
                    <YAxis type="category" dataKey="name" tick={{ fontSize: 7 }} /> {/* YAxis now is for categories */}
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="positive" fill="#000" stackId="a" label={{ position: 'inside', formatter: (value) => `${value}%` }} stroke="#000" strokeWidth={1} />
                    <Bar dataKey="negative" fill="#FFF" stackId="a" stroke="#000" strokeWidth={1} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProgressionBarPerformanceReview;
