import React from 'react';
import { MenuItem, Stack, TextField } from '@mui/material';

function ExchangePlatformSection({ values, handleChange, errors }) {
    return (
        <div>
            <Stack>
                <TextField
                    select
                    label="Select Exchange Platform"
                    value={values.exchange || ''}
                    onChange={(event) => handleChange('exchange', event.target.value)}
                    variant="filled"
                >
                    <MenuItem value="binance">Binance</MenuItem>
                    <MenuItem value="bitpanda">Bitpanda</MenuItem>
                    {/* Add more platforms as needed */}
                </TextField>
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    variant='filled'
                    type='api_key'
                    label='Api key'
                    value={values.api_key || ''}
                    onChange={(event) => handleChange('api_key', event.target.value)}
                    error={errors.api_key}
                    helperText={errors.api_key && 'Please insert a correct api key'}
                />
                <TextField
                    variant='filled'
                    type='secret_key'
                    label='Secret key'
                    value={values.secret_key || ''}
                    onChange={(event) => handleChange('secret_key', event.target.value)}
                    error={errors.secret_key}
                    helperText={errors.secret_key && 'Please insert a correct secret key'}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                <TextField
                    variant='filled'
                    type='initial_spot'
                    label='Funds (Spot)'
                    value={values.initial_spot || ''}
                    onChange={(event) => handleChange('initial_spot', event.target.value)}
                    error={errors.initial_spot}
                    helperText={errors.initial_spot && 'Please insert a correct number'}
                />
                <TextField
                    variant='filled'
                    type='initial_margin'
                    label='Funds (Margin)'
                    value={values.initial_margin || ''}
                    onChange={(event) => handleChange('initial_margin', event.target.value)}
                    error={errors.initial_margin}
                    helperText={errors.initial_margin && 'Please insert a correct number'}
                />
                <TextField
                    variant='filled'
                    type='initial_future'
                    label='Funds (Future)'
                    value={values.initial_future || ''}
                    onChange={(event) => handleChange('initial_future', event.target.value)}
                    error={errors.initial_future}
                    helperText={errors.initial_future && 'Please insert a correct number'}
                />
            </Stack>
        </div>
    );
}

export default ExchangePlatformSection;
