import React, { useState, useEffect, useContext,useRef } from "react";
import logo from './images/wild_15.png';
import './stylesheets/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars, faBell, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../App";
import Setting from "./Setting";
import Tutorial from "./Tutorial";
import LanguageSwitcher from "../LanguageSwitcher";
import {useTranslation} from "react-i18next";

const Header = () => {
    const { t } = useTranslation();
    const [menuVisible, setMenuVisible] = useState(false);
    const [notifications, setNotifications] = useState(0); // Initialize with 0
    const [notificationDetails, setNotificationDetails] = useState([]); // Store notification details
    const [showNotifications, setShowNotifications] = useState(false); // Toggle notifications dropdown
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
    const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);
    const [reason, setReason] = useState(""); // Reason for unsubscribing
    const [confirmText, setConfirmText] = useState(""); // Confirmation text
    const userContext = useContext(UserContext);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };
    const handleUnsubscribe = () => {
        // Open modal for confirmation and reason input
        setIsUnsubscribeOpen(true);
    };
    const handleConfirmUnsubscribe = async () => {
        if (confirmText !== "confirm") {
            alert(t("menu.unsubscribe_confirm_error"));
            return;
        }

        if (!reason) {
            alert(t("menu.unsubscribe_reason_required"));
            return;
        }

        // Send the unsubscribe request and reason to the backend
        await unsubscribeUserPlan();

        // Send email with the unsubscribe details
        await sendUnsubscribeEmail();

        // Close modal after action
        setIsUnsubscribeOpen(false);
    };

    const unsubscribeUserPlan = async () => {
        try {
            if (!userContext || !userContext.username) {
                alert(t("menu.unsubscribe_error_no_user"));
                console.error("Unsubscribe failed: userContext is missing", userContext);
                return;
            }

            if (!userContext.subscription_id) {
                alert(t("menu.unsubscribe_error_no_subscription"));
                console.error("Unsubscribe failed: Subscription ID is missing", userContext);
                return;
            }

            const data = {
                username: userContext.username,
                subscriptionId: userContext.subscription_id
            };

            console.log("Attempting to unsubscribe with data:", data);

            const response = await fetch('/api/unsubscribe', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                alert(t("menu.unsubscribe_success"));
                console.log("Unsubscribe successful");

                // Update userContext to reflect the change (set subscription_id to null)
                userContext.subscription_id = null;
                setMenuVisible(false);
            } else {
                const errorData = await response.json();
                console.error("Unsubscribe failed:", errorData);
                alert(t("menu.unsubscribe_failed"));
            }
        } catch (error) {
            console.error("Error unsubscribing:", error);
            alert(t("menu.unsubscribe_failed"));
        }
    };
    const sendUnsubscribeEmail = async () => {
        try {
            const emailData = {
                username: userContext.username,
                reason: reason
            };

            const response = await fetch('/api/send-unsubscribe-email', {
                method: 'POST',
                body: JSON.stringify(emailData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log("Unsubscribe email sent successfully.");
            } else {
                console.error("Failed to send unsubscribe email.");
            }
        } catch (error) {
            console.error("Error sending unsubscribe email:", error);
        }
    };
    const handleLogout = async () => {
        try {
            const response = await fetch('/api/logout', {
                method: 'DELETE',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log('Logout successful');
                window.location.href = '/login';
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    // Fetch notifications count when component mounts
    useEffect(() => {
        const fetchNotificationsCount = async () => {
            try {
                const response = await fetch('/api/notifications');
                const data = await response.json();
                setNotifications(data.count); // Use count from data
            } catch (error) {
                console.error('Failed to fetch notifications:', error);
            }
        };

        fetchNotificationsCount();
        // Optional: Set up a polling interval for periodic updates
        const interval = setInterval(fetchNotificationsCount, 120000); // Fetch every 2 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const handleNotificationClick = async () => {
        try {
            const response = await fetch('/api/notifications');
            const data = await response.json();
            console.log('Fetched notification details:', data); // Log the response data

            setNotificationDetails(data.notifications || []); // Set notifications or default to empty array
            setNotifications(data.count); // Update notification count based on fetched data
            setShowNotifications(!showNotifications); // Toggle the dropdown

            // If there are unread notifications, mark them as read
            if (data.count > 0) {
                await fetch('/api/notifications/mark-as-read', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });

                // Reset the notifications count to 0 after marking them as read
                setNotifications(0);
            }
        } catch (error) {
            console.error('Failed to fetch notifications or mark as read:', error);
        }
    };

    // Open the settings modal when the "Settings" menu item is clicked
    const handleSettingsClick = () => {
        setIsModalOpen(true);
    };

    return (
        <header className="header">
            <img src={logo} className="wildfire_logo" alt="logo" />

            <div className="icon-container">
                <LanguageSwitcher />
                <div className="notifications" onClick={handleNotificationClick}>
                    <FontAwesomeIcon icon={faBell} style={{ color: "#ffffff", paddingLeft: "10px" }} />
                    {notifications > 0 && (
                        <span className="notification-badge">{notifications}</span>
                    )}
                </div>

                {showNotifications && (
                    <div className="notification-dropdown">
                        <ul>
                            {notificationDetails.length > 0 ? (
                                notificationDetails.map((notification, index) => (
                                    <li key={index} className={notification.read ? "read-notification" : "unread-notification"}>
                                        <div className="notification-message">
                                            <span className="notification-timestamp">
                                                {new Date(notification.createdAt).toISOString().slice(0, 16).replace('T', ' ')}
                                            </span>
                                            <span style={{ paddingLeft: "5px" }}>{notification.message}</span>
                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li>No notifications</li>
                            )}
                        </ul>
                    </div>
                )}

                <div className={`menu-container ${menuVisible ? 'menu-visible' : ''}`}>
                    <button className="menu-button" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} style={{ color: "#ffffff" }} />
                    </button>

                    <div className="menu">
                        <ul className="menu-list">
                            <img src={`https://robohash.org/${userContext.username}?set=set1`} alt="User Avatar" style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
                            <div style={{ color: '#777' }}>{userContext.username}</div>
                            <div>
                                <span style={{ fontSize: '7px', display: "flex", justifyContent: "center", alignItems: "center", color: '#BBB' }}>
                                    {t("menu.level")}: 1
                                </span>
                            </div>

                            <li style={{ paddingTop: '20px' }}><a href="#" onClick={handleSettingsClick}>{t("menu.setting")}</a></li>
                            <li><a href="#">{t("menu.contact")}</a></li>
                            <li><a onClick={handleLogout}>{t("menu.logout")}</a></li>
                            <li><a href="#" onClick={() => setShowTutorial(true)}>{t("menu.tutorial")}</a></li>
                            <li style={{ paddingTop: '20px' }}>
                                {userContext.subscription_id !== null && (
                                    <a href="#" onClick={handleUnsubscribe} style={{ color: 'red' }}>
                                        {t("menu.unsubscribe")}
                                    </a>
                                )}
                            </li>
                        </ul>
                        {/* Show Tutorial only when triggered */}
                        {showTutorial && <Tutorial onClose={() => setShowTutorial(false)} />}

                    </div>
                </div>
            </div>

            {/* Modal for Unsubscribe */}
            {isUnsubscribeOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t("menu.unsubscribe_warning")}</h2>
                        <p>{t("menu.unsubscribe_confirm_message")}</p>
                        <input
                            type="text"
                            placeholder={t("menu.unsubscribe_reason_placeholder")}
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder={t("menu.confirm_text_placeholder")}
                            value={confirmText}
                            onChange={(e) => setConfirmText(e.target.value)}
                        />
                        <button onClick={handleConfirmUnsubscribe}>{t("menu.unsubscribe_confirm")}</button>
                        <button onClick={() => setIsUnsubscribeOpen(false)}>{t("button.cancel")}</button>
                    </div>
                </div>
            )}

            <Setting isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </header>
    );
};

export default Header;
