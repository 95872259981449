import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowTrendDown, faPause } from "@fortawesome/free-solid-svg-icons";
import indicatorNameMap from './IndicatorNameMapping';
import indicatorTimeframeMap from './IndicatorTimeframeMapping';
// Utility to generate color class based on recommendation value
const getColorClass = (value) => {
	if (value === -1) return "recommendation-red";
	if (value === 1) return "recommendation-green";
	return "recommendation-grey";
};

// Component for displaying recommendation icon and values
const RecommendationIcon = ({ value }) => {
	// Determine the icon based on the value
	const icon =
		value === 1 ? faArrowTrendUp : value === -1 ? faArrowTrendDown : faPause;
	const colorClass = getColorClass(value);
	return <FontAwesomeIcon icon={icon} className={`indicator-icon ${colorClass}`} />;
};

// Main CoinHistory component
const CoinHistory = ({ selectedCurrency, onCurrencyChange }) => {
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [technicalData, setTechnicalData] = useState(null);

	useEffect(() => {
		fetch("/api/coinhistory")
			.then((response) => response.json())
			.then((data) => {
				if (Array.isArray(data)) {
					setCurrencyOptions(data);
				}
			})
			.catch((error) => {
				console.error("Error fetching currency data:", error);
			});

		if (selectedCurrency) {
			fetch(`/api/coinhistory?currency=${selectedCurrency}`)
				.then((response) => response.json())
				.then((data) => {
					if (Array.isArray(data) && data.length > 0) {
						setTechnicalData(data[0].technical);
					} else {
						setTechnicalData(null);
					}
				})
				.catch((error) => {
					console.error("Error fetching technical data:", error);
					setTechnicalData(null);
				});
		} else {
			setTechnicalData(null);
		}
	}, [selectedCurrency]);


	const renderIndicators = (indicatorsList, timeframe) => {
		return indicatorsList.map((indicator, idx) => {
			const indicatorValue = technicalData ? technicalData[indicator] : null;
			const readableName = indicatorNameMap[indicator] || indicator; // Fallback to raw name
			return (
				<div key={idx} className="indicator-item">
					<RecommendationIcon value={indicatorValue} />
					<span className="indicator-name">{readableName}</span>
					<span className="indicator-value">
          {indicatorValue !== null ? indicatorValue : "N/A"}
        </span>
				</div>
			);
		});
	};

	const renderTimeframeColumn = (timeframe, indicators) => (
		<div className="timeframe-column">
			<h3 className="timeframe-title">{timeframe}</h3>
			{["OSCILLATORS","EMA", "CANDLESTICKS", "OTHER"].map((category, idx) => (
				<div key={idx} className="indicator-category">
					<h4 className="styled-heading-indicator">{category}</h4>
					<div className="indicators">{renderIndicators(indicators[category], timeframe)}</div>
				</div>
			))}
		</div>
	);

	return (
		<div className="linechart col-12 row-12 pad-right-10 border-radius">
			<div className="dashboard_title_2 col-12 s10 border-radius-top">Technical analysis</div>
				<div className="coinhistory-container">
					<div className="technical-analysis">
						<div className="currency-selector">
							<select
								className="currency-dropdown"
								value={selectedCurrency}
								onChange={(e) => onCurrencyChange(e.target.value)}
							>
								{currencyOptions.map((currency, idx) => (
									<option key={idx} value={currency}>
										{currency}
									</option>
								))}
							</select>
						</div>
						{technicalData && (
							<div className="timeframes">
								{Object.entries(indicatorTimeframeMap).map(([timeframe, indicators]) =>
									renderTimeframeColumn(timeframe, indicators)
								)}
							</div>
						)}
					</div>
				</div>
		</div>

	);
};

export default CoinHistory;
