import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ProgressionBarChart = () => {
    const [data, setData] = useState([]);
    const [containerHeight, setContainerHeight] = useState(400); // Initial height

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/markets'); // Replace '/api/data' with your API endpoint
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setData(jsonData.map(entry => ({
                    platform: entry.platform,
                    positive_pair: entry.positive_pair,
                    negative_pair: entry.negative_pair
                })));

                // Calculate the height dynamically based on the number of platform names
                const height = Math.max(400, jsonData.length * 20); // Minimum height of 300 pixels
                setContainerHeight(height);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#888', padding: '10px' }}>
                    {payload.map((entry, index) => (
                        <p key={index}>
                            <span style={{ color: entry.dataKey === 'positive_pair' ? '#000' : '#FFF' }}>
                                {entry.dataKey === 'positive_pair' ? 'Positive' : 'Negative'}: {entry.value}%
                            </span>
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div style={{padding: '5px'}}>
            <ResponsiveContainer width="100%" height={containerHeight}>
                <BarChart data={data} layout="vertical">
                    <CartesianGrid horizontal={false} />
                    <XAxis type="number" hide />
                    <YAxis type="category" dataKey="platform" tick={{ fontSize: 10 }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="positive_pair" fill="#000" stackId="a" label={{ position: 'center', formatter: (value) => `${value}%` }} stroke="#000" strokeWidth={1} />
                    <Bar dataKey="negative_pair" fill="#FFF" stackId="a" stroke="#000" strokeWidth={1} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProgressionBarChart;
