import React, { useState, useEffect, useContext } from "react";
import logo from './images/wild_15.png';
import './stylesheets/header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../App";

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [notifications, setNotifications] = useState(0); // Initialize with 0
    const [notificationDetails, setNotificationDetails] = useState([]); // Store notification details
    const [showNotifications, setShowNotifications] = useState(false); // Toggle notifications dropdown

    const userContext = useContext(UserContext);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const handleLogout = async () => {
        try {
            const response = await fetch('/api/logout', {
                method: 'DELETE',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log('Logout successful');
                window.location.href = '/login';
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    // Fetch notifications count when component mounts
    useEffect(() => {
        const fetchNotificationsCount = async () => {
            try {
                const response = await fetch('/api/notifications');
                const data = await response.json();
                setNotifications(data.count); // Use count from data
            } catch (error) {
                console.error('Failed to fetch notifications:', error);
            }
        };

        fetchNotificationsCount();
    }, []);

    const handleNotificationClick = async () => {
        try {
            const response = await fetch('/api/notifications');
            const data = await response.json();
            console.log('Fetched notification details:', data); // Log the response data

            setNotificationDetails(data.notifications || []); // Set notifications or default to empty array
            setNotifications(data.count); // Update notification count based on fetched data
            setShowNotifications(!showNotifications); // Toggle the dropdown

            // If there are unread notifications, mark them as read
            if (data.count > 0) {
                await fetch('/api/notifications/mark-as-read', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });

                // Reset the notifications count to 0 after marking them as read
                setNotifications(0);
            }
        } catch (error) {
            console.error('Failed to fetch notifications or mark as read:', error);
        }
    };

    return (
        <header className="header">
            <img src={logo} className="wildfire_logo" alt="logo" />

            <div className="icon-container">
                {/* Notification Icon */}
                <div className="notifications" onClick={handleNotificationClick}>
                    <FontAwesomeIcon icon={faBell} style={{ color: "#ffffff" }} />
                    {notifications > 0 && (
                        <span className="notification-badge">{notifications}</span>
                    )}
                </div>

                {/* Notification Dropdown */}
                {showNotifications && (
                    <div className="notification-dropdown">
                        <ul>
                            {notificationDetails.length > 0 ? (
                                notificationDetails.map((notification, index) => (
                                    <li
                                        key={index}
                                        className={notification.read ? "read-notification" : "unread-notification"}
                                    >
                                        <div className="notification-message">
                                            <span className="notification-timestamp">
                                                {new Date(notification.createdAt).toISOString().slice(0, 16).replace('T', ' ')}
                                            </span>
                                            <span style={{ paddingLeft: "5px" }}>{notification.message}</span>


                                        </div>
                                    </li>
                                ))
                            ) : (
                                <li>No notifications</li>
                            )}
                        </ul>
                    </div>
                )}

                {/* Menu Icon */}
                <div className={`menu-container ${menuVisible ? 'menu-visible' : ''}`}>
                    <button className="menu-button" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} style={{ color: "#ffffff" }} />
                    </button>

                    {/* Menu List */}
                    <div className="menu">
                        <ul className="menu-list">
                            <img
                                src={`https://robohash.org/${userContext.username}?set=set1`}
                                alt="User Avatar"
                                className="user-avatar"
                                style={{ width: '60px', height: '60px', borderRadius: '50%' }}
                            />
                            <div style={{ color:'#777'}}>{userContext.username}</div>
                            <div>
                                <span style={{ fontSize: '7px',display: "flex", justifyContent: "center", alignItems: "center",color:'#BBB'}}>
                                  Level: 1
                                </span>
                            </div>

                            <li style={{ paddingTop: '20px' }}><a href="#">Setting</a></li>
                            <li><a href="#">Contact</a></li>
                            <li><a onClick={handleLogout}>Logout</a></li>
                            <li><a href="#">Help</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
