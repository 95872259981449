import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrash, faSliders, faToggleOff} from "@fortawesome/free-solid-svg-icons";
import AddBotForm from "./AddBotForm";

const Element = ({ name, account_type, trade, timeframe,count_tp,count_sl,count_ind,take_profit,stoploss,amount,buy_indicator_value,sell_indicator_value,indicator_period,max_order,frequency,first_ma_value,second_ma_value,third_ma_value,fourth_ma_value,indicator, variation, benefits, state, botId,bot_type, onDelete }) => {
    const [currentState, setCurrentState] = useState(state);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [selectedBotId, setSelectedBotId] = useState(null);

    const variationColor = (variation < 50) ? '#f44336' : (variation >= 50 && variation <= 70 ? 'orange' : (variation > 70 ? '#4CAF50' : '#AAA'));
    const benefitsColor = benefits < 0 ? '#f44336' : (benefits > 0 ? '#4CAF50' : '#AAA');
    const circleColor = currentState === -1 ? 'grey' : (currentState === 1 ? '#00aa00' : '#ff0000');

    const handleOpenSetting = (botId) => {
        console.log(`Opening settings for bot with ID: ${botId}`);
        setSettingsModalOpen(true);
        setSelectedBotId(botId);
    };
    const handleIconClick = async (botId) => {
        // Determine the next state in the cycle 0 -> 1 -> -1 -> 0
        let newState;
        switch (currentState) {
            case 0:
                newState = 1;
                break;
            case 1:
                newState = -1;
                break;
            case -1:
                newState = 0;
                break;
            default:
                newState = 0;
        }

        // Update the state locally
        setCurrentState(newState);

        try {
            // Send a PUT request to your backend API to update the state in the database
            const response = await fetch(`/api/bots/${botId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ state: newState }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const updatedData = await response.json();
            // Handle the updated data if needed
            console.log(updatedData);
        } catch (error) {
            // Handle errors
            console.error('Failed to update database:', error);
        }
    };

    const handleDelete = async (botId) => {
        try {
            const response = await fetch(`/api/bots/${botId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to delete the bot');
            }

            const deletedBotData = await response.json();
            // Call the onDelete function passed from the parent component
            onDelete(botId);
            console.log(deletedBotData);
        } catch (error) {
            console.error('Error deleting bot:', error);
        }
    };

    return (
        <div style={styles.element}>
            <div style={styles.header}>
                <div style={styles.leftSection}>
                    <div style={styles.symbolContainer}>
                        <span style={styles.symbol}>{name}</span>
                        <span className="s10-portfolio_type" style={{ color: account_type === 'SPOT' ? '#f44336' : account_type === 'X-MARGIN' ? '#555' : '#AAA', display: 'block' }}>{account_type}</span>
                    </div>
                </div>
                <div style={styles.leftSection}>
                    <div style={styles.tradeDetails}>
                        <span style={styles.trade}>{trade} trades</span>
                        <span>{indicator} | {timeframe}</span>
                    </div>
                </div>
                <div style={styles.successInfo}>
                    <span><span style={styles.success}>Success : </span><span style={{ ...styles.variation, color: variationColor }}>{typeof variation === 'number' ? variation.toFixed(2) : variation}%</span></span>
                    <span><span style={styles.success}></span><span style={{ ...styles.benefits, color: benefitsColor }}> {typeof benefits === 'number' ? benefits.toFixed(2) : benefits} $</span></span>
                </div>
                <span className="control_portfolio" style={{ alignItems: "center",minWidth:"80px" }}>
                       <FontAwesomeIcon
                           icon={faSliders} // Replace this with the settings icon you want
                           size="sm"
                           style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                           onClick={() => handleOpenSetting(botId)}
                       />
                        <FontAwesomeIcon
                            icon={faTrash}
                            size="sm"
                            style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                            onClick={() => handleDelete(botId)} // Call handleDelete on click
                        />
                    {bot_type}
                    <FontAwesomeIcon
                        icon={faToggleOff}
                        size="xl"
                        style={{ color: circleColor, cursor: "pointer", marginLeft: '5px' }}
                        onClick={() => handleIconClick(botId)} // Pass botId to handleIconClick
                    />
                </span>
            </div>
            {settingsModalOpen && selectedBotId === botId && (
                <div className="form-container">
                    <div className="form-grid-detail-bots">
                        {[
                            { label: 'Amount', value: amount },
                            { label: 'Take Profit', value: take_profit },
                            { label: 'Stoploss', value: stoploss },
                            { label: 'Indicator', value: indicator },
                            { label: 'Trades', value: trade },
                            { label: 'TP', value: count_tp },
                            { label: 'IND', value: count_ind },
                            { label: 'SL', value: count_sl },
                            { label: 'Period', value: indicator_period },
                            { label: 'Buy value', value: buy_indicator_value },
                            { label: 'Sell value', value: sell_indicator_value },
                            { label: 'Max Order', value: max_order },
                            { label: 'Frequency', value: frequency },
                            { label: 'MA #1', value: first_ma_value },
                            { label: 'MA #2', value: second_ma_value },
                            { label: 'MA #3', value: third_ma_value },
                            { label: 'MA #4', value: fourth_ma_value }
                        ].map(({ label, value }, index) => (
                            <div className="form-group" key={index}>
                                <span style={styles.label}>{label}:</span>
                                <span style={styles.value}>{value !== null && value !== undefined ? value : 'N/A'}</span>
                            </div>
                        ))}
                        <button className="table_button" onClick={() => setSettingsModalOpen(false)}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};


const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '2px',
        width: '100%'
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center'
    },
    symbolContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    symbol: {
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#000',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)'
    },
    success: {
        fontSize: '9px',
        fontWeight: 'normal',
        color: '#444',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)'
    },
    accountType: {
        fontSize: '10px',
        color: '#888'
    },

    tradeDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '11px',
        color: '#AAA',
        textAlign: 'center',
        flexWrap: 'wrap',
        width: '100%'
    },
    trade: {
        color: '#555',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)',

    },
    successInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontSize: '10px'
    },
    statusInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontSize: '7px'
    },
    variation: {
        fontSize: '10px',
        fontWeight: 'bold'
    },
    benefits: {
        fontSize: '10px',
        fontWeight: 'bold',
        marginTop: '2px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        padding: '2px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    elementContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '2px',
        border: '1px solid #DDD',
        backgroundColor: '#f9f9f9',
        width: '100%'
    },
    element: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
    },
    label: {
        fontWeight: 'bold',
        color: '#555',
        fontSize: '9px',
    },
    value: {
        color: '#777',
        fontSize: '9px',
    }
};

const Bots = () => {
    const [data, setData] = useState([]);

    const fetchData = () => {
        fetch('/api/bots')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching symbol info:', error));
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 20000); // Fetch every 60 seconds
        return () => clearInterval(intervalId);
    }, []);

    const handleAddBot = (newBot) => {
        setData((prevData) => [...prevData, newBot]);
    };

    const handleDeleteBot = (botId) => {
        setData((prevData) => prevData.filter(bot => bot._id !== botId));
    };

    // Extract the symbols from the existing bots
    const existingSymbols = data.map(item => item.symbol);

    return (
        <div style={styles.container}>
            <AddBotForm onAddBot={handleAddBot} existingSymbols={existingSymbols} />
            {data.map((item, index) => (
                <div style={styles.elementContainer} key={index}>
                    <Element
                        name={item.symbol}
                        account_type={item.account_type}
                        trade={item.trades}
                        count_tp={item.count_tp}
                        count_sl={item.count_sl}
                        count_ind={item.count_ind}
                        take_profit={item.take_profit}
                        stoploss={item.stoploss}
                        amount={item.amount}
                        buy_indicator_value={item.buy_indicator_value}
                        sell_indicator_value={item.sell_indicator_value}
                        indicator_period={item.indicator_period}
                        max_order={item.max_order}
                        frequency={item.frequency}
                        first_ma_value={item.first_ma_value}
                        second_ma_value={item.second_ma_value}
                        third_ma_value={item.third_ma_value}
                        fourth_ma_value={item.fourth_ma_value}
                        timeframe={item.timeframe}
                        variation={item.rates}
                        benefits={item.benefits}
                        state={item.state}
                        bot_type={item.bot_type}
                        indicator={item.indicator}
                        botId={item._id}
                        onDelete={handleDeleteBot} // Pass the delete handler
                    />
                </div>
            ))}
        </div>
    );
};

export default Bots;

