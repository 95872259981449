// TableComponent.js
import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

const IndicatorStrategieBuy = () => {
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState({
        indicator: '',
        condition: '',
        value: '',
        minValue: '',
        maxValue: '',
    });
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const [EntryPrice, setEntryPrice] = useState([]);
    const [MaxCount, setMaxCount] = useState([]);
    const [BuyThreshold, setBuyThreshold] = useState([]);
    const [SellThreshold, setSellThreshold] = useState([]);
    const [StoplossThreshold, setStoplossThreshold] = useState([]);
    const [ProdEntryPrice, setProdEntryPrice] = useState([]);
    const [Multiplier, setMultiplier] = useState([]);
    const [SellType, setSellType] = useState([]);
    const [StoplossType, setStoplossType] = useState([]);
    const [ProdTotal, setProdTotal] = useState([]);
    const [selectedProdTotal, setSelectedProdTotal] = useState('');
    const isFloat = (value) => {
        return /^-?\d*\.?\d*$/.test(value);
    };
    const [indicatorsList, setIndicatorsList] = useState([]);
    const [error, setError] = useState('');
    const fetchData = async () => {
        try {
            const response = await fetch('/api/simulator');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const fetchIndicators = async () => {
        try {
            const response = await fetch('/api/simulator');
            const result = await response.json();
            const indicators = result.map((item) => item.indicator);
            setIndicatorsList(indicators);
        } catch (error) {
            console.error('Error fetching indicators:', error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchIndicators();
    }, []);

    const handleAdd = async () => {
        if (!newData.indicator || !newData.condition) {
            setError('Please fill out all fields.');
            return;
        }
        if (newData.condition === 'between') {
            if (!newData.minValue || !newData.maxValue) {
                setError('Please fill out all fields.');
                return;
            }
        } else {
            if (!newData.value) {
                setError('Please fill out all fields.');
                return;
            }
        }
        try {
            const username = getCookie('username'); // Get the username from the cookie
            let dataToAdd = { ...newData, username };
            if (newData.condition === 'between') {
                dataToAdd = { ...dataToAdd, value: `${newData.minValue} - ${newData.maxValue}` };
            }
            await fetch('/api/simulator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToAdd),
            });
            fetchData();
            fetchIndicators();
            setNewData({
                indicator: '',
                condition: '',
                value: '',
                minValue: '',
                maxValue: '',
            });
            setError('');
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };


    const handleRemove = async (simulatorId, indicatorId) => {
        try {
            await fetch(`/api/simulator/${simulatorId}/indicator/${indicatorId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            fetchData();
            fetchIndicators(); // Call fetchIndicators after removing data
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };


    const handleUpdate = async () => {
        try {
            // Loop through itemsSimulator to perform update for each item
            for (let index = 0; index < itemsSimulator.length; index++) {
                const simulatorId = itemsSimulator[index]._id;

                // Define the updated data for the simulator
                const updatedData = {
                    max_count: MaxCount[index], // Replace with the appropriate value or state
                    entry_price: EntryPrice[index], // Replace with the appropriate value or state
                    buy_threshold: BuyThreshold[index], // Replace with the appropriate value or state
                    sell_threshold: SellThreshold[index], // Replace with the appropriate value or state
                    stoploss_threshold: StoplossThreshold[index], // Replace with the appropriate value or state
                    multiplier: Multiplier[index], // Replace with the appropriate value or state
                    sell_type: SellType[index], // Replace with the appropriate value or state
                    stoploss_type: StoplossType[index], // Replace with the appropriate value or state
                    prod_entry_price: ProdEntryPrice[index],
                    prod_total: ProdTotal[index],
                };

                // Make a PUT request to update the simulator for the given ID
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${updatedSimulator}`);
                // Perform any necessary actions after successful update for each simulator
            }
            // Perform additional actions after updating all simulators, if needed
            alert('All simulators updated successfully');
        } catch (error) {
            console.error('Error updating simulators:', error);
            alert('Failed to update simulators. Please try again.');
        }
    };

    return (
        <div>

            <table id="alert-table">
                <tbody>
                {data.map((simulator) => (
                    <React.Fragment key={simulator._id}>
                        {simulator.indicators.map((item) => (
                            <tr key={item._id}>
                                <td>{item.indicator}</td>
                                <td>{item.condition}</td>
                                <td>{item.value}</td>
                                <td>
                                    <button className="alert_button"  onClick={() => handleRemove(simulator._id, item._id)}>
                                        Remove
                                    </button>
                                </td>

                            </tr>
                        ))}
                    </React.Fragment>
                ))}
                </tbody>
            </table>

            <div>
                <select
                    value={newData.indicator}
                    onChange={(e) => setNewData({ ...newData, indicator: e.target.value })}
                >
                    <option value="">Select Indicator</option>
                    {["RSI", "Rec_all","Rec_ma","Rec_other","CCI20","AO","ADX","MOM","MACD","UO","WR","Stoch_RSI","BBPower","KDJ", "Rec_uo", "Rec_vwma", "Rec_bbpower", "Rec_hull_ma9", "Rec_wr", "Rec_ichimoku", "Rec_stoch_rsi","kdj_cross","Rec_adx","Rec_cci","Rec_mom","Rec_rsi","Rec_macd","Rec_mfi","Rec_obv","Rec_vwap","Rec_morningstar","Rec_engulfing","Rec_hammer","Rec_tr","Rec_cmf","Rec_ema9_20","Rec_upperband","Rec_middleband","Rec_lowerband",'Price_direction','Rec_psar','Volatility','Rec_qstick','Mass','Rec_vortex','Rec_dm','Rec_dmi','Rec_wildfire','Death_cross_wf','Golden_cross_wf','Rec_ema0510_wf','Rec_ema100_wf','Rec_ema1020_wf','Rec_ema10_wf','Rec_ema1550_wf','Rec_ema15_wf','Rec_ema200_wf','Rec_ema20_wf','Rec_ema30_wf','Rec_ema50200_wf','Rec_ema50_wf','Rec_ema5_wf','Rec_golden_cross_wf','Rec_kdj_angle_wf','Rec_kdj_wf','Rec_macd_wf','Rec_psar_wf','Rec_rsi14_bull_wf','Rec_rsi30_bull_wf','Rec_rsi6_bull_wf','Rec_sma0510_wf','Rec_sma100_wf','Rec_sma1020_wf','Rec_sma10_wf','Rec_sma1550_wf','Rec_sma15_wf','Rec_sma200_wf','Rec_sma20_wf','Rec_sma30_wf','Rec_sma50_wf','Rec_sma5_wf','Rec_stochrsi_wf','Rec_vwma_wf','Rsi6_wf','Rsi14_wf','Rsi30_wf','Stochrsi_wf','Mastochrsi_wf','Macd_wf','Signal_wf','Macd_histogram_wf','Rec_j_wf']
                        .filter(
                            (indicator) =>
                                !indicatorsList.includes(indicator) &&
                                !data.some((simulator) =>
                                    simulator.indicators.some((item) => item.indicator === indicator)
                                )
                        )
                        .map((indicator) => (
                            <option key={indicator} value={indicator}>
                                {indicator}
                            </option>
                        ))}
                </select>

                <select
                    value={newData.condition}
                    onChange={(e) => setNewData({ ...newData, condition: e.target.value })}
                >
                    <option value="">Select Condition</option>
                    <option value="greater_than">Greater than</option>
                    <option value="lower_than">Lower than</option>
                    <option value="between">Between</option>
                </select>
                {newData.condition !== 'between' && (
                    <input
                        type="text"
                        value={newData.value}
                        onChange={(e) => {
                            if (isFloat(e.target.value)) {
                                setNewData({ ...newData, value: e.target.value });
                            }
                        }}
                    />
                )}
                {newData.condition === 'between' && (
                    <div>
                        <input
                            type="text"
                            placeholder="Min Value"
                            value={newData.minValue}
                            onChange={(e) => {
                                if (isFloat(e.target.value)) {
                                    setNewData({ ...newData, minValue: e.target.value });
                                }
                            }}
                        />
                        <input
                            type="text"
                            placeholder="Max Value"
                            value={newData.maxValue}
                            onChange={(e) => {
                                if (isFloat(e.target.value)) {
                                    setNewData({ ...newData, maxValue: e.target.value });
                                }
                            }}
                        />
                    </div>
                )}
                <button className="alert_button" onClick={handleAdd}>Add</button>
            </div>
            <div style={{ color: 'red' }}>{error}</div>
            <p></p>

            <button className="alert_button" onClick={handleUpdate}>Update</button>
            <p></p>
        </div>

    );
};

export default IndicatorStrategieBuy;
