import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
    .use(initReactI18next) // Integrate with React
    .use(LanguageDetector) // Detect browser language
    .use(HttpApi) // Load translations from /public/locales/
    .init({
        supportedLngs: ["en", "fr"],
        fallbackLng: "en",
        debug: false, // Set to true to debug issues
        detection: {
            order: ["localStorage", "navigator"], // Detect language automatically
            caches: ["localStorage"], // Save selected language in localStorage
        },
        backend: {
            loadPath: "/locales/{{lng}}.json", // Fetch translations dynamically
        },
    });

export default i18n;
