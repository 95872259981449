import React, { useEffect, useState } from 'react';
import { loadScript } from '@paypal/paypal-js';

const SecurePaymentComponent = () => {
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        const initializePayPal = async () => {
            try {
                // Load PayPal SDK asynchronously
                const paypalSdk = await loadScript({
                    'client-id': 'BAAehxLUl5ul6IGJC5cgdaWrQRBDIRLzu8SX-1QXLe8sR81sugoiwxZGWslu8U3eYzMFZ-LQsULSAocZrE',
                    components: 'hosted-buttons',
                    'disable-funding': 'venmo',
                    currency: 'EUR',
                });

                // Initialize Hosted Buttons
                paypalSdk.HostedButtons({
                    hostedButtonId: '3JU5XC2FBKW8C', // Replace with your hosted button ID
                }).render('#paypal-container-3JU5XC2FBKW8C');
            } catch (error) {
                console.error('PayPal SDK error:', error);
            }
        };

        initializePayPal();
    }, []);

    const handlePaymentSubmit = () => {
        setPaymentStatus('Payment processing... Please wait for confirmation.');

        // Example: Collecting options selected by the user
        const paypalForm = document.querySelector('#paypal-container-3JU5XC2FBKW8C form');
        const formData = new FormData(paypalForm);
        const selectedOptions = {};

        for (const [name, value] of formData.entries()) {
            selectedOptions[name] = value;
        }

        console.log('Selected options:', selectedOptions);
    };

    return (
        <div>
            <div id="paypal-container-3JU5XC2FBKW8C"></div>

            <button onClick={handlePaymentSubmit} style={{ marginTop: '20px' }}>
                Submit Payment
            </button>

            {paymentStatus && (
                <p style={{ marginTop: '10px' }}>{paymentStatus}</p>
            )}
        </div>
    );
};

export default SecurePaymentComponent;
