import React from "react";
import style from './stylesheets/footer.css'

const Footer = () => {

	return (
		<div class="footer text_align">
			<h7>Copyright - WildFire Powered by <a href="/" target="_blank">lepasc</a></h7>
		</div>
	)
}

export default Footer;