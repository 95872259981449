const indicatorTimeframeMap = {
    "15m": {
        OSCILLATORS: [
            "rsi6_15m_wf",
            "rec_rsi6_bull_15m_wf",
            "rsi14_15m_wf",
            "rec_rsi14_bull_15m_wf",
            "rsi30_15m_wf",
            "rec_rsi30_bull_15m_wf",
        ],
        EMA: ["rec_golden_cross_15m_wf","rec_death_cross_15m_wf","rec_ema0510_15m_wf","rec_ema1020_15m_wf","rec_ema1550_15m_wf","rec_ema50200_15m_wf","rec_ema719_15m_wf","rec_ema921_15m_wf","rec_ema725_15m_wf","rec_ema2599_15m_wf"],
        CANDLESTICKS: ["rec_doji_15m_wf","rec_hammer_15m_wf","rec_hanging_man_15m_wf","rec_morning_star_15m_wf","rec_evening_star_15m_wf","rec_shooting_star_15m_wf","rec_inverted_hammer_15m_wf","rec_three_inside_up_15m_wf","rec_three_inside_down_15m_wf","rec_three_white_soldiers_15m_wf","rec_three_black_crows_15m_wf","rec_engulfing_15m_wf"],
        OTHER: ["rec_vwma14_15m_wf", "rec_macd_12269_15m_wf"],
    },
    "1h": {
        OSCILLATORS: [
            "rsi6_1h_wf",
            "rec_rsi6_bull_1h_wf",
            "rsi14_1h_wf",
            "rec_rsi14_bull_1h_wf",
            "rsi30_1h_wf",
            "rec_rsi30_bull_1h_wf",
        ],
        EMA: ["rec_golden_cross_15m_wf","rec_death_cross_15m_wf","rec_ema0510_15m_wf","rec_ema1020_15m_wf","rec_ema1550_15m_wf","rec_ema50200_15m_wf","rec_ema719_15m_wf","rec_ema921_15m_wf","rec_ema725_15m_wf","rec_ema2599_15m_wf"],
        CANDLESTICKS: ["rec_doji_1h_wf","rec_hammer_1h_wf","rec_hanging_man_1h_wf","rec_morning_star_1h_wf","rec_evening_star_1h_wf","rec_shooting_star_1h_wf","rec_inverted_hammer_1h_wf","rec_three_inside_up_1h_wf","rec_three_inside_down_1h_wf","rec_three_white_soldiers_1h_wf","rec_three_black_crows_1h_wf","rec_engulfing_1h_wf"],
        OTHER: ["rec_vwma14_1h_wf", "rec_macd_12269_1h_wf"],
    },
    "1d": {
        OSCILLATORS: [
            "rsi6_1d_wf",
            "rec_rsi6_bull_1d_wf",
            "rsi14_1d_wf",
            "rec_rsi14_bull_1d_wf",
            "rsi30_1d_wf",
            "rec_rsi30_bull_1d_wf",
        ],
        EMA: ["rec_golden_cross_15m_wf","rec_death_cross_15m_wf","rec_ema0510_15m_wf","rec_ema1020_15m_wf","rec_ema1550_15m_wf","rec_ema50200_15m_wf","rec_ema719_15m_wf","rec_ema921_15m_wf","rec_ema725_15m_wf","rec_ema2599_15m_wf"],
        CANDLESTICKS: ["rec_doji_1d_wf","rec_hammer_1d_wf","rec_hanging_man_1d_wf","rec_morning_star_1d_wf","rec_evening_star_1d_wf","rec_shooting_star_1d_wf","rec_inverted_hammer_1d_wf","rec_three_inside_up_1d_wf","rec_three_inside_down_1d_wf","rec_three_white_soldiers_1d_wf","rec_three_black_crows_1d_wf","rec_engulfing_1d_wf"],
        OTHER: ["rec_vwma14_1d_wf", "rec_macd_12269_1d_wf"],
    },
};

export default indicatorTimeframeMap