// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle{border-radius:50%}

.avatar {
  width:80px;
  margin-left:16px!important;
  margin-right:16px!important;
  margin-top:2px!important;
  margin-bottom:2px!important;
  float:center;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/img.css"],"names":[],"mappings":"AAAA,QAAQ,iBAAiB;;AAEzB;EACE,UAAU;EACV,0BAA0B;EAC1B,2BAA2B;EAC3B,wBAAwB;EACxB,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":[".circle{border-radius:50%}\r\n\r\n.avatar {\r\n  width:80px;\r\n  margin-left:16px!important;\r\n  margin-right:16px!important;\r\n  margin-top:2px!important;\r\n  margin-bottom:2px!important;\r\n  float:center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
