export async function fetchWithAuth(url, options = {}) {
    try {
        const response = await fetch(url, {
            ...options,
            credentials: 'include', // Ensure cookies/session are included
        });

        if (response.status === 401) {
            //console.error('Session expired, redirecting...');
            window.location.href = '/'; // Ensure the redirect occurs only after handling the response
            return null; // Prevent further execution
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json(); // Parse JSON response
    } catch (error) {
        console.error(`Fetch failed: ${error.message}`);
        return null; // Return null on error
    }
}
