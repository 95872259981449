import React, {useEffect, useRef, useState, useCallback} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { regexPassword } from '../utils';
import avatar from './images/botmight_logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faRectangleXmark} from "@fortawesome/free-solid-svg-icons";
import ExchangePlatformSection from './ExchangePlatformSection';
import BinanceComponent from './BinanceComponent';
import BitpandaComponent from './BitpandaComponent';
import PaypalSubscribeBasic from "./PaypalSubscribeBasic";
import PaypalSubscribeEssential from "./PaypalSubscribeEssential";
import PaypalSubscribePremium from "./PaypalSubscribePremium";


import {
    Paper, Container, Link, Stack, Button, Box, Divider, Select, MenuItem, Avatar, Typography, TextField, FilledInput,
    InputAdornment, IconButton, InputLabel, FormControl, FormHelperText, Checkbox, FormControlLabel, Card, CardContent, Grid, Tooltip} from '@mui/material';
import {Visibility, VisibilityOff,CheckCircle, Warning, Cancel,WorkspacePremium, AutoGraph, MilitaryTech
} from '@mui/icons-material';
import TermsModal from "./TermsConditions";


function Signup() {
    const [exchange, setExchange] = useState('');
    const [values, setValues] = useState({
        country: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        repeatPassword: '',
        showPassword: false,
        showRepeatPassword: false,
    });
    const [errors, setErrors] = useState({
        email: false,
        username: false,
        phone: false,
        password: false,
        repeatPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
    });

    const [showTermsModal, setShowTermsModal] = useState(false);
    const termsModalRef = useRef(null);
    const [selectedPlan, setSelectedPlan] = useState('');
    const handleOpenTermsModal = () => setShowTermsModal(true);
    const handleCloseTermsModal = () => setShowTermsModal(false);
    const [isCGUApproved, setCGUApproved] = useState(false); // Track CGU approval
    const [platforms, setPlatforms] = useState([{ values: {exchange: ''}, errors: {} }]);
    const [showInfo, setShowInfo] = useState(false);
    const alertRef = useRef(null);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
    const [subscription_id, setSubscriptionId] = useState(null);
    const [plan_id, setPlanId] = useState(null);

    const plans = [
        { value: "basic", title: "Basic", price: "11.99€ / month", services: [
                { name: "Automated portfolio", availability: "full" },
                { name: "Market overview", availability: "full" },
                { name: "Technical analysis", availability: "full" },
                { name: "Long position only", availability: "partial" },
                { name: "Spot only", availability: "limited" },
                { name: "Strategy Builder (9 indicators, Virtual only, 10 symbols)", availability: "limited" },
                { name: "Automated Self Service ($10 per trade, 1 symbol)", availability: "limited" }
            ]},
        { value: "essential", title: "Essential", price: "24.99€ / month", services: [
                { name: "Automated portfolio", availability: "full" },
                { name: "Market overview", availability: "full" },
                { name: "Technical analysis", availability: "full" },
                { name: "Long position only", availability: "partial" },
                { name: "Spot & Cross margin", availability: "partial" },
                { name: "Strategy Builder (42 indicators, Virtual & Prod, 30 symbols)", availability: "partial" },
                { name: "Automated Self Service ($100 per trade, 5 symbols)", availability: "partial" }
            ]},
        { value: "premium", title: "Premium", price: "47.99€ / month", services: [
                { name: "Automated portfolio", availability: "full" },
                { name: "Market overview", availability: "full" },
                { name: "Technical analysis", availability: "full" },
                { name: "Long & Short position", availability: "full" },
                { name: "Spot, Cross & Isolated margin", availability: "full" },
                { name: "Strategy Builder (100+ indicators, Virtual & Prod, 100+ symbols)", availability: "full" },
                { name: "Automated Self Service (no limit per trade, 20 symbols)", availability: "full" }
            ]}
    ];

    const planIcons = {
        basic: <WorkspacePremium sx={{ color: "#1e88e5", fontSize: 40 }} />,
        essential: <AutoGraph sx={{ color: "#43a047", fontSize: 40 }} />,
        premium: <MilitaryTech sx={{ color: "#ffb300", fontSize: 40 }} />
    };

    const availabilityLegend = {
        full: "Fully Available",
        partial: "Partially Available",
        limited: "Limited Availability"
    };
    const handlePlanSelect = (planValue) => {
        handleChangePlan("selectedPlan", planValue);
    };

    const handlePaymentSuccess = useCallback((success, subscriptionID, planId) => {
        setPaymentSuccessful(success);
        setSubscriptionId(subscriptionID);
        setPlanId(planId); // Ensure planId is stored in state
    }, []);
    const handleChange = (index) => (fieldName, value) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms[index] = {
            ...updatedPlatforms[index],
            values: {
                ...updatedPlatforms[index].values,
                [fieldName]: value,
            },
        };
        setPlatforms(updatedPlatforms);
    };
    const handleChangePlatform = (field, value) => {
        // Update the state with the new value
        setExchange(value);
    };
    const handleChangePlan = (field, value) => {
        // Update the state with the new value
        setSelectedPlan(value);
    };
    const handleClose = () => {
        setShowInfo(false);
        setExchange("");
    };
    const handleHelpClick = () => {
        setShowInfo(true);
    };
    const handleChangeField = (fieldName) => (event) => {
        const currValue = event.target.value
        switch (fieldName) {
            case 'email':
                validator.isEmail(currValue)
                    ? setErrors({ ...errors, email: false })
                    : setErrors({ ...errors, email: true })
                break

            case 'password':
                regexPassword.test(currValue)
                    ? setErrors({ ...errors, password: false })
                    : setErrors({ ...errors, password: true })
                break

            case 'repeatPassword':
                currValue === values.password
                    ? setErrors({ ...errors, repeatPassword: false })
                    : setErrors({ ...errors, repeatPassword: true })
                break
        }
        setValues({ ...values, [fieldName]: event.target.value })
    }
    const handleShowPassword = (showPasswordField) => {
        setValues({
            ...values,
            [showPasswordField]: !values[showPasswordField],
        });
    };

    const handleAddPlatform = () => {
        setPlatforms([...platforms, { values: {exchange: ''}, errors: {} }]);
    };

    const handleRemovePlatform = (indexToRemove) => {
        setPlatforms(platforms.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if CGU approval and payment success status are valid
        if (!isCGUApproved) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'You must agree to the terms and conditions to register.',
            });
            return;
        }

        if (!isPaymentSuccessful) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'Payment is required before registration.',
            });
            return;
        }

        // Validate platform information
        const isValidPlatforms = platforms.every((platform) => {
            return (
                platform.values.exchange &&
                platform.values.initial_fund &&
                platform.values.api_key &&
                platform.values.secret_key
            );
        });

        if (!isValidPlatforms) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'All platform fields are required.',
            });
            return;
        }

        // Construct request body including the new CGU and payment fields
        const requestBody = {
            email: values.email,
            password: values.password,
            username: values.username,
            country: values.country,
            phone: values.phone,
            isCGUApproved: isCGUApproved,
            isPaymentSuccessful: isPaymentSuccessful,
            subscription_id: subscription_id,
            plan_id: plan_id,
            platforms: platforms.map((platform) => ({
                exchange: platform.values.exchange,
                initial_fund: platform.values.initial_fund,
                api_key: platform.values.api_key,
                secret_key: platform.values.secret_key,
            })),
        };

        try {
            const res = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!res.ok) {
                const error = await res.json();
                setErrors({
                    ...errors,
                    fetchError: true,
                    fetchErrorMsg: error.msg,
                });
                return;
            }

            const data = await res.json();
            setErrors({
                ...errors,
                fetchError: false, // Clear previous errors
                fetchErrorMsg: data.msg,
            });

            // Reset form fields after successful registration
            setValues({
                email: '',
                username: '',
                country: '',
                phone: '',
                password: '',
                repeatPassword: '',
                showPassword: false,
                showRepeatPassword: false,
            });

            // Reset platform data
            const resetPlatforms = platforms.map(platform => ({
                values: { exchange: '', initial_fund: '', api_key: '', secret_key: '' },
                errors: {},
            }));
            setPlatforms(resetPlatforms);

            setPaymentSuccessful(false);
            setCGUApproved(false);
            setSubscriptionId('');
            setPlanId('');
            setSelectedPlan('');

        } catch (error) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: 'There was a problem with our server, please try again later',
            });
        }
    };


    return (
        <>
            <Container sx={{ marginTop: '70px',marginBottom: '70px' }} maxWidth='sm'>
                <Paper elevation={6}>
                    <Container
                        maxWidth='sm'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                        }}>
                        <Avatar
                            alt="avatar"
                            src={avatar}
                            sx={{ width: 70, height: 70 }}
                        />
                        <h2>Register a new account</h2>
                    </Container>

                    <Stack
                        component='form'
                        onSubmit={handleSubmit}
                        noValidate
                        spacing={2}
                        sx={{ bgcolor: '#f5f5f6', padding: '20px', maxWidth: '100%' }}
                    >
                        <Container
                            maxWidth='sm'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '30px'
                            }}>
                            <h5>User profile</h5>
                        </Container>
                        <Divider />
                        {/* Personal Information Section */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                variant='filled'
                                type='email'
                                label='Email'
                                sx={{ width: "50%" }}
                                value={values.email || ''}
                                onChange={handleChangeField('email')}
                                error={errors.email}
                                helperText={errors.email && 'Please insert a valid email address'}
                            />
                            <TextField
                                variant='filled'
                                type='username'
                                label='Username'
                                sx={{ width: "50%" }}
                                value={values.username || ''}
                                onChange={handleChangeField('username')}
                                error={errors.username}
                                helperText={errors.username && 'Please insert a valid username'}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="flex-end">
                            <FormControl variant="filled" sx={{ width: "30%" }}>
                                <InputLabel id="country-label">Select Country</InputLabel>
                                <Select
                                    labelId="country-label"
                                    value={values.country || ''}
                                    onChange={handleChangeField('country')}
                                >
                                    <MenuItem value="US">United States</MenuItem>
                                    <MenuItem value="UK">United Kingdom</MenuItem>
                                    <MenuItem value="CA">Canada</MenuItem>
                                    <MenuItem value="AU">Australia</MenuItem>
                                    <MenuItem value="DE">Germany</MenuItem>
                                    <MenuItem value="FR">France</MenuItem>
                                    <MenuItem value="IT">Italy</MenuItem>
                                    <MenuItem value="ES">Spain</MenuItem>
                                    <MenuItem value="JP">Japan</MenuItem>
                                    <MenuItem value="CN">China</MenuItem>
                                    <MenuItem value="IN">India</MenuItem>
                                    <MenuItem value="BR">Brazil</MenuItem>
                                    <MenuItem value="ZA">South Africa</MenuItem>
                                    <MenuItem value="RU">Russia</MenuItem>
                                    <MenuItem value="MX">Mexico</MenuItem>
                                    <MenuItem value="KR">South Korea</MenuItem>
                                    <MenuItem value="NL">Netherlands</MenuItem>
                                    <MenuItem value="CH">Switzerland</MenuItem>
                                    <MenuItem value="SE">Sweden</MenuItem>
                                    <MenuItem value="SG">Singapore</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                variant='filled'
                                type='phone'
                                label='Phone'
                                value={values.phone || ''}
                                onChange={handleChangeField('phone')}
                                error={errors.phone}
                                helperText={errors.phone && 'Please insert a valid phone number'}
                                sx={{ width: "70%" }}
                            />
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-field'>Password</InputLabel>
                                <FilledInput
                                    id='password-field'
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password || ''}
                                    onChange={handleChangeField('password')}
                                    error={errors.password}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showPassword')}
                                                edge='end'>
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                <FormHelperText error={errors.password}>
                                    Password must be at least 8 characters, have one symbol, 1
                                    uppercase letter, 1 lowercase and 1 digit
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-repeat-field'>
                                    Repeat password
                                </InputLabel>
                                <FilledInput
                                    id='password-repeat-field'
                                    type={values.showRepeatPassword ? 'text' : 'password'}
                                    value={values.repeatPassword || ''}
                                    onChange={handleChangeField('repeatPassword')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showRepeatPassword')}
                                                edge='end'>
                                                {values.showRepeatPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {errors.repeatPassword && (
                                    <FormHelperText error={errors.repeatPassword}>
                                        Password must be the same as above
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        {/* Trading Information Section */}
                        <Container
                            maxWidth='sm'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '30px'
                            }}>
                            <h5>
                                API Management&nbsp;
                                <FontAwesomeIcon onClick={handleHelpClick} icon={faCircleQuestion} style={{ color: "#000000" }} />
                            </h5>
                            {showInfo && (
                                <div className="overlay" style={{ zIndex: '1000' }}>
                                    <div className="alert" ref={alertRef}>
                                                    <span className="close-icon" onClick={handleClose} style={{ position: 'absolute', top: '0', right: '0', padding: '2px' }}>
                                                        <FontAwesomeIcon icon={faRectangleXmark} size="xl" style={{ color: "#808080" }} />
                                                    </span>
                                        <div className="row-19 border-radius" style={{ position: 'relative' }}>
                                            <h2>Help Center</h2>
                                            <Divider />
                                            <div className="col-12 row-20">
                                                <Stack>
                                                    <TextField
                                                        select
                                                        label="Select Exchange Platform"
                                                        value={exchange}
                                                        onChange={(event) => handleChangePlatform('exchange', event.target.value)}
                                                        variant="filled"
                                                    >
                                                        <MenuItem value="binance">Binance</MenuItem>
                                                        {/*<MenuItem value="bitpanda">Bitpanda</MenuItem>*/}
                                                    </TextField>
                                                </Stack>
                                                <Divider />
                                                {exchange === 'binance' && <BinanceComponent />}
                                                {/*{exchange === 'bitpanda' && <BitpandaComponent />}*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Container>
                        <Divider />
                        {/* Render existing platform sections */}
                        {platforms.map((platform, index) => (
                            <div key={index}>
                                <ExchangePlatformSection
                                    values={platform.values}
                                    errors={platform.errors}
                                    handleChange={(field, value) => handleChange(index)(field, value)}
                                />
                                {/* <Button
                                    onClick={() => handleRemovePlatform(index)}
                                    style={{ float: 'right', fontSize: '6px' }}
                                >
                                    Remove Platform
                                </Button>*/}
                            </div>
                        ))}
                        {/* Add Platform Button */}
                        {/* <Button onClick={handleAddPlatform} style={{ fontSize: '12px' }}>
                            Add Platform
                        </Button>*/}





                        {/* Plan and Payment Section */}
                        <Container
                            maxWidth="sm"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '40px',
                            }}
                        >
                            <h5>Subscription and Payment</h5>
                        </Container>
                        <Divider />
                        {!isPaymentSuccessful ? (
                            <Stack alignItems="center" spacing={2}>
                                <div className="col-12 row-20">
                                    <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                        {plans.map((plan) => (
                                            <Grid item xs={12} sm={6} md={4} key={plan.value} sx={{ display: "flex" }}>
                                                <Card
                                                    onClick={() => handlePlanSelect(plan.value)}
                                                    sx={{
                                                        cursor: "pointer",
                                                        border: selectedPlan === plan.value ? "1px solid #d9363e" : "1px solid #e0e0e0",
                                                        boxShadow: selectedPlan === plan.value ? "0px 2px 6px rgba(217, 54, 62, 0.5)" : "0px 2px 6px rgba(0, 0, 0, 1)",
                                                        transition: "0.3s",
                                                        borderRadius: "16px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        height: "100%",
                                                        width: "100%",
                                                        "&:hover": {
                                                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                                                            transform: "translateY(-3px)"
                                                        }
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            flexGrow: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start",  // Ensure content aligns to the top
                                                            minHeight: "400px" // Set a fixed height for consistency
                                                        }}
                                                    >
                                                        {/* Icon and Title */}
                                                        <Box sx={{ textAlign: "center" }}>
                                                            <div>{planIcons[plan.value]}</div>
                                                            <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, fontSize: "1.5rem" }}>
                                                                {plan.title}
                                                            </Typography>
                                                            <Typography variant="body1" sx={{ color: "#d9363e", mt: 1, fontSize: "0.7rem" }}>
                                                                {plan.price}
                                                            </Typography>
                                                        </Box>

                                                        {/* Services List - aligned at the top */}
                                                        <Box sx={{ mt: 2, flexGrow: 1, paddingTop: "10px" }}>
                                                            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                                                {plan.services.map((service, index) => {
                                                                    let color;
                                                                    let IconComponent = CheckCircle;
                                                                    let legend = availabilityLegend[service.availability] || "Unknown Status";

                                                                    switch (service.availability) {
                                                                        case "full":
                                                                            color = "#4CAF50"; // Green
                                                                            break;
                                                                        case "partial":
                                                                            color = "#FFA500"; // Orange
                                                                            IconComponent = Warning;
                                                                            break;
                                                                        case "limited":
                                                                            color = "#D32F2F"; // Red
                                                                            IconComponent = Cancel;
                                                                            break;
                                                                        default:
                                                                            color = "#000"; // Default
                                                                    }

                                                                    return (
                                                                        <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                                                                            {/* Tooltip for legend information */}
                                                                            <Tooltip title={legend} arrow>
                                                                                <IconComponent sx={{ color, fontSize: "1rem", marginRight: "8px" }} />
                                                                            </Tooltip>
                                                                            <Typography variant="body2" color="textSecondary" sx={{ fontSize: "0.8rem" }}>
                                                                                {service.name}
                                                                            </Typography>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </div>

                                {selectedPlan === 'basic' && (
                                    <PaypalSubscribeBasic onSuccess={handlePaymentSuccess} />
                                )}
                                {selectedPlan === 'essential' && (
                                    <PaypalSubscribeEssential onSuccess={handlePaymentSuccess} />
                                )}
                                {selectedPlan === 'premium' && (
                                    <PaypalSubscribePremium onSuccess={handlePaymentSuccess} />
                                )}
                                <Divider />
                                <Divider />
                                <Button
                                    variant='contained'
                                    size='large'
                                    type='submit'
                                    sx={{
                                        minWidth: '70%',
                                    }}
                                    disabled={true}
                                >
                                    REGISTER
                                </Button>
                            </Stack>
                        ) : (
                            <Stack alignItems="center" spacing={2}>
                                <Typography color="green">
                                    Payment successful!
                                </Typography>
                                {/* Register Button and Login Link */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pt: 2,
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        size='large'
                                        type='submit'
                                        sx={{
                                            minWidth: '70%',
                                        }}
                                    >
                                        REGISTER
                                    </Button>
                                </Box>
                            </Stack>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCGUApproved}
                                    onChange={() => {
                                        if (!isCGUApproved) {
                                            handleOpenTermsModal(); // Open the modal on first click
                                        } else {
                                            setCGUApproved(false); // Uncheck if already checked
                                        }
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    Read and agree to the Terms and Conditions.

                                    {/* Modal for terms and conditions */}
                                    {showTermsModal && (
                                        <div className="modal" ref={termsModalRef}>
                                            <div className="modal-content">
                                                <button
                                                    onClick={() => {
                                                        handleCloseTermsModal();
                                                    }}
                                                    className="close-btn"
                                                >
                                                    X
                                                </button>
                                                <div className="terms-content">
                                                    <TermsModal />
                                                    <div className="terms-footer">
                                                        <button
                                                            onClick={() => {
                                                                handleCloseTermsModal();
                                                                setCGUApproved(true); // Tick the checkbox when the modal is closed
                                                            }}
                                                            className="update-btn"
                                                        >
                                                            I have read and agree to the Terms and Conditions.
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Typography>
                            }
                        />

                        {errors.fetchError && (
                            <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
                        )}
                        <Divider />
                        <Typography paragraph align='center'>
                            Already have an account?{' '}
                            <Link component={RouterLink} to='/'>
                                Login here
                            </Link>
                        </Typography>
                    </Stack>
                </Paper>
            </Container>
        </>
    );
}

export default Signup;
