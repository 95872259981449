import React, { useContext, useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from "../App";
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Select, MenuItem, Stack, FormControl, InputLabel, FilledInput, InputAdornment, IconButton, FormHelperText, Typography, Box, Grid
} from "@mui/material";
import validator from "validator";
import {regexPassword} from "../utils";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import ExchangePlatformSection from "./ExchangePlatformSection";
import {useTranslation} from "react-i18next";

const Setting = ({ isOpen, onClose, isLoading }) => {
    const { t } = useTranslation();
    const [UsersInfo, setUsersInfo] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [formData, setFormData] = useState({}); // Include password field here
    const [passwordError, setPasswordError] = useState(""); // Optional: To display password mismatch error
    const [emailError, setEmailError] = useState(""); // Error state for email validation
    const [phoneError, setPhoneError] = useState(""); // Error state for phone validation
    const userContext = useContext(UserContext);
    const [platforms, setPlatforms] = useState([{ values: { exchange: '' }, errors: {} }]);
    const [currentPlatformIndex, setCurrentPlatformIndex] = useState(0);

    const [errors, setErrors] = useState({
        email: false,
        username: false,
        phone: false,
        password: false,
        repeatPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
    });
    const fetchUsers = async () => {
        try {
            const response = await fetch("/api/users");
            const data = await response.json();
            setUsersInfo(data);
        } catch (error) {
            console.error("Error fetching users info:", error);
        }
    };

    useEffect(() => {
        if (isOpen) fetchUsers();
    }, [isOpen]);
    const handleChangeField = (fieldName) => (event) => {
        const currValue = event.target.value
        switch (fieldName) {
            case 'email':
                validator.isEmail(currValue)
                    ? setErrors({ ...errors, email: false })
                    : setErrors({ ...errors, email: true })
                break

            case 'password':
                regexPassword.test(currValue)
                    ? setErrors({ ...errors, password: false })
                    : setErrors({ ...errors, password: true })
                break

            case 'repeatPassword':
                currValue === formData.password
                    ? setErrors({ ...errors, repeatPassword: false })
                    : setErrors({ ...errors, repeatPassword: true })
                break
        }
        setFormData({ ...formData, [fieldName]: event.target.value })
    }
    const handleEdit = (item) => {
        setEditingUser(item.username);
        setFormData({
            email: item.email,
            phone: item.phone,
            country: item.country,
            password: "",
            repeatPassword: "",
        });

        // Ensure platforms from DB are correctly mapped to match UI state
        const initializedPlatforms = item.platforms?.length > 0
            ? item.platforms.map((p) => ({
                values: {
                    exchange: p.exchange || "",
                    initial_fund: p.initial_fund || 0,
                    api_key: p.api_key || "",
                    secret_key: p.secret_key || "",
                    apiExpiration: p.apiExpiration || new Date()
                },
                errors: {}
            }))
            : [{ values: { exchange: '', initial_fund: 0, api_key: '', secret_key: '', apiExpiration: new Date() }, errors: {} }];

        setPlatforms(initializedPlatforms);
        setCurrentPlatformIndex(0); // Default to first platform
    };




    const handleChange = (index) => (field, value) => {
        setPlatforms((prevPlatforms) => {
            const updatedPlatforms = [...prevPlatforms];
            updatedPlatforms[index] = {
                ...updatedPlatforms[index],
                values: {
                    ...updatedPlatforms[index].values,
                    [field]: value
                }
            };
            return updatedPlatforms;
        });
    };


    const validateInput = () => {
        let valid = true;

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            setEmailError("Invalid email address");
            valid = false;
        } else {
            setEmailError("");
        }

        if (!formData.phone || formData.phone.length < 10) {
            setPhoneError("Phone number must be at least 10 characters");
            valid = false;
        } else {
            setPhoneError("");
        }

        if (formData.password && formData.password !== formData.repeatPassword) {
            setPasswordError("Passwords do not match");
            valid = false;
        } else {
            setPasswordError("");
        }

        return valid;
    };
    const handleShowPassword = (showPasswordField) => {
        setFormData({
            ...formData,
            [showPasswordField]: !formData[showPasswordField],
        });
    };

    const handleUpdate = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            // Ensure platforms are formatted properly
            const updatedData = {
                ...formData,
                platforms: platforms.map((p) => ({
                    exchange: p.values.exchange || "",
                    initial_fund: p.values.initial_fund || 0,
                    api_key: p.values.api_key || "",
                    secret_key: p.values.secret_key || "",
                    apiExpiration: p.values.apiExpiration || new Date(),
                })),
            };

            const response = await fetch(`/api/users`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                alert("User info updated successfully");
                fetchUsers(); // Refresh users after update
                setEditingUser(null);
            } else {
                const error = await response.json();
                console.error(error.message);
                alert(`Update failed: ${error.message || "Unknown error"}`);
            }
        } catch (error) {
            console.error("Update error:", error);
            alert("An error occurred while updating.");
        }
    };



    if (!isOpen) return null;

    return (
        <>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{t("modal_title.user_setting")}</DialogTitle>
                <DialogContent sx={{ backgroundColor: 'white' }}>

                    {UsersInfo.length === 0 ? (
                        <div>No user data found.</div>
                    ) : (
                        UsersInfo.map((item) => (
                            <div key={item.username}>
                                {isLoading ? (
                                    <div className="loading">
                                        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                                        <p>Loading...</p>
                                    </div>
                                ) : (
                                    <>
                                        <div className="user-info">
                                            <img
                                                src={`https://robohash.org/${userContext.username}?set=set1`}
                                                alt="User Avatar"
                                                className="user-avatar"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                            <h3>{userContext.username}</h3>
                                        </div>
                                        {editingUser === item.username ? (
                                            <>
                                                <Stack
                                                    component='form'
                                                    spacing={2}

                                                >
                                                    <Stack direction="row" spacing={2}>
                                                        <TextField
                                                            variant='filled'
                                                            type='email'
                                                            label={t("profile.email")}
                                                            sx={{ width: "100%" }}
                                                            value={formData.email || ''}
                                                            onChange={handleChangeField('email')}
                                                            error={errors.email}
                                                            helperText={errors.email && 'Please insert a valid email address'}
                                                        />
                                                    </Stack>
                                                    <Stack direction="row" spacing={2} alignItems="flex-end">
                                                        <FormControl variant="filled" sx={{ width: "30%" }}>
                                                            <InputLabel id="country-label">{t("profile.country")}</InputLabel>
                                                            <Select
                                                                labelId="country-label"
                                                                value={formData.country || ''}
                                                                onChange={handleChangeField('country')}
                                                            >
                                                                <MenuItem value="AT">{t("countries.AT")}</MenuItem>
                                                                <MenuItem value="BE">{t("countries.BE")}</MenuItem>
                                                                <MenuItem value="BG">{t("countries.BG")}</MenuItem>
                                                                <MenuItem value="HR">{t("countries.HR")}</MenuItem>
                                                                <MenuItem value="CY">{t("countries.CY")}</MenuItem>
                                                                <MenuItem value="CZ">{t("countries.CZ")}</MenuItem>
                                                                <MenuItem value="DK">{t("countries.DK")}</MenuItem>
                                                                <MenuItem value="EE">{t("countries.EE")}</MenuItem>
                                                                <MenuItem value="FI">{t("countries.FI")}</MenuItem>
                                                                <MenuItem value="FR">{t("countries.FR")}</MenuItem>
                                                                <MenuItem value="DE">{t("countries.DE")}</MenuItem>
                                                                <MenuItem value="GR">{t("countries.GR")}</MenuItem>
                                                                <MenuItem value="HU">{t("countries.HU")}</MenuItem>
                                                                <MenuItem value="IE">{t("countries.IE")}</MenuItem>
                                                                <MenuItem value="IT">{t("countries.IT")}</MenuItem>
                                                                <MenuItem value="LV">{t("countries.LV")}</MenuItem>
                                                                <MenuItem value="LT">{t("countries.LT")}</MenuItem>
                                                                <MenuItem value="LU">{t("countries.LU")}</MenuItem>
                                                                <MenuItem value="MT">{t("countries.MT")}</MenuItem>
                                                                <MenuItem value="NL">{t("countries.NL")}</MenuItem>
                                                                <MenuItem value="PL">{t("countries.PL")}</MenuItem>
                                                                <MenuItem value="PT">{t("countries.PT")}</MenuItem>
                                                                <MenuItem value="RO">{t("countries.RO")}</MenuItem>
                                                                <MenuItem value="SK">{t("countries.SK")}</MenuItem>
                                                                <MenuItem value="SI">{t("countries.SI")}</MenuItem>
                                                                <MenuItem value="ES">{t("countries.ES")}</MenuItem>
                                                                <MenuItem value="SE">{t("countries.SE")}</MenuItem>
                                                                <MenuItem value="UK">{t("countries.UK")}</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        <TextField
                                                            variant='filled'
                                                            type='phone'
                                                            label={t("profile.phone")}
                                                            value={formData.phone || ''}
                                                            onChange={handleChangeField('phone')}
                                                            error={errors.phone}
                                                            helperText={errors.phone && 'Please insert a valid phone number'}
                                                            sx={{ width: "70%" }}
                                                        />
                                                    </Stack>
                                                    <Stack spacing={2}>
                                                        <FormControl variant='filled'>
                                                            <InputLabel htmlFor='password-field'>{t("profile.password")}</InputLabel>
                                                            <FilledInput
                                                                id='password-field'
                                                                type={formData.showPassword ? 'text' : 'password'}
                                                                value={formData.password || ''}
                                                                onChange={handleChangeField('password')}
                                                                error={errors.password}
                                                                endAdornment={
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='toggle password visibility'
                                                                            onClick={() => handleShowPassword('showPassword')}
                                                                            edge='end'>
                                                                            {formData.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />

                                                            <FormHelperText error={errors.password}>
                                                                Password must be at least 8 characters, have one symbol, 1
                                                                uppercase letter, 1 lowercase and 1 digit
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Stack>
                                                    <Stack spacing={2}>
                                                        <FormControl variant='filled'>
                                                            <InputLabel htmlFor='password-repeat-field'>
                                                                {t("profile.repeat_password")}
                                                            </InputLabel>
                                                            <FilledInput
                                                                id='password-repeat-field'
                                                                type={formData.showRepeatPassword ? 'text' : 'password'}
                                                                value={formData.repeatPassword || ''}
                                                                onChange={handleChangeField('repeatPassword')}
                                                                endAdornment={
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='toggle password visibility'
                                                                            onClick={() => handleShowPassword('showRepeatPassword')}
                                                                            edge='end'>
                                                                            {formData.showRepeatPassword ? (
                                                                                <VisibilityOff />
                                                                            ) : (
                                                                                <Visibility />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            {errors.repeatPassword && (
                                                                <FormHelperText error={errors.repeatPassword}>
                                                                    Password must be the same as above
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Stack>
                                                    {platforms.length > 1 && (
                                                        <select
                                                            value={currentPlatformIndex}
                                                            onChange={(e) => setCurrentPlatformIndex(Number(e.target.value))}
                                                        >
                                                            {platforms.map((_, index) => (
                                                                <option key={index} value={index}>
                                                                    Platform {index + 1}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    <ExchangePlatformSection
                                                        values={platforms[currentPlatformIndex]?.values || {}}
                                                        errors={platforms[currentPlatformIndex]?.errors || {}}
                                                        handleChange={(field, value) => handleChange(currentPlatformIndex)(field, value)}
                                                    />

                                                    {errors.fetchError && (
                                                        <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
                                                    )}

                                                    <DialogActions>
                                                        <Button onClick={handleUpdate} color="primary">
                                                            {t("button.save")}
                                                        </Button>
                                                        <Button onClick={() => setEditingUser(null)} sx={{ color: '#000' }}>
                                                            {t("button.cancel")}
                                                        </Button>
                                                    </DialogActions>
                                                </Stack>
                                            </>
                                        ) : (
                                            <>
                                                <Stack component="form" sx={{ padding: 2 }}>
                                                    {/* User Details Section */}
                                                    <Typography variant="h6" fontWeight="600" color="primary" gutterBottom>
                                                        {t("profile.user_details")}
                                                    </Typography>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Typography variant="body1" fontWeight="500"><strong>E-mail:</strong> {item.email}</Typography>
                                                        <Typography variant="body1" fontWeight="500"><strong>Phone:</strong> {item.phone}</Typography>
                                                        <Typography variant="body1" fontWeight="500"><strong>Country:</strong> {item.country}</Typography>
                                                        <Typography variant="body1" fontWeight="500">
                                                            <strong>{t("profile.created_account")}:</strong> {new Date(item.creation_date).toLocaleDateString('fr-FR')}
                                                        </Typography>
                                                        <Typography variant="body1" fontWeight="500">
                                                            <strong>{t("profile.free_trial_expiration")}:</strong> {new Date(item.freeTrialExpiration).toLocaleDateString('fr-FR')}
                                                        </Typography>
                                                    </Box>

                                                    {/* Platform Information */}
                                                    {item.platforms && item.platforms.length > 0 && (
                                                        <Box sx={{ borderTop: 2, borderColor: 'grey.300', paddingTop: 2, pt: 3 }}>

                                                            <Typography variant="h6" fontWeight="600" color="primary" gutterBottom>
                                                                {t("profile.platform_info")}
                                                            </Typography>
                                                            {item.platforms.map((platform, index) => (
                                                                <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body1" fontWeight="500">
                                                                            <strong>{t("profile.exchange")} {index + 1}:</strong> {platform.exchange}
                                                                        </Typography>
                                                                        <Typography variant="body1" fontWeight="500">
                                                                            <strong>{t("profile.initial_fund")}:</strong> {platform.initial_fund}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <Typography variant="body1" fontWeight="500">
                                                                            <strong>{t("profile.api_key_expiration")}:</strong> {new Date(platform.apiExpiration).toLocaleDateString('fr-FR')}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Box>
                                                    )}

                                                    {/* Edit Button */}
                                                    <Box sx={{ marginTop: 3 }}>
                                                        <Button
                                                            onClick={() => handleEdit(item)}
                                                            variant="contained"
                                                            color="primary"
                                                            fullWidth
                                                            sx={{ padding: '12px 0', fontSize: '16px' }}
                                                        >
                                                            {t("button.edit")}
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </>

                                        )}
                                    </>
                                )}
                            </div>
                        ))
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Setting;
