import React, { useEffect } from 'react';
import { loadScript } from '@paypal/paypal-js';

const PaypalSubscribe = ({ onSuccess }) => {
    const planId = 'P-1ES70193JW282635YM6CCKUY'; // Define the plan ID here

    useEffect(() => {
        const initializePayPal = async () => {
            try {
                const paypalSdk = await loadScript({
                    'client-id': 'ARGC-kno6poFvgCL4yk3pO6HxBG6I2mbKyqyle6H1XLr0XfpYm8dHy2F5zcFUAVx5yC1BbGx31H5Z8vC',
                    vault: true,
                    intent: 'subscription',
                });

                if (!paypalSdk) {
                    console.error('Failed to load PayPal SDK');
                    alert('An error occurred loading PayPal. Please try again.');
                    return;
                }

                const container = document.querySelector(`#paypal-button-container-${planId}`);
                if (!container) {
                    console.error('PayPal button container not found.');
                    return;
                }

                paypalSdk.Buttons({
                    style: { shape: 'rect', color: 'black', layout: 'horizontal', label: 'subscribe' },
                    createSubscription: (data, actions) => actions.subscription.create({ plan_id: planId }),
                    onApprove: (data, actions) => {
                        console.log('Subscription approved:', data);
                        onSuccess(true, data.subscriptionID, planId);
                    },
                    onError: (err) => {
                        console.error('PayPal button error:', err);
                        alert('An error occurred with the PayPal button.');
                        onSuccess(false);
                    },
                }).render(`#paypal-button-container-${planId}`);
            } catch (error) {
                console.error('PayPal SDK loading error:', error);
                alert('An error occurred initializing PayPal.');
            }
        };

        initializePayPal();
    }, [onSuccess, planId]);

    return (
        <div>
            <div id={`paypal-button-container-${planId}`}></div>
        </div>
    );
};

export default PaypalSubscribe;
