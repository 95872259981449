const IndicatorNameMappingSimulatorBasic = {
    // 15m Indicators
    "rsi14_15m_wf": "RSI (14) 15m",
    "rec_rsi14_bull_15m_wf": "RSI (14) Bullish 15m",
    "rec_ema1550_15m_wf": "EMA 1550 15m",


    // 1h Indicators
    "rsi14_1h_wf": "RSI (14) 1h",
    "rec_rsi14_bull_1h_wf": "RSI (14) Bullish 1h",
    "rec_ema1550_1h_wf": "EMA 1550 1h",

    // 1d Indicators
    "rsi14_1d_wf": "RSI (14) 1d",
    "rec_rsi14_bull_1d_wf": "RSI (14) Bullish 1d",
    "rec_ema1550_1d_wf": "EMA 1550 1d"

};
export default IndicatorNameMappingSimulatorBasic;