// TableComponent.js
import React, {useState, useEffect, useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faUpRightAndDownLeftFromCenter,faSpinner} from "@fortawesome/free-solid-svg-icons";
import ResultSimulator from "./ReportSimulator";
import FixedResultSimulator from "./FixedResultSimulator";
const TableComponent = () => {
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState({
        indicator: '',
        condition: '',
        value: '',
        minValue: '',
        maxValue: '',
    });

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItem2, setSelectedItem2] = useState(null);
    const [alertTriggered, setAlertTriggered] = useState(false);
    const alertRef = useRef(null); // Assuming you're using useRef for the alertRef
    const alertRef2 = useRef(null);
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const [BuyThresholdT1, setBuyThresholdT1] = useState([]);
    const [selectedBuyThresholdT1, setSelectedBuyThresholdT1] = useState('');
    const [BuyThresholdT2, setBuyThresholdT2] = useState([]);
    const [selectedBuyThresholdT2, setSelectedBuyThresholdT2] = useState('');
    const [BuyThresholdT3, setBuyThresholdT3] = useState([]);
    const [selectedBuyThresholdT3, setSelectedBuyThresholdT3] = useState('');
    const [BuyThresholdT4, setBuyThresholdT4] = useState([]);
    const [selectedBuyThresholdT4, setSelectedBuyThresholdT4] = useState('');
    const [BuyThresholdT5, setBuyThresholdT5] = useState([]);
    const [selectedBuyThresholdT5, setSelectedBuyThresholdT5] = useState('');
    const [MultiplierBuyT1, setMultiplierBuyT1] = useState([]);
    const [selectedMultiplierBuyT1, setSelectedMultiplierBuyT1] = useState('');
    const [MultiplierBuyT2, setMultiplierBuyT2] = useState([]);
    const [selectedMultiplierBuyT2, setSelectedMultiplierBuyT2] = useState('');
    const [MultiplierBuyT3, setMultiplierBuyT3] = useState([]);
    const [selectedMultiplierBuyT3, setSelectedMultiplierBuyT3] = useState('');
    const [MultiplierBuyT4, setMultiplierBuyT4] = useState([]);
    const [selectedMultiplierBuyT4, setSelectedMultiplierBuyT4] = useState('');
    const [MultiplierBuyT5, setMultiplierBuyT5] = useState([]);
    const [selectedMultiplierBuyT5, setSelectedMultiplierBuyT5] = useState('');
    const [SellThresholdT1, setSellThresholdT1] = useState([]);
    const [selectedSellThresholdT1, setSelectedSellThresholdT1] = useState('');
    const [SellThresholdT2, setSellThresholdT2] = useState([]);
    const [selectedSellThresholdT2, setSelectedSellThresholdT2] = useState('');
    const [SellThresholdT3, setSellThresholdT3] = useState([]);
    const [selectedSellThresholdT3, setSelectedSellThresholdT3] = useState('');
    const [SellThresholdT4, setSellThresholdT4] = useState([]);
    const [selectedSellThresholdT4, setSelectedSellThresholdT4] = useState('');
    const [SellThresholdT5, setSellThresholdT5] = useState([]);
    const [selectedSellThresholdT5, setSelectedSellThresholdT5] = useState('');
    const [MultiplierSellT1, setMultiplierSellT1] = useState([]);
    const [selectedMultiplierSellT1, setSelectedMultiplierSellT1] = useState('');
    const [MultiplierSellT2, setMultiplierSellT2] = useState([]);
    const [selectedMultiplierSellT2, setSelectedMultiplierSellT2] = useState('');
    const [MultiplierSellT3, setMultiplierSellT3] = useState([]);
    const [selectedMultiplierSellT3, setSelectedMultiplierSellT3] = useState('');
    const [MultiplierSellT4, setMultiplierSellT4] = useState([]);
    const [selectedMultiplierSellT4, setSelectedMultiplierSellT4] = useState('');
    const [MultiplierSellT5, setMultiplierSellT5] = useState([]);
    const [selectedMultiplierSellT5, setSelectedMultiplierSellT5] = useState('');
    const [statusVirtual, setStatusVirtual] = useState([]);
    const [statusListConsolidate, setStatusListConsolidate] = useState([]);
    const [statusListSell, setStatusListSell] = useState([]);
    const [statusListStoploss, setStatusListStoploss] = useState([]);
    const [statusProd, setStatusProd] = useState([]);
    const [criteriaList, setCriteriaList] = useState([]);

    const [EntryPrice, setEntryPrice] = useState([]);
    const [selectedEntryPrice, setSelectedEntryPrice] = useState('');
    const [MaxCount, setMaxCount] = useState([]);
    const [selectedMaxCount, setSelectedMaxCount] = useState('');
    const [StoplossThreshold, setStoplossThreshold] = useState([]);
    const [selectedStoplossThreshold, setSelectedStoplossThreshold] = useState('');
    const [VirtualType, setVirtualType] = useState([]);
    const [selectedVirtualType, setSelectedVirtualType] = useState('');
    const [ProdType, setProdType] = useState([]);
    const [selectedProdType, setSelectedProdType] = useState('');
    const [Amount, setSelectedAmount] = useState([]);
    const [selectedProdTotal, setSelectedProdTotal] = useState('');

    const isFloat = (value) => {
        return /^-?\d*\.?\d*$/.test(value);
    };
    const [indicatorsList, setIndicatorsList] = useState([]);
    const [error, setError] = useState('');
    const fetchData = async () => {
        try {
            const response = await fetch('/api/simulator');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleButtonClick = (e, item, targetItem) => {
        e.stopPropagation();
        if (targetItem === 'item') {
            setSelectedItem(item);
        } else if (targetItem === 'item2') {
            setSelectedItem2(item);
        }
        //alert("Item selected: " + item.id);
    };
    const handleClickOutside = (e) => {
        if (alertRef.current && !alertRef.current.contains(e.target)) {
            setSelectedItem(null);
        }
        if (alertRef2.current && !alertRef2.current.contains(e.target)) {
            setSelectedItem2(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleEntryPriceChange = (e, index) => {
        const newEntryPrice = [...EntryPrice];
        newEntryPrice[index] = e.target.value;
        setEntryPrice(newEntryPrice);
        setSelectedEntryPrice(e.target.value);
    };
    const handleMaxCountChange = (e, index) => {
        const newMaxCount = [...MaxCount];
        newMaxCount[index] = e.target.value;
        setMaxCount(newMaxCount);
        setSelectedMaxCount(e.target.value);
    };
    const handleThresholdChange = (e, index, thresholdArray, setThreshold, setSelectedThreshold, thresholdItem) => {
        const newThreshold = [...thresholdArray];
        newThreshold[index] = e.target.value;
        setThreshold(newThreshold);
        setSelectedThreshold(e.target.value);
    };

    const handleStoplossThresholdChange = (e, index) => {
        const newStoplossThreshold = [...StoplossThreshold];
        newStoplossThreshold[index] = e.target.value;
        setStoplossThreshold(newStoplossThreshold);
        setSelectedStoplossThreshold(e.target.value);
    };

    const handleVirtualTypeChange = (e, index) => {
        const newValue = e.target.value;
        const newVirtualType = [...VirtualType];
        newVirtualType[index] = newValue;
        setVirtualType(newVirtualType);
        setSelectedVirtualType(newValue);
        // Call handleModeUpdate function here with the updated value
        handleModeUpdate(newVirtualType, index, 'virtual_type');
    };
    const handleProdTypeChange = (e, index) => {
        const newProdType = [...ProdType];
        newProdType[index] = e.target.value;
        setProdType(newProdType);
        setSelectedProdType(e.target.value);
        // Call handleModeUpdate function here with the updated value
        handleModeUpdate(newProdType, index, 'prod_type');
    };
    const handleAmountChange = (e, index) => {
        const newAmount = [...Amount];
        newAmount[index] = e.target.value;
        setSelectedAmount(newAmount);
    };
    // Function to fetch simulator data and update statusList
    const fetchSimulatorDataAndUpdateStatus = async () => {
        try {
            const simulatorResponse = await fetch('/api/simulator');
            const simulatorData = await simulatorResponse.json();
            setItemsSimulator(simulatorData);

            const updatedStatusVirtual = simulatorData.map(item => (item.virtual_state === 1 ? 'online' : 'offline'));
            setStatusVirtual(updatedStatusVirtual);
            const updatedStatusProd = simulatorData.map(item => (item.prod_state === 1 ? 'online' : 'offline'));
            setStatusProd(updatedStatusProd);
        } catch (error) {
            console.error('Error fetching simulator data:', error);
        }
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const fetchIndicators = async () => {
        try {
            const response = await fetch('/api/simulator');
            const result = await response.json();
            const indicators = result.map((item) => item.indicator);
            setIndicatorsList(indicators);
        } catch (error) {
            console.error('Error fetching indicators:', error);
        }
    };

    function Countdown({ elapsed_time }) {
        const [remainingSeconds, setRemainingSeconds] = useState(getRemainingSeconds());

        // Update remaining seconds every second
        useEffect(() => {
            const intervalId = setInterval(() => {
                setRemainingSeconds(getRemainingSeconds());
            }, 1000);

            // Clear interval on component unmount
            return () => clearInterval(intervalId);
        }, []);

        // Function to calculate remaining seconds until the end of the minute
        function getRemainingSeconds() {
            const now = new Date();
            const endOfMinute = new Date(now);
            endOfMinute.setSeconds(59, 999); // Set the seconds to 59 and milliseconds to 999 (end of the minute)
            const timeDiff = endOfMinute.getTime() - now.getTime();
            return Math.ceil(timeDiff / 1000); // Convert milliseconds to seconds
        }

        return (
            <div>
                {(60-elapsed_time) < remainingSeconds ? (
                    <div><FontAwesomeIcon icon={faSpinner} spin spinReverse style={{"--fa-primary-color": "#000000", "--fa-secondary-color": "#000000",}} />&nbsp;
                        Running...
                    </div>
                ) : (
                    <div>
                        Next run in {remainingSeconds} seconds
                    </div>
                )}
            </div>
        );
    }


    useEffect(() => {
        fetchData();
        fetchIndicators();
        fetchSimulatorDataAndUpdateStatus(); // Fetch simulator data and update statusList
    }, []);

    const handleAdd = async () => {
        if (!newData.indicator || !newData.condition) {
            setError('Please fill out all fields.');
            return;
        }
        if (newData.condition === 'between') {
            if (!newData.minValue || !newData.maxValue) {
                setError('Please fill out all fields.');
                return;
            }
        } else {
            if (!newData.value) {
                setError('Please fill out all fields.');
                return;
            }
        }
        try {
            const username = getCookie('username'); // Get the username from the cookie
            let dataToAdd = { ...newData, username };
            if (newData.condition === 'between') {
                dataToAdd = { ...dataToAdd, value: `${newData.minValue} - ${newData.maxValue}` };
            }
            await fetch('/api/simulator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToAdd),
            });
            fetchData();
            fetchIndicators();
            setNewData({
                indicator: '',
                condition: '',
                value: '',
                minValue: '',
                maxValue: '',
            });
            setError('');
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };


    const handleRemove = async (simulatorId, indicatorId) => {
        try {
            await fetch(`/api/simulator/${simulatorId}/indicator/${indicatorId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            fetchData();
            fetchIndicators(); // Call fetchIndicators after removing data
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    const handleStatusChange = (list, setList, stateKey, index) => {
        const updatedStatusList = [...list];
        const updatedStatus = updatedStatusList[index] === 'online' ? 'offline' : 'online';
        updatedStatusList[index] = updatedStatus;
        setList(updatedStatusList);

        const simulatorId = itemsSimulator[index]._id;
        const updatedStatusValue = updatedStatus === 'online' ? 1 : 0;

        const requestBody = {};
        requestBody[stateKey] = updatedStatusValue;

        fetch(`/api/simulator/${simulatorId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((updatedData) => {
                console.log(updatedData); // Log the updated data received from the server
            })
            .catch((error) => {
                console.error('Failed to update simulator:', error);
            });
    };

// Initialize an object to store the previous max_count values
    const prevMaxCounts = {};

    const handleUpdate = async () => {
        try {
            // Loop through itemsSimulator to perform update for each item
            for (let index = 0; index < itemsSimulator.length; index++) {
                const simulatorId = itemsSimulator[index]._id;

                // Get the current max_count value
                const currentMaxCount = MaxCount[index];

                // Get the previous max_count value for this simulator
                const prevMaxCount = prevMaxCounts[simulatorId];

                // Define the updated data for the simulator
                const updatedData = {
                    max_count: currentMaxCount,
                    entry_price: EntryPrice[index], // Replace with the appropriate value or state
                    buy_t1: BuyThresholdT1[index], // Replace with the appropriate value or state
                    buy_t2: BuyThresholdT2[index], // Replace with the appropriate value or state
                    buy_t3: BuyThresholdT3[index], // Replace with the appropriate value or state
                    buy_t4: BuyThresholdT4[index], // Replace with the appropriate value or state
                    buy_t5: BuyThresholdT5[index], // Replace with the appropriate value or state
                    multiplier_buy_t1: MultiplierBuyT1[index],
                    multiplier_buy_t2: MultiplierBuyT2[index],
                    multiplier_buy_t3: MultiplierBuyT3[index],
                    multiplier_buy_t4: MultiplierBuyT4[index],
                    multiplier_buy_t5: MultiplierBuyT5[index],
                    sell_t1: SellThresholdT1[index], // Replace with the appropriate value or state
                    sell_t2: SellThresholdT2[index], // Replace with the appropriate value or state
                    sell_t3: SellThresholdT3[index], // Replace with the appropriate value or state
                    sell_t4: SellThresholdT4[index], // Replace with the appropriate value or state
                    sell_t5: SellThresholdT5[index], // Replace with the appropriate value or state
                    multiplier_sell_t1: MultiplierSellT1[index],
                    multiplier_sell_t2: MultiplierSellT2[index],
                    multiplier_sell_t3: MultiplierSellT3[index],
                    multiplier_sell_t4: MultiplierSellT4[index],
                    multiplier_sell_t5: MultiplierSellT5[index],
                    stoploss_t1: StoplossThreshold[index], // Replace with the appropriate value or state
                    virtual_type: VirtualType[index], // Replace with the appropriate value or state
                    prod_type: ProdType[index], // Replace with the appropriate value or state
                    amount: Amount[index],
                    first_run: currentMaxCount !== prevMaxCount ? 1 : 0, // Set first_run to 1 only if max_count has changed
                };

                // Store the current max_count value as the previous value for the next iteration
                prevMaxCounts[simulatorId] = currentMaxCount;

                // Make a PUT request to update the simulator for the given ID
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${updatedSimulator}`);
                // Perform any necessary actions after successful update for each simulator
            }
            // Perform additional actions after updating all simulators, if needed
            alert('All simulators updated successfully');
        } catch (error) {
            console.error('Error updating simulators:', error);
            alert('Failed to update simulators. Please try again.');
        }
    };

    const handleModeUpdate = async (newValue, index, type) => {
        try {
            // If the newValue is an array, convert it to a string
            const updatedValue = Array.isArray(newValue) ? newValue.join(', ') : newValue;

            // Loop through itemsSimulator to perform update for each item
            for (let i = 0; i < itemsSimulator.length; i++) {
                const simulatorId = itemsSimulator[i]._id;

                // Define the updated data for the simulator
                const updatedData = {
                    [type]: updatedValue,
                };

                // Make a PUT request to update the simulator for the given ID
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${JSON.stringify(updatedSimulator)}`);
                // Perform any necessary actions after successful update for each simulator
            }
            // Perform additional actions after updating all simulators, if needed
            alert('Mode changed successfully');
        } catch (error) {
            console.error('Error updating simulators:', error);
            alert('Failed to update simulators. Please try again.');
        }
    };
    return (
        <div>
            <div className="s10b">WildFire</div>
            <p></p>
            {itemsSimulator.map((item, index) => (
                <div key={index} className="col-12">
                    <div className="dashboard_title_2 s10-section">SETTINGS&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} size="lg" onClick={(e) => handleButtonClick(e, item, 'item2')} /></div>
                    <div style={{ margin: '5px' }} key={index} className="col-12">
                        <div className="col-4">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                BUY LIMIT
                            </div>
                            <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                {item.max_count}
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                AMOUNT
                            </div>
                            <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                {item.amount} $
                            </div>
                        </div>
                        <div className="col-4">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                STOPLOSS
                            </div>
                            <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                {item.stoploss_t1} %
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {itemsSimulator.map((item, index) => (
                <div key={index} className="col-12">
                    <div className="dashboard_title_2 s10-section">INDICATORS&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} size="lg" onClick={(e) => handleButtonClick(e, item, 'item')} /></div>
                    <div style={{ margin: '5px' }} key={index} className="col-12">
                        <div className="col-3">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                TOTAL
                            </div>
                            <div style={{ display: 'inline-block', color: '#555', fontSize: '16px' }}>
                                {item.indicators.length}
                            </div>

                        </div>
                        <div className="col-3">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                EMA
                            </div>
                            <div style={{ display: 'inline-block', color: '#555', fontSize: '16px' }}>
                                {/* Filter indicators array to get only items containing 'ema' */}
                                {item.indicators.filter(obj => obj.indicator && typeof obj.indicator === 'string' && obj.indicator.includes('ema')).length}
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                SMA
                            </div>
                            <div style={{ display: 'inline-block', color: '#555', fontSize: '16px' }}>
                                {/* Filter indicators array to get only items containing 'ema' */}
                                {item.indicators.filter(obj => obj.indicator && typeof obj.indicator === 'string' && obj.indicator.includes('sma')).length}
                            </div>
                        </div>
                        <div className="col-3">
                            <div style={{ color: '#777',fontSize:'8px' }}>
                                OSCILLATORS
                            </div>
                            <div style={{ display: 'inline-block', color: '#555', fontSize: '16px' }}>
                                {/* Filter indicators array to get only items containing 'ema' */}
                                {item.indicators.length-item.indicators.filter(obj => obj.indicator && typeof obj.indicator === 'string' && obj.indicator.includes('sma')).length-item.indicators.filter(obj => obj.indicator && typeof obj.indicator === 'string' && obj.indicator.includes('ema')).length}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {selectedItem && (
                <div className="overlay">
                    <div className="alert" ref={alertRef}>
                        <div className="card dashboard_text_3 row-19 border-radius">
                            <div className="dashboard_title_2 col-12 s10 border-radius-top">Indicators</div>
                            <select
                                value={newData.indicator}
                                onChange={(e) => setNewData({ ...newData, indicator: e.target.value })}
                            >
                                <option value="">Select Indicator</option>
                                {[
                                    "rec_sma_15m_wf","rec_ema_15m_wf","rec_ma_15m_wf","rsi5_15m_wf","rec_rsi5_bull_15m_wf","rec_stochrsi5_15m_wf","rsi6_15m_wf",
                                    "rec_rsi6_bull_15m_wf","rec_stochrsi6_15m_wf","rsi7_15m_wf","rec_rsi7_bull_15m_wf", "rec_stochrsi7_15m_wf","rsi9_15m_wf","rec_rsi9_bull_15m_wf",
                                    "rec_stochrsi9_15m_wf","rsi14_15m_wf","rec_rsi14_bull_15m_wf","rec_stochrsi14_15m_wf","rsi30_15m_wf","rec_rsi30_bull_15m_wf","rec_stochrsi30_15m_wf"
                                    ,"rec_kdj_9_15m_wf","rec_dmi_30_15m_wf", "rec_sma0510_15m_wf","rec_sma1020_15m_wf","rec_sma1550_15m_wf","rec_golden_cross_15m_wf","rec_death_cross_15m_wf",
                                    "rec_ema0510_15m_wf","rec_ema1020_15m_wf","rec_ema1550_15m_wf","rec_ema50200_15m_wf","rec_ema719_15m_wf","rec_ema725_15m_wf","rec_ema2599_15m_wf",
                                    "rec_psar_15m_wf","rec_doji_15m_wf","rec_hammer_15m_wf","rec_hanging_man_15m_wf","rec_morning_star_15m_wf","rec_evening_star_15m_wf","rec_shooting_star_15m_wf",
                                    "rec_inverted_hammer_15m_wf","rec_three_inside_up_15m_wf","rec_three_inside_down_15m_wf","rec_three_white_soldiers_15m_wf","rec_three_black_crows_15m_wf",
                                    "rec_engulfing_15m_wf","rec_vwma14_15m_wf","rec_macd_12269_15m_wf","rec_macd_245218_15m_wf","rec_macd_4810436_15m_wf","rec_macd_6134_15m_wf","Recommend_Other_15m_wf",
                                    "Recommend_All_15m_wf", "Recommend_MA_15m_wf","CCI20_15m_wf","Rec_Stoch_RSI_15m_wf","Rec_WR_15m_wf","Rec_BBPower_15m_wf","BBPower_15m_wf",
                                    "Rec_UO_15m_wf","Rec_Ichimoku_15m_wf","Rec_VWMA_15m_wf","Rec_HullMA9_15m_wf","rec_BB_lower_15m_wf","rec_BB_upper_15m_wf",
                                    "change_15m_wf","rec_pivot_15m_wf","rec_cci20_15m_wf",
                                    "btc_rec_sma_15m_wf","btc_rec_ema_15m_wf","btc_rec_ma_15m_wf","btc_rsi5_15m_wf","btc_rec_rsi5_bull_15m_wf","btc_rec_stochrsi5_15m_wf","btc_rsi6_15m_wf",
                                    "btc_rec_rsi6_bull_15m_wf","btc_rec_stochrsi6_15m_wf","btc_rsi7_15m_wf","btc_rec_rsi7_bull_15m_wf", "btc_rec_stochrsi7_15m_wf","btc_rsi9_15m_wf","btc_rec_rsi9_bull_15m_wf",
                                    "btc_rec_stochrsi9_15m_wf","btc_rsi14_15m_wf","btc_rec_rsi14_bull_15m_wf","btc_rec_stochrsi14_15m_wf","btc_rsi30_15m_wf","btc_rec_rsi30_bull_15m_wf","btc_rec_stochrsi30_15m_wf"
                                    ,"btc_rec_kdj_9_15m_wf","btc_rec_dmi_30_15m_wf", "btc_rec_sma0510_15m_wf","btc_rec_sma1020_15m_wf","btc_rec_sma1550_15m_wf","btc_rec_golden_cross_15m_wf","btc_rec_death_cross_15m_wf",
                                    "btc_rec_ema0510_15m_wf","btc_rec_ema1020_15m_wf","btc_rec_ema1550_15m_wf","btc_rec_ema50200_15m_wf","btc_rec_ema719_15m_wf","btc_rec_ema725_15m_wf","btc_rec_ema2599_15m_wf",
                                    "btc_rec_psar_15m_wf","btc_rec_doji_15m_wf","btc_rec_hammer_15m_wf","btc_rec_hanging_man_15m_wf","btc_rec_morning_star_15m_wf","btc_rec_evening_star_15m_wf","btc_rec_shooting_star_15m_wf",
                                    "btc_rec_inverted_hammer_15m_wf","btc_rec_three_inside_up_15m_wf","btc_rec_three_inside_down_15m_wf","btc_rec_three_white_soldiers_15m_wf","btc_rec_three_black_crows_15m_wf",
                                    "btc_rec_engulfing_15m_wf","btc_rec_vwma14_15m_wf","btc_rec_macd_12269_15m_wf","btc_rec_macd_245218_15m_wf","btc_rec_macd_4810436_15m_wf","btc_rec_macd_6134_15m_wf","btc_Recommend_Other_15m_wf",
                                    "btc_Recommend_All_15m_wf", "btc_Recommend_MA_15m_wf","btc_CCI20_15m_wf","btc_rec_Stoch_RSI_15m_wf","btc_rec_WR_15m_wf","btc_rec_BBPower_15m_wf","btc_BBPower_15m_wf",
                                    "btc_rec_UO_15m_wf","btc_rec_Ichimoku_15m_wf","btc_rec_VWMA_15m_wf","btc_rec_HullMA9_15m_wf","btc_rec_BB_lower_15m_wf","btc_rec_BB_upper_15m_wf",
                                    "btc_change_15m_wf","btc_rec_pivot_15m_wf","btc_rec_cci20_15m_wf",

                                    "rec_sma_1h_wf","rec_ema_1h_wf","rec_ma_1h_wf","rsi5_1h_wf","rec_rsi5_bull_1h_wf","rec_stochrsi5_1h_wf","rsi6_1h_wf",
                                    "rec_rsi6_bull_1h_wf","rec_stochrsi6_1h_wf","rsi7_1h_wf","rec_rsi7_bull_1h_wf", "rec_stochrsi7_1h_wf","rsi9_1h_wf","rec_rsi9_bull_1h_wf",
                                    "rec_stochrsi9_1h_wf","rsi14_1h_wf","rec_rsi14_bull_1h_wf","rec_stochrsi14_1h_wf","rsi30_1h_wf","rec_rsi30_bull_1h_wf","rec_stochrsi30_1h_wf"
                                    ,"rec_kdj_9_1h_wf","rec_dmi_30_1h_wf", "rec_sma0510_1h_wf","rec_sma1020_1h_wf","rec_sma1550_1h_wf","rec_golden_cross_1h_wf","rec_death_cross_1h_wf",
                                    "rec_ema0510_1h_wf","rec_ema1020_1h_wf","rec_ema1550_1h_wf","rec_ema50200_1h_wf","rec_ema719_1h_wf","rec_ema725_1h_wf","rec_ema2599_1h_wf",
                                    "rec_psar_1h_wf","rec_doji_1h_wf","rec_hammer_1h_wf","rec_hanging_man_1h_wf","rec_morning_star_1h_wf","rec_evening_star_1h_wf","rec_shooting_star_1h_wf",
                                    "rec_inverted_hammer_1h_wf","rec_three_inside_up_1h_wf","rec_three_inside_down_1h_wf","rec_three_white_soldiers_1h_wf","rec_three_black_crows_1h_wf",
                                    "rec_engulfing_1h_wf","rec_vwma14_1h_wf","rec_macd_12269_1h_wf","rec_macd_245218_1h_wf","rec_macd_4810436_1h_wf","rec_macd_6134_1h_wf","Recommend_Other_1h_wf",
                                    "Recommend_All_1h_wf", "Recommend_MA_1h_wf","CCI20_1h_wf","Rec_Stoch_RSI_1h_wf","Rec_WR_1h_wf","Rec_BBPower_1h_wf","BBPower_1h_wf",
                                    "Rec_UO_1h_wf","Rec_Ichimoku_1h_wf","Rec_VWMA_1h_wf","Rec_HullMA9_1h_wf","rec_BB_lower_1h_wf","rec_BB_upper_1h_wf",
                                    "change_1h_wf","rec_pivot_1h_wf","rec_cci20_1h_wf",
                                    "btc_rec_sma_1h_wf","btc_rec_ema_1h_wf","btc_rec_ma_1h_wf","btc_rsi5_1h_wf","btc_rec_rsi5_bull_1h_wf","btc_rec_stochrsi5_1h_wf","rsi6_1h_wf",
                                    "btc_rec_rsi6_bull_1h_wf","btc_rec_stochrsi6_1h_wf","btc_rsi7_1h_wf","btc_rec_rsi7_bull_1h_wf", "btc_rec_stochrsi7_1h_wf","btc_rsi9_1h_wf","btc_rec_rsi9_bull_1h_wf",
                                    "btc_rec_stochrsi9_1h_wf","btc_rsi14_1h_wf","btc_rec_rsi14_bull_1h_wf","btc_rec_stochrsi14_1h_wf","btc_rsi30_1h_wf","btc_rec_rsi30_bull_1h_wf","btc_rec_stochrsi30_1h_wf",
                                    "btc_rec_kdj_9_1h_wf","btc_rec_dmi_30_1h_wf", "btc_rec_sma0510_1h_wf","btc_rec_sma1020_1h_wf","btc_rec_sma1550_1h_wf","btc_rec_golden_cross_1h_wf","btc_rec_death_cross_1h_wf",
                                    "btc_rec_ema0510_1h_wf","btc_rec_ema1020_1h_wf","btc_rec_ema1550_1h_wf","btc_rec_ema50200_1h_wf","btc_rec_ema719_1h_wf","btc_rec_ema725_1h_wf","btc_rec_ema2599_1h_wf",
                                    "btc_rec_psar_1h_wf","btc_rec_doji_1h_wf","btc_rec_hammer_1h_wf","btc_rec_hanging_man_1h_wf","btc_rec_morning_star_1h_wf","btc_rec_evening_star_1h_wf","btc_rec_shooting_star_1h_wf",
                                    "btc_rec_inverted_hammer_1h_wf","btc_rec_three_inside_up_1h_wf","btc_rec_three_inside_down_1h_wf","btc_rec_three_white_soldiers_1h_wf","btc_rec_three_black_crows_1h_wf",
                                    "btc_rec_engulfing_1h_wf","btc_rec_vwma14_1h_wf","btc_rec_macd_12269_1h_wf","btc_rec_macd_245218_1h_wf","btc_rec_macd_4810436_1h_wf","btc_rec_macd_6134_1h_wf","btc_Recommend_Other_1h_wf",
                                    "btc_Recommend_All_1h_wf", "btc_Recommend_MA_1h_wf","btc_CCI20_1h_wf","btc_rec_Stoch_RSI_1h_wf","btc_rec_WR_1h_wf","btc_rec_BBPower_1h_wf","btc_BBPower_1h_wf",
                                    "btc_rec_UO_1h_wf","btc_rec_Ichimoku_1h_wf","btc_rec_VWMA_1h_wf","btc_rec_HullMA9_1h_wf","btc_rec_BB_lower_1h_wf","btc_rec_BB_upper_1h_wf",
                                    "btc_change_1h_wf","btc_rec_pivot_1h_wf","btc_rec_cci20_1h_wf",

                                    "rec_sma_1d_wf","rec_ema_1d_wf","rec_ma_1d_wf","rsi5_1d_wf","rec_rsi5_bull_1d_wf","rec_stochrsi5_1d_wf","rsi6_1d_wf",
                                    "rec_rsi6_bull_1d_wf","rec_stochrsi6_1d_wf","rsi7_1d_wf","rec_rsi7_bull_1d_wf", "rec_stochrsi7_1d_wf","rsi9_1d_wf","rec_rsi9_bull_1d_wf",
                                    "rec_stochrsi9_1d_wf","rsi14_1d_wf","rec_rsi14_bull_1d_wf","rec_stochrsi14_1d_wf","rsi30_1d_wf","rec_rsi30_bull_1d_wf","rec_stochrsi30_1d_wf"
                                    ,"rec_kdj_9_1d_wf","rec_dmi_30_1d_wf", "rec_sma0510_1d_wf","rec_sma1020_1d_wf","rec_sma1550_1d_wf","rec_golden_cross_1d_wf","rec_death_cross_1d_wf",
                                    "rec_ema0510_1d_wf","rec_ema1020_1d_wf","rec_ema1550_1d_wf","rec_ema50200_1d_wf","rec_ema719_1d_wf","rec_ema725_1d_wf","rec_ema2599_1d_wf",
                                    "rec_psar_1d_wf","rec_doji_1d_wf","rec_hammer_1d_wf","rec_hanging_man_1d_wf","rec_morning_star_1d_wf","rec_evening_star_1d_wf","rec_shooting_star_1d_wf",
                                    "rec_inverted_hammer_1d_wf","rec_three_inside_up_1d_wf","rec_three_inside_down_1d_wf","rec_three_white_soldiers_1d_wf","rec_three_black_crows_1d_wf",
                                    "rec_engulfing_1d_wf","rec_vwma14_1d_wf","rec_macd_12269_1d_wf","rec_macd_245218_1d_wf","rec_macd_4810436_1d_wf","rec_macd_6134_1d_wf","Recommend_Other_1d_wf",
                                    "Recommend_All_1d_wf", "Recommend_MA_1d_wf","CCI20_1d_wf","Rec_Stoch_RSI_1d_wf","Rec_WR_1d_wf","Rec_BBPower_1d_wf","BBPower_1d_wf",
                                    "Rec_UO_1d_wf","Rec_Ichimoku_1d_wf","Rec_VWMA_1d_wf","Rec_HullMA9_1d_wf","rec_BB_lower_1d_wf","rec_BB_upper_1d_wf",
                                    "change_1d_wf","rec_pivot_1d_wf","rec_cci20_1d_wf",
                                    "btc_rec_sma_1d_wf","btc_rec_ema_1d_wf","btc_rec_ma_1d_wf","btc_rsi5_1d_wf","btc_rec_rsi5_bull_1d_wf","btc_rec_stochrsi5_1d_wf","btc_rsi6_1d_wf",
                                    "btc_rec_rsi6_bull_1d_wf","btc_rec_stochrsi6_1d_wf","btc_rsi7_1d_wf","btc_rec_rsi7_bull_1d_wf", "btc_rec_stochrsi7_1d_wf","btc_rsi9_1d_wf","btc_rec_rsi9_bull_1d_wf",
                                    "btc_rec_stochrsi9_1d_wf","btc_rsi14_1d_wf","btc_rec_rsi14_bull_1d_wf","btc_rec_stochrsi14_1d_wf","btc_rsi30_1d_wf","btc_rec_rsi30_bull_1d_wf","btc_rec_stochrsi30_1d_wf"
                                    ,"btc_rec_kdj_9_1d_wf","btc_rec_dmi_30_1d_wf", "btc_rec_sma0510_1d_wf","btc_rec_sma1020_1d_wf","btc_rec_sma1550_1d_wf","btc_rec_golden_cross_1d_wf","btc_rec_death_cross_1d_wf",
                                    "btc_rec_ema0510_1d_wf","btc_rec_ema1020_1d_wf","btc_rec_ema1550_1d_wf","btc_rec_ema50200_1d_wf","btc_rec_ema719_1d_wf","btc_rec_ema725_1d_wf","btc_rec_ema2599_1d_wf",
                                    "btc_rec_psar_1d_wf","btc_rec_doji_1d_wf","btc_rec_hammer_1d_wf","btc_rec_hanging_man_1d_wf","btc_rec_morning_star_1d_wf","btc_rec_evening_star_1d_wf","btc_rec_shooting_star_1d_wf",
                                    "btc_rec_inverted_hammer_1d_wf","btc_rec_three_inside_up_1d_wf","btc_rec_three_inside_down_1d_wf","btc_rec_three_white_soldiers_1d_wf","btc_rec_three_black_crows_1d_wf",
                                    "btc_rec_engulfing_1d_wf","btc_rec_vwma14_1d_wf","btc_rec_macd_12269_1d_wf","btc_rec_macd_245218_1d_wf","btc_rec_macd_4810436_1d_wf","btc_rec_macd_6134_1d_wf","btc_Recommend_Other_1d_wf",
                                    "btc_Recommend_All_1d_wf", "btc_Recommend_MA_1d_wf","btc_CCI20_1d_wf","btc_rec_Stoch_RSI_1d_wf","btc_rec_WR_1d_wf","btc_rec_BBPower_1d_wf","btc_BBPower_1d_wf",
                                    "btc_rec_UO_1d_wf","btc_rec_Ichimoku_1d_wf","btc_rec_VWMA_1d_wf","btc_rec_HullMA9_1d_wf","btc_rec_BB_lower_1d_wf","btc_rec_BB_upper_1d_wf",
                                    "btc_change_1d_wf","btc_rec_pivot_1d_wf","btc_rec_cci20_1d_wf"]
                                    .filter(
                                        (indicator) =>
                                            !indicatorsList.includes(indicator) &&
                                            !data.some((simulator) =>
                                                simulator.indicators.some((item) => item.indicator === indicator)
                                            )
                                    )
                                    .map((indicator) => (
                                        <option key={indicator} value={indicator}>
                                            {indicator}
                                        </option>
                                    ))}
                            </select>

                            <select
                                value={newData.condition}
                                onChange={(e) => setNewData({ ...newData, condition: e.target.value })}
                            >
                                <option value="">Select Condition</option>
                                <option value="greater_than">Greater than</option>
                                <option value="lower_than">Lower than</option>
                                <option value="between">Between</option>
                            </select>
                            {newData.condition !== 'between' && (
                                <input
                                    type="text"
                                    value={newData.value}
                                    onChange={(e) => {
                                        if (isFloat(e.target.value)) {
                                            setNewData({ ...newData, value: e.target.value });
                                        }
                                    }}
                                />
                            )}
                            {newData.condition === 'between' && (
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Min Value"
                                        value={newData.minValue}
                                        onChange={(e) => {
                                            if (isFloat(e.target.value)) {
                                                setNewData({ ...newData, minValue: e.target.value });
                                            }
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Max Value"
                                        value={newData.maxValue}
                                        onChange={(e) => {
                                            if (isFloat(e.target.value)) {
                                                setNewData({ ...newData, maxValue: e.target.value });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {error && <div className="error-message">{error}</div>}
                            <button className="table_button" onClick={handleAdd}>Add</button>

                        <p></p>
                        <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                            <table style={{ width: "100%", tableLayout: "fixed" }}>
                                <thead>
                                <tr>
                                    <th style={{ width: "33.333%" }}>Indicator</th>
                                    <th style={{ width: "33.333%" }}>Condition</th>
                                    <th style={{ width: "33.333%" }}>Remove</th>
                                </tr>
                                </thead>
                            </table>
                        </div>
                        <div style={{ overflowY: "auto", maxHeight: "calc(300px - 20px)", borderTop: "1px solid #ccc" }}>
                            <table id="portfolio-table">
                                <tbody>
                                {data.map((simulator) => (
                                    <React.Fragment key={simulator._id}>
                                        {simulator.indicators.map((item) => (
                                            <tr key={item._id}>
                                                <td style={{ width: "33.333%" }}>{item.indicator}</td>
                                                <td style={{ width: "33.333%" }}>
                                                    <div>
                                                        {item.condition}
                                                    </div>
                                                    <div>
                                                      <span className="benefits" >
                                                        {item.value}
                                                      </span>
                                                    </div>
                                                </td>
                                                <td style={{ display: 'inline-block', width: "33.333%",border:'None' }}>
                                                    <button className="table_button" onClick={() => handleRemove(simulator._id, item._id)}>
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedItem2 && (
                <div className="overlay">
                    {itemsSimulator.find((item) => item.user === selectedItem2.user) && (
                        itemsSimulator.map((item, index) => (
                        <div className="alert" ref={alertRef2} key={index}>

                            <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                                <div className="linechart col-12 row-20 pad-right-10 border-radius">
                                    <div className="dashboard_text_3 col-12 row-20 border-radius">
                                        <div className="dashboard_title_2 s10-strategie-buy border-radius-top center">BUY</div>
                                        <div className="main-line-details">
                                            <div className="main-line-details">
                                                <div className="dashboard_title_2 col-4 s10b">Buy limit</div>
                                                <div className="dashboard_title_2 col-4 s10b">Amount</div>
                                                <div className="dashboard_title_2 col-4 s10b">Entry Price</div>

                                                <div className="s10-grey col-4">
                                                    <select
                                                        value={MaxCount[index]}
                                                        onChange={(e) => handleMaxCountChange(e, index)}
                                                    >
                                                        <option value="">{item.max_count}</option>
                                                        {Array.from({ length: 20 }, (_, i) => (
                                                            <option key={(i + 1)} value={`${(i + 1)}`}>{(i + 1)}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="s10-grey col-4">
                                                    <select
                                                        value={Amount[index]}
                                                        onChange={(e) => handleAmountChange(e, index)}
                                                    >
                                                        <option value="">{item.amount}</option>
                                                        {Array.from({ length: 401 }, (_, i) => (
                                                            <option key={i} value={(i * 0.5).toString()}>{(i * 0.5).toFixed(1)}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <select
                                                        value={EntryPrice[index]}
                                                        onChange={(e) => handleEntryPriceChange(e, index)}
                                                    >
                                                        <option value="">{item.entry_price}</option>
                                                        {Array.from({ length: 37 }, (_, i) => {
                                                            let value, displayValue;
                                                            if (i < 9) {
                                                                value = `-0.00${i + 1}`;
                                                                displayValue = `-0.00${i + 1}`;
                                                            } else if (i > 8 && i <18) {
                                                                value = `-0.0${i - 8}`;
                                                                displayValue = `-0.0${i - 8}`;
                                                            } else if (i > 17 && i <27) {
                                                                value = `-0.${i - 17}`;
                                                                displayValue = `-0.${i - 17}`;
                                                            } else {
                                                                value = `-${i - 26}`;
                                                                displayValue = `-${i - 26}`;
                                                            }
                                                            return (
                                                                <option key={i} value={value}>{displayValue}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Consolidate</div>
                                        </div>

                                        <div className="subline col-12 row-20 border-radius">
                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#1:</span>
                                                <select
                                                    value={BuyThresholdT1[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, BuyThresholdT1, setBuyThresholdT1, setSelectedBuyThresholdT1, item.buy_t1)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}

                                                    <option value="" selected={item.buy_t1 !== null || item.buy_t1 !== ""}>
                                                        {item.buy_t1 !== null && item.buy_t1 !== "" ?
                                                            item.buy_t1 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierBuyT1[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierBuyT1, setMultiplierBuyT1, setSelectedMultiplierBuyT1, item.multiplier_buy_t1)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_buy_t1}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => (
                                                        (item.amount * (i / 10 + 0.1)) >= 5 && (
                                                            <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                x {(i / 10 + 0.1).toFixed(1)}
                                                            </option>
                                                        )
                                                    ))}
                                                    {[...Array(30).keys()].map((i) => (
                                                        (item.amount * (i + 1)) >= 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>


                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#2:</span>
                                                <select
                                                    value={BuyThresholdT2[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, BuyThresholdT2, setBuyThresholdT2, setSelectedBuyThresholdT2, item.buy_t2)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.buy_t2 !== null || item.buy_t2 !== ""}>
                                                        {item.buy_t2 !== null && item.buy_t2 !== "" ?
                                                            item.buy_t2 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierBuyT2[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierBuyT2, setMultiplierBuyT2, setSelectedMultiplierBuyT2, item.multiplier_buy_t2)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_buy_t2}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => (
                                                        (item.amount * (i / 10 + 0.1)) >= 5 && (
                                                            <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                x {(i / 10 + 0.1).toFixed(1)}
                                                            </option>
                                                        )
                                                    ))}
                                                    {[...Array(30).keys()].map((i) => (
                                                        (item.amount * (i + 1)) >= 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#3:</span>
                                                <select
                                                    value={BuyThresholdT3[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, BuyThresholdT3, setBuyThresholdT3, setSelectedBuyThresholdT3, item.buy_t3)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.buy_t3 !== null || item.buy_t3 !== ""}>
                                                        {item.buy_t3 !== null && item.buy_t3 !== "" ?
                                                            item.buy_t3 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierBuyT3[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierBuyT3, setMultiplierBuyT3, setSelectedMultiplierBuyT3, item.multiplier_buy_t3)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_buy_t3}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => (
                                                        (item.amount * (i / 10 + 0.1)) >= 5 && (
                                                            <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                x {(i / 10 + 0.1).toFixed(1)}
                                                            </option>
                                                        )
                                                    ))}
                                                    {[...Array(30).keys()].map((i) => (
                                                        (item.amount * (i + 1)) >= 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#4:</span>
                                                <select
                                                    value={BuyThresholdT4[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, BuyThresholdT4, setBuyThresholdT4, setSelectedBuyThresholdT4, item.buy_t4)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.buy_t4 !== null || item.buy_t4 !== ""}>
                                                        {item.buy_t4 !== null && item.buy_t4 !== "" ?
                                                            item.buy_t4 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierBuyT4[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierBuyT4, setMultiplierBuyT4, setSelectedMultiplierBuyT4, item.multiplier_buy_t4)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_buy_t4}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => (
                                                        (item.amount * (i / 10 + 0.1)) >= 5 && (
                                                            <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                x {(i / 10 + 0.1).toFixed(1)}
                                                            </option>
                                                        )
                                                    ))}
                                                    {[...Array(30).keys()].map((i) => (
                                                        (item.amount * (i + 1)) >= 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#5:</span>
                                                <select
                                                    value={BuyThresholdT5[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, BuyThresholdT5, setBuyThresholdT5, setSelectedBuyThresholdT5, item.buy_t5)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.buy_t5 !== null || item.buy_t5 !== ""}>
                                                        {item.buy_t5 !== null && item.buy_t5 !== "" ?
                                                            item.buy_t5 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierBuyT5[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierBuyT5, setMultiplierBuyT5, setSelectedMultiplierBuyT5, item.multiplier_buy_t5)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_buy_t5}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => (
                                                        (item.amount * (i / 10 + 0.1)) >= 5 && (
                                                            <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                x {(i / 10 + 0.1).toFixed(1)}
                                                            </option>
                                                        )
                                                    ))}
                                                    {[...Array(30).keys()].map((i) => (
                                                        (item.amount * (i + 1)) >= 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                                <div className="linechart col-12 row-20 border-radius">
                                    <div className="dashboard_text_3 col-12 row-20 border-radius">
                                        <div className="dashboard_title_2 s10-strategie-sell border-radius-top center">SELL</div>
                                        <div className="main-line-details">
                                            <div className="main-line-details">
                                                <div className="dashboard_title_2 col-12 s10b">Stoploss</div>
                                                <div className="s10-grey col-12">
                                                    <select
                                                        value={StoplossThreshold[index]}
                                                        onChange={(e) => handleThresholdChange(e, index, StoplossThreshold, setStoplossThreshold, setSelectedStoplossThreshold, item.stoploss_t1)}
                                                    >
                                                        {Array.from({ length: 900 }, (_, i) => {
                                                            const value = -90 + i / 10;
                                                            return (
                                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                    {value.toFixed(1) + " %"}
                                                                </option>
                                                            );
                                                        })}
                                                        <option value="" selected={item.stoploss_t1 !== null || item.stoploss_t1 !== ""}>
                                                            {item.stoploss_t1 !== null && item.stoploss_t1 !== "" ?
                                                                item.stoploss_t1 + " %" :
                                                                "Select an option"
                                                            }
                                                        </option>
                                                        {Array.from({ length: 900 }, (_, i) => {
                                                            const value = 0 + i / 10;
                                                            return (
                                                                <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                    {(value).toFixed(1) + " %"}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>&nbsp;
                                                </div>
                                            </div>
                                            <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Take Profit</div>
                                        </div>
                                        <div className="subline col-12 row-20 border-radius">
                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#1:</span>
                                                <select
                                                    value={SellThresholdT1[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, SellThresholdT1, setSellThresholdT1, setSelectedSellThresholdT1, item.sell_t1)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.sell_t1 !== null || item.sell_t1 !== ""}>
                                                        {item.sell_t1 !== null && item.sell_t1 !== "" ?
                                                            item.sell_t1 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierSellT1[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierSellT1, setMultiplierSellT1, setSelectedMultiplierSellT1, item.multiplier_sell_t1)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_sell_t1}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => {
                                                        const initInvest = item.amount;
                                                        const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1);
                                                        if (calculatedValue > 5) {
                                                            return (
                                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                                </option>
                                                            );
                                                        }
                                                        return null; // Return null for options that don't meet the condition
                                                    })}

                                                    {[...Array(1).keys()].map((i) => (
                                                        (item.amount * (i + 1)) > 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#2:</span>
                                                <select
                                                    value={SellThresholdT2[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, SellThresholdT2, setSellThresholdT2, setSelectedSellThresholdT2, item.sell_t2)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.sell_t2 !== null || item.sell_t2 !== ""}>
                                                        {item.sell_t2 !== null && item.sell_t2 !== "" ?
                                                            item.sell_t2 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}

                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierSellT2[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierSellT2, setMultiplierSellT2, setSelectedMultiplierSellT2, item.multiplier_sell_t2)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_sell_t2}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => {
                                                        const initInvest = item.amount;
                                                        const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1);
                                                        if (calculatedValue > 5) {
                                                            return (
                                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                                </option>
                                                            );
                                                        }
                                                        return null; // Return null for options that don't meet the condition
                                                    })}

                                                    {[...Array(1).keys()].map((i) => (
                                                        (item.amount * (i + 1)) > 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#3:</span>
                                                <select
                                                    value={SellThresholdT3[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, SellThresholdT3, setSellThresholdT3, setSelectedSellThresholdT3, item.sell_t3)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.sell_t3 !== null || item.sell_t3 !== ""}>
                                                        {item.sell_t3 !== null && item.sell_t3 !== "" ?
                                                            item.sell_t3 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierSellT3[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierSellT3, setMultiplierSellT3, setSelectedMultiplierSellT3, item.multiplier_sell_t3)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_sell_t3}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => {
                                                        const initInvest = item.amount;
                                                        const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1);
                                                        if (calculatedValue > 5) {
                                                            return (
                                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                                </option>
                                                            );
                                                        }
                                                        return null; // Return null for options that don't meet the condition
                                                    })}

                                                    {[...Array(1).keys()].map((i) => (
                                                        (item.amount * (i + 1)) > 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#4:</span>
                                                <select
                                                    value={SellThresholdT4[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, SellThresholdT4, setSellThresholdT4, setSelectedSellThresholdT4, item.sell_t4)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.sell_t4 !== null || item.sell_t4 !== ""}>
                                                        {item.sell_t4 !== null && item.sell_t4 !== "" ?
                                                            item.sell_t4 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}

                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierSellT4[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierSellT4, setMultiplierSellT4, setSelectedMultiplierSellT4, item.multiplier_sell_t4)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_sell_t4}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => {
                                                        const initInvest = item.amount;
                                                        const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1);
                                                        if (calculatedValue > 5) {
                                                            return (
                                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                                </option>
                                                            );
                                                        }
                                                        return null; // Return null for options that don't meet the condition
                                                    })}

                                                    {[...Array(1).keys()].map((i) => (
                                                        (item.amount * (i + 1)) > 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <span style={{ marginRight: "5px" }}>#5:</span>
                                                <select
                                                    value={SellThresholdT5[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, SellThresholdT5, setSellThresholdT5, setSelectedSellThresholdT5, item.sell_t5)}
                                                >
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = -90 + i / 10;
                                                        return (
                                                            <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                                {value.toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}
                                                    <option value="" selected={item.sell_t5 !== null || item.sell_t5 !== ""}>
                                                        {item.sell_t5 !== null && item.sell_t5 !== "" ?
                                                            item.sell_t5 + " %" :
                                                            "Select an option"
                                                        }
                                                    </option>
                                                    {Array.from({ length: 900 }, (_, i) => {
                                                        const value = 0 + i / 10;
                                                        return (
                                                            <option key={(value).toFixed(1)} value={(value).toFixed(1)}>
                                                                {(value).toFixed(1) + " %"}
                                                            </option>
                                                        );
                                                    })}

                                                </select>&nbsp;
                                                <select
                                                    value={MultiplierSellT5[index]}
                                                    onChange={(e) => handleThresholdChange(e, index, MultiplierSellT5, setMultiplierSellT5, setSelectedMultiplierSellT5, item.multiplier_sell_t5)}
                                                >
                                                    <option value="">
                                                        {"x " + item.multiplier_sell_t5}
                                                    </option>
                                                    {[...Array(9).keys()].map((i) => {
                                                        const initInvest = item.amount;
                                                        const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1);
                                                        if (calculatedValue > 5) {
                                                            return (
                                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                                </option>
                                                            );
                                                        }
                                                        return null; // Return null for options that don't meet the condition
                                                    })}

                                                    {[...Array(1).keys()].map((i) => (
                                                        (item.amount * (i + 1)) > 5 && (
                                                            <option key={i + 1} value={i + 1}>
                                                                x {i + 1}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 s10b">
                                <button className="table_button" onClick={handleUpdate}>Update</button>
                            </div>
                        </div>))
                    )}
                </div>
            )}
            <p></p>
            {itemsSimulator.map((item, index) => (
                <div key={index}>
                    <div className="dashboard_title_2 col-12 s10-section">START</div>

                    <div className="linechart col-6 pad-top-5 pad-bottom-5">
                        <table id="simulator-table-mode">
                            <tbody>
                            <tr>
                                <td style={{ display: 'inline-block',color: '#000',fontSize:'12px' }}>MODE</td>
                                <td>
                                    <select
                                        value={VirtualType[index]}
                                        onChange={(e) => handleVirtualTypeChange(e, index)}
                                    >
                                        <option value={item.virtual_type}>{item.virtual_type}</option>
                                        {item.virtual_type !== 'Auto' && (
                                            <option value="Auto">Auto</option>
                                        )}
                                        {item.virtual_type !== 'Basic' && (
                                            <option value="Basic">Basic</option>
                                        )}
                                        {item.virtual_type !== 'Multiple Thresholds' && (
                                            <option value="Multiple Thresholds">Multiple Thresholds</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button className="table_button" onClick={() => {
                            if (statusVirtual[index] === 'offline' && statusProd[index] === 'online') {
                                handleStatusChange(statusVirtual, setStatusVirtual, 'virtual_state', index);
                                handleStatusChange(statusProd, setStatusProd, 'prod_state', index);
                            }
                            else {handleStatusChange(statusVirtual, setStatusVirtual, 'virtual_state', index);}
                        }}>
                            VIRTUAL{' '}
                            {statusVirtual[index] === 'online' ? (
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size="sm"
                                    style={{ color: '#00aa00', cursor: 'pointer' }}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size="sm"
                                    style={{ color: '#ff0000', cursor: 'pointer' }}
                                />
                            )}
                        </button>
                    </div>

                    <div className="linechart col-6 pad-top-5 pad-bottom-5">
                        <table id="simulator-table-mode">
                            <tbody>
                            <tr>
                                <td style={{ display: 'inline-block',color: '#000',fontSize:'12px' }}>MODE</td>
                                <td>
                                    <select
                                        value={VirtualType[index]}
                                        onChange={(e) => handleProdTypeChange(e, index)}
                                    >
                                        <option value={item.prod_type}>{item.prod_type}</option>
                                        {item.prod_type !== 'Fixed' && (
                                            <option value="Fixed">Fixed</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <button className="table_button" onClick={() => {
                            if (statusVirtual[index] === 'online' && statusProd[index] === 'offline') {
                                handleStatusChange(statusVirtual, setStatusVirtual, 'virtual_state', index);
                                handleStatusChange(statusProd, setStatusProd, 'prod_state', index);
                            }
                            else {handleStatusChange(statusProd, setStatusProd, 'prod_state', index);}
                        }}>
                            PROD{' '}
                            {statusProd[index] === 'online' ? (
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size="sm"
                                    style={{ color: '#00aa00', cursor: 'pointer' }}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={faCircle}
                                    size="sm"
                                    style={{ color: '#ff0000', cursor: 'pointer' }}
                                />
                            )}
                        </button>
                    </div>

                    <div className="s10-grey col-12 pad-top-5 pad-bottom-5"><Countdown elapsed_time={item.elapsed_time}/></div>
                    <div className="dashboard_title_2 col-12 s10-section pad-bottom-5">RESULTS</div>
                    <div className="linechart col-12 ">
                        <div style={{ margin: '5px' }} className="col-12">
                            <div className="col-4">
                                <div style={{ color: '#777',fontSize:'8px' }}>
                                    BUY ORDERS
                                </div>
                                <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                    {item.current_count}
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ color: '#777',fontSize:'8px' }}>
                                    INVESTED
                                </div>
                                <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                    {item.investment} $
                                </div>
                            </div>
                            <div className="col-4">
                                <div style={{ color: '#777',fontSize:'8px' }}>
                                    LOSSES
                                </div>
                                <div style={{ display: 'inline-block',color: '#555',fontSize:'16px' }}>
                                    {item.stoploss/100*item.investment*item.ratio_stoploss} $
                                </div>
                            </div>
                        </div>
                        {item.virtual_type === "Auto" ? <ResultSimulator /> : <FixedResultSimulator />}
                    </div>
                </div>
            ))}
        </div>

    );
};

export default TableComponent;