import React, { useEffect, useState, useMemo } from 'react';
import {useTranslation} from "react-i18next";

const AddBotForm = ({ onAddBot, existingSymbols, isFreeTrial, isEssential, isPremium }) => {
    const { t } = useTranslation();
    const availableSymbols = useMemo(() => {
        if (isPremium || isFreeTrial) {
            return ['BTCUSDC', 'ETHUSDC', 'LDOUSDC', 'SOLUSDC', 'PEPEUSDC', 'SHIBUSDC'].filter(symbol => !existingSymbols.includes(symbol));
        } else if (isEssential) {
            return ['BTCUSDC', 'ETHUSDC'].filter(symbol => !existingSymbols.includes(symbol));
        } else {
            return ['BTCUSDC'].filter(symbol => !existingSymbols.includes(symbol));
        }
    }, [existingSymbols]);


    const [symbol, setSymbol] = useState(availableSymbols[0] || '');
    const [botType, setBotType] = useState('');
    const [accountType, setAccountType] = useState('');
    const [amount, setAmount] = useState('');
    const [takeprofit, setTakeprofit] = useState('');
    const [stoploss, setStoploss] = useState('');
    const [indicator, setIndicator] = useState('');
    const [indicatorRSIPeriod, setIndicatorRSIPeriod] = useState('');
    const [buyRSIIndicatorValue, setBuyRSIIndicatorValue] = useState('');
    const [sellRSIIndicatorValue, setSellRSIIndicatorValue] = useState('');
    const [buyBBIndicatorValue, setBuyBBIndicatorValue] = useState('');
    const [sellBBIndicatorValue, setSellBBIndicatorValue] = useState('');
    const [firstMAIndicatorValue, setFirstMAIndicatorValue] = useState('');
    const [secondMAIndicatorValue, setSecondMAIndicatorValue] = useState('');
    const [thirdMAIndicatorValue, setThirdMAIndicatorValue] = useState('');
    const [fourthMAIndicatorValue, setFourthMAIndicatorValue] = useState('');
    const [frequency, setFrequency] = useState('');
    const [timeframe, setTimeframe] = useState('');
    const [maxOrder, setMaxOrder] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!availableSymbols.includes(symbol)) {
            setSymbol(availableSymbols[0] || '');
        }
    }, [availableSymbols, symbol]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if account type is selected
        if (!accountType) {
            setError('Account type is not selected');
            return;
        }
        if (!stoploss) {
            setError('Stoploss is not selected');
            return;
        }
        if (!takeprofit) {
            setError('Take Profit is not selected');
            return;
        }
        if (!botType ) {
            setError('Bot type is not selected');
            return;
        }
        if (!timeframe ) {
            setError('Timeframe is not selected');
            return;
        }
        if (!indicator ) {
            setError('Indicator is not selected');
            return;
        }
        if (
            (indicator === 'RSI' && (!buyRSIIndicatorValue || !sellRSIIndicatorValue || !indicatorRSIPeriod)) ||
            (indicator === 'BB' && (!buyBBIndicatorValue || !sellBBIndicatorValue || !buyRSIIndicatorValue || !sellRSIIndicatorValue || !indicatorRSIPeriod))
        ) {
            setError('All values for the selected indicator must be filled out');
            return;
        }
        if (
            (indicator === 'EMA' && (!firstMAIndicatorValue))
        ) {
            setError('At least one value for the selected indicator must be filled out');
            return;
        }
        if (parseFloat(amount) < 10) {
            setError('Amount is lower than 10.');
            return;
        } else {
            setError('');
        }

        const newBot = {
            symbol: symbol,
            bot_type: botType,
            account_type: accountType,
            amount: parseFloat(amount),
            take_profit: parseFloat(1 + takeprofit / 100),
            stoploss: parseFloat(1 + stoploss / 100),
            timeframe: timeframe,
            ...(botType === 'scalping' && {
                indicator,
                ...(indicator === 'RSI' && {
                    indicator_rsi_period: parseInt(indicatorRSIPeriod, 10),
                    buy_rsi_indicator_value: parseFloat(buyRSIIndicatorValue),
                    sell_rsi_indicator_value: parseFloat(sellRSIIndicatorValue),
                }),
                ...(indicator === 'BB' && {
                    buy_bb_indicator_value: parseFloat(1 + buyBBIndicatorValue / 100),
                    sell_bb_indicator_value: parseFloat(1 + sellBBIndicatorValue / 100),
                    indicator_rsi_period: parseInt(indicatorRSIPeriod, 10),
                    buy_rsi_indicator_value: parseFloat(buyRSIIndicatorValue),
                    sell_rsi_indicator_value: parseFloat(sellRSIIndicatorValue),
                }),
                ...(indicator === 'EMA' && {
                    first_ma_value: firstMAIndicatorValue,
                    second_ma_value: secondMAIndicatorValue,
                    third_ma_value: thirdMAIndicatorValue,
                    fourth_ma_value: fourthMAIndicatorValue,
                }),
            }),
            ...(botType === 'dca' && {
                frequency,
                max_order: parseInt(maxOrder, 10) || null // Ensure maxOrder is parsed correctly
            }),
        };

        try {
            const response = await fetch('/api/bots', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newBot),
            });

            if (!response.ok) {
                throw new Error('Failed to add bot');
            }

            const result = await response.json();
            console.log('New bot added:', result);

            onAddBot(result);
            // Clear the form fields to default values
            setSymbol(availableSymbols[0] || '');
            setBotType('');
            setAccountType('');
            setAmount('');
            setTakeprofit('');
            setStoploss('');
            setIndicator('');
            setIndicatorRSIPeriod('');
            setBuyRSIIndicatorValue('');
            setSellRSIIndicatorValue('');
            setBuyBBIndicatorValue('');
            setSellBBIndicatorValue('');
            setFirstMAIndicatorValue('');
            setSecondMAIndicatorValue('');
            setThirdMAIndicatorValue('');
            setFourthMAIndicatorValue('');
            setFrequency('');
            setTimeframe('');
            setMaxOrder('');
            setError('');
        } catch (error) {
            console.error('Error adding bot:', error);
        }
    };

    return (
        <div className="form-container">
            {availableSymbols.length === 0 ? (
                <div className="error-message">{t("automated_self_services.no_available_symbol")}</div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-grid">
                        <div className="form-group">
                            <select value={symbol} onChange={(e) => setSymbol(e.target.value)}>
                                {availableSymbols.map((symbol) => (
                                    <option key={symbol} value={symbol}>
                                        {symbol}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select value={botType} onChange={(e) => setBotType(e.target.value)}>
                                <option value="" disabled hidden>{t("automated_self_services.bot_type")}</option>
                                <option value="scalping">Scalping</option>
                            </select>
                        </div>
                        <div className="form-group">

                            <select value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                                <option value="" disabled hidden>{t("automated_self_services.account")}</option>

                                {isPremium || isFreeTrial ? (
                                    <>
                                        <option value="SPOT">SPOT</option>
                                        <option value="X-MARGIN">X-MARGIN</option>
                                        <option value="I-MARGIN">I-MARGIN</option>
                                    </>
                                ) : isEssential ? (
                                    <>
                                        <option value="SPOT">SPOT</option>
                                        <option value="X-MARGIN">X-MARGIN</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="SPOT">SPOT</option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <input
                                type="number"
                                value={amount}
                                placeholder={`${t("automated_self_services.amount")} $`}
                                onChange={(e) => {
                                    let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                    setAmount(newValue); // Allow free input
                                }}
                                onBlur={(e) => {
                                    let numericValue = parseInt(e.target.value, 10);

                                    if (isNaN(numericValue)) {
                                        setAmount("7"); // Default to 7 if empty
                                    } else {
                                        if (isPremium || isFreeTrial) {
                                            if (numericValue < 7) {
                                                setAmount(7);
                                            } else {
                                                setAmount(numericValue);
                                            }
                                        } else if (isEssential) {
                                            if (numericValue < 7) {
                                                setAmount(7);
                                            } else if (numericValue > 100) {
                                                setAmount(100);
                                            } else {
                                                setAmount(numericValue);
                                            }
                                        } else {
                                            if (numericValue < 7) {
                                                setAmount(7);
                                            } else if (numericValue > 10) {
                                                setAmount(10);
                                            } else {
                                                setAmount(numericValue);
                                            }
                                        }
                                    }
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "." || e.key === ",") {
                                        e.preventDefault(); // Prevent decimals
                                    }
                                }}
                                inputMode="numeric"
                            />

                        </div>
                        <div className="form-group">
                            <select
                                value={takeprofit && takeprofit !== "" ? takeprofit : ""}
                                onChange={(e) => setTakeprofit(Number(e.target.value))}
                                placeholder="Take Profit"
                            >
                                <option value="" disabled hidden>{t("automated_self_services.take_profit")}</option>
                                {Array.from({ length: 601 }, (_, i) => i - 300).map((value) => (
                                    <option key={value} value={value / 10}>
                                        {value / 10} %
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select
                                value={stoploss && stoploss !== "" ? stoploss : ""}
                                onChange={(e) => setStoploss(Number(e.target.value))}
                            >
                                <option value="" disabled hidden>{t("automated_self_services.stoploss")}</option>
                                {Array.from({ length: 601 }, (_, i) => i - 300).map((value) => (
                                    <option key={value} value={value / 10}>
                                        {value / 10} %
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select value={timeframe} onChange={(e) => setTimeframe(e.target.value)}>
                                <option value="" disabled hidden>{t("automated_self_services.timeframe")}</option>
                                <option value="5m">5m</option>
                                <option value="15m">15m</option>
                                <option value="1h">1h</option>
                            </select>
                        </div>
                        {botType === 'scalping' && (
                            <div className="form-group">
                                <select value={indicator} onChange={(e) => setIndicator(e.target.value)}>
                                    <option value="" disabled hidden>{t("automated_self_services.indicator")}</option>
                                    <option value="RSI">RSI</option>
                                    <option value="BB">Bollinger Bands</option>
                                    <option value="EMA">EMA</option>
                                </select>
                            </div>
                        )}
                        {/* Show RSI value input if the selected indicator is RSI */}
                        {botType === 'scalping' && indicator === 'RSI' && (
                            <>
                                <select value={indicatorRSIPeriod} onChange={(e) => setIndicatorRSIPeriod(e.target.value)}>
                                    <option value="" disabled hidden>{t("automated_self_services.rsi_period")}</option>
                                    {[
                                        3, 6, 9, 14, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100
                                    ].map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-group">
                                    <select value={buyRSIIndicatorValue} onChange={(e) => setBuyRSIIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.buy_rsi_value")}</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select value={sellRSIIndicatorValue} onChange={(e) => setSellRSIIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.sell_rsi_value")}</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        {/* Show BB value input if the selected indicator is BB */}
                        {botType === 'scalping' && indicator === 'BB' && (
                            <>
                                <div className="form-group">
                                    <select value={buyBBIndicatorValue} onChange={(e) => setBuyBBIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.bb_lower")} %</option>
                                        {Array.from({ length: 41 }, (_, i) => (i - 20) / 10).map((value) => (
                                            <option key={value} value={value}>
                                                {value} %
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select value={sellBBIndicatorValue} onChange={(e) => setSellBBIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.bb_upper")} %</option>
                                        {Array.from({ length: 41 }, (_, i) => (i - 20) / 10).map((value) => (
                                            <option key={value} value={value}>
                                                {value} %
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <select value={indicatorRSIPeriod} onChange={(e) => setIndicatorRSIPeriod(e.target.value)}>
                                    <option value="" disabled hidden>{t("automated_self_services.rsi_period")}</option>
                                    {[
                                        3, 6, 9, 14, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100
                                    ].map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                                <div className="form-group">
                                    <select value={buyRSIIndicatorValue} onChange={(e) => setBuyRSIIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.buy_rsi_value")}</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select value={sellRSIIndicatorValue} onChange={(e) => setSellRSIIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>{t("automated_self_services.sell_rsi_value")}</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        {/* Show BB value input if the selected indicator is BB */}
                        {botType === 'scalping' && indicator === 'EMA' && (
                            <>
                                <div className="form-group">
                                    <select value={firstMAIndicatorValue} onChange={(e) => setFirstMAIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>EMA #1</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).flatMap((shortEMA) =>
                                            Array.from({ length: 100 }, (_, j) => j + 1)
                                                .filter((longEMA) => longEMA > shortEMA)
                                                .map((longEMA) => `${shortEMA}.${longEMA}`)
                                        ).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <select value={secondMAIndicatorValue} onChange={(e) => setSecondMAIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>EMA #2</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).flatMap((shortEMA) =>
                                            Array.from({ length: 100 }, (_, j) => j + 1)
                                                .filter((longEMA) => longEMA > shortEMA)
                                                .map((longEMA) => `${shortEMA}.${longEMA}`)
                                        ).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select value={thirdMAIndicatorValue} onChange={(e) => setThirdMAIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>EMA #3</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).flatMap((shortEMA) =>
                                            Array.from({ length: 100 }, (_, j) => j + 1)
                                                .filter((longEMA) => longEMA > shortEMA)
                                                .map((longEMA) => `${shortEMA}.${longEMA}`)
                                        ).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <select value={fourthMAIndicatorValue} onChange={(e) => setThirdMAIndicatorValue(e.target.value)}>
                                        <option value="" disabled hidden>EMA #4</option>
                                        {Array.from({ length: 100 }, (_, i) => i + 1).flatMap((shortEMA) =>
                                            Array.from({ length: 100 }, (_, j) => j + 1)
                                                .filter((longEMA) => longEMA > shortEMA)
                                                .map((longEMA) => `${shortEMA}.${longEMA}`)
                                        ).map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        {botType === 'dca' && (
                            <>
                                <div className="form-group">
                                    <select style={{ background: '#fff' }}value={frequency} onChange={(e) => setFrequency(e.target.value)}>
                                        <option value="" disabled hidden>Frequency</option>
                                        <option value="hourly">Hourly</option>
                                        <option value="daily">Daily</option>
                                        <option value="weekly">Weekly</option>
                                        <option value="monthly">Monthly</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="number"
                                        value={maxOrder}
                                        onChange={(e) => setMaxOrder(e.target.value)}
                                        required
                                        placeholder="Max Orders"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button className="table_button" type="submit">{t("button.add_bot")}</button>
                </form>
            )}
        </div>
    );
};

export default AddBotForm;
