import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import validator from 'validator'
import { regexPassword, regexUsername } from '../utils'
import avatar from './images/botmight_logo.png';
import style from './stylesheets/img.css'

import {
  Paper,
  Container,
  Link,
  Stack,
  Button,
  Box,
  Divider,
  Avatar,
  Typography,
  TextField,
  FilledInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material'
import {
  Face as FaceIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import theme from '../styles/theme'
import {useTranslation} from "react-i18next";

function Login({}) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    username: '',
    password: '',
    showPassword: false,
  })
  const [errors, setErrors] = useState({
    username: false,
    password: false,
    fetchError: false,
    fetchErrorMsg: '',
  })

  const handleChange = (fieldName) => (event) => {
    const currValue = event.target.value;
    let isCorrectValue;

    if (fieldName === 'username') {
      // Regular expression to allow letters, numbers, and hyphen
      isCorrectValue = /^[A-Za-z0-9-]*$/.test(currValue);
    } else if (fieldName === 'password') {
      // Regular expression for password validation
      isCorrectValue = regexPassword.test(currValue);
    }

    isCorrectValue
        ? setErrors({ ...errors, [fieldName]: false })
        : setErrors({ ...errors, [fieldName]: true });

    setValues({ ...values, [fieldName]: currValue });
  };


  const handleShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      })

      if (!res.ok) {
        const error = await res.json()
        return setErrors({
          ...errors,
          fetchError: true,
          fetchErrorMsg: error.msg,
        })
      }

      const data = await res.json()
      console.log({ data })

      // this is just a visual feedback for user for this demo
      // this will not be an error, rather we will show a different UI or redirect user to dashboard
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg: data.msg,
      })
      setValues({
        username: '',
        password: '',
        showPassword: false,
      })
      window.location.href = '/';

    } catch (error) {
      setErrors({
        ...errors,
        fetchError: true,
        fetchErrorMsg:
            t("error.issue_server"),
      })
    }
  }

  return (
      <>
        <Container sx={{marginTop: 'calc(100vh - 90vh)',}} maxWidth='xs'>
          <Paper elevation={6}>
            <Container
                maxWidth='sm'
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px',
                }}>
              <Avatar
                  alt="avatar"
                  src={avatar}
                  sx={{ width: 70, height: 70 }}
              />
              <h2>{t("profile.authentication")}</h2>
            </Container>
            <Stack
                component='form'
                onSubmit={handleSubmit}
                noValidate
                spacing={6}
                sx={{ bgcolor: '#f5f5f6', padding: '40px' }}>
              <TextField
                  variant='filled'
                  type='username'
                  label={t("profile.username")}
                  value={values.username}
                  onChange={handleChange('username')}
                  error={errors.username}
                  helperText={errors.username && t("error.valid_username")}
              />

              <FormControl variant='filled'>
                <InputLabel htmlFor='password-field'>{t("profile.password")}</InputLabel>
                <FilledInput
                    id='password-field'
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    error={errors.password}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleShowPassword}
                            edge='end'>
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                />
              </FormControl>
              <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',

                  }}>
                <Button
                    variant='contained'
                    size='large'
                    type='submit'
                    disabled={errors.username || errors.password}
                    sx={{
                      minWidth: '70%',
                    }}>
                  {t("button.login")}
                </Button>
              </Box>
              {errors.fetchError && (
                  <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
              )}
              <Divider />
              <Typography paragraph align='center'>
                {t("profile.not_account_yet")}{' '}
                <Link component={RouterLink} to='/signup'>
                  {t("profile.sign_up_here")}
                </Link>
              </Typography>
            </Stack>
          </Paper>
        </Container>
      </>
  )
}

export default Login