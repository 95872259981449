import React, { useState, useEffect } from 'react';

const ResultSimulator = () => {
    const [data, setData] = useState([]);
    const [initialFetchDone, setInitialFetchDone] = useState(false);
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const fetchData = async () => {
        try {
            const username = getCookie('username'); // Get the username from the cookie
            const response = await fetch(`/api/simulator?username=${username}`);
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleReset = async (simulatorId) => {
        try {
            const updatedData = {
                    virtual_state:0,
                    stoploss_state:0,
                    reset: 1,
                    first_run: 1,
                    prod_state:0,
                    current_count:0,
                    investment:0,
                    elapsed_time:0,
                    ratio_buy_t1:0,
                    ratio_buy_t2:0,
                    ratio_buy_t3:0,
                    ratio_buy_t4:0,
                    ratio_buy_t5:0,
                    ratio_sell_t1:0,
                    ratio_sell_t2:0,
                    ratio_sell_t3:0,
                    ratio_sell_t4:0,
                    ratio_sell_t5:0,
                    ratio_sell_t6:0,
                    ratio_sell_t7:0,
                    ratio_sell_t8:0,
                    ratio_sell_t9:0,
                    ratio_sell_t10:0,
                    ratio_sell_t11:0,
                    ratio_sell_t12:0,
                    ratio_sell_t13:0,
                    ratio_sell_t14:0,
                    ratio_sell_t15:0,
                    ratio_sell_t16:0,
                    ratio_sell_t17:0,
                    ratio_sell_t18:0,
                    ratio_sell_t19:0,
                    ratio_sell_t20:0,
                    ratio_sell_t21:0,
                    ratio_sell_t22:0,
                    ratio_sell_t23:0,
                    ratio_sell_t24:0,
                    ratio_sell_t25:0,
                    ratio_sell_t26:0,
                    ratio_sell_t27:0,
                    ratio_sell_t28:0,
                    ratio_sell_t29:0,
                    ratio_sell_t30:0,
                    ratio_sell_t31:0,
                    ratio_sell_t32:0,
                    ratio_sell_t33:0,
                    ratio_sell_t34:0,
                    ratio_sell_t35:0,
                    ratio_sell_t36:0,
                    ratio_sell_t37:0,
                    ratio_sell_t38:0,
                    ratio_sell_t39:0,
                    ratio_sell_t40:0,
                    ratio_negative_sell_t1:0,
                    ratio_negative_sell_t2:0,
                    ratio_negative_sell_t3:0,
                    ratio_negative_sell_t4:0,
                    ratio_negative_sell_t5:0,
                    ratio_negative_sell_t6:0,
                    ratio_negative_sell_t7:0,
                    ratio_negative_sell_t8:0,
                    ratio_negative_sell_t9:0,
                    ratio_negative_sell_t10:0,
                    ratio_negative_sell_t11:0,
                    ratio_negative_sell_t12:0,
                    ratio_negative_sell_t13:0,
                    ratio_negative_sell_t14:0,
                    ratio_negative_sell_t15:0,
                    ratio_negative_sell_t16:0,
                    ratio_negative_sell_t17:0,
                    ratio_negative_sell_t18:0,
                    ratio_negative_sell_t19:0,
                    ratio_negative_sell_t20:0,
                    ratio_negative_sell_t21:0,
                    ratio_negative_sell_t22:0,
                    ratio_negative_sell_t23:0,
                    ratio_negative_sell_t24:0,
                    ratio_negative_sell_t25:0,
                    ratio_negative_sell_t26:0,
                    ratio_negative_sell_t27:0,
                    ratio_negative_sell_t28:0,
                    ratio_negative_sell_t29:0,
                    ratio_negative_sell_t30:0,
                    ratio_negative_sell_t31:0,
                    ratio_negative_sell_t32:0,
                    ratio_negative_sell_t33:0,
                    ratio_negative_sell_t34:0,
                    ratio_negative_sell_t35:0,
                    ratio_negative_sell_t36:0,
                    ratio_negative_sell_t37:0,
                    ratio_negative_sell_t38:0,
                    ratio_negative_sell_t39:0,
                    ratio_negative_sell_t40:0,
                    ratio_stoploss_t1:0,
                    sell_count:0,
                    stoploss_count:0,
                    sell:0,
                    stoploss:0,
                    results:{},
                    BLOCKED:{},
                };
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${updatedSimulator}`);
                // Perform any necessary actions after successful update for each simulator
                alert('Simulator updated successfully');
            } catch (error) {
                console.error('Error updating simulator:', error);
                alert('Failed to update simulator. Please try again.');
            }
        };
    const convertToCSV = (data) => {
        if (!data || !data[0] || !data[0].results) {
            console.error('Results data is missing or undefined.');
            return ''; // Return an empty string or handle the error accordingly
        }

        const resultsData = data[0].results; // Extracting the 'results' object from the first item

        const currencies = Object.keys(resultsData); // Get all currency names

        // Collect all unique keys (properties) for the 'Strategie' objects across all currencies
        const allKeys = new Set();
        Object.values(resultsData).forEach(currencyData => {
            if (currencyData.Strategie) {
                Object.keys(currencyData.Strategie).forEach(key => allKeys.add(key));
            }
        });

        // Create headers by including 'Currency' and all available keys for strategy
        const headers = ['Currency', ...Array.from(allKeys).map(key => `${key}`)].join(",") + "\n";

        // Create rows for each currency with its strategy values
        const rows = currencies.map(currency => {
            const currencyData = resultsData[currency].Strategie || {}; // If 'Strategie' is missing, use an empty object
            const currencyInfo = [currency, ...Array.from(allKeys).map(key => {
                if (key === 'date_buy' || key.startsWith('date_sell')) {
                    // Formatting date values using toLocaleString()
                    return currencyData[key] ? `"${new Date(currencyData[key]).toLocaleString()}"` : '""';
                }
                return `"${currencyData[key] || ''}"`; // Use empty string if the key is missing
            })];
            return currencyInfo.join(",");
        }).join("\n");

        // Combine headers and rows to create the CSV content
        const csvContent = `data:text/csv;charset=utf-8,${headers}${rows}`;
        return encodeURI(csvContent);
    };




    const exportToCSV = () => {
        const csvData = convertToCSV(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvData;
        downloadLink.download = 'simulation_results.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 3 seconds
        }, 180000);

        return () => clearInterval(intervalId);
    }, []); // Run the effect only once on component mount

    return (
        <div>
            {data.map((simulator) => (
                <div key={simulator._id} style={{ margin: '20px' }}>
                    <table id="simulator-table-result">
                        <tbody>
                        <tr>
                            <td rowSpan="6" style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', whiteSpace: 'nowrap',color:"#555" }}>
                                POSITIVE THRESHOLDS
                            </td>
                        </tr>
                        {[...Array(5)].map((_, i) => (
                            <tr key={i}>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+1)/10}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 1}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+1)/10+0.5}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 6}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+2)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 11}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+7)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 16}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+12)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 21}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+17)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 26}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+22)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 31}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'12px' }}>
                                        {`${(i+27)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_sell_t${i + 36}`]}%
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <table id="simulator-table-result">
                        <tbody>
                        <tr>
                            <td rowSpan="6" style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)', whiteSpace: 'nowrap',color:"#555" }}>
                                NEGATIVE THRESHOLDS
                            </td>
                        </tr>
                        {[...Array(5)].map((_, i) => (
                            <tr key={i}>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+1)/10}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px'}}>
                                        {simulator[`ratio_negative_sell_t${i + 1}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+1)/10+0.5}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px'}}>
                                        {simulator[`ratio_negative_sell_t${i + 6}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+2)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 11}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+7)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 16}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+12)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 21}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+17)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 26}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+22)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 31}`]}%
                                    </div>
                                </td>
                                <td style={{ width: '12.5%' }}>
                                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'12px' }}>
                                        {`${(i+27)}% `}
                                    </div>
                                    <div style={{ color: '#666',fontSize:'8px' }}>
                                        {simulator[`ratio_negative_sell_t${i + 36}`]}%
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button className="table_button" onClick={() => handleReset(simulator._id)}>Reset</button>&nbsp;
                    <button className="table_button" onClick={exportToCSV}>Export results</button>

                </div>
            ))}
        </div>
    );
};

export default ResultSimulator;
