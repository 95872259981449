const indicatorTimeframeMap = {
    "15m": {
        SUPPORT_RESISTANCE: [
            "Pivot_M_Classic_S3_15m_wf", "Pivot_M_Classic_S2_15m_wf", "Pivot_M_Classic_S1_15m_wf", "Pivot_M_Classic_Middle_15m_wf",
            "Pivot_M_Classic_R1_15m_wf", "Pivot_M_Classic_R2_15m_wf", "Pivot_M_Classic_R3_15m_wf",
        ],
        OSCILLATORS: [
            "rsi6_15m_wf", "rec_rsi6_bull_15m_wf", "rsi14_15m_wf", "rec_rsi14_bull_15m_wf",
            "rec_stochrsi14_15m_wf", "rec_macd_15m_wf", "rec_kdj_15m_wf", "rec_dmi_14_15m_wf", "rec_cci_15m_wf",
            "rec_william_15m_wf",
        ],
        EMA: ["rec_hma_15m_wf","rec_golden_cross_15m_wf","rec_death_cross_15m_wf","rec_ema510_15m_wf","rec_ema1020_15m_wf","rec_ema1550_15m_wf","rec_ema2599_15m_wf","rec_ema50200_15m_wf"],
        CANDLESTICKS: ["rec_doji_15m_wf","rec_hammer_15m_wf","rec_hanging_man_15m_wf","rec_morning_star_15m_wf","rec_evening_star_15m_wf","rec_shooting_star_15m_wf","rec_inverted_hammer_15m_wf","rec_three_inside_up_15m_wf","rec_three_inside_down_15m_wf","rec_three_white_soldiers_15m_wf","rec_three_black_crows_15m_wf","rec_bullish_engulfing_15m_wf","rec_bearish_engulfing_15m_wf"],
        OTHER: ["rec_vwma_14_15m_wf", "rec_psar_15m_wf","BB_lower_15m_wf","BB_upper_15m_wf","rec_ichimoku_15m_wf","rec_atr_15m_wf"],
    },
    "1h": {
        SUPPORT_RESISTANCE: [
            "Pivot_M_Classic_S3_1h_wf", "Pivot_M_Classic_S2_1h_wf", "Pivot_M_Classic_S1_1h_wf", "Pivot_M_Classic_Middle_1h_wf",
            "Pivot_M_Classic_R1_1h_wf", "Pivot_M_Classic_R2_1h_wf", "Pivot_M_Classic_R3_1h_wf",
        ],
        OSCILLATORS: [
            "rsi6_1h_wf", "rec_rsi6_bull_1h_wf", "rsi14_1h_wf", "rec_rsi14_bull_1h_wf",
            "rec_stochrsi14_1h_wf", "rec_macd_1h_wf", "rec_kdj_1h_wf", "rec_dmi_14_1h_wf", "rec_cci_1h_wf",
            "rec_william_1h_wf",
        ],
        EMA: ["rec_hma_1h_wf","rec_golden_cross_1h_wf","rec_death_cross_1h_wf","rec_ema510_1h_wf","rec_ema1020_1h_wf","rec_ema1550_1h_wf","rec_ema2599_1h_wf","rec_ema50200_1h_wf"],
        CANDLESTICKS: ["rec_doji_1h_wf","rec_hammer_1h_wf","rec_hanging_man_1h_wf","rec_morning_star_1h_wf","rec_evening_star_1h_wf","rec_shooting_star_1h_wf","rec_inverted_hammer_1h_wf","rec_three_inside_up_1h_wf","rec_three_inside_down_1h_wf","rec_three_white_soldiers_1h_wf","rec_three_black_crows_1h_wf","rec_bullish_engulfing_1h_wf","rec_bearish_engulfing_1h_wf"],
        OTHER: ["rec_vwma_14_1h_wf", "rec_psar_1h_wf","BB_lower_1h_wf","BB_upper_1h_wf","rec_ichimoku_1h_wf","rec_atr_1h_wf"],
    },
    "1d": {
        SUPPORT_RESISTANCE: [
            "Pivot_M_Classic_S3_1d_wf", "Pivot_M_Classic_S2_1d_wf", "Pivot_M_Classic_S1_1d_wf", "Pivot_M_Classic_Middle_1d_wf",
            "Pivot_M_Classic_R1_1d_wf", "Pivot_M_Classic_R2_1d_wf", "Pivot_M_Classic_R3_1d_wf",
        ],
        OSCILLATORS: [
            "rsi6_1d_wf", "rec_rsi6_bull_1d_wf", "rsi14_1d_wf", "rec_rsi14_bull_1d_wf",
            "rec_stochrsi14_1d_wf", "rec_macd_1d_wf", "rec_kdj_1d_wf", "rec_dmi_14_1d_wf", "rec_cci_1d_wf",
            "rec_william_1d_wf",
        ],
        EMA: ["rec_hma_1d_wf","rec_golden_cross_1d_wf","rec_death_cross_1d_wf","rec_ema510_1d_wf","rec_ema1020_1d_wf","rec_ema1550_1d_wf","rec_ema2599_1d_wf","rec_ema50200_1d_wf"],
        CANDLESTICKS: ["rec_doji_1d_wf","rec_hammer_1d_wf","rec_hanging_man_1d_wf","rec_morning_star_1d_wf","rec_evening_star_1d_wf","rec_shooting_star_1d_wf","rec_inverted_hammer_1d_wf","rec_three_inside_up_1d_wf","rec_three_inside_down_1d_wf","rec_three_white_soldiers_1d_wf","rec_three_black_crows_1d_wf","rec_bullish_engulfing_1d_wf","rec_bearish_engulfing_1d_wf"],
        OTHER: ["rec_vwma_14_1d_wf", "rec_psar_1d_wf","BB_lower_1d_wf","BB_upper_1d_wf","rec_ichimoku_1d_wf","rec_atr_1d_wf"],
    },
};

export default indicatorTimeframeMap