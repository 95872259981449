import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function WhalesDetection() {
  const [whales, setWhales] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      fetch('/api/whales')
          .then(res => res.json())
          .then(data => setWhales(data))
          .catch(err => console.error(err));
    };

    // Fetch data initially
    fetchData();

    // Fetch data every 10 seconds
    const intervalId = setInterval(fetchData, 10000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []);

  return (
      <div className="table-container" style={{ overflowX: "auto", maxWidth: "100%", height: "586px" }}>
        <table id="portfolio-table">
          <thead>
          <tr>
            <th>Symbols</th>
            <th>Side</th>
            <th>Amount</th>
            <th>Date / Time</th>
          </tr>
          </thead>
          <tbody>
          {whales.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <div style={{ display: 'inline-block' }}>
                    {item.symbol}
                  </div>
                  <div className="s10-portfolio_type" style={{ color: item.platform === 'BINANCE' ? '#F0B90B' : item.platform === 'MARGIN' ? '#555' : '#AAA', display: 'block' }}>
                    {item.platform}
                  </div>
                </td>
                <td>
                  <div style={{ color: item.side === 'SELL' ? '#f44336' : item.side === 'BUY' ? '#74d600' : '#AAA', display: 'block' }}>
                    {item.side}
                  </div>
                </td>
                <td>{item.amount}</td>
                <td>{item.date}</td>
              </tr>
          ))}
          </tbody>
        </table>
      </div>
  );
}

export default WhalesDetection;