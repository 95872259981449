import React, {useState, useEffect, useCallback, useMemo} from 'react';
import ProgressionBarPerformanceReview from "./ProgressionBarPerformanceReview";
import indicatorNameMapSimulatorBasic from './IndicatorNameMappingSimulatorBasic';
import indicatorNameMapSimulatorEssential from './IndicatorNameMappingSimulatorEssential';
import indicatorNameMapSimulatorPremium from './IndicatorNameMappingSimulatorPremium';
import {faTrash, faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

const Simulator_2 = ({ isFreeTrial,isBasic, isEssential, isPremium, isAdmin }) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const [symbolDetails, setSymbolDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isVirtualMode, setIsVirtualMode] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [indicatorsList, setIndicatorsList] = useState([]);
    const [statusVirtual, setStatusVirtual] = useState([]);
    const [statusProd, setStatusProd] = useState([]);
    const [newIndicator, setNewIndicator] = useState({
        indicator: '',
        condition: '',
        value: ''
    });
    const existingIndicators = itemsSimulator.flatMap((simulator) =>
        simulator.indicators.map((item) => item.indicator)
    );
// Filter out the indicators that are already present in existingIndicators
    const availableIndicators = Object.entries(
        isFreeTrial === true || isPremium === true
            ? indicatorNameMapSimulatorPremium
            : isEssential === true
                ? indicatorNameMapSimulatorEssential
                : indicatorNameMapSimulatorBasic
    ).filter(([key]) => !existingIndicators.includes(key))
        .map(([key, value]) => {
            // Translate the indicator name using `t`
            const translatedName = t(`indicator.${key}`) || value; // Translate based on key (indicator)
            return [key, translatedName]; // Return key and translated name
        });

    const isFloat = (value) => {
        return /^-?\d*\.?\d*$/.test(value);
    };
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };
    const convertToCSV = (item) => {
        if (!item || !item.results) {
            console.error("Results data is missing or undefined.");
            return '';
        }

        const resultsData = item.results; // Access the 'results' object for the specific item

        const currencies = Object.keys(resultsData); // Get all currency names

        // Collect all unique keys for the 'Allmighty' objects across all currencies
        const allKeys = new Set();
        Object.values(resultsData).forEach((currencyData) => {
            if (currencyData.Allmighty) {
                Object.keys(currencyData.Allmighty).forEach((key) => allKeys.add(key));
            }
        });

        // Create headers for CSV
        const headers = ['Currency', ...Array.from(allKeys)].join(",") + "\n";

        // Create rows for each currency
        const rows = currencies.map((currency) => {
            const currencyData = resultsData[currency]?.Allmighty || {};
            const row = [
                currency, // Add the currency code
                ...Array.from(allKeys).map((key) => {
                    if (key === 'date_buy') {
                        // Format date values using toLocaleString()
                        return currencyData[key]
                            ? `"${new Date(currencyData[key]).toLocaleString()}"`
                            : '""';
                    }
                    return `"${currencyData[key] || ''}"`; // Use empty string if the key is missing
                }),
            ];
            return row.join(",");
        }).join("\n");

        // Combine headers and rows to create the CSV content
        const csvContent = `data:text/csv;charset=utf-8,${headers}${rows}`;
        return encodeURI(csvContent);
    };

    const exportToCSV = (id) => {
        // Find the specific item in `itemsSimulator` matching the `_id`
        const selectedItem = itemsSimulator.find((simItem) => simItem._id === id);
        if (!selectedItem) {
            console.error("No data found for the given ID:", id);
            return;
        }

        // Convert selected item to CSV
        const csvData = convertToCSV(selectedItem);
        if (!csvData) {
            console.error("Failed to generate CSV data.");
            return;
        }

        // Create a download link for the CSV data
        const downloadLink = document.createElement("a");
        downloadLink.href = csvData;
        //downloadLink.download = `simulation_results_${id}.csv`;
        downloadLink.download = `Strategy_Builder_Allmighty.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    const fetchIndicators = async () => {
        try {
            const response = await fetch('/api/simulator');
            const result = await response.json();
            const indicators = result.map((item) => item.indicator);
            setIndicatorsList(indicators);
        } catch (error) {
            console.error('Error fetching indicators:', error);
        }
    };
    const handleReset = async (simulatorId) => {
        try {
            const updatedData = {
                virtual_state:0,
                prod_state:0,
                first_run: 1,
                current_count:0,
                investment:0,
                elapsed_time:0,
                benefits:0,
                stability:0,
                avg_high:0,
                avg_low:0,
                range_ratio:0,
                price_position:0,
                performance_score:0,
                success:0,
                tp:0,
                sl:0,
                results:{},
                BLOCKED:{},
            };
            const response = await fetch(`/api/simulator/${simulatorId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                throw new Error('Failed to update simulator');
            }

            const updatedSimulator = await response.json();
            console.log(`Simulator updated: ${updatedSimulator}`);
            // Perform any necessary actions after successful update for each simulator
            alert('Simulator updated successfully');
        } catch (error) {
            console.error('Error updating simulator:', error);
            alert('Failed to update simulator. Please try again.');
        }
    };
    const handleRemove = async (simulatorId, indicatorId) => {
        try {
            await fetch(`/api/simulator/${simulatorId}/indicator/${indicatorId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            fetchData();
            fetchIndicators(); // Call fetchIndicators after removing data
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };
    const handleAdd = async () => {
        if (!newIndicator.indicator || !newIndicator.condition) {
            setError(t("error.all_fields"));
            return;
        }
        if (newIndicator.condition === 'between') {
            if (!newIndicator.minValue || !newIndicator.maxValue) {
                setError(t("error.all_fields"));
                return;
            }
        } else {
            if (!newIndicator.value) {
                setError(t("error.all_fields"));
                return;
            }
        }
        try {
            const username = getCookie('username'); // Get the username from the cookie
            let dataToAdd = { ...newIndicator, username };
            if (newIndicator.condition === 'between') {
                dataToAdd = { ...dataToAdd, value: `${newIndicator.minValue} - ${newIndicator.maxValue}` };
            }
            await fetch('/api/simulator', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToAdd),
            });
            fetchData();
            fetchIndicators();
            setNewIndicator({
                indicator: '',
                condition: '',
                value: '',
                minValue: '',
                maxValue: '',
            });
            setError('');
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const simulatorResponse = await fetch('/api/simulator');
            const simulatorData = await simulatorResponse.json();

            // Check if simulatorData is an array before processing it
            const resultsData = Array.isArray(simulatorData) && simulatorData?.[0]?.results ? simulatorData[0].results : {};
            setItemsSimulator(Array.isArray(simulatorData) ? simulatorData : []); // Ensure it's an array
            setSymbolDetails(resultsData);

            // Initialize statusVirtual and statusProd states based on the fetched data
            const virtualStates = Array.isArray(simulatorData) ? simulatorData.map(item => item.virtual_state === 1 ? 'online' : 'offline') : [];
            const prodStates = Array.isArray(simulatorData) ? simulatorData.map(item => item.prod_state === 1 ? 'online' : 'offline') : [];

            setStatusVirtual(virtualStates);
            setStatusProd(prodStates);

            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    }, []);


    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => clearInterval(intervalId);
    }, [fetchData]);
    const handleInputChange = (e, key) => {
        const value = e.target.value;
        setSelectedItem((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleSaveSettings = async () => {
        try {
            // Loop through itemsSimulator to perform update for each item
            for (let index = 0; index < itemsSimulator.length; index++) {
                const simulatorId = itemsSimulator[index]._id;

                // Define the updated data for the simulator
                const updatedData = {
                    entry_price: selectedItem.entry_price,
                    amount: selectedItem.amount,
                    take_profit: selectedItem.take_profit,
                    stoploss: selectedItem.stoploss,
                    max_count: selectedItem.max_count,
                    mode: selectedItem.mode,
                    account: selectedItem.account,
                    stablecoin: selectedItem.stablecoin,
                    first_run: 1,
                };

                // Make a PUT request to update the simulator for the given ID
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${updatedSimulator}`);
                // Perform any necessary actions after successful update for each simulator
            }

            // Perform additional actions after updating all simulators, if needed
            alert('All simulators updated successfully');
        } catch (error) {
            console.error('Error updating simulators:', error);
            alert('Failed to update simulators. Please try again.');
        }
    };

    const toggleMode = (index) => {
        const currentVirtualState = statusVirtual[index];
        const currentProdState = statusProd[index];

        // Determine new states based on current states
        let newVirtualState = 'offline';
        let newProdState = 'offline';

        if (isEssential || isPremium || isFreeTrial) {
            if (currentVirtualState === 'offline' && currentProdState === 'offline') {
                newVirtualState = 'online';
            } else if (currentVirtualState === 'online') {
                newProdState = 'online';
            }
        }
        if (isBasic && !isFreeTrial) {
            if (currentVirtualState === 'offline') {
                newVirtualState = 'online';
            } else if (currentVirtualState === 'online') {
                newVirtualState = 'offline';
            }
        }
        // Update local state
        const updatedStatusVirtual = [...statusVirtual];
        const updatedStatusProd = [...statusProd];
        updatedStatusVirtual[index] = newVirtualState;
        updatedStatusProd[index] = newProdState;

        setStatusVirtual(updatedStatusVirtual);
        setStatusProd(updatedStatusProd);

        // Prepare database update
        const simulatorId = itemsSimulator[index]._id;

        const requestBody = {
            virtual_state: newVirtualState === 'online' ? 1 : 0,
            prod_state: newProdState === 'online' ? 1 : 0,
            first_run:1,
        };

        // Update the database
        fetch(`/api/simulator/${simulatorId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((updatedData) => {
                console.log(`Mode toggled:`, updatedData);
            })
            .catch((error) => {
                console.error('Failed to update simulator:', error);
            });
    };


    const openModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);
    };
    // Process data for charts
    const performanceData = itemsSimulator.map((item) => ({
        name: item.strategie || 'N/A',
        successRate: item.variation,
        stability: Math.max(0, item.current_count / item.max_count),
    }));

    const pieData = [
        { name: 'Stable', value: performanceData.filter(d => d.stability > 0.5).length },
        { name: 'Unstable', value: performanceData.filter(d => d.stability <= 0.5).length },
    ];

    const COLORS = ['#0088FE', '#FF8042'];

    return (
        <div className="card-grid-wrapper-simulator">
            <div className="card-grid-simulator">
                {itemsSimulator.map((item, index) => {
                    const variationColor =
                        item.success < 50
                            ? '#f44336'
                            : item.success >= 50 && item.success <= 70
                                ? 'orange'
                                : '#4CAF50';

                    const benefitsColor =
                        item.benefits < 0 ? '#f44336' : item.benefits > 0 ? '#4CAF50' : '#AAA';

                    return ( // Add this return statement
                        <div className="card-simulator border-radius-bottom" key={index}>
                            {itemsSimulator.map((item, index) => (
                                <div key={item._id}>
                                    <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.strategie}
                                            <span className="s10-simulator_type" style={{ color: item.account === 'SPOT' ? '#f44336' : item.account === 'X-MARGIN' ? '#555' : '#AAA' }}>
                                                {item.account}
                                            </span>
                                        </div>
                                        {statusProd[index] === 'offline' && statusVirtual[index] === 'offline' ? (
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                size="sm"
                                                style={{ color: '#ff0000' }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faCircle}
                                                size="sm"
                                                style={{ color: '#00aa00' }}
                                            />
                                        )}
                                    </h3>
                                    <h2>
                                        {statusVirtual[index] === 'online'
                                            ? t("strategy_builder.virtual")
                                            : statusProd[index] === 'online'
                                                ? t("strategy_builder.production")
                                                : t("strategy_builder.off")}
                                    </h2>
                                    <h1>{t("strategy_builder.controls")}</h1>
                                    <div className="form-grid-simulator">
                                        <p><strong>{t("strategy_builder.amount")}:</strong> {item.amount} $</p>
                                        <p><strong>{t("strategy_builder.entry_price")}:</strong> {item.entry_price} %</p>
                                    </div>
                                    <div className="form-grid-simulator">
                                        <p><strong>{t("strategy_builder.take_profit")}:</strong> {item.take_profit} %</p>
                                        <p><strong>{t("strategy_builder.stoploss")}:</strong> {item.stoploss} %</p>
                                    </div>
                                    <div className="form-grid-simulator">
                                        <p><strong>{t("strategy_builder.current_count")}:</strong> {item.current_count}</p>
                                        <p><strong>{t("strategy_builder.max_count")}:</strong> {item.max_count}</p>
                                    </div>
                                    <div className="form-grid-simulator">
                                        <p>
                                            <strong>Mode:</strong>
                                            {item.mode === "Automatic" ? t("strategy_builder.automatic") : item.mode === "Thresholds" ? t("strategy_builder.thresholds") : item.mode}
                                        </p>
                                        <p><strong>{t("strategy_builder.blocked_symbols")}:</strong> {item.BLOCKED?.["Strategie"]?.length ?? 0}</p>
                                    </div>
                                    <div className="form-grid-simulator">
                                        <p><strong>{t("strategy_builder.stablecoins")}:</strong> {item.stablecoin}</p>
                                    </div>
                                    <div className="form-grid-simulator">
                                        <button className="details-button" onClick={() => openModal(item)}>{t("button.view_details")}</button>
                                        {(isEssential || isPremium || isFreeTrial) && (
                                            <button
                                                className="details-button"
                                                onClick={() => toggleMode(index)}
                                            >
                                                {statusVirtual[index] === "online"
                                                    ? t("button.switch_prod")
                                                    : statusProd[index] === "online"
                                                        ? t("button.switch_off")
                                                        : t("button.switch_virtual")}
                                            </button>
                                        )}
                                        {(isBasic && !isFreeTrial) && (
                                            <button
                                                className="details-button"
                                                onClick={() => toggleMode(index)}
                                            >
                                                {statusVirtual[index] === "online"
                                                    ? t("button.switch_off")
                                                    : t("button.switch_virtual")}
                                            </button>
                                        )}
                                    </div>
                                    <div className="form-grid-simulator">
                                        <button className="details-button" onClick={() => handleReset(item._id)}>{t("button.clear_data")}</button>
                                        {isAdmin && (
                                            <button
                                                className="details-button"
                                                onClick={() => exportToCSV(item._id)} // Pass the specific item's _id
                                            >
                                                {t("button.export_data")}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="symbol-cards-container">
                                <div className="symbol-cards">
                                    {Object.entries(symbolDetails).map(([symbol, details]) => {
                                        // Access the strategy data (e.g., "Allmighty")
                                        const strategyData = details.Allmighty;

                                        return (
                                            <div className="symbol-card" key={symbol}>
                                                <div className="symbol-header">
                                                    <h3>{symbol}</h3>
                                                </div>
                                                <div className="symbol-content">
                                                    <p><strong>Low:</strong> {strategyData?.low ?? '0'}</p>
                                                    <p><strong>High:</strong> {strategyData?.high ?? '0'}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* Modal */}
                            {isModalOpen && selectedItem && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <h3 style={{ paddingBottom: "25px" }}>{t("modal_title.details_allmighty")} {selectedItem.strategie || 'Invalid Strategy'}</h3>
                                        <button onClick={closeModal} className="close-btn">X</button>
                                        <div className="form-container" style={{ paddingBottom: "15px" }}>
                                            <form
                                                onSubmit={async (e) => {
                                                    e.preventDefault();
                                                    await handleSaveSettings(selectedItem.id);
                                                    // Optionally reload or fetch updated data here
                                                    fetchData();
                                                }}
                                            >
                                                <h1>{t("strategy_builder.settings")}</h1>
                                                <div className="form-grid" style={{ paddingTop: "5px" }}>
                                                    {[
                                                        { label: t("strategy_builder.amount"), key: 'amount', value: selectedItem.amount },
                                                        { label: t("strategy_builder.entry_price"), key: 'entry_price', value: selectedItem.entry_price },
                                                        { label: t("strategy_builder.take_profit"), key: 'take_profit', value: selectedItem.take_profit },
                                                        { label: t("strategy_builder.stoploss"), key: 'stoploss', value: selectedItem.stoploss },
                                                        { label: t("strategy_builder.max_count"), key: 'max_count', value: selectedItem.max_count },
                                                        { label: 'Mode', key: 'mode', value: selectedItem.mode },
                                                        { label: t("strategy_builder.account"), key: 'account', value: selectedItem.account },
                                                        { label: t("strategy_builder.stablecoins"), key: 'stablecoin', value: selectedItem.stablecoin },
                                                    ].map(({ label, key, value }, index) => (
                                                        <div className="form-group" key={index}>
                                                            <label style={styles.label}>{label}:</label>
                                                            {key === 'mode' ? (
                                                                <select
                                                                    value={selectedItem[key] || ''}
                                                                    onChange={(e) => handleInputChange(e, key)}
                                                                    style={styles.value}
                                                                >
                                                                    <option value="Thresholds">{t("strategy_builder.thresholds")}</option>
                                                                    <option value="Automatic">{t("strategy_builder.automatic")}</option>
                                                                </select>
                                                            ) : key === 'account' ? (
                                                                <select
                                                                    value={selectedItem[key] || ''}
                                                                    onChange={(e) => handleInputChange(e, key)}
                                                                    style={styles.value}
                                                                >
                                                                    <option value="SPOT">SPOT</option>
                                                                    <option value="X-MARGIN">X-MARGIN</option>
                                                                </select>
                                                            ) : key === 'max_count' ? (
                                                                <input
                                                                    type="number" // Use text to have full control over input behavior
                                                                    value={selectedItem[key] || ''}
                                                                    onChange={(e) => {
                                                                        let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters

                                                                        if (newValue === "") newValue = "1"; // Prevent empty value

                                                                        let intValue = parseInt(newValue, 10);

                                                                        if (isPremium || isFreeTrial) {
                                                                            if (intValue >= 1) {
                                                                                handleInputChange({ target: { value: intValue } }, key);
                                                                            }
                                                                        } else if (isEssential) {
                                                                            if (intValue >= 1 && intValue <= 30) {
                                                                                handleInputChange({ target: { value: intValue } }, key);
                                                                            }
                                                                        } else {
                                                                            if (intValue >= 1 && intValue <= 10) {
                                                                                handleInputChange({ target: { value: intValue } }, key);
                                                                            }
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "." || e.key === ",") {
                                                                            e.preventDefault(); // Stop decimals from being typed
                                                                        }
                                                                    }}
                                                                    inputMode="numeric" // Ensures numeric keyboard on mobile
                                                                    style={styles.value}
                                                                />
                                                            ) : key === 'take_profit' || key === 'entry_price' || key === 'stoploss' ? (
                                                                <select
                                                                    value={selectedItem[key] ?? ''}
                                                                    onChange={(e) => handleInputChange(e, key)}
                                                                    style={styles.value}
                                                                >
                                                                    {Array.from({ length: 601 }, (_, i) => i - 300).map((value) => (
                                                                        <option key={value/10} value={value/10}>
                                                                            {value/10} %
                                                                        </option>
                                                                    ))}
                                                                    {selectedItem[key] !== null && selectedItem[key] !== undefined && (
                                                                        <option value={selectedItem[key]} hidden>
                                                                            {selectedItem[key]} %
                                                                        </option>
                                                                    )}
                                                                </select>

                                                            ) : key === 'stablecoin' ? (
                                                                <select
                                                                    value={selectedItem[key] || ''}
                                                                    onChange={(e) => handleInputChange(e, key)}
                                                                    style={styles.value}
                                                                >
                                                                    <option value="USDT">USDT</option>
                                                                    <option value="USDC">USDC</option>
                                                                </select>
                                                            ) : (
                                                                <input
                                                                    type="number"
                                                                    value={selectedItem[key] || ""}
                                                                    onChange={(e) => {
                                                                        let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                                                                        handleInputChange({ target: { value: newValue } }, key); // Allow free input
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let numericValue = parseInt(e.target.value, 10);

                                                                        if (isNaN(numericValue)) {
                                                                            handleInputChange({ target: { value: 7 } }, key); // Default to 7 if empty
                                                                        } else {
                                                                            if (isPremium || isFreeTrial) {
                                                                                if (numericValue < 7) {
                                                                                    handleInputChange({ target: { value: 7 } }, key);
                                                                                } else {
                                                                                    handleInputChange({ target: { value: numericValue } }, key);
                                                                                }
                                                                            } else if (isEssential) {
                                                                                if (numericValue < 7) {
                                                                                    handleInputChange({ target: { value: 7 } }, key);
                                                                                } else if (numericValue > 100) {
                                                                                    handleInputChange({ target: { value: 100 } }, key);
                                                                                } else {
                                                                                    handleInputChange({ target: { value: numericValue } }, key);
                                                                                }
                                                                            } else {
                                                                                if (numericValue < 7) {
                                                                                    handleInputChange({ target: { value: 7 } }, key);
                                                                                } else if (numericValue > 10) {
                                                                                    handleInputChange({ target: { value: 10 } }, key);
                                                                                } else {
                                                                                    handleInputChange({ target: { value: numericValue } }, key);
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "." || e.key === ",") {
                                                                            e.preventDefault(); // Prevent decimals
                                                                        }
                                                                    }}
                                                                    inputMode="numeric"
                                                                    style={styles.value}
                                                                />


                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                                <button type="submit" className="details-button">{t("button.save")}</button>
                                                <h1 style={{ paddingTop: "15px" }}>{t("strategy_builder.indicators")}</h1>

                                                {/* Add Indicator Fields */}
                                                <div className="form-grid" style={{ paddingTop: "5px" }}>
                                                    <div className="form-group">
                                                        <select
                                                            value={newIndicator.indicator || ''}
                                                            onChange={(e) => setNewIndicator({ ...newIndicator, indicator: e.target.value })}
                                                        >
                                                            <option value="">{t("strategy_builder.select_indicator")}</option>
                                                            {availableIndicators.map(([value, label]) => (
                                                                <option key={value} value={value}>
                                                                    {label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <select
                                                            value={newIndicator.condition || ''}
                                                            onChange={(e) => setNewIndicator({ ...newIndicator, condition: e.target.value })}
                                                        >
                                                            <option value="">{t("strategy_builder.select_condition")}</option>
                                                            <option value="greater_than">{t("strategy_builder.greater_than")}</option>
                                                            <option value="lower_than">{t("strategy_builder.lower_than")}</option>
                                                            <option value="between">{t("strategy_builder.between")}</option>
                                                        </select>
                                                    </div>
                                                    {newIndicator.condition !== 'between' && (
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                placeholder={t("strategy_builder.value")}
                                                                value={newIndicator.value}
                                                                onChange={(e) => {
                                                                    if (isFloat(e.target.value)) {
                                                                        setNewIndicator({ ...newIndicator, value: e.target.value });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {newIndicator.condition === 'between' && (
                                                        <>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    placeholder={t("strategy_builder.min_value")}
                                                                    value={newIndicator.minValue}
                                                                    onChange={(e) => {
                                                                        if (isFloat(e.target.value)) {
                                                                            setNewIndicator({ ...newIndicator, minValue: e.target.value });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    placeholder={t("strategy_builder.max_value")}
                                                                    value={newIndicator.maxValue}
                                                                    onChange={(e) => {
                                                                        if (isFloat(e.target.value)) {
                                                                            setNewIndicator({ ...newIndicator, maxValue: e.target.value });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                </div>
                                                {error && <div className="error-message">{error}</div>}
                                                <button
                                                    type="button"
                                                    onClick={handleAdd}
                                                    className="details-button"
                                                >
                                                    {t("button.add_indicator")}
                                                </button>

                                                <div className="list-container">
                                                    {itemsSimulator.map((simulator) => (
                                                        <React.Fragment key={simulator._id}>
                                                            {simulator.indicators.map((item) => (
                                                                <div key={item._id} className="list-item">
                                                                    <div className="list-item-indicator">
                                                                        {/* Use indicatorNameMapSimulatorPremium to get the corresponding indicator name in French */}
                                                                        {t(`indicator.${item.indicator}`)}
                                                                    </div>
                                                                    <div className="list-item-details">
                                                                        <div style={{ fontSize: "8px", display: "inline" }}>
                                                                            {item.condition === "greater_than"
                                                                                ? t("strategy_builder.greater_than")
                                                                                : item.condition === "lower_than"
                                                                                    ? t("strategy_builder.lower_than")
                                                                                    : t("strategy_builder.between")}
                                                                        </div>
                                                                        <strong>{item.value}</strong>
                                                                    </div>
                                                                    <div className="list-item-actions">
                                                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleRemove(simulator._id, item._id)} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <h1>{t("strategy_builder.performance_review")}</h1>
                            <div className="form-grid-simulator">
                                <p><strong>{t("strategy_builder.investment")}:</strong> {item.investment.toFixed(1)} $</p>
                            </div>
                            <div className="form-grid-simulator">
                                <p><strong>{t("strategy_builder.avg_low")}:</strong> {(-1*item.avg_low).toFixed(2)} %</p>
                                <p><strong>{t("strategy_builder.avg_high")}:</strong> {item.avg_high.toFixed(2)} %</p>
                            </div>
                            <div className="form-grid-simulator">
                                <p><strong>{t("strategy_builder.tp_triggered")}:</strong> {item.tp}</p>
                                <p><strong>{t("strategy_builder.sl_triggered")}:</strong> {item.sl}</p>
                            </div>
                            {item.mode === "Automatic" && (
                                <ProgressionBarPerformanceReview
                                    stability={item.stability}
                                    range_ratio={item.range_ratio}
                                    price_position={item.price_position}
                                    performance_score={item.performance_score}
                                />
                            )}
                            {item.mode === "Thresholds" && (
                            <div className="simulator-success-info" style={{ paddingTop: "15px" }}>
                                <span>
                                    <span className="simulator-success">{t("strategy_builder.success")}: </span>
                                    <span
                                        className="simulator-variation"
                                        style={{ color: variationColor }}
                                    >
                                        {typeof item.success === 'number' ? item.success.toFixed(2) : item.success} %
                                    </span>
                                </span>
                                <span>
                                    <span className="simulator-success">{t("strategy_builder.b_l")}: </span>
                                    <span className="simulator-benefits" style={{ color: benefitsColor }}>
                                        {typeof item.benefits === 'number' ? item.benefits.toFixed(2) : item.benefits} $
                                    </span>
                                </span>
                            </div>
                            )}
                        </div>
                    );
                })}

            </div>
        </div>
    );
};
const styles = {
    label: {
        fontWeight: 'bold',
        color: '#555',
        fontSize: '9px',
    },
    value: {
        color: '#777',
        fontSize: '9px',
    }
};
export default Simulator_2;
