import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    // Map language codes to flag emojis
    const flagMap = {
        en: "🇬🇧", // UK flag for English
        fr: "🇫🇷", // French flag for French
    };

    return (
        <Select
            value={i18n.language} // Keeps track of selected language
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            displayEmpty
            size="small"
            sx={{
                minWidth: "auto",
                width: "36px",
                height: "36px",
                padding: "0",
                background: "transparent",
                textAlign: "center",
                "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 !important",
                    minHeight: "unset",
                },
                "& .MuiOutlinedInput-notchedOutline": { display: "none" },
                "&:hover .MuiOutlinedInput-notchedOutline": { display: "none" },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": { display: "none" },
            }}
            renderValue={() => flagMap[i18n.language] || "🌍"} // Show selected flag
            IconComponent={() => null} // Removes default dropdown arrow
        >
            <MenuItem value="en">🇬🇧 English</MenuItem>
            <MenuItem value="fr">🇫🇷 Français</MenuItem>
        </Select>
    );
};

export default LanguageSwitcher;
