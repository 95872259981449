//Hooks
import React, { useState, useEffect, useRef } from 'react';

//Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import { faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
//import { faFire } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faTrash, faSliders} from "@fortawesome/free-solid-svg-icons";

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import BuyStrategie from "./BuyStrategie";
import SellStrategie from "./SellStrategie";
//Styles
import './table.css';

const TablePortfolio = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItem2, setSelectedItem2] = useState(null);
  const [selectedCriteriaBuy, setSelectedCriteriaBuy] = useState('');
  const [itemsPortfolio, setItemsPortfolio] = useState([]);
  const [itemsHistory, setItemsHistory] = useState([]);
  const alertRef = useRef(null);
  const alertRef2 = useRef(null);
  const [selectedCriteriaStrategie, setSelectedCriteriaStrategie] = useState('');
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteriaListSell, setCriteriaListSell] = useState([]);
  const [criteriaListSell2, setCriteriaListSell2] = useState([]);
  const [criteriaListStopLoss, setCriteriaListStopLoss] = useState([]);
  const [criteriaListBuy, setCriteriaListBuy] = useState([]);
  const [criteriaListBuy2, setCriteriaListBuy2] = useState([]);
  const [criteriaListBuy3, setCriteriaListBuy3] = useState([]);
  const [criteriaListBuy4, setCriteriaListBuy4] = useState([]);
  const [criteriaListBuy5, setCriteriaListBuy5] = useState([]);
  const [criteriaListStrategie, setCriteriaListStrategie] = useState([]);
  const [statusList, setStatusList] = useState(itemsPortfolio.map(() => 'online'));
  const [alertTriggered, setAlertTriggered] = useState(false);
  const [selectedThresholds, setSelectedThresholds] = useState({
    buy_t1: '',
    buy_t2: '',
    buy_t3: '',
    buy_t4: '',
    buy_t5: '',
    sell_t1: '',
    sell_t2: '',
    sell_t3: '',
    sell_t4: '',
    sell_t5: '',
    multiplier_buy_t1: '',
    multiplier_buy_t2: '',
    multiplier_buy_t3: '',
    multiplier_buy_t4: '',
    multiplier_buy_t5: '',
    multiplier_sell_t1: '',
    multiplier_sell_t2: '',
    multiplier_sell_t3: '',
    multiplier_sell_t4: '',
    multiplier_sell_t5: '',
    buy_t1_cross_up_down: '',
    buy_t2_cross_up_down: '',
    buy_t3_cross_up_down: '',
    buy_t4_cross_up_down: '',
    buy_t5_cross_up_down: '',
    sell_t1_cross_up_down: '',
    sell_t2_cross_up_down: '',
    sell_t3_cross_up_down: '',
    sell_t4_cross_up_down: '',
    sell_t5_cross_up_down: '',
    stoploss: '',
    strategie: '',
  });
  function roundDecimals(num) {
    let numStr = num.toString();
    let [integerPart, decimalPart] = numStr.split('.');
    let numLen = integerPart.length + (decimalPart ? decimalPart.length : 0); // Add a check for undefined decimalPart

    if (num < 1) {
      return parseFloat(num.toFixed(Math.min(6, decimalPart ? decimalPart.length : 0))); // Add a check for undefined decimalPart
    } else if (num < 10) {
      return parseFloat(num.toFixed(Math.min(6, numLen)));
    } else if (num < 100) {
      return parseFloat(num.toFixed(Math.min(5, numLen)));
    } else if (num < 1000) {
      return parseFloat(num.toFixed(Math.min(4, numLen)));
    } else if (num < 10000) {
      return parseFloat(num.toFixed(Math.min(3, numLen)));
    } else if (num < 100000) {
      return parseFloat(num.toFixed(Math.min(2, numLen)));
    }
  }

  const handleDelete = async (item) => {
    const portfolioId = item._id;  // Extract the portfolio ID from the item object
    console.log('Deleting portfolio with ID:', portfolioId);  // Log the correct ID

    try {
      const response = await fetch(`/api/portfolios/${portfolioId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete the portfolio row');
      }

      const deletedPortfolioRowData = await response.json();

      // Update the portfolio list by removing the deleted one
      setItemsPortfolio(prevItems => prevItems.filter(portfolio => portfolio._id !== portfolioId));

      console.log('Deleted portfolio:', deletedPortfolioRowData);
    } catch (error) {
      console.error('Error deleting portfolio:', error);
    }
  };
  const handleUpdateButtonClick = () => {
    // Get the current thresholds for the selected item
    const currentItem = itemsPortfolio.find((item) =>
        item.cryptoname === selectedItem2.cryptoname && item.type === selectedItem2.type
    );
    // Create an updatedData object with the new values
    const updatedData = {
      buy_t1: selectedThresholds.buy_t1 !== '' ? selectedThresholds.buy_t1 : currentItem.buy_t1,
      buy_t2: selectedThresholds.buy_t2 !== '' ? selectedThresholds.buy_t2 : currentItem.buy_t2,
      buy_t3: selectedThresholds.buy_t3 !== '' ? selectedThresholds.buy_t3 : currentItem.buy_t3,
      buy_t4: selectedThresholds.buy_t4 !== '' ? selectedThresholds.buy_t4 : currentItem.buy_t4,
      buy_t5: selectedThresholds.buy_t5 !== '' ? selectedThresholds.buy_t5 : currentItem.buy_t5,
      sell_t1: selectedThresholds.sell_t1 !== '' ? selectedThresholds.sell_t1 : currentItem.sell_t1,
      sell_t2: selectedThresholds.sell_t2 !== '' ? selectedThresholds.sell_t2 : currentItem.sell_t2,
      sell_t3: selectedThresholds.sell_t3 !== '' ? selectedThresholds.sell_t3 : currentItem.sell_t3,
      sell_t4: selectedThresholds.sell_t4 !== '' ? selectedThresholds.sell_t4 : currentItem.sell_t4,
      sell_t5: selectedThresholds.sell_t5 !== '' ? selectedThresholds.sell_t5 : currentItem.sell_t5,
      multiplier_buy_t1: selectedThresholds.multiplier_buy_t1 !== '' ? selectedThresholds.multiplier_buy_t1 : currentItem.multiplier_buy_t1,
      multiplier_buy_t2: selectedThresholds.multiplier_buy_t2 !== '' ? selectedThresholds.multiplier_buy_t2 : currentItem.multiplier_buy_t2,
      multiplier_buy_t3: selectedThresholds.multiplier_buy_t3 !== '' ? selectedThresholds.multiplier_buy_t3 : currentItem.multiplier_buy_t3,
      multiplier_buy_t4: selectedThresholds.multiplier_buy_t4 !== '' ? selectedThresholds.multiplier_buy_t4 : currentItem.multiplier_buy_t4,
      multiplier_buy_t5: selectedThresholds.multiplier_buy_t5 !== '' ? selectedThresholds.multiplier_buy_t5 : currentItem.multiplier_buy_t5,
      multiplier_sell_t1: selectedThresholds.multiplier_sell_t1 !== '' ? selectedThresholds.multiplier_sell_t1 : currentItem.multiplier_sell_t1,
      multiplier_sell_t2: selectedThresholds.multiplier_sell_t2 !== '' ? selectedThresholds.multiplier_sell_t2 : currentItem.multiplier_sell_t2,
      multiplier_sell_t3: selectedThresholds.multiplier_sell_t3 !== '' ? selectedThresholds.multiplier_sell_t3 : currentItem.multiplier_sell_t3,
      multiplier_sell_t4: selectedThresholds.multiplier_sell_t4 !== '' ? selectedThresholds.multiplier_sell_t4 : currentItem.multiplier_sell_t4,
      multiplier_sell_t5: selectedThresholds.multiplier_sell_t5 !== '' ? selectedThresholds.multiplier_sell_t5 : currentItem.multiplier_sell_t5,
      buy_t1_cross_up_down: selectedThresholds.buy_t1_cross_up_down !== '' ? selectedThresholds.buy_t1_cross_up_down : currentItem.buy_t1_cross_up_down,
      buy_t2_cross_up_down: selectedThresholds.buy_t2_cross_up_down !== '' ? selectedThresholds.buy_t2_cross_up_down : currentItem.buy_t2_cross_up_down,
      buy_t3_cross_up_down: selectedThresholds.buy_t3_cross_up_down !== '' ? selectedThresholds.buy_t3_cross_up_down : currentItem.buy_t3_cross_up_down,
      buy_t4_cross_up_down: selectedThresholds.buy_t4_cross_up_down !== '' ? selectedThresholds.buy_t4_cross_up_down : currentItem.buy_t4_cross_up_down,
      buy_t5_cross_up_down: selectedThresholds.buy_t5_cross_up_down !== '' ? selectedThresholds.buy_t5_cross_up_down : currentItem.buy_t5_cross_up_down,
      sell_t1_cross_up_down: selectedThresholds.sell_t1_cross_up_down !== '' ? selectedThresholds.sell_t1_cross_up_down : currentItem.sell_t1_cross_up_down,
      sell_t2_cross_up_down: selectedThresholds.sell_t2_cross_up_down !== '' ? selectedThresholds.sell_t2_cross_up_down : currentItem.sell_t2_cross_up_down,
      sell_t3_cross_up_down: selectedThresholds.sell_t3_cross_up_down !== '' ? selectedThresholds.sell_t3_cross_up_down : currentItem.sell_t3_cross_up_down,
      sell_t4_cross_up_down: selectedThresholds.sell_t4_cross_up_down !== '' ? selectedThresholds.sell_t4_cross_up_down : currentItem.sell_t4_cross_up_down,
      sell_t5_cross_up_down: selectedThresholds.sell_t5_cross_up_down !== '' ? selectedThresholds.sell_t5_cross_up_down : currentItem.sell_t5_cross_up_down,
      stoploss: selectedThresholds.stoploss !== '' ? selectedThresholds.stoploss : currentItem.stoploss,
      strategie: selectedThresholds.strategie !== '' ? selectedThresholds.strategie : currentItem.strategie,
    };

    // Send a PUT request to update the data in the database
    const portfolioId = currentItem._id;

    fetch(`/api/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
        .then((response) => response.json())
        .then((updatedData) => {
          // Update the state with the newly updated data
          const updatedItemsPortfolio = itemsPortfolio.map(item => {
            if (item._id === portfolioId) {
              return {
                ...item,
                ...updatedData,
              };
            }
            return item;
          });

          // Update the state with the new data
          setItemsPortfolio(updatedItemsPortfolio);

          // Optionally provide feedback to the user
          alert('Data updated successfully!');
        })
        .catch((error) => {
          // Handle errors
          console.error('Failed to update portfolio:', error);
          // Optionally provide feedback to the user
          alert('Failed to update data. Please try again later.');
        });
  };
// Update the handleCriteriaChangeBuy function to handle changes in threshold values
  const handleCriteriaChangeBuy = (e, thresholdName) => {
    const { value } = e.target;
    setSelectedThresholds(prevState => ({
      ...prevState,
      [thresholdName]: value,
    }));
  };
  const handleButtonClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    //alert("Item selected: " + item.id);
  };
  const handleButtonClick_Strategie = (e, item) => {
    const selectedItemInList = criteriaListStrategie.some((criteria) => criteria !== '');

    if (!selectedItemInList && item) {
      e.stopPropagation();
      setSelectedItem2(item);
      setAlertTriggered(true);
    } else if (selectedItemInList && item && selectedItem2 !== item) {
      e.stopPropagation();
      setSelectedItem2(item);
      setAlertTriggered(true);
    } else {
      // If another line is selected while alert is already triggered, reset alertTriggered
      setAlertTriggered(false);
    }
  };
  const handleClickOutside = (e) => {
    if (alertRef.current && !alertRef.current.contains(e.target)) {
      setSelectedItem(null);
    }
    if (alertRef2.current && !alertRef2.current.contains(e.target)) {
      setSelectedItem2(null);
      setCriteriaListStrategie([]);
      setSelectedThresholds({
        buy_t1: '',
        buy_t2: '',
        buy_t3: '',
        buy_t4: '',
        buy_t5: '',
        sell_t1: '',
        sell_t2: '',
        sell_t3: '',
        sell_t4: '',
        sell_t5: '',
        multiplier_buy_t1: '',
        multiplier_buy_t2: '',
        multiplier_buy_t3: '',
        multiplier_buy_t4: '',
        multiplier_buy_t5: '',
        multiplier_sell_t1: '',
        multiplier_sell_t2: '',
        multiplier_sell_t3: '',
        multiplier_sell_t4: '',
        multiplier_sell_t5: '',
        buy_t1_cross_up_down: '',
        buy_t2_cross_up_down: '',
        buy_t3_cross_up_down: '',
        buy_t4_cross_up_down: '',
        buy_t5_cross_up_down: '',
        sell_t1_cross_up_down: '',
        sell_t2_cross_up_down: '',
        sell_t3_cross_up_down: '',
        sell_t4_cross_up_down: '',
        sell_t5_cross_up_down: '',
        stoploss: '',
        strategie: '',
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleCriteriaChangeStrategie = (e, index) => {
    const newCriteriaListStrategie = [...criteriaListStrategie];
    newCriteriaListStrategie[index] = e.target.value;
    setCriteriaListStrategie(newCriteriaListStrategie);
    setSelectedCriteriaStrategie(e.target.value);
  };
  const handleStatusChange = (index) => {
    const updatedStatusList = [...statusList];
    const updatedStatus = updatedStatusList[index] === 'online' ? 'offline' : 'online';
    updatedStatusList[index] = updatedStatus;
    setStatusList(updatedStatusList);

    const portfolioId = itemsPortfolio[index]._id;
    const updatedStatusValue = updatedStatus === 'online' ? 1 : 0;
    // Create the request body
    const updatedData = {
      state: updatedStatusValue,
    };
    fetch(`/api/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ state: updatedStatusValue }),
    })
        .then((response) => response.json())
        .then((updatedData) => {
          // Handle the updated portfolio data
          console.log(updatedData);
        })
        .catch((error) => {
          // Handle errors
          console.error('Failed to update portfolio:', error);
        });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch initial data for portfolios
        const portfoliosResponse = await fetch('/api/portfolios');
        const portfoliosData = await portfoliosResponse.json();
        setItemsPortfolio(portfoliosData);
        setCriteriaList(portfoliosData.map(() => ''));
        setStatusList(portfoliosData.map(item => (item.state === 1 ? 'online' : 'offline')));

        // Fetch initial data for orderfilleds if selectedItem exists
        if (selectedItem) {
          const orderFilledResponse = await fetch(`/api/orderfilleds?portfolio_id=${selectedItem.id}`);
          const orderFilledData = await orderFilledResponse.json();
          setItemsHistory(orderFilledData);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData(); // Fetch initial data immediately when component mounts

    // Set up interval for subsequent updates after the initial fetch
    const intervalId = setInterval(() => {
      fetchData(); // Fetch data every 3 seconds
    }, 60000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [selectedItem]); // Run the effect only when selectedItem changes

// Define a function to calculate the value based on the selected percentage
  function calculateValue(selectedPercentage) {
    const buy_t1Value = parseFloat(selectedPercentage || 0); // Parse to float, handle null or empty string
    if (isNaN(buy_t1Value)) return '-'; // Display a dash if the value is not valid
    const boughtPrice = itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).bought_price;
    const calculatedValue = roundDecimals((100 + buy_t1Value) / 100 * boughtPrice);

    // Check if calculatedValue is undefined
    if (typeof calculatedValue === 'undefined') {
      return '-'; // Return dash if calculatedValue is undefined
    }

    return calculatedValue; // Return calculatedValue if it's not undefined
  }
  function calculateValueShort(selectedPercentage) {
    const buy_t1Value = parseFloat(selectedPercentage || 0); // Parse to float, handle null or empty string
    if (isNaN(buy_t1Value)) return '-'; // Display a dash if the value is not valid
    const boughtPrice = itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).bought_price;
    const calculatedValue = roundDecimals(((100 - buy_t1Value)) / 100 * boughtPrice);

    // Check if calculatedValue is undefined
    if (typeof calculatedValue === 'undefined') {
      return '-'; // Return dash if calculatedValue is undefined
    }

    return calculatedValue; // Return calculatedValue if it's not undefined
  }
  return (
      <div className="table-container" style={{ overflow: "auto", maxWidth: "100%", height: "93%" }}>
        <table id="portfolio-table">
          <thead>
          <tr>
            <th style={{ width: "25%" }}>Symbol</th>
            <th style={{ width: "25%" }}>Amount</th>
            <th style={{ width: "25%" }}>Position</th>
            <th style={{ width: "25%" }}>Control</th>
          </tr>
          </thead>
          <tbody>
          {itemsPortfolio.map((item, index) => (
              <tr key={item.id}>
                <td onClick={(e) => handleButtonClick(e, item)} style={{ width: "25%" }}>
                  <div style={{ display: 'inline-block' }}>
                    {item.cryptoname}
                  </div>
                  <div className="s10-portfolio_type" style={{ color: item.type === 'SPOT' ? '#f44336' : item.type === 'X-MARGIN' ? '#555' : '#AAA', display: 'block' }}>
                    {item.type}
                  </div>
                </td>
                <td style={{ width: "25%" }}>
                  <div>
                    {item.init_invest}
                    <span className="benefits" style={{ fontSize: 'smaller', marginLeft: '5px' }}>
                      ({item.benefits})
                    </span>
                                  </div>
                                  <div style={{ marginTop: '1px' }}>
                    <span style={{ color: item.variation > 0 ? '#4CAF50' : item.variation < 0 ? '#f44336' : '#AAA' }}>
                      {item.variation}
                    </span>
                  </div>
                </td>
                <td style={{ width: "25%" }}>
                  <div className="s12" style={{ color: item.position === 'SHORT' ? '#e25045' : item.position === 'LONG' ? '#4CAF50' : '#F0B90B', display: 'block' }}>
                    <span>{item.position}</span>
                  </div>
                  <span>level: {item.level}</span>
                </td>
                <td style={{ width: "25%" }}>
                  <span className="control_portfolio" style={{ alignItems: "center",minWidth:"100px" }}>

                    <FontAwesomeIcon
                        icon={faSliders} // Replace this with the settings icon you want
                        size="sm"
                        style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                        onClick={(e) => handleButtonClick_Strategie(e, item)}
                    />
                  <FontAwesomeIcon
                      icon={faTrash} // Replace this with the settings icon you want
                      size="sm"
                      style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                      onClick={() => handleDelete(item)}
                  />
                    {item.strategie}
                    {statusList[index] === 'online' ? (
                        <FontAwesomeIcon
                            icon={faCircle}
                            size="sm"
                            style={{ color: "#00aa00", cursor: "pointer", marginLeft: '5px' }}
                            onClick={() => handleStatusChange(index)}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faCircle}
                            size="sm"
                            style={{ color: "#ff0000", cursor: "pointer", marginLeft: '5px' }}
                            onClick={() => handleStatusChange(index)}
                        />
                    )}

                  </span>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
        {selectedItem && (
            <div className="overlay">
              {itemsPortfolio
                      .find((item) => item.cryptoname === selectedItem.cryptoname) // use find instead of filter
                  && (
                      <div className="alert" ref={alertRef}>
                        <div className="card dashboard_text_3 row-19 border-radius">
                          <div className="dashboard_title_2 col-12 s10 border-radius-top">Orders history</div>
                          <table id="portfolio-table">
                            <thead>
                            <tr>
                              <th>cryptoname</th>
                              <th>price</th>
                              <th>date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {itemsHistory
                                .filter((item) => item.cryptoname === selectedItem.cryptoname)
                                .map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        <div style={{ display: 'inline-block' }}>
                                          {item.cryptoname}
                                        </div>
                                        <div className="s10-portfolio_type" style={{ color: item.side === 'BUY' ? '#74d600' : '#f44336', display: 'block' }}>
                                          {item.side}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {item.price}
                                        </div>
                                        <div>
                                          <span className="benefits" style={{ fontSize: 'smaller'}}>
                                            ({item.quantity})
                                          </span>
                                        </div>
                                      </td>
                                      <td>{item.date}</td>
                                    </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                  )}
            </div>
        )}
        {selectedItem2 && (
            <div className="overlay">
              {itemsPortfolio
                      .find((item) => item.cryptoname === selectedItem2.cryptoname) // use find instead of filter
                  && (

                      <div className="alert" ref={alertRef2}>
                        <div className="linechart col-12 s10b">
                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).cryptoname}&nbsp;&nbsp;
                          <select
                              value={selectedThresholds.strategie}
                              onChange={(e) => handleCriteriaChangeBuy(e, 'strategie')}
                          >
                            {/* Display current value */}
                            <option value="">
                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).strategie}
                            </option>
                            <option value="scalping">scalping</option>
                            <option value="dca">dca</option>
                            <option value="swing">swing</option>
                            <option value="daily">daily</option>
                            <option value="listing">listing</option>
                            <option value="botmight">botmight</option>
                          </select>
                        </div>
                        {/* Check if the position is LONG */}
                        {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).position === "LONG" ? (
                            <>
                        <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                          <div className="linechart col-12 row-20 pad-right-10 border-radius">
                            <div className="dashboard_text_3 col-12 row-20 border-radius">
                              {/* Main Line */}
                              <div className="dashboard_title_2 col-12 s10-strategie-buy border-radius-top center">BUY</div>
                              <div className="mobile_textsize">
                              <div className="main-line-details">
                                <div className="main-line-details">
                                  <div className="dashboard_title_2 col-6 s10b">Bought</div>
                                  <div className="dashboard_title_2 col-6 s10b">Amount</div>
                                  <div className="s10-grey col-6">
                                      {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).bought_price}
                                  </div>
                                  <div className="s10-grey col-6">
                                      {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest}
                                  </div>
                                </div>
                                <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Consolidate</div>
                              </div>

                              {/* Subline for Consolidate */}
                                      <div className="subline col-12 row-20 border-radius">
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <>
                                                <span style={{ color: selectedItem2.threshold_buy === 1 ? "#74d600" : "inherit" }}>#1&nbsp;&nbsp;</span>
                                                <select
                                                    value={selectedThresholds.buy_t1}
                                                    onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t1')}
                                                >
                                                  {/* Dynamically generated options for negative values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = -90 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}

                                                  {/* Option for selected value */}
                                                  <option
                                                      value=""
                                                      selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 === null}
                                                  >
                                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 !== null ?
                                                        itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 + " % (" + calculateValue(selectedItem2.buy_t1) + ")" :
                                                        "Select an option"
                                                    }
                                                  </option>
                                                  {/* Dynamically generated options for positive values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = 0 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}
                                                </select>
                                                &nbsp;
                                              </>
                                          <select
                                              value={selectedThresholds.multiplier_buy_t1}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t1')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t1}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                            {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t1_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t1_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <>
                                                <span style={{ color: selectedItem2.threshold_buy === 2 ? "#74d600" : "inherit" }}>#2&nbsp;&nbsp;</span>
                                                <select
                                                    value={selectedThresholds.buy_t2}
                                                    onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t2')}
                                                >
                                                  {/* Dynamically generated options for negative values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = -90 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}

                                                  {/* Option for selected value */}
                                                  <option
                                                      value=""
                                                      selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 === null}
                                                  >
                                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 !== null ?
                                                        itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 + " % (" + calculateValue(selectedItem2.buy_t2) + ")" :
                                                        "Select an option"
                                                    }
                                                  </option>
                                                  {/* Dynamically generated options for positive values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = 0 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}
                                                </select>
                                                &nbsp;
                                              </>
                                          <select
                                              value={selectedThresholds.multiplier_buy_t2}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t2')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t2}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t2_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t2_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <>
                                                <span style={{ color: selectedItem2.threshold_buy === 3 ? "#74d600" : "inherit" }}>#3&nbsp;&nbsp;</span>
                                                <select
                                                    value={selectedThresholds.buy_t3}
                                                    onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t3')}
                                                >
                                                  {/* Dynamically generated options for negative values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = -90 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}

                                                  {/* Option for selected value */}
                                                  <option
                                                      value=""
                                                      selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 === null}
                                                  >
                                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 !== null ?
                                                        itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 + " % (" + calculateValue(selectedItem2.buy_t3) + ")" :
                                                        "Select an option"
                                                    }
                                                  </option>
                                                  {/* Dynamically generated options for positive values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = 0 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}
                                                </select>
                                                &nbsp;
                                              </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t3}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t3')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t3}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t3_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t3_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>

                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <>
                                                <span style={{ color: selectedItem2.threshold_buy === 4 ? "#74d600" : "inherit" }}>#4&nbsp;&nbsp;</span>
                                                <select
                                                    value={selectedThresholds.buy_t4}
                                                    onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t4')}
                                                >
                                                  {/* Dynamically generated options for negative values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = -90 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}

                                                  {/* Option for selected value */}
                                                  <option
                                                      value=""
                                                      selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 === null}
                                                  >
                                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 !== null ?
                                                        itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 + " % (" + calculateValue(selectedItem2.buy_t4) + ")" :
                                                        "Select an option"
                                                    }
                                                  </option>
                                                  {/* Dynamically generated options for positive values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = 0 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}
                                                </select>
                                                &nbsp;
                                              </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t4}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t4')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t4}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t4_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t4_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy border-radius-bottom" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <>
                                                <span style={{ color: selectedItem2.threshold_buy === 5 ? "#74d600" : "inherit" }}>#5&nbsp;&nbsp;</span>
                                                <select
                                                    value={selectedThresholds.buy_t5}
                                                    onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t5')}
                                                >
                                                  {/* Dynamically generated options for negative values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = -90 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}

                                                  {/* Option for selected value */}
                                                  <option
                                                      value=""
                                                      selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 === null}
                                                  >
                                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 !== null ?
                                                        itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 + " % (" + calculateValue(selectedItem2.buy_t5) + ")" :
                                                        "Select an option"
                                                    }
                                                  </option>
                                                  {/* Dynamically generated options for positive values */}
                                                  {Array.from({ length: 900 }, (_, i) => {
                                                    const value = 0 + i / 10;
                                                    return (
                                                        <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                          {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                        </option>
                                                    );
                                                  })}
                                                </select>
                                                &nbsp;
                                              </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t5}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t5')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t5}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t5_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t5_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>

                                      </div>
                              </div>
                           </div>
                          </div>
                        </div>
                            </>
                        ) : (
                            <>
                        <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                          <div className="linechart col-12 row-20 border-radius">
                            <div className="dashboard_text_3 col-12 row-20 border-radius">

                              {/* Main Line */}
                              <div className="dashboard_title_2 col-12 s10-strategie-sell border-radius-top center">SELL</div>
                              <div className="main-line-details">
                                <div className="main-line-details">
                                  <div className="dashboard_title_2 col-6 s10b">Bought</div>
                                  <div className="dashboard_title_2 col-6 s10b">Amount</div>
                                  <div className="s10-grey col-6">
                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).bought_price}
                                  </div>
                                  <div className="s10-grey col-6">
                                    {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest}
                                  </div>
                                </div>
                                <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Consolidate</div>
                              </div>

                                  <div className="subline col-12 row-20 border-radius">
                                    <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_sell === 1 ? "#f44336" : "inherit" }}>#1&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.sell_t1}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t1')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 + " % (" + calculateValueShort(selectedItem2.sell_t1) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>
                                      <select
                                          value={selectedThresholds.multiplier_sell_t1}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t1')}
                                      >
                                        <option value="">
                                          {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t1}
                                        </option>

                                        {/* Adding values from 0.01 to 0.09 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                  x {((i + 1) / 100).toFixed(2)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 0.1 to 0.9 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                  x {(i / 10 + 0.1).toFixed(1)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 1 onwards */}
                                        {[...Array(1).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          if ((initInvest * (i + 1)) <= initInvest) {
                                            return (
                                                <option key={i + 1} value={i + 1}>
                                                  x {i + 1}
                                                </option>
                                            );
                                          }
                                          return null;
                                        })}
                                      </select>

                                      &nbsp;
                                      {/* Cross up/down select */}
                                      <select
                                          value={selectedThresholds.sell_t1_cross_up_down}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t1_cross_up_down')}
                                      >
                                        {/* Display current value */}
                                        <option value="">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1_cross_up_down}
                                        </option>
                                        <option value="Cross Up">Cross Up</option>
                                        <option value="Cross Down">Cross Down</option>
                                      </select>
                                    </div>
                                    <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <>
                                        <span style={{ color: selectedItem2.threshold_sell === 2 ? "#f44336" : "inherit" }}>#2&nbsp;&nbsp;</span>
                                        <select
                                            value={selectedThresholds.sell_t2}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t2')}
                                        >
                                          {/* Dynamically generated options for negative values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = -90 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}

                                          {/* Option for selected value */}
                                          <option
                                              value=""
                                              selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 === null}
                                          >
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 !== null ?
                                                itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 + " % (" + calculateValueShort(selectedItem2.sell_t2) + ")" :
                                                "Select an option"
                                            }
                                          </option>
                                          {/* Dynamically generated options for positive values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = 0 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}
                                        </select>
                                        &nbsp;
                                      </>
                                      <select
                                          value={selectedThresholds.multiplier_sell_t2}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t2')}
                                      >
                                        <option value="">
                                          {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t2}
                                        </option>

                                        {/* Adding values from 0.01 to 0.09 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                  x {((i + 1) / 100).toFixed(2)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 0.1 to 0.9 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                  x {(i / 10 + 0.1).toFixed(1)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 1 onwards */}
                                        {[...Array(1).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          if ((initInvest * (i + 1)) <= initInvest) {
                                            return (
                                                <option key={i + 1} value={i + 1}>
                                                  x {i + 1}
                                                </option>
                                            );
                                          }
                                          return null;
                                        })}
                                      </select>
                                      &nbsp;
                                      {/* Cross up/down select */}
                                      <select
                                          value={selectedThresholds.sell_t2_cross_up_down}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t2_cross_up_down')}
                                      >
                                        {/* Display current value */}
                                        <option value="">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2_cross_up_down}
                                        </option>
                                        <option value="Cross Up">Cross Up</option>
                                        <option value="Cross Down">Cross Down</option>
                                      </select>
                                    </div>
                                    <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <>
                                        <span style={{ color: selectedItem2.threshold_sell === 3 ? "#f44336" : "inherit" }}>#3&nbsp;&nbsp;</span>
                                        <select
                                            value={selectedThresholds.sell_t3}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t3')}
                                        >
                                          {/* Dynamically generated options for negative values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = -90 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}

                                          {/* Option for selected value */}
                                          <option
                                              value=""
                                              selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 === null}
                                          >
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 !== null ?
                                                itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 + " % (" + calculateValueShort(selectedItem2.sell_t3) + ")" :
                                                "Select an option"
                                            }
                                          </option>
                                          {/* Dynamically generated options for positive values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = 0 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}
                                        </select>
                                        &nbsp;
                                      </>
                                      <select
                                          value={selectedThresholds.multiplier_sell_t3}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t3')}
                                      >
                                        <option value="">
                                          {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t3}
                                        </option>

                                        {/* Adding values from 0.01 to 0.09 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                  x {((i + 1) / 100).toFixed(2)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 0.1 to 0.9 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                  x {(i / 10 + 0.1).toFixed(1)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 1 onwards */}
                                        {[...Array(1).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          if ((initInvest * (i + 1)) <= initInvest) {
                                            return (
                                                <option key={i + 1} value={i + 1}>
                                                  x {i + 1}
                                                </option>
                                            );
                                          }
                                          return null;
                                        })}
                                      </select>
                                      &nbsp;
                                      {/* Cross up/down select */}
                                      <select
                                          value={selectedThresholds.sell_t3_cross_up_down}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t3_cross_up_down')}
                                      >
                                        {/* Display current value */}
                                        <option value="">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3_cross_up_down}
                                        </option>
                                        <option value="Cross Up">Cross Up</option>
                                        <option value="Cross Down">Cross Down</option>
                                      </select>
                                    </div>
                                    <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <>
                                        <span style={{ color: selectedItem2.threshold_sell === 4 ? "#f44336" : "inherit" }}>#4&nbsp;&nbsp;</span>
                                        <select
                                            value={selectedThresholds.sell_t4}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t4')}
                                        >
                                          {/* Dynamically generated options for negative values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = -90 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}

                                          {/* Option for selected value */}
                                          <option
                                              value=""
                                              selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 === null}
                                          >
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 !== null ?
                                                itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 + " % (" + calculateValueShort(selectedItem2.sell_t4) + ")" :
                                                "Select an option"
                                            }
                                          </option>
                                          {/* Dynamically generated options for positive values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = 0 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}
                                        </select>
                                        &nbsp;
                                      </>
                                      <select
                                          value={selectedThresholds.multiplier_sell_t4}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t4')}
                                      >
                                        <option value="">
                                          {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t4}
                                        </option>

                                        {/* Adding values from 0.01 to 0.09 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                  x {((i + 1) / 100).toFixed(2)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 0.1 to 0.9 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                  x {(i / 10 + 0.1).toFixed(1)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 1 onwards */}
                                        {[...Array(1).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          if ((initInvest * (i + 1)) <= initInvest) {
                                            return (
                                                <option key={i + 1} value={i + 1}>
                                                  x {i + 1}
                                                </option>
                                            );
                                          }
                                          return null;
                                        })}
                                      </select>
                                      &nbsp;
                                      {/* Cross up/down select */}
                                      <select
                                          value={selectedThresholds.sell_t4_cross_up_down}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t4_cross_up_down')}
                                      >
                                        {/* Display current value */}
                                        <option value="">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4_cross_up_down}
                                        </option>
                                        <option value="Cross Up">Cross Up</option>
                                        <option value="Cross Down">Cross Down</option>
                                      </select>
                                    </div>
                                    <div className="dashboard_title_2 s15-buy border-radius-bottom" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <>
                                        <span style={{ color: selectedItem2.threshold_sell === 5 ? "#f44336" : "inherit" }}>#5&nbsp;&nbsp;</span>
                                        <select
                                            value={selectedThresholds.sell_t5}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t5')}
                                        >
                                          {/* Dynamically generated options for negative values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = -90 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}

                                          {/* Option for selected value */}
                                          <option
                                              value=""
                                              selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 === null}
                                          >
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 !== null ?
                                                itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 + " % (" + calculateValueShort(selectedItem2.sell_t5) + ")" :
                                                "Select an option"
                                            }
                                          </option>
                                          {/* Dynamically generated options for positive values */}
                                          {Array.from({ length: 900 }, (_, i) => {
                                            const value = 0 + i / 10;
                                            return (
                                                <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                  {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                </option>
                                            );
                                          })}
                                        </select>
                                        &nbsp;
                                      </>
                                      <select
                                          value={selectedThresholds.multiplier_sell_t5}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t5')}
                                      >
                                        <option value="">
                                          {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t5}
                                        </option>

                                        {/* Adding values from 0.01 to 0.09 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                  x {((i + 1) / 100).toFixed(2)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 0.1 to 0.9 */}
                                        {[...Array(9).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                          if (calculatedValue > 5) {
                                            return (
                                                <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                  x {(i / 10 + 0.1).toFixed(1)}
                                                </option>
                                            );
                                          }
                                          return null; // Return null for options that don't meet the condition
                                        })}

                                        {/* Adding values from 1 onwards */}
                                        {[...Array(1).keys()].map((i) => {
                                          const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                          const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                          if ((initInvest * (i + 1)) <= initInvest) {
                                            return (
                                                <option key={i + 1} value={i + 1}>
                                                  x {i + 1}
                                                </option>
                                            );
                                          }
                                          return null;
                                        })}
                                      </select>
                                      &nbsp;
                                      {/* Cross up/down select */}
                                      <select
                                          value={selectedThresholds.sell_t5_cross_up_down}
                                          onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t5_cross_up_down')}
                                      >
                                        {/* Display current value */}
                                        <option value="">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5_cross_up_down}
                                        </option>
                                        <option value="Cross Up">Cross Up</option>
                                        <option value="Cross Down">Cross Down</option>
                                      </select>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                            </>
                        )}
                        {/* Check if the position is LONG */}
                        {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).position === "SHORT" ? (
                            <>
                              <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                                <div className="linechart col-12 row-20 pad-left-10 border-radius">
                                  <div className="dashboard_text_3 col-12 row-20 border-radius">
                                    {/* Main Line */}
                                    <div className="dashboard_title_2 col-12 s10-strategie-buy border-radius-top center">BUY</div>
                                    <div className="mobile_textsize">
                                      <div className="main-line-details">
                                        <div className="main-line-details">
                                          <div className="dashboard_title_2 col-6 s10b">Current</div>
                                          <div className="dashboard_title_2 col-6 s10b">Stoploss</div>
                                          <div className="s10-grey col-6">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).current_price}
                                          </div>
                                          <div className="s10-grey col-6">
                                            <select
                                                value={selectedThresholds.stoploss}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'stoploss')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss + " % (" + calculateValueShort(selectedItem2.stoploss) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </div>
                                        </div>
                                        <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Take Profit</div>
                                      </div>

                                      {/* Subline for Consolidate */}
                                      <div className="subline col-12 row-20 border-radius">
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_buy === 1 ? "#74d600" : "inherit" }}>#1&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.buy_t1}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t1')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)* -1} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1 + " % (" + calculateValueShort(selectedItem2.buy_t1) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1)+ " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>
                                          <select
                                              value={selectedThresholds.multiplier_buy_t1}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t1')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t1}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t1_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t1_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t1_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_buy === 2 ? "#74d600" : "inherit" }}>#2&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.buy_t2}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t2')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2 + " % (" + calculateValueShort(selectedItem2.buy_t2) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>
                                          <select
                                              value={selectedThresholds.multiplier_buy_t2}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t2')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t2}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t2_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t2_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t2_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_buy === 3 ? "#74d600" : "inherit" }}>#3&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.buy_t3}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t3')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3 + " % (" + calculateValueShort(selectedItem2.buy_t3) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t3}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t3')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t3}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t3_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t3_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t3_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>

                                        </div>
                                        <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_buy === 4 ? "#74d600" : "inherit" }}>#4&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.buy_t4}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t4')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4 + " % (" + calculateValueShort(selectedItem2.buy_t4) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t4}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t4')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t4}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t4_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t4_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t4_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>
                                        <div className="dashboard_title_2 s15-buy border-radius-bottom" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <>
                                            <span style={{ color: selectedItem2.threshold_buy === 5 ? "#74d600" : "inherit" }}>#5&nbsp;&nbsp;</span>
                                            <select
                                                value={selectedThresholds.buy_t5}
                                                onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t5')}
                                            >
                                              {/* Dynamically generated options for negative values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = -90 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}

                                              {/* Option for selected value */}
                                              <option
                                                  value=""
                                                  selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 === null}
                                              >
                                                {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 !== null ?
                                                    itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5 + " % (" + calculateValueShort(selectedItem2.buy_t5) + ")" :
                                                    "Select an option"
                                                }
                                              </option>
                                              {/* Dynamically generated options for positive values */}
                                              {Array.from({ length: 900 }, (_, i) => {
                                                const value = 0 + i / 10;
                                                return (
                                                    <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                      {value.toFixed(1) + " % (" + calculateValueShort(value) + ")"}
                                                    </option>
                                                );
                                              })}
                                            </select>
                                            &nbsp;
                                          </>

                                          <select
                                              value={selectedThresholds.multiplier_buy_t5}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_buy_t5')}
                                          >
                                            <option value="">
                                              {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_buy_t5}
                                            </option>
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * ((i + 1) / 100)) >= 5 && (
                                                    <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                      x {((i + 1) / 100).toFixed(2)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(9).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i / 10 + 0.1)) >= 5 && (
                                                    <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                      x {(i / 10 + 0.1).toFixed(1)}
                                                    </option>
                                                )
                                            ))}
                                            {[...Array(30).keys()].map((i) => (
                                                ((itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).init_invest) * (i + 1)) >= 5 && (
                                                    <option key={i + 1} value={i + 1}>
                                                      x {i + 1}
                                                    </option>
                                                )
                                            ))}
                                          </select>
                                          &nbsp;
                                          {/* Cross up/down select */}
                                          <select
                                              value={selectedThresholds.buy_t5_cross_up_down}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'buy_t5_cross_up_down')}
                                          >
                                            {/* Display current value */}
                                            <option value="">
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).buy_t5_cross_up_down}
                                            </option>
                                            <option value="Cross Up">Cross Up</option>
                                            <option value="Cross Down">Cross Down</option>
                                          </select>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                        ) : (
                            <>
                              <div className="card col-6 row-20 pad-top-10 pad-bottom-10 border-radius">
                                <div className="linechart col-12 row-20 border-radius">
                                  <div className="dashboard_text_3 col-12 row-20 border-radius">

                                    {/* Main Line */}
                                    <div className="dashboard_title_2 col-12 s10-strategie-sell border-radius-top center">SELL</div>
                                    <div className="main-line-details">
                                      <div className="main-line-details">
                                        <div className="dashboard_title_2 col-6 s10b">Current</div>
                                        <div className="dashboard_title_2 col-6 s10b">Stoploss</div>
                                        <div className="s10-grey col-6">
                                          {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).current_price}
                                        </div>
                                        <div className="s10-grey col-6">
                                          <select
                                              value={selectedThresholds.stoploss}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'stoploss')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).stoploss + " % (" + calculateValue(selectedItem2.stoploss) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </div>
                                      </div>
                                      <div className="dashboard_title_2 col-12 s10b border-radius-bottom border-style-top">Take Profit</div>
                                    </div>

                                    <div className="subline col-12 row-20 border-radius">
                                      <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <>
                                          <span style={{ color: selectedItem2.threshold_sell === 1 ? "#f44336" : "inherit" }}>#1&nbsp;&nbsp;</span>
                                          <select
                                              value={selectedThresholds.sell_t1}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t1')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1 + " % (" + calculateValue(selectedItem2.sell_t1) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </>
                                        <select
                                            value={selectedThresholds.multiplier_sell_t1}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t1')}
                                        >
                                          <option value="">
                                            {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t1}
                                          </option>

                                          {/* Adding values from 0.01 to 0.09 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                    x {((i + 1) / 100).toFixed(2)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 0.1 to 0.9 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 1 onwards */}
                                          {[...Array(1).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            if ((initInvest * (i + 1)) <= initInvest) {
                                              return (
                                                  <option key={i + 1} value={i + 1}>
                                                    x {i + 1}
                                                  </option>
                                              );
                                            }
                                            return null;
                                          })}
                                        </select>

                                        &nbsp;
                                        {/* Cross up/down select */}
                                        <select
                                            value={selectedThresholds.sell_t1_cross_up_down}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t1_cross_up_down')}
                                        >
                                          {/* Display current value */}
                                          <option value="">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t1_cross_up_down}
                                          </option>
                                          <option value="Cross Up">Cross Up</option>
                                          <option value="Cross Down">Cross Down</option>
                                        </select>
                                      </div>
                                      <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <>
                                          <span style={{ color: selectedItem2.threshold_sell === 2 ? "#f44336" : "inherit" }}>#2&nbsp;&nbsp;</span>
                                          <select
                                              value={selectedThresholds.sell_t2}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t2')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2 + " % (" + calculateValue(selectedItem2.sell_t2) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </>
                                        <select
                                            value={selectedThresholds.multiplier_sell_t2}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t2')}
                                        >
                                          <option value="">
                                            {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t2}
                                          </option>

                                          {/* Adding values from 0.01 to 0.09 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                    x {((i + 1) / 100).toFixed(2)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 0.1 to 0.9 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 1 onwards */}
                                          {[...Array(1).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            if ((initInvest * (i + 1)) <= initInvest) {
                                              return (
                                                  <option key={i + 1} value={i + 1}>
                                                    x {i + 1}
                                                  </option>
                                              );
                                            }
                                            return null;
                                          })}
                                        </select>
                                        &nbsp;
                                        {/* Cross up/down select */}
                                        <select
                                            value={selectedThresholds.sell_t2_cross_up_down}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t2_cross_up_down')}
                                        >
                                          {/* Display current value */}
                                          <option value="">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t2_cross_up_down}
                                          </option>
                                          <option value="Cross Up">Cross Up</option>
                                          <option value="Cross Down">Cross Down</option>
                                        </select>
                                      </div>
                                      <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <>
                                          <span style={{ color: selectedItem2.threshold_sell === 3 ? "#f44336" : "inherit" }}>#3&nbsp;&nbsp;</span>
                                          <select
                                              value={selectedThresholds.sell_t3}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t3')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3 + " % (" + calculateValue(selectedItem2.sell_t3) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </>
                                        <select
                                            value={selectedThresholds.multiplier_sell_t3}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t3')}
                                        >
                                          <option value="">
                                            {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t3}
                                          </option>

                                          {/* Adding values from 0.01 to 0.09 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                    x {((i + 1) / 100).toFixed(2)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 0.1 to 0.9 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 1 onwards */}
                                          {[...Array(1).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            if ((initInvest * (i + 1)) <= initInvest) {
                                              return (
                                                  <option key={i + 1} value={i + 1}>
                                                    x {i + 1}
                                                  </option>
                                              );
                                            }
                                            return null;
                                          })}
                                        </select>
                                        &nbsp;
                                        {/* Cross up/down select */}
                                        <select
                                            value={selectedThresholds.sell_t3_cross_up_down}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t3_cross_up_down')}
                                        >
                                          {/* Display current value */}
                                          <option value="">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t3_cross_up_down}
                                          </option>
                                          <option value="Cross Up">Cross Up</option>
                                          <option value="Cross Down">Cross Down</option>
                                        </select>
                                      </div>
                                      <div className="dashboard_title_2 s15-buy" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <>
                                          <span style={{ color: selectedItem2.threshold_sell === 4 ? "#f44336" : "inherit" }}>#4&nbsp;&nbsp;</span>
                                          <select
                                              value={selectedThresholds.sell_t4}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t4')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4 + " % (" + calculateValue(selectedItem2.sell_t4) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </>
                                        <select
                                            value={selectedThresholds.multiplier_sell_t4}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t4')}
                                        >
                                          <option value="">
                                            {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t4}
                                          </option>

                                          {/* Adding values from 0.01 to 0.09 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                    x {((i + 1) / 100).toFixed(2)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 0.1 to 0.9 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 1 onwards */}
                                          {[...Array(1).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            if ((initInvest * (i + 1)) <= initInvest) {
                                              return (
                                                  <option key={i + 1} value={i + 1}>
                                                    x {i + 1}
                                                  </option>
                                              );
                                            }
                                            return null;
                                          })}
                                        </select>
                                        &nbsp;
                                        {/* Cross up/down select */}
                                        <select
                                            value={selectedThresholds.sell_t4_cross_up_down}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t4_cross_up_down')}
                                        >
                                          {/* Display current value */}
                                          <option value="">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t4_cross_up_down}
                                          </option>
                                          <option value="Cross Up">Cross Up</option>
                                          <option value="Cross Down">Cross Down</option>
                                        </select>
                                      </div>
                                      <div className="dashboard_title_2 s15-buy border-radius-bottom" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <>
                                          <span style={{ color: selectedItem2.threshold_sell === 5 ? "#f44336" : "inherit" }}>#5&nbsp;&nbsp;</span>
                                          <select
                                              value={selectedThresholds.sell_t5}
                                              onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t5')}
                                          >
                                            {/* Dynamically generated options for negative values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = -90 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}

                                            {/* Option for selected value */}
                                            <option
                                                value=""
                                                selected={itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 === null}
                                            >
                                              {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 !== null ?
                                                  itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5 + " % (" + calculateValue(selectedItem2.sell_t5) + ")" :
                                                  "Select an option"
                                              }
                                            </option>
                                            {/* Dynamically generated options for positive values */}
                                            {Array.from({ length: 900 }, (_, i) => {
                                              const value = 0 + i / 10;
                                              return (
                                                  <option key={value.toFixed(1)} value={value.toFixed(1)}>
                                                    {value.toFixed(1) + " % (" + calculateValue(value) + ")"}
                                                  </option>
                                              );
                                            })}
                                          </select>
                                          &nbsp;
                                        </>
                                        <select
                                            value={selectedThresholds.multiplier_sell_t5}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'multiplier_sell_t5')}
                                        >
                                          <option value="">
                                            {"x " + itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).multiplier_sell_t5}
                                          </option>

                                          {/* Adding values from 0.01 to 0.09 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * ((i + 1) / 100)).toFixed(2); // Values from 0.01 to 0.09
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                                    x {((i + 1) / 100).toFixed(2)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 0.1 to 0.9 */}
                                          {[...Array(9).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            const calculatedValue = (initInvest * (i / 10 + 0.1)).toFixed(1); // Values from 0.1 to 0.9
                                            if (calculatedValue > 5) {
                                              return (
                                                  <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                                    x {(i / 10 + 0.1).toFixed(1)}
                                                  </option>
                                              );
                                            }
                                            return null; // Return null for options that don't meet the condition
                                          })}

                                          {/* Adding values from 1 onwards */}
                                          {[...Array(1).keys()].map((i) => {
                                            const selectedItem = itemsPortfolio.find(item => item.cryptoname === selectedItem2.cryptoname);
                                            const initInvest = selectedItem ? selectedItem.init_invest : 0;
                                            if ((initInvest * (i + 1)) <= initInvest) {
                                              return (
                                                  <option key={i + 1} value={i + 1}>
                                                    x {i + 1}
                                                  </option>
                                              );
                                            }
                                            return null;
                                          })}
                                        </select>
                                        &nbsp;
                                        {/* Cross up/down select */}
                                        <select
                                            value={selectedThresholds.sell_t5_cross_up_down}
                                            onChange={(e) => handleCriteriaChangeBuy(e, 'sell_t5_cross_up_down')}
                                        >
                                          {/* Display current value */}
                                          <option value="">
                                            {itemsPortfolio.find((item) => item.cryptoname === selectedItem2.cryptoname).sell_t5_cross_up_down}
                                          </option>
                                          <option value="Cross Up">Cross Up</option>
                                          <option value="Cross Down">Cross Down</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                        )}
                        <div className="col-12 s10b">
                          <button className="table_button" onClick={handleUpdateButtonClick}>Update</button>
                        </div>

                      </div>
                  )}
            </div>
        )}
      </div>

  );
};

export default TablePortfolio;
