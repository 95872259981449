import React, { useState, useEffect, useCallback } from 'react';
import ProgressionBarPerformanceReview from "./ProgressionBarPerformanceReview";

const CardGridComponent = () => {
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const [symbolDetails, setSymbolDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isVirtualMode, setIsVirtualMode] = useState(true);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const simulatorResponse = await fetch('/api/simulator');
            const simulatorData = await simulatorResponse.json();

            const resultsData = simulatorData?.[0]?.results || {};
            setItemsSimulator(simulatorData);
            setSymbolDetails(resultsData);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 20000);
        return () => clearInterval(intervalId);
    }, [fetchData]);

    const toggleMode = () => setIsVirtualMode((prevMode) => !prevMode);

    // Process data for charts
    const performanceData = itemsSimulator.map((item) => ({
        name: item.strategie || 'N/A',
        successRate: item.variation,
        stability: Math.max(0, item.current_count / item.max_count),
    }));

    const pieData = [
        { name: 'Stable', value: performanceData.filter(d => d.stability > 0.5).length },
        { name: 'Unstable', value: performanceData.filter(d => d.stability <= 0.5).length },
    ];

    const COLORS = ['#0088FE', '#FF8042'];

    return (
        <div className="card-grid-wrapper-simulator">
            <div className="card-grid-simulator">
                {itemsSimulator.map((item, index) => {
                    const variationColor =
                        item.variation < 50
                            ? '#f44336'
                            : item.variation >= 50 && item.variation <= 70
                                ? 'orange'
                                : '#4CAF50';

                    const benefitsColor =
                        item.benefits < 0 ? '#f44336' : item.benefits > 0 ? '#4CAF50' : '#AAA';

                    return ( // Add this return statement
                        <div className="card-simulator" key={index}>
                            <h3>{item.strategie || 'Strategy N/A'}</h3>
                            <h2>{isVirtualMode ? "Virtual Mode" : "Production Mode"}</h2>
                            <div className="form-grid-simulator">
                                <p><strong>Entry Price:</strong> {item.entry_price}</p>
                                <p><strong>Amount:</strong> {item.amount}</p>
                            </div>
                            <div className="form-grid-simulator">
                                <p><strong>Current Count:</strong> {item.current_count}</p>
                                <p><strong>Max Count:</strong> {item.max_count}</p>
                            </div>
                            <div className="form-grid-simulator">
                                <p><strong>State:</strong> {isVirtualMode ? item.virtual_state : item.prod_state}</p>
                                <p><strong>Stoploss:</strong> {item.stoploss}</p>
                            </div>
                            <div className="form-grid-simulator">
                                <button className="details-button">View Details</button>
                                <button onClick={toggleMode} className="details-button">
                                    {isVirtualMode ? "Production Mode" : "Virtual Mode"}
                                </button>
                            </div>
                            <div className="symbol-cards-container">
                                <div className="symbol-cards">
                                    {Object.entries(symbolDetails).map(([symbol, details]) => (
                                        <div className="symbol-card" key={symbol}>
                                            <div className="symbol-header">
                                                <h3>{symbol}</h3>
                                            </div>
                                            <div className="symbol-content">
                                                <p><strong>Lowest:</strong> {details.Lowest || '-3'}</p>
                                                <p><strong>Highest:</strong> {details.Highest || '27'}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <h1>Performance review</h1>
                            <div className="simulator-success-info">
                                <span>
                                    <span className="simulator-success">Success: </span>
                                    <span
                                        className="simulator-variation"
                                        style={{ color: variationColor }}
                                    >
                                        {typeof item.variation === 'number' ? item.variation.toFixed(2) : item.variation}%
                                    </span>
                                </span>
                                <span>
                                    <span className="simulator-success"></span>
                                    <span className="simulator-benefits" style={{ color: benefitsColor }}>
                                        {typeof item.benefits === 'number' ? item.benefits.toFixed(2) : item.benefits} $
                                    </span>
                                </span>
                            </div>
                            <ProgressionBarPerformanceReview highest={item.highest} lowest={item.lowest} stability={item.stability} delay={item.delay} />
                        </div>
                    );
                })}

            </div>
        </div>
    );
};

export default CardGridComponent;
