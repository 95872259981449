import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash, faToggleOn, faToggleOff, faSpinner, faSliders} from "@fortawesome/free-solid-svg-icons";

import './table.css';

const TablePortfolio = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemsPortfolio, setItemsPortfolio] = useState([]);
  const [itemsHistory, setItemsHistory] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for order history
  const [showSettings, setShowSettings] = useState(false); // Show portfolio settings modal
  const [showHistoryModal, setShowHistoryModal] = useState(false); // Show order history modal
  const alertRef = useRef(null);
  const initialThresholds = {
    buy_t1: '', buy_t2: '', buy_t3: '', buy_t4: '', buy_t5: '', buy_t6: '', buy_t7: '', buy_t8: '', buy_t9: '', buy_t10: '',
    sell_t1: '', sell_t2: '', sell_t3: '', sell_t4: '', sell_t5: '', sell_t6: '', sell_t7: '', sell_t8: '', sell_t9: '', sell_t10: '',
    multiplier_buy_t1: '', multiplier_buy_t2: '', multiplier_buy_t3: '', multiplier_buy_t4: '', multiplier_buy_t5: '', multiplier_buy_t6: '',
    multiplier_buy_t7: '', multiplier_buy_t8: '', multiplier_buy_t9: '', multiplier_buy_t10: '', multiplier_sell_t1: '', multiplier_sell_t2: '',
    multiplier_sell_t3: '', multiplier_sell_t4: '', multiplier_sell_t5: '', multiplier_sell_t6: '', multiplier_sell_t7: '', multiplier_sell_t8: '',
    multiplier_sell_t9: '', multiplier_sell_t10: '', buy_t1_cross_up_down: '',buy_t2_cross_up_down: '', buy_t3_cross_up_down: '', buy_t4_cross_up_down: '',
    buy_t5_cross_up_down: '', buy_t6_cross_up_down: '', buy_t7_cross_up_down: '', buy_t8_cross_up_down: '', buy_t9_cross_up_down: '', buy_t10_cross_up_down: '',
    sell_t1_cross_up_down: '', sell_t2_cross_up_down: '', sell_t3_cross_up_down: '', sell_t4_cross_up_down: '', sell_t5_cross_up_down: '', sell_t6_cross_up_down: '',
    sell_t7_cross_up_down: '', sell_t8_cross_up_down: '', sell_t9_cross_up_down: '', sell_t10_cross_up_down: '', stoploss: '',  strategie: '',};
  const [selectedThresholds, setSelectedThresholds] = useState(initialThresholds);


  const updatePortfolioSettings = () => {
    const currentItem = itemsPortfolio.find(
        (item) =>
            item.cryptoname === selectedItem.cryptoname && item.type === selectedItem.type
    );

    if (!currentItem) {
      alert("No matching portfolio item found.");
      return;
    }

    const updatedData = Object.keys(initialThresholds).reduce((acc, key) => {
      acc[key] = selectedThresholds[key] !== '' ? selectedThresholds[key] : currentItem[key];
      return acc;
    }, {});

    // Perform the update request
    fetch(`/api/portfolios/${currentItem._id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedData),
    })
        .then((response) => response.json())
        .then((updatedItem) => {
          setItemsPortfolio((prev) =>
              prev.map((item) =>
                  item._id === currentItem._id ? { ...item, ...updatedItem } : item
              )
          );

          alert('Data updated successfully!');
          setSelectedThresholds(initialThresholds); // Reset the form
        })
        .catch((error) => {
          console.error('Failed to update portfolio:', error);
          alert('Failed to update data. Please try again later.');
        });
  };

  const handleCriteriaChange = (e, thresholdName) => {
    const { value } = e.target;
    setSelectedThresholds(prevState => ({
      ...prevState,
      [thresholdName]: value,
    }));
  };
  const fetchData = useCallback(async () => {
    try {
      const portfoliosResponse = await fetch('/api/portfolios');
      const portfoliosData = await portfoliosResponse.json();
      setItemsPortfolio(portfoliosData);
      setStatusList(portfoliosData.map((portfolio) => portfolio.state === 1 ? 'online' : 'offline'));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  function roundDecimals(num) {
    let numStr = num.toString();
    let [integerPart, decimalPart] = numStr.split('.');
    let numLen = integerPart.length + (decimalPart ? decimalPart.length : 0); // Add a check for undefined decimalPart

    if (num < 1) {
      return parseFloat(num.toFixed(Math.min(7, decimalPart ? decimalPart.length : 0))); // Add a check for undefined decimalPart
    } else if (num < 10) {
      return parseFloat(num.toFixed(Math.min(6, numLen)));
    } else if (num < 100) {
      return parseFloat(num.toFixed(Math.min(5, numLen)));
    } else if (num < 1000) {
      return parseFloat(num.toFixed(Math.min(4, numLen)));
    } else if (num < 10000) {
      return parseFloat(num.toFixed(Math.min(3, numLen)));
    } else if (num < 100000) {
      return parseFloat(num.toFixed(Math.min(2, numLen)));
    } else if (num > 100000) {
      return parseFloat(num.toFixed(Math.min(1, numLen)));
    }
  }
  const handleStatusChange = (index) => {
    const updatedStatusList = [...statusList];
    const updatedStatus = updatedStatusList[index] === 'online' ? 'offline' : 'online';
    updatedStatusList[index] = updatedStatus;
    setStatusList(updatedStatusList);

    const portfolioId = itemsPortfolio[index]._id;
    const updatedStatusValue = updatedStatus === 'online' ? 1 : 0;

    fetch(`/api/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ state: updatedStatusValue }),
    })
        .then((response) => response.json())
        .then((updatedData) => {
          console.log('Updated portfolio status:', updatedData);
        })
        .catch((error) => {
          console.error('Failed to update portfolio:', error);
        });
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 20000);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const handleDelete = useCallback(async (item) => {
    const portfolioId = item._id;
    try {
      const response = await fetch(`/api/portfolios/${portfolioId}`, { method: 'DELETE' });
      if (response.ok) {
        setItemsPortfolio(prevItems => prevItems.filter(portfolio => portfolio._id !== portfolioId));
        console.log('Deleted portfolio:', portfolioId);
      } else throw new Error('Failed to delete portfolio');
    } catch (error) {
      console.error('Error deleting portfolio:', error);
    }
  }, []);

  useEffect(() => {
    const fetchOrderHistory = async () => {
      if (selectedItem) {
        setIsLoading(true); // Start loading
        try {
          // Simulating a 1-second loading delay
          await new Promise(resolve => setTimeout(resolve, 200));
          const orderFilledResponse = await fetch(`/api/orderfilleds?cryptoname=${selectedItem.cryptoname}&type=${selectedItem.type}`);
          const orderFilledData = await orderFilledResponse.json();
          setItemsHistory(orderFilledData);
        } catch (error) {
          console.error('Error fetching order history:', error);
        } finally {
          setIsLoading(false); // End loading
        }
      }
    };

    fetchOrderHistory();
  }, [selectedItem]);

  const handleButtonClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setShowHistoryModal(true); // Open order history modal
    setShowSettings(false); // Close settings modal if open
  };

  const handleCloseHistoryModal = () => {
    setShowHistoryModal(false);
    setSelectedItem(null); // Clear selected item when closing the modal
    setItemsHistory([]); // Clear the history when closing the modal
  };

  const handleSettingsToggle = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    setShowSettings(true); // Open the settings modal
    setShowHistoryModal(false); // Close history modal if open
  };

  const handleCloseSettingsModal = () => {
    setShowSettings(false); // Close the settings modal
  };

  return (
      <div className="table-container" style={{ overflow: "auto", maxWidth: "100%", height: "93%" }}>
        <table id="portfolio-table">
          <thead>
          <tr>
            <th>Symbol</th>
            <th>Amount</th>
            <th>Position</th>
            <th>Control</th>
          </tr>
          </thead>
          <tbody>
          {itemsPortfolio.map((item, index) => (
              <TableRow
                  key={item._id}
                  item={item}
                  status={statusList[index]}
                  onStatusChange={() => handleStatusChange(index)}
                  handleDelete={handleDelete}
                  handleButtonClick={handleButtonClick}
                  handleSettingsToggle={handleSettingsToggle}
              />
          ))}
          </tbody>
        </table>
        {showHistoryModal && selectedItem && (
            <div className="modal" ref={alertRef}>
              <div className="modal-content">
                <h2>Order History for {selectedItem.cryptoname}</h2>
                <button onClick={handleCloseHistoryModal} className="close-btn">X</button>
                <div className="history-list">
                  {isLoading ? (
                      <div>
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        <p>Loading...</p>
                      </div>
                  ) : (
                      itemsHistory.length > 0 ? (
                          itemsHistory.map((order, index) => (
                              <div className="order-item" key={index}>
                                <div className="order-row">
                                  <div className="order-detail"><span>Price:</span> {order.price}</div>
                                  <div className="order-detail"><span>Side:</span> {order.side}</div>
                                </div>
                                <div className="order-row">
                                  <div className="order-detail"><span>Quantity:</span> {order.quantity}</div>
                                  <div className="order-detail"><span>Date:</span> {order.date}</div>
                                </div>
                              </div>
                          ))
                      ) : (
                          <p>No order history found.</p>
                      )
                  )}
                </div>
              </div>
            </div>
        )}
        {/* Portfolio Settings Modal */}
        {showSettings && (
            <div className="modal" ref={alertRef}>
              <div className="modal-content">
                <h2>{selectedItem.cryptoname}</h2>
                <div className="form-grid-portfolio-details" style={{paddingBottom:"15px"}}>
                  <div className="form-group">
                    <label>Current Price</label>
                    <div style={{border:"None", fontSize:"12px"}}>{selectedItem.current_price}</div>
                  </div>
                  <div className="form-group">
                    <label>Bought Price</label>
                    <div style={{border:"None", fontSize:"12px"}}>{selectedItem.bought_price}</div>
                  </div>
                  <div className="form-group">
                    <label>Strategie</label>
                    <select
                        defaultValue={selectedItem[`strategie`] || ''}
                        onChange={(e) => handleCriteriaChange(e, 'strategie')}
                        style={{border:"None", fontSize:"12px"}}
                    >
                      {/* Display current value */}
                      <option value="">
                        {selectedItem.strategie}
                      </option>
                      <option value="scalping">scalping</option>
                      <option value="dca">dca</option>
                      <option value="swing">swing</option>
                      <option value="daily">daily</option>
                      <option value="listing">listing</option>
                      <option value="botmight">botmight</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Stoploss</label>
                    <select
                        defaultValue={selectedItem[`stoploss`] || ''}
                        onChange={(e) => handleCriteriaChange(e, `stoploss`)}
                        style={{border:"None", fontSize:"12px"}}
                    >
                      <optgroup label="Negative Values">
                      {Array.from({ length: 900 }, (_, i) => {
                        const value = -90 + i / 10;
                        const bought_price = selectedItem['bought_price'];

                        // Ensure current_price is a valid number before calculation
                        const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100 * (selectedItem.position === 'LONG' ? 1 : -1)) : 'N/A';

                        return (
                            <option key={i} value={roundDecimals(target_price)}>
                              {target_price !== 'N/A'
                                  ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                  : 'Invalid Price'}
                            </option>
                        );
                      })}
                      </optgroup>
                      {selectedItem['stoploss'] === null && (
                          <option value="" disabled selected>
                            -
                          </option>
                      )}
                      <optgroup label="Positive Values">
                      {Array.from({ length: 900 }, (_, i) => {
                        const value = 0 + i / 10;
                        const bought_price = selectedItem['bought_price'];
                        // Ensure bought_price is a valid number before calculation
                        const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100 * (selectedItem.position === 'LONG' ? 1 : -1)) : 'N/A';


                        return (
                            <option key={i} value={roundDecimals(target_price)}>
                              {target_price !== 'N/A'
                                  ? `${roundDecimals(target_price)} (${value.toFixed(1)} %)`
                                  : 'Invalid Price'}
                            </option>
                        );
                      })}
                      </optgroup>
                      {selectedItem['stoploss'] !== null && (
                          <option value={selectedItem[`stoploss`]}>
                            {selectedItem[`stoploss`]}
                          </option>
                      )}
                    </select>
                  </div>
                </div>

                <button onClick={handleCloseSettingsModal} className="close-btn">X</button>
                <div className="settings-grid">
                  {selectedItem.position === 'LONG' ? (
                      <>
                        {/* Buy Settings Column */}
                        <div className="form-container">
                          <h3 className="styled-heading-buy">Buy Settings</h3>
                          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
                              <div
                                  className="form-grid-portfolio"
                                  key={`buy-t${index}`}
                                  style={{
                                    border: selectedItem[`threshold_buy`] === index ? "0.5px solid #74d600" : "1px solid inherit",
                                    borderRadius: "5px",
                                  }}
                              >
                                <select defaultValue={selectedItem[`buy_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }} onChange={(e) => handleCriteriaChange(e, `buy_t${index}`)}>
                                  <optgroup label="Negative Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = -90 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`buy_t${index}`] === null && (
                                      <option value="" disabled selected>
                                        Buy T{index}
                                      </option>
                                  )}
                                  <optgroup label="Positive Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = 0 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`buy_t${index}`] !== null && (
                                      <option value={selectedItem[`buy_t${index}`]}>
                                        {selectedItem[`buy_t${index}`]}
                                      </option>
                                  )}
                                </select>

                                <select defaultValue={selectedItem[`multiplier_buy_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }}  onChange={(e) => handleCriteriaChange(e, `multiplier_buy_t${index}`)}>
                                  <optgroup label="Multiplier">
                                  <option value="" hidden>
                                    {selectedItem[`multiplier_buy_t${index}`] !== null
                                        ? selectedItem[`multiplier_buy_t${index}`]
                                        : `Multiplier Buy T${index}`}
                                  </option>
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * ((i + 1) / 100)) >= 5 && (
                                          <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                            x {((i + 1) / 100).toFixed(2)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i / 10 + 0.1)) >= 5 && (
                                          <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                            x {(i / 10 + 0.1).toFixed(1)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(100).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i + 1)) >= 5 && (
                                          <option key={i + 1} value={i + 1}>
                                            x {i + 1}
                                          </option>
                                      )
                                  ))}
                                  </optgroup>
                                </select>

                                <select
                                    defaultValue={selectedItem[`buy_t${index}_cross_up_down`] || ''}
                                    style={{ border: 'None',fontWeight: 'bold', fontSize: '11px' }}
                                    onChange={(e) => handleCriteriaChange(e, `buy_t${index}_cross_up_down`)}

                                >
                                  <optgroup label="Direction">
                                    <option value="" hidden>
                                      {selectedItem[`buy_t${index}_cross_up_down`] !== null
                                          ? selectedItem[`buy_t${index}_cross_up_down`]
                                          : '-'}
                                    </option>
                                    <option value="cross_up" style={{ color: '#74d600', fontWeight: 'bold', fontSize: '24px'}}>⬆</option>
                                    <option value="cross_down" style={{ color: '#f44336', fontWeight: 'bold', fontSize: '24px'}}>⬇</option>
                                  </optgroup>
                                </select>
                              </div>
                          ))}
                        </div>
                      </>
                  ) : (
                      <>
                        {/* Sell Settings Column */}
                        <div className="form-container">
                          <h3 className="styled-heading-sell">Sell Settings</h3>
                          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
                              <div
                                  className="form-grid-portfolio"
                                  key={`sell-t${index}`}
                                  style={{
                                    border: selectedItem[`threshold_sell`] === index ? "0.5px solid #f44336" : "1px solid inherit",
                                    borderRadius: "5px",
                                  }}
                              >
                                <select defaultValue={selectedItem[`sell_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }}  onChange={(e) => handleCriteriaChange(e, `sell_t${index}`)}>
                                  <optgroup label="Negative Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    // Generate values from -90 to almost 0 in increments of 1/25
                                    const value = -90 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price)
                                        ? bought_price * (1 + value / 100 * (selectedItem.position === 'LONG' ? 1 : -1))  // Apply `value / 25` as a factor on `bought_price`
                                        : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}:
                                  </optgroup>
                                  {selectedItem[`sell_t${index}`] === null && (
                                      <option value="" disabled selected>
                                        Sell T{index}
                                      </option>
                                  )}
                                  <optgroup label="Positive Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = 0 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100 * (selectedItem.position === 'LONG' ? 1 : -1)) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`sell_t${index}`] !== null && (
                                      <option value={selectedItem[`sell_t${index}`]}>
                                        {selectedItem[`sell_t${index}`]}
                                      </option>
                                  )}
                                </select>

                                <select defaultValue={selectedItem[`multiplier_sell_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }} onChange={(e) => handleCriteriaChange(e, `multiplier_sell_t${index}`)}>
                                  <optgroup label="Multiplier">
                                    <option value="" hidden>
                                    {selectedItem[`multiplier_sell_t${index}`] !== null
                                        ? selectedItem[`multiplier_sell_t${index}`]
                                        : `Multiplier Sell T${index}`}
                                  </option>
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * ((i + 1) / 100)) >= 5 && (
                                          <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                            x {((i + 1) / 100).toFixed(2)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i / 10 + 0.1)) >= 5 && (
                                          <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                            x {(i / 10 + 0.1).toFixed(1)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(100).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i + 1)) <= selectedItem[`init_invest`] && (
                                          <option key={i + 1} value={i + 1}>
                                            x {i + 1}
                                          </option>
                                      )
                                  ))}
                                  </optgroup>
                                </select>

                                <select defaultValue={selectedItem[`sell_t${index}_cross_up_down`] || ''} style={{ border: 'None',fontWeight: 'bold', fontSize: '11px' }} onChange={(e) => handleCriteriaChange(e, `sell_t${index}_cross_up_down`)}>
                                  <optgroup label="Direction">
                                    <option value="" hidden>
                                      {selectedItem[`sell_t${index}_cross_up_down`] !== null
                                          ? selectedItem[`sell_t${index}_cross_up_down`]
                                          : '-'}
                                    </option>
                                    <option value="cross_up" style={{ color: '#f44336', fontWeight: 'bold', fontSize: '24px'}}>⬆</option>
                                    <option value="cross_down" style={{ color: '#74d600', fontWeight: 'bold', fontSize: '24px'}}>⬇</option>
                                  </optgroup>
                                </select>
                              </div>
                          ))}
                        </div>
                      </>
                  )}
                  {selectedItem.position === 'SHORT' ? (
                      <>
                        {/* Buy Settings Column */}
                        <div className="form-container">
                          <h3 className="styled-heading-buy">Buy Settings</h3>
                          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
                              <div
                                  className="form-grid-portfolio"
                                  key={`buy-t${index}`}
                                  style={{
                                    border: selectedItem[`threshold_buy`] === index ? "0.5px solid #74d600" : "1px solid inherit",
                                    borderRadius: "5px",
                                  }}
                              >
                                <select defaultValue={selectedItem[`buy_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }} onChange={(e) => handleCriteriaChange(e, `buy_t${index}`)}>
                                  <optgroup label="Negative Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = -90 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100 * (selectedItem.position === 'LONG' ? 1 : -1)) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`buy_t${index}`] === null && (
                                      <option value="" disabled selected>
                                        Buy T{index}
                                      </option>
                                  )}
                                  <optgroup label="Positive Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = 0 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value  / 100 * (selectedItem.position === 'LONG' ? 1 : -1)) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`buy_t${index}`] !== null && (
                                      <option value={selectedItem[`buy_t${index}`]}>
                                        {selectedItem[`buy_t${index}`]}
                                      </option>
                                  )}
                                </select>

                                <select defaultValue={selectedItem[`multiplier_buy_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }}  onChange={(e) => handleCriteriaChange(e, `multiplier_buy_t${index}`)}>
                                  <optgroup label="Multiplier">
                                    <option value="" hidden>
                                    {selectedItem[`multiplier_buy_t${index}`] !== null
                                        ? selectedItem[`multiplier_buy_t${index}`]
                                        : `Multiplier Buy T${index}`}
                                  </option>
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * ((i + 1) / 100)) >= 5 && (
                                          <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                            x {((i + 1) / 100).toFixed(2)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i / 10 + 0.1)) >= 5 && (
                                          <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                            x {(i / 10 + 0.1).toFixed(1)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(100).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i + 1)) >= 5 && (
                                          <option key={i + 1} value={i + 1}>
                                            x {i + 1}
                                          </option>
                                      )
                                  ))}
                                  </optgroup>
                                </select>

                                <select defaultValue={selectedItem[`buy_t${index}_cross_up_down`] || ''} style={{ border: 'None',fontWeight: 'bold', fontSize: '11px' }}  onChange={(e) => handleCriteriaChange(e, `buy_t${index}_cross_up_down`)}>
                                  <optgroup label="Direction">
                                    <option value="" hidden>
                                      {selectedItem[`buy_t${index}_cross_up_down`] !== null
                                          ? selectedItem[`buy_t${index}_cross_up_down`]
                                          : '-'}
                                    </option>
                                    <option value="cross_up" style={{ color: '#f44336', fontWeight: 'bold', fontSize: '24px'}}>⬆</option>
                                    <option value="cross_down" style={{ color: '#74d600', fontWeight: 'bold', fontSize: '24px'}}>⬇</option>
                                  </optgroup>
                                </select>
                              </div>
                          ))}
                        </div>
                      </>
                  ) : (
                      <>
                        {/* Sell Settings Column */}
                        <div className="form-container">
                          <h3 className="styled-heading-sell">Sell Settings</h3>
                          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
                              <div
                                  className="form-grid-portfolio"
                                  key={`sell-t${index}`}
                                  style={{
                                    border: selectedItem[`threshold_sell`] === index ? "0.5px solid #f44336" : "1px solid inherit",
                                    borderRadius: "5px",
                                  }}
                              >
                                <select defaultValue={selectedItem[`sell_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }}  onChange={(e) => handleCriteriaChange(e, `sell_t${index}`)}>
                                  <optgroup label="Negative Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    // Generate values from -90 to almost 0 in increments of 1/25
                                    const value = -90 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price)
                                        ? bought_price * (1 + value / 100)  // Apply `value / 25` as a factor on `bought_price`
                                        : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`sell_t${index}`] === null && (
                                      <option value="" disabled selected>
                                        Sell T{index}
                                      </option>
                                  )}
                                  <optgroup label="Positive Values">
                                  {Array.from({ length: 900 }, (_, i) => {
                                    const value = 0 + i / 10;
                                    const bought_price = selectedItem['bought_price'];

                                    // Ensure bought_price is a valid number before calculation
                                    const target_price = !isNaN(bought_price) ? bought_price * (1 + value / 100) : 'N/A';

                                    return (
                                        <option key={i} value={roundDecimals(target_price)}>
                                          {target_price !== 'N/A'
                                              ? `${roundDecimals(target_price)} (${value.toFixed(2)} %)`
                                              : 'Invalid Price'}
                                        </option>
                                    );
                                  })}
                                  </optgroup>
                                  {selectedItem[`sell_t${index}`] !== null && (
                                      <option value={selectedItem[`sell_t${index}`]}>
                                        {selectedItem[`sell_t${index}`]}
                                      </option>
                                  )}
                                </select>

                                <select defaultValue={selectedItem[`multiplier_sell_t${index}`] || ''} style={{ border: 'None', fontSize: '9px' }} onChange={(e) => handleCriteriaChange(e, `multiplier_sell_t${index}`)}>
                                  <optgroup label="Multiplier">
                                    <option value="" hidden>
                                    {selectedItem[`multiplier_sell_t${index}`] !== null
                                        ? selectedItem[`multiplier_sell_t${index}`]
                                        : `Multiplier Sell T${index}`}
                                  </option>
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * ((i + 1) / 100)) >= 5 && (
                                          <option key={((i + 1) / 100).toFixed(2)} value={((i + 1) / 100).toFixed(2)}>
                                            x {((i + 1) / 100).toFixed(2)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(9).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i / 10 + 0.1)) >= 5 && (
                                          <option key={(i / 10 + 0.1).toFixed(1)} value={(i / 10 + 0.1).toFixed(1)}>
                                            x {(i / 10 + 0.1).toFixed(1)}
                                          </option>
                                      )
                                  ))}
                                  {[...Array(100).keys()].map((i) => (
                                      (selectedItem[`init_invest`] * (i + 1)) <= selectedItem[`init_invest`] && (
                                          <option key={i + 1} value={i + 1}>
                                            x {i + 1}
                                          </option>
                                      )
                                  ))}
                                  </optgroup>
                                </select>

                                <select defaultValue={selectedItem[`sell_t${index}_cross_up_down`] || ''} style={{ border: 'None',fontWeight: 'bold', fontSize: '11px' }} onChange={(e) => handleCriteriaChange(e, `sell_t${index}_cross_up_down`)}>
                                  <optgroup label="Direction">
                                    <option value="" hidden>
                                      {selectedItem[`sell_t${index}_cross_up_down`] !== null
                                          ? selectedItem[`sell_t${index}_cross_up_down`]
                                          : '-'}
                                    </option>
                                    <option value="cross_up" style={{ color: '#74d600', fontWeight: 'bold', fontSize: '24px'}}>⬆</option>
                                    <option value="cross_down" style={{ color: '#f44336', fontWeight: 'bold', fontSize: '24px'}}>⬇</option>
                                  </optgroup>
                                </select>
                              </div>
                          ))}
                        </div>
                      </>
                  )}

                </div>
                <button onClick={updatePortfolioSettings} className="update-btn">Update</button>
              </div>
            </div>
        )}
      </div>
  );
};
const TableRow = ({ item, status, onStatusChange, handleDelete, handleButtonClick, handleSettingsToggle }) => (
    <tr>
      <td style={{ width: "25%" }} onClick={(e) => handleButtonClick(e, item)}>
        <div>{item.cryptoname}</div>
        <div className="s10-portfolio_type" style={{ color: item.type === 'SPOT' ? '#f44336' : item.type === 'X-MARGIN' ? '#555' : '#AAA' }}>
          {item.type}
        </div>
      </td>
      <td style={{ width: "25%" }}>
        <div>
          {item.init_invest}
          <span className="benefits" style={{ fontSize: 'smaller', marginLeft: '5px' }}>
            ({item.benefits})
          </span>
        </div>
        <div style={{ marginTop: '1px' }}>
          <span style={{ color: item.variation > 0 ? '#4CAF50' : item.variation < 0 ? '#f44336' : '#AAA' }}>
            {item.variation}
          </span>
        </div>
      </td>
      <td style={{ width: "25%" }}>
        <div className="s12" style={{ color: item.position === 'SHORT' ? '#e25045' : item.position === 'LONG' ? '#4CAF50' : '#F0B90B', display: 'block' }}>
          <span>{item.position}</span>
        </div>
        <span>level: {item.level}</span>
      </td>
      <td style={{ width: "25%" }}>
        <span className="control_portfolio" style={{ alignItems: "center",minWidth:"100px" }}>
          <FontAwesomeIcon
              icon={faSliders} // Portfolio settings icon
              size="sm"
              style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
              onClick={(e) => handleSettingsToggle(e, item)}
          />
          <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(item)} style={{cursor: 'pointer', marginRight: '5px'}}/>
          {item.strategie}
          <FontAwesomeIcon
              size="xl"
              icon={status === 'online' ? faToggleOn : faToggleOff}
              onClick={onStatusChange}
              style={{ color: status === 'online' ? '#4CAF50' : '#f44336', cursor: 'pointer', marginLeft: '5px' }}
          />
        </span>
      </td>
    </tr>
);
export default TablePortfolio;


