import React, { useState, useEffect } from 'react';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip,Label } from 'recharts';

const ProgressionBarTechnical = ({ recommend_all, recommend_other, recommend_sma, recommend_ema }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const calculateData = (recommendations) => {
            return recommendations.map(recommendation => ({
                name: recommendation.name,
                positive: ((recommendation.value + 1) * 50).toFixed(2),
                negative: (100 - ((recommendation.value + 1) * 50)).toFixed(2)
            }));
        };

        const recommendations = [
            { name: 'ALL', value: recommend_all },
            { name: 'SMA', value: recommend_sma },
            { name: 'EMA', value: recommend_ema },
            { name: 'OTHER', value: recommend_other }

        ];

        setData(calculateData(recommendations));
    }, [recommend_all, recommend_other, recommend_sma, recommend_ema]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#888', padding: '10px' }}>
                    {payload.map((entry, index) => (
                        <p key={index}>
                        <span style={{ color: entry.dataKey === 'positive' ? '#000' : '#FFF' }}>
                            {entry.dataKey === 'positive' ? 'Positive' : 'Negative'}: {typeof entry.value === 'number' ? entry.value.toFixed(2) + '%' : entry.value}
                        </span>
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <ResponsiveContainer width="100%" height={150}>
                <BarChart data={data} layout="horizontal">
                    <CartesianGrid vertical={false} />
                    <XAxis type="category" dataKey="name" tick={{ fontSize: 7 }}>
                    </XAxis>
                    <YAxis type="number" hide />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="positive" fill="#000" stackId="a" label={{ position: 'center', formatter: (value) => `${value}%` }} stroke="#000" strokeWidth={1} />
                    <Bar dataKey="negative" fill="#FFF" stackId="a" stroke="#000" strokeWidth={1} />
                </BarChart>
            </ResponsiveContainer>

        </div>
    );
};

export default ProgressionBarTechnical;