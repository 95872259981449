import { useContext } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import Login from './components/Login'
import Signup from './components/Signup'
import AppWebsiteVisits from "./components/AppWebsiteVisits";

// Components for route main page
import Header from './components/header.js'
import Footer from './components/footer.js'
import Content from './components/content.js'

import { UserContext } from './App'


function RoutesComp() {
  const userContext = useContext(UserContext);

  return (
    <>
      <Routes>
        {userContext.username && (
		<>
          <Route path='/home' element={<>Welcome {userContext.username}</>} />
		  <Route path="/" element={<><Header /><Content /><Footer /></>} />
		  <Route path="/visits" element={<AppWebsiteVisits />} />
		</>
        )}
        {!userContext.username && (
          <>
            <Route path='/' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
          </>
        )}
		<Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </>
  )
}

export default RoutesComp
