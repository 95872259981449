import React, {useCallback, useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrash, faSliders, faToggleOff} from "@fortawesome/free-solid-svg-icons";
import AddBotForm from "./AddBotForm";
import {useTranslation} from "react-i18next";
import { fetchWithAuth } from './fetchWithAuth';

const Element = ({ name, account_type, trade, timeframe,count_tp,count_sl,count_ind,take_profit,stoploss,amount,buy_rsi_indicator_value,sell_rsi_indicator_value,indicator_rsi_period,max_order,frequency,buy_bb_indicator_value,sell_bb_indicator_value,first_ma_value,second_ma_value,third_ma_value,fourth_ma_value,indicator, variation, benefits, state, botId,bot_type, onDelete }) => {
    const { t } = useTranslation();
    const [currentState, setCurrentState] = useState(state);
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [selectedBotId, setSelectedBotId] = useState(null);

    const variationColor = (variation < 50) ? '#f44336' : (variation >= 50 && variation <= 70 ? 'orange' : (variation > 70 ? '#4CAF50' : '#AAA'));
    const benefitsColor = benefits < 0 ? '#f44336' : (benefits > 0 ? '#4CAF50' : '#AAA');
    const circleColor = currentState === -1 ? 'grey' : (currentState === 1 ? '#00aa00' : '#ff0000');

    const handleOpenSetting = (botId) => {
        console.log(`Opening settings for bot with ID: ${botId}`);
        setSettingsModalOpen(true);
        setSelectedBotId(botId);
    };
    const handleCloseSetting = () => {
        setSettingsModalOpen(false);
        setSelectedBotId(null);

    };
    function roundDecimals(num) {
        let numStr = num.toString();
        let [integerPart, decimalPart] = numStr.split('.');
        let numLen = integerPart.length + (decimalPart ? decimalPart.length : 0); // Add a check for undefined decimalPart

        if (num < 1) {
            return parseFloat(num.toFixed(Math.min(7, decimalPart ? decimalPart.length : 0))); // Add a check for undefined decimalPart
        } else if (num < 10) {
            return parseFloat(num.toFixed(Math.min(6, numLen)));
        } else if (num < 100) {
            return parseFloat(num.toFixed(Math.min(5, numLen)));
        } else if (num < 1000) {
            return parseFloat(num.toFixed(Math.min(4, numLen)));
        } else if (num < 10000) {
            return parseFloat(num.toFixed(Math.min(3, numLen)));
        } else if (num < 100000) {
            return parseFloat(num.toFixed(Math.min(2, numLen)));
        } else if (num > 100000) {
            return parseFloat(num.toFixed(Math.min(1, numLen)));
        }
    }

    const handleIconClick = async (botId) => {
        // Determine the next state in the cycle 0 -> 1 -> -1 -> 0
        let newState;
        switch (currentState) {
            case 0:
                newState = 1;
                break;
            case 1:
                newState = -1;
                break;
            case -1:
                newState = 0;
                break;
            default:
                newState = 0;
        }

        // Update the state locally
        setCurrentState(newState);

        try {
            // Send a PUT request to your backend API to update the state in the database
            const response = await fetch(`/api/bots/${botId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ state: newState }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const updatedData = await response.json();
            // Handle the updated data if needed
            console.log(updatedData);
        } catch (error) {
            // Handle errors
            console.error('Failed to update database:', error);
        }
    };

    const handleDelete = async (botId) => {
        try {
            const response = await fetch(`/api/bots/${botId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Failed to delete the bot');
            }

            const deletedBotData = await response.json();
            // Call the onDelete function passed from the parent component
            onDelete(botId);
            console.log(deletedBotData);
        } catch (error) {
            console.error('Error deleting bot:', error);
        }
    };

    return (
        <div style={styles.element}>
            <div style={styles.header}>
                <div style={styles.leftSection}>
                    <div style={styles.symbolContainer}>
                        <span style={styles.symbol}>{name}</span>
                        <span className="s10-portfolio_type" style={{ color: account_type === 'SPOT' ? '#f44336' : account_type === 'X-MARGIN' ? '#555' : '#AAA', display: 'block' }}>{account_type}</span>
                    </div>
                </div>
                <div style={styles.leftSection}>
                    <div style={styles.tradeDetails}>
                        <span style={styles.trade}>{trade} {t("automated_self_services.trades")}</span>
                        <span>{indicator} | {timeframe}</span>
                    </div>
                </div>
                <div style={styles.successInfo}>
                    <span><span style={styles.success}>{t("automated_self_services.success")} : </span><span style={{ ...styles.variation, color: variationColor }}>{typeof variation === 'number' ? variation.toFixed(2) : variation}%</span></span>
                    <span><span style={styles.success}></span><span style={{ ...styles.benefits, color: benefitsColor }}> {typeof benefits === 'number' ? benefits.toFixed(2) : benefits} $</span></span>
                </div>
                <span className="control_portfolio" style={{ alignItems: "center",minWidth:"80px" }}>
                       <FontAwesomeIcon
                           icon={faSliders} // Replace this with the settings icon you want
                           size="sm"
                           style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                           onClick={() => handleOpenSetting(botId)}
                       />
                        <FontAwesomeIcon
                            icon={faTrash}
                            size="sm"
                            style={{ color: '#FFF', cursor: "pointer", marginRight: '5px' }}
                            onClick={() => handleDelete(botId)} // Call handleDelete on click
                        />
                    {bot_type}
                    <FontAwesomeIcon
                        icon={faToggleOff}
                        size="xl"
                        style={{ color: circleColor, cursor: "pointer", marginLeft: '5px' }}
                        onClick={() => handleIconClick(botId)} // Pass botId to handleIconClick
                    />
                </span>
            </div>
            {settingsModalOpen && selectedBotId === botId && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t("modal_title.bot_setting")} {name || 'Invalid symbol'}</h2>
                        <button onClick={handleCloseSetting} className="close-btn">X</button>
                        <div className="form-container" style={{paddingBottom:"15px"}}>
                            <div className="form-grid">
                        {[
                            { label: t("automated_self_services.amount"), value: amount + " $" },
                            { label: t("automated_self_services.take_profit"), value: roundDecimals((take_profit-1)*100) + " %" },
                            { label: t("automated_self_services.stoploss"), value: roundDecimals((stoploss-1)*100) + " %" },
                            { label: t("automated_self_services.indicator"), value: indicator },
                            { label: 'Trades', value: trade },
                            { label: 'TP', value: count_tp },
                            { label: 'IND', value: count_ind },
                            { label: 'SL', value: count_sl },
                            { label: t("automated_self_services.rsi_period"), value: indicator_rsi_period },
                            { label: t("automated_self_services.buy_rsi_value"), value: buy_rsi_indicator_value },
                            { label: t("automated_self_services.sell_rsi_value"), value: sell_rsi_indicator_value },
                            { label: t("automated_self_services.bb_lower"), value: roundDecimals((buy_bb_indicator_value-1)*100) + " %" },
                            { label: t("automated_self_services.bb_upper"), value: roundDecimals((sell_bb_indicator_value-1)*100) + " %" },
                            { label: t("automated_self_services.max_order"), value: max_order },
                            { label: t("automated_self_services.frequency"), value: frequency },
                            { label: 'MA #1', value: first_ma_value },
                            { label: 'MA #2', value: second_ma_value },
                            { label: 'MA #3', value: third_ma_value },
                            { label: 'MA #4', value: fourth_ma_value }
                        ].map(({ label, value }, index) => (


                                <div className="form-group" key={index}>
                                    <span style={styles.label}>{label}:</span>
                                    <span style={styles.value}>{value !== null && value !== undefined ? value : 'N/A'}</span>
                                </div>

                        ))}
                        </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    );
};


const styles = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '2px',
        width: '100%'
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center'
    },
    symbolContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    symbol: {
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#000',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)'
    },
    success: {
        fontSize: '9px',
        fontWeight: 'normal',
        color: '#444',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)'
    },
    accountType: {
        fontSize: '10px',
        color: '#888'
    },

    tradeDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: '11px',
        color: '#AAA',
        textAlign: 'center',
        flexWrap: 'wrap',
        width: '100%'
    },
    trade: {
        color: '#555',
        textShadow: '0px 0px 1px rgba(128, 128, 128, 0.5), 0px 1px 1px rgba(128, 128, 128, 0.5), 0px 0px 1px rgba(128, 128, 128, 0.5)',

    },
    successInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontSize: '10px'
    },
    statusInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontSize: '7px'
    },
    variation: {
        fontSize: '10px',
        fontWeight: 'bold'
    },
    benefits: {
        fontSize: '10px',
        fontWeight: 'bold',
        marginTop: '2px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        padding: '2px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    elementContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '2px',
        border: '1px solid #DDD',
        backgroundColor: '#f9f9f9',
        width: '100%'
    },
    element: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%'
    },
    label: {
        fontWeight: 'bold',
        color: '#555',
        fontSize: '9px',
    },
    value: {
        color: '#777',
        fontSize: '9px',
    }
};

const Bots = ({ isFreeTrial, isEssential, isPremium }) => {
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        const data = await fetchWithAuth('/api/bots');

        if (data) {
            console.log('Fetched bots:', data);
            if (Array.isArray(data)) {
                setData(data);
            } else {
                console.error('Unexpected API response format:', data);
                setData([]);
            }
        } else {
            setData([]); // Ensure empty state on error
        }
    }, []);

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60000); // Fetch every 60 seconds
        return () => clearInterval(intervalId);
    }, []);

    const handleAddBot = (newBot) => {
        setData((prevData) => [...prevData, newBot]);
    };

    const handleDeleteBot = (botId) => {
        setData((prevData) => prevData.filter(bot => bot._id !== botId));
    };

    // Extract the symbols from the existing bots
    const existingSymbols = Array.isArray(data) ? data.map(item => item.symbol) : [];

    return (
        <div style={styles.container}>
            <AddBotForm
                onAddBot={handleAddBot}
                existingSymbols={existingSymbols}
                isFreeTrial={isFreeTrial}
                isEssential={isEssential}
                isPremium={isPremium}
            />
            {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                    <div style={styles.elementContainer} key={index}>
                        <Element
                            name={item.symbol}
                            account_type={item.account_type}
                            trade={item.trades}
                            count_tp={item.count_tp}
                            count_sl={item.count_sl}
                            count_ind={item.count_ind}
                            take_profit={item.take_profit}
                            stoploss={item.stoploss}
                            amount={item.amount}
                            buy_rsi_indicator_value={item.buy_rsi_indicator_value}
                            sell_rsi_indicator_value={item.sell_rsi_indicator_value}
                            buy_bb_indicator_value={item.buy_bb_indicator_value}
                            sell_bb_indicator_value={item.sell_bb_indicator_value}
                            indicator_rsi_period={item.indicator_rsi_period}
                            max_order={item.max_order}
                            frequency={item.frequency}
                            first_ma_value={item.first_ma_value}
                            second_ma_value={item.second_ma_value}
                            third_ma_value={item.third_ma_value}
                            fourth_ma_value={item.fourth_ma_value}
                            timeframe={item.timeframe}
                            variation={item.rates}
                            benefits={item.benefits}
                            state={item.state}
                            bot_type={item.bot_type}
                            indicator={item.indicator}
                            botId={item._id}
                            onDelete={handleDeleteBot} // Pass the delete handler
                        />
                    </div>
                ))
            ) : (
                <p>No data available or loading...</p> // Fallback message
            )}
        </div>
    );
};

export default Bots;

