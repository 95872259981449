import React, {useEffect, useRef, useState} from 'react';
import IndicatorStrategieBuy from "./IndicatorStrategieBuy";

const BuyStrategie = () => {
  const [consolidateSelection, setConsolidateSelection] = useState('NO');
  const [indicatorSelection, setIndicatorSelection] = useState('NO');
  const [selectedItem, setSelectedItem] = useState(null);

  const [itemsPortfolio, setItemsPortfolio] = useState([]);
  const [itemsHistory, setItemsHistory] = useState([]);
  const alertRef = useRef(null);
  const [selectedCriteriaSell, setSelectedCriteriaSell] = useState('');
  const [selectedCriteriaSell2, setSelectedCriteriaSell2] = useState('');
  const [selectedCriteriaStopLoss, setSelectedCriteriaStopLoss] = useState('');
  const [selectedCriteriaBuy, setSelectedCriteriaBuy] = useState('');
  const [selectedCriteriaBuy2, setSelectedCriteriaBuy2] = useState('');
  const [selectedCriteriaBuy3, setSelectedCriteriaBuy3] = useState('');
  const [selectedCriteriaBuy4, setSelectedCriteriaBuy4] = useState('');
  const [selectedCriteriaBuy5, setSelectedCriteriaBuy5] = useState('');
  const [selectedCriteriaStrategie, setSelectedCriteriaStrategie] = useState('');
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteriaListSell, setCriteriaListSell] = useState([]);
  const [criteriaListSell2, setCriteriaListSell2] = useState([]);
  const [criteriaListStopLoss, setCriteriaListStopLoss] = useState([]);
  const [criteriaListBuy, setCriteriaListBuy] = useState([]);
  const [criteriaListBuy2, setCriteriaListBuy2] = useState([]);
  const [criteriaListBuy3, setCriteriaListBuy3] = useState([]);
  const [criteriaListBuy4, setCriteriaListBuy4] = useState([]);
  const [criteriaListBuy5, setCriteriaListBuy5] = useState([]);
  const [criteriaListStrategie, setCriteriaListStrategie] = useState([]);
  const [statusList, setStatusList] = useState(itemsPortfolio.map(() => 'online'));
  const handleButtonClick = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item);
    //alert("Item selected: " + item.id);
  };

  const handleClickOutside = (e) => {
    if (alertRef.current && !alertRef.current.contains(e.target)) {
      setSelectedItem(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleCriteriaChangeStopLoss = (e, index) => {
    const newCriteriaListStopLoss = [...criteriaListStopLoss];
    newCriteriaListStopLoss[index] = e.target.value;
    setCriteriaListStopLoss(newCriteriaListStopLoss);
    setSelectedCriteriaStopLoss(e.target.value);
  };

  const handleCriteriaChangeSell = (e, index) => {
    const newCriteriaListSell = [...criteriaListSell];
    newCriteriaListSell[index] = e.target.value;
    setCriteriaListSell(newCriteriaListSell);
    setSelectedCriteriaSell(e.target.value);
  };

  const handleCriteriaChangeSell2 = (e, index) => {
    const newCriteriaListSell2 = [...criteriaListSell2];
    newCriteriaListSell2[index] = e.target.value;
    setCriteriaListSell2(newCriteriaListSell2);
    setSelectedCriteriaSell2(e.target.value);
  };

  const handleCriteriaChangeBuy = (e, index) => {
    const newCriteriaListBuy = [...criteriaListBuy];
    newCriteriaListBuy[index] = e.target.value;
    setCriteriaListBuy(newCriteriaListBuy);
    setSelectedCriteriaBuy(e.target.value);
  };
  const handleCriteriaChangeBuy2 = (e, index) => {
    const newCriteriaListBuy2 = [...criteriaListBuy2];
    newCriteriaListBuy2[index] = e.target.value;
    setCriteriaListBuy2(newCriteriaListBuy2);
    setSelectedCriteriaBuy2(e.target.value);
  };
  const handleCriteriaChangeBuy3 = (e, index) => {
    const newCriteriaListBuy3 = [...criteriaListBuy3];
    newCriteriaListBuy3[index] = e.target.value;
    setCriteriaListBuy3(newCriteriaListBuy3);
    setSelectedCriteriaBuy3(e.target.value);
  };
  const handleCriteriaChangeBuy4 = (e, index) => {
    const newCriteriaListBuy4 = [...criteriaListBuy4];
    newCriteriaListBuy4[index] = e.target.value;
    setCriteriaListBuy4(newCriteriaListBuy4);
    setSelectedCriteriaBuy4(e.target.value);
  };
  const handleCriteriaChangeBuy5 = (e, index) => {
    const newCriteriaListBuy5 = [...criteriaListBuy5];
    newCriteriaListBuy5[index] = e.target.value;
    setCriteriaListBuy5(newCriteriaListBuy5);
    setSelectedCriteriaBuy5(e.target.value);
  };

  const handleCriteriaChangeStrategie = (e, index) => {
    const newCriteriaListStrategie = [...criteriaListStrategie];
    newCriteriaListStrategie[index] = e.target.value;
    setCriteriaListStrategie(newCriteriaListStrategie);
    setSelectedCriteriaStrategie(e.target.value);
  };
  const handleStatusChange = (index) => {
    const updatedStatusList = [...statusList];
    const updatedStatus = updatedStatusList[index] === 'online' ? 'offline' : 'online';
    updatedStatusList[index] = updatedStatus;
    setStatusList(updatedStatusList);

    const portfolioId = itemsPortfolio[index]._id;
    const updatedStatusValue = updatedStatus === 'online' ? 1 : 0;
    // Create the request body
    const updatedData = {
      state: updatedStatusValue,
    };
    fetch(`/api/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ state: updatedStatusValue }),
    })
        .then((response) => response.json())
        .then((updatedData) => {
          // Handle the updated portfolio data
          console.log(updatedData);
        })
        .catch((error) => {
          // Handle errors
          console.error('Failed to update portfolio:', error);
        });
  };



  const handleSubmit = (e, id) => {
    e.preventDefault();
    const selectedCriteriaSell = criteriaListSell[id];
    const selectedCriteriaSell2 = criteriaListSell2[id];
    const selectedCriteriaStopLoss = criteriaListStopLoss[id];
    const selectedCriteriaBuy = criteriaListBuy[id];
    const selectedCriteriaBuy2 = criteriaListBuy2[id];
    const selectedCriteriaBuy3 = criteriaListBuy3[id];
    const selectedCriteriaBuy4 = criteriaListBuy4[id];
    const selectedCriteriaBuy5 = criteriaListBuy5[id];
    const selectedCriteriaStrategie = criteriaListStrategie[id];

    // Create the request body
    const updatedData = {
      buy_t1: selectedCriteriaBuy,
      buy_t2: selectedCriteriaBuy2,
      buy_t3: selectedCriteriaBuy3,
      buy_t4: selectedCriteriaBuy4,
      buy_t5: selectedCriteriaBuy5,
      sell_t1: selectedCriteriaSell,
      sell_t2: selectedCriteriaSell2,
      stoploss: selectedCriteriaStopLoss,
      strategie: selectedCriteriaStrategie,
    };

    const portfolioId = itemsPortfolio[id]._id; // Replace with the actual portfolio ObjectId
    // Make a PUT request to update the portfolio
    fetch(`/api/portfolios/${portfolioId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    })
        .then((response) => response.json())
        .then((updatedData) => {
          // Handle the updated portfolio data
          console.log(updatedData);
          // Reset the form or update state as needed

          alert(`You are updating your strategy for the portfolio:
          Sell = ${selectedCriteriaSell},
          Sell2 = ${selectedCriteriaSell2},
          Stop Loss = ${selectedCriteriaStopLoss},
          Buy = ${selectedCriteriaBuy},
          Buy2 = ${selectedCriteriaBuy2},
          Buy3 = ${selectedCriteriaBuy3},
          Buy4 = ${selectedCriteriaBuy4},
          Buy5 = ${selectedCriteriaBuy5},
          Strategy = ${selectedCriteriaStrategie}`);

        })
        .catch((error) => {
          // Handle errors
          console.error('Failed to update portfolio:', error);
        });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch initial data for portfolios
        const portfoliosResponse = await fetch('/api/portfolios');
        const portfoliosData = await portfoliosResponse.json();
        setItemsPortfolio(portfoliosData);
        setCriteriaList(portfoliosData.map(() => ''));
        setStatusList(portfoliosData.map(item => (item.state === 1 ? 'online' : 'offline')));

        // Fetch initial data for orderfilleds if selectedItem exists
        if (selectedItem) {
          const orderFilledResponse = await fetch(`/api/orderfilleds?portfolio_id=${selectedItem.id}`);
          const orderFilledData = await orderFilledResponse.json();
          setItemsHistory(orderFilledData);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchData(); // Fetch initial data immediately when component mounts

    // Set up interval for subsequent updates after the initial fetch
    const intervalId = setInterval(() => {
      fetchData(); // Fetch data every 3 seconds
    }, 60000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [selectedItem]); // Run the effect only when selectedItem changes
  return (
      <div className="card col-6 row-20 pad-top-10 border-radius">
        <div className="linechart col-12 row-20 pad-right-10 border-radius">
          <div className="dashboard_text_3 col-12 row-20 border-radius">

            {/* Main Line */}
            <div className="dashboard_title_2 col-12 s10-strategie-buy border-radius-top center">BUY</div>
            <div className="main-line-details">
              <div className="main-line-details">
                <div className="dashboard_title_2 col-4 s10b">Entry Price</div>
                <div className="dashboard_title_2 col-4 s10b">Amount</div>
                <div className="dashboard_title_2 col-4 s10b">Frequency</div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="0">0%</option>
                    <option value="1">1%</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="10">10 $</option>
                    <option value="100">100 $</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="1d">1d</option>
                    <option value="1w">1w</option>
                  </select>
                </div>
              </div>
              <div className="dashboard_title_2 col-8 s10b border-style-top">Consolidate</div>
              <div className="col-4 border-style-top border-style-top">
                <select className="custom-select" onChange={(e) => setConsolidateSelection(e.target.value)}>
                  <option value="NO">NO</option>
                  <option value="YES">YES</option>
                </select>
              </div>
            </div>

            {/* Subline for Consolidate */}
            {consolidateSelection === 'YES' &&
                itemsPortfolio.map((item, index) => (
                <div className="subline col-12 row-20 border-radius" key={index}>
                  <div className="dashboard_title_2 s10b3">Threshold #1:
                    <select
                        value={criteriaListBuy[index]}
                        onChange={(e) => handleCriteriaChangeBuy(e, index)}
                    >
                      <option value="">{item.buy_t1}</option>
                      {Array.from({ length: 109 }, (_, i) => {
                        if (i < 9) {
                          return (
                              <option key={-i} value={`-0.${i + 1}`}>{`-0.${i + 1}`}</option>
                          );
                        } else {
                          return (
                              <option key={9 - i} value={`${9 - i}`}>{9 - i}</option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>))}
            )}

            {/* Indicator
            <div className="dashboard_title_2 col-8 s10b border-style-top">Indicators</div>
            <div className="col-4 border-style-top">
              <select className="custom-select" onChange={(e) => setIndicatorSelection(e.target.value)}>
                <option value="NO">NO</option>
                <option value="YES">YES</option>
              </select>
            </div>*/}

            {/* Subline for Indicator
            {indicatorSelection === 'YES' && (
                <div className="subline col-12 row-20 border-radius">
                    <IndicatorStrategieBuy/>
                </div>
            )}*/}
          </div>
        </div>
      </div>
  );
};

export default BuyStrategie;
