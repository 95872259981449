import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp, faArrowTrendDown, faPause } from "@fortawesome/free-solid-svg-icons";
import indicatorNameMap from './IndicatorNameMapping';
import indicatorTimeframeMap from './IndicatorTimeframeMapping';
import {useTranslation} from "react-i18next";
// Utility to generate color class based on recommendation value
const getColorClass = (value) => {
	if (value === -1) return "recommendation-red";
	if (value === 1) return "recommendation-green";
	return "recommendation-grey";
};
const getColorClassChg = (value) => {
	if (value < 0) return "recommendation-red";
	if (value > 0) return "recommendation-green";
	return "recommendation-grey";
};
// Component for displaying recommendation icon and values
const RecommendationIcon = ({ value }) => {
	// Determine the icon based on the value
	const icon =
		value === 1 ? faArrowTrendUp : value === -1 ? faArrowTrendDown : faPause;
	const colorClass = getColorClass(value);
	return <FontAwesomeIcon icon={icon} className={`indicator-icon ${colorClass}`} />;
};
const RecommendationIconChg = ({ value }) => {
	// Determine the icon based on the value
	const icon =
		value > 0 ? faArrowTrendUp : value < 0 ? faArrowTrendDown : faPause;
	const colorClass = getColorClassChg(value);
	return <FontAwesomeIcon icon={icon} className={`indicator-icon-chg ${colorClass}`} />;
};
// Main CoinHistory component
const CoinHistory = ({ selectedCurrency, onCurrencyChange }) => {
	const { t } = useTranslation();
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [technicalData, setTechnicalData] = useState(null);

	useEffect(() => {
		// Function to fetch currency options
		const fetchCurrencyData = () => {
			fetch("/api/technical_info")
				.then((response) => response.json())
				.then((data) => {
					if (Array.isArray(data)) {
						setCurrencyOptions(data);
					}
				})
				.catch((error) => {
					console.error("Error fetching currency data:", error);
				});
		};

		// Function to fetch technical data based on selected currency
		const fetchTechnicalData = () => {
			if (selectedCurrency) {
				fetch(`/api/technical_info?currency=${selectedCurrency}`)
					.then((response) => response.json())
					.then((data) => {
						if (Array.isArray(data) && data.length > 0) {
							setTechnicalData(data[0].technical);
						} else {
							setTechnicalData(null);
						}
					})
					.catch((error) => {
						console.error("Error fetching technical data:", error);
						setTechnicalData(null);
					});
			} else {
				setTechnicalData(null);
			}
		};

		// Fetch data initially
		fetchCurrencyData();
		fetchTechnicalData();

		// Set interval to fetch data every 60 seconds
		const intervalId = setInterval(() => {
			fetchTechnicalData();
		}, 60000); // 60 seconds

		// Cleanup the interval when the component unmounts or selectedCurrency changes
		return () => clearInterval(intervalId);
	}, [selectedCurrency]);


	const renderIndicators = (indicatorsList, timeframe) => {
		return indicatorsList.map((indicator, idx) => {
			const indicatorValue = technicalData ? technicalData[indicator] : null;

			// Translation fallback
			let readableName = t(`indicator.${indicator}`) || indicator;

			// Remove timeframe from readableName (assuming timeframe is at the end of the string)
			readableName = readableName.replace(/( \d+m|\d+h|\d+d)$/g, ''); // Regex to remove 15m, 1h, 1d, etc.

			return (
				<div key={idx} className="indicator-item">
					<RecommendationIcon value={indicatorValue} />
					<span className="indicator-name">{readableName}</span>
					<span className="indicator-value">
          {indicatorValue !== null ? indicatorValue : "N/A"}
        </span>
				</div>
			);
		});
	};


	const renderTimeframeColumn = (timeframe, indicators) => (
		<div className="timeframe-column">
			<h3 className="timeframe-title">{timeframe}</h3>
			{["SUPPORT_RESISTANCE", "OSCILLATORS", "EMA", "CANDLESTICKS", "OTHER"].map((category, idx) => (
				<div key={idx} className="indicator-category">
					<h4 className="styled-heading-indicator">
						{category === "OSCILLATORS"
							? t("technical_analysis.oscillators")
							:  category === "EMA"
								? t("technical_analysis.ema_crossings")
								: category === "CANDLESTICKS"
									? t("technical_analysis.candlesticks")
									: category === "SUPPORT_RESISTANCE"
										? t("technical_analysis.support_resistance")
										: t("technical_analysis.other")}
					</h4>

					<div className="indicators">
						{renderIndicators(indicators[category], timeframe)} {/* Call renderIndicators for each category */}
					</div>
				</div>
			))}
		</div>
	);

	return (
		<div className="coinhistory-container">
			<div className="currency-selector">
				<select
					className="currency-dropdown"
					value={selectedCurrency}
					onChange={(e) => onCurrencyChange(e.target.value)}
				>
					{currencyOptions.map((currency, idx) => (
						<option key={idx} value={currency}>
							{currency}
						</option>
					))}
				</select>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexWrap: "wrap",
					gap: "3px",
					paddingBottom: "10px",
				}}
			>
				{[
					{ label: t(`indicator.current_price_wf`), value: technicalData?.current_price_wf, unit: "$" },
					{ label: t(`indicator.lowest_price_wf`), value: technicalData?.lowest_price_wf, unit: "$" },
					{ label: t(`indicator.highest_price_wf`), value: technicalData?.highest_price_wf, unit: "$" },
					{ label: t(`indicator.price_position_wf`), value: technicalData?.price_position_wf, unit: "%" },
					{ label: t(`indicator.score_wf`), value: technicalData?.score_wf, unit: "" },
				].map((item, index) => (
					<div
						key={index}
						style={{
							flex: "1 1 calc(20% - 10px)",
							padding: "5px",
							fontSize: "14px",
							textAlign: "center",
							backgroundColor: "#fff",
							borderRadius: "8px",
							border: "1px solid #DDD",
						}}
					>
						<div style={{ fontSize: "8px", color: "#000" }}>
							{item.value !== undefined ? `${item.unit} ${item.value}` : "-"}
						</div>
						<div className="styled-heading-metric" style={{ color: "#e74c3c" }}>{item.label}</div>
					</div>
				))}

			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexWrap: "wrap",
					gap: "2px",
					paddingBottom: "10px",
				}}
			>
				{[
					{ label: t(`indicator.change_15m_wf`), value: technicalData?.change_15m_wf, unit: "%" },
					{ label: t(`indicator.change_1h_wf`), value: technicalData?.change_1h_wf, unit: "%" },
					{ label: t(`indicator.change_1d_wf`), value: technicalData?.change_1d_wf, unit: "%" },
					{ label: t(`indicator.change_1w_wf`), value: technicalData?.change_1w_wf, unit: "%" },
					{ label: t(`indicator.change_1m_wf`), value: technicalData?.change_1m_wf, unit: "%" },
					{ label: t(`indicator.volume_1d_wf`), value: technicalData?.volume_1d_wf, unit: "" },
					{ label: t(`indicator.market_cap_wf`), value: technicalData?.market_cap_wf, unit: "" },
				].map((item, index) => (
					<div
						key={index}
						style={{
							flex: "1 1 calc(10% - 10px)",
							padding: "2px",
							textAlign: "center",
							backgroundColor: "#fff",
							borderRadius: "8px",
							border: "1px solid #DDD",
						}}
					>
						<div style={{ fontSize: "6px", color: "#000" }}>
							{![t(`indicator.volume_1d_wf`), t(`indicator.market_cap_wf`)].includes(item.label) && (
								<RecommendationIconChg value={item.value} />
							)}
							{item.value != null ? `${item.value}` : "-"}
						</div>
						<div className="styled-heading-chg" style={{ color: "#888" }}>
							{`${item.label} ${item.unit}`}
						</div>
					</div>
				))}

			</div>
			{technicalData && (
				<div className="timeframes">
					{Object.entries(indicatorTimeframeMap).map(([timeframe, indicators]) =>
						renderTimeframeColumn(timeframe, indicators) // Render timeframe columns with indicators
					)}
				</div>
			)}
		</div>
	);
};

export default CoinHistory;
