import React from 'react';
import { useTranslation } from "react-i18next";

const TermsModal = () => {
    const { t } = useTranslation();

    return (
        <div className="terms-modal">
            <h1 className="terms-title">{t("modal_title.term_condition")}</h1>
            <div className="terms-content">
                <section>
                    <h2>{t("terms.step_titles.intro")}</h2>
                    <p>{t("terms.intro")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.services")}</h2>
                    <p>{t("terms.services_description")}</p>
                    <ul>
                        <li>Self-service robot creation (Scalping, DCA (Dollar-Cost Averaging), and more)</li>
                        <li>Strategy creation, virtual mode, and production (real purchase)</li>
                        <li>Market information</li>
                        <li>Financial indicators</li>
                    </ul>
                </section>
                <section>
                    <h2>{t("terms.step_titles.registration")}</h2>
                    <p>{t("terms.registration_info")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.responsibilities")}</h2>
                    <p>{t("terms.user_responsibilities")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.liability")}</h2>
                    <p>{t("terms.limitation_of_liability")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.privacy")}</h2>
                    <p>{t("terms.privacy")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.third_party")}</h2>
                    <p>{t("terms.third_party_platforms")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.changes")}</h2>
                    <p>{t("terms.changes_to_tnc")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.termination")}</h2>
                    <p>{t("terms.termination")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.jurisdiction")}</h2>
                    <p>{t("terms.jurisdiction")}</p>
                </section>
                <section>
                    <h2>{t("terms.step_titles.contact")}</h2>
                    <p>{t("terms.contact")}</p>
                </section>
            </div>
        </div>
    );
};

export default TermsModal;
