import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";

const FixedResultSimulator = () => {
    const [data, setData] = useState([]);
    const [initialFetchDone, setInitialFetchDone] = useState(false);
    const [itemsSimulator, setItemsSimulator] = useState([]);
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const fetchData = async () => {
        try {
            const username = getCookie('username'); // Get the username from the cookie
            const response = await fetch(`/api/simulator?username=${username}`);
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleReset = async (simulatorId) => {
        try {
            const updatedData = {
                    buy_state:0,
                    consolidate_state:0,
                    sell_state:0,
                    stoploss_state:0,
                    prod_state:0,
                    max_count: "Max count", // Replace with the appropriate value or state
                    entry_price: "Entry Price", // Replace with the appropriate value or state
                    buy_threshold: "Threshold", // Replace with the appropriate value or state
                    sell_threshold: "Threshold", // Replace with the appropriate value or state
                    stoploss_threshold: "Threshold", // Replace with the appropriate value or state
                    multiplier: "Multiplier", // Replace with the appropriate value or state
                    sell_type: "Type", // Replace with the appropriate value or state
                    stoploss_type: "Type", // Replace with the appropriate value or state
                    prod_entry_price: "Entry Price",
                    prod_total: "Total",
                    currency_count:0,
                    investment:0,
                    ratio_sell_0_1p:0,
                    ratio_sell_0_2p:0,
                    ratio_sell_0_3p:0,
                    ratio_sell_0_4p:0,
                    ratio_sell_0_5p:0,
                    ratio_sell_0_6p:0,
                    ratio_sell_0_7p:0,
                    ratio_sell_0_8p:0,
                    ratio_sell_0_9p:0,
                    ratio_sell_1p:0,
                    ratio_sell_2p:0,
                    ratio_sell_3p:0,
                    ratio_sell_4p:0,
                    ratio_sell_5p:0,
                    ratio_sell_6p:0,
                    ratio_sell_7p:0,
                    ratio_sell_8p:0,
                    ratio_sell_9p:0,
                    ratio_sell_10p:0,
                    ratio_sell_11p:0,
                    ratio_sell_12p:0,
                    ratio_sell_13p:0,
                    ratio_sell_14p:0,
                    ratio_sell_15p:0,
                    ratio_sell_16p:0,
                    ratio_sell_17p:0,
                    ratio_sell_18p:0,
                    ratio_sell_19p:0,
                    ratio_sell_20p:0,
                    ratio_sell_21p:0,
                    ratio_sell_22p:0,
                    ratio_sell_23p:0,
                    ratio_sell_24p:0,
                    ratio_sell_25p:0,
                    ratio_sell_26p:0,
                    ratio_sell_27p:0,
                    ratio_sell_28p:0,
                    ratio_sell_29p:0,
                    ratio_sell_30p:0,
                    sell_count:0,
                    stoploss_count:0,
                    sell:0,
                    results:{},
                };
                const response = await fetch(`/api/simulator/${simulatorId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });

                if (!response.ok) {
                    throw new Error('Failed to update simulator');
                }

                const updatedSimulator = await response.json();
                console.log(`Simulator updated: ${updatedSimulator}`);
                // Perform any necessary actions after successful update for each simulator
                alert('Simulator updated successfully');
            } catch (error) {
                console.error('Error updating simulator:', error);
                alert('Failed to update simulator. Please try again.');
            }
        };
    const convertToCSV = (data) => {
        if (!data || !data[0] || !data[0].results) {
            console.error('Results data is missing or undefined.');
            return ''; // Return an empty string or handle the error accordingly
        }

        const resultsData = data[0].results; // Extracting the 'results' object from the first item

        const currencies = Object.keys(resultsData); // Get all currency names

        // Collect all unique keys (properties) for the 'Strategie' objects across all currencies
        const allKeys = new Set();
        Object.values(resultsData).forEach(currencyData => {
            if (currencyData.Strategie) {
                Object.keys(currencyData.Strategie).forEach(key => allKeys.add(key));
            }
        });

        // Create headers by including 'Currency' and all available keys for strategy
        const headers = ['Currency', ...Array.from(allKeys).map(key => `${key}`)].join(",") + "\n";

        // Create rows for each currency with its strategy values
        const rows = currencies.map(currency => {
            const currencyData = resultsData[currency].Strategie || {}; // If 'Strategie' is missing, use an empty object
            const currencyInfo = [currency, ...Array.from(allKeys).map(key => {
                if (key === 'date_buy' || key.startsWith('date_sell')) {
                    // Formatting date values using toLocaleString()
                    return currencyData[key] ? `"${new Date(currencyData[key]).toLocaleString()}"` : '""';
                }
                return `"${currencyData[key] || ''}"`; // Use empty string if the key is missing
            })];
            return currencyInfo.join(",");
        }).join("\n");

        // Combine headers and rows to create the CSV content
        const csvContent = `data:text/csv;charset=utf-8,${headers}${rows}`;
        return encodeURI(csvContent);
    };
    function createTransactionRow(number,multiplier_buy,buy_threshold,result_buy,multiplier_sell,sell_threshold,result_sell) {
        return (
            <tr>
                <td>
                    <div style={{ display: 'inline-block',color: '#74d600',fontSize:'13px' }}>
                        BUY T{number}
                    </div>
                    <div style={{ color: '#666',fontSize:'9px' }}>
                        Indice: {multiplier_buy}
                    </div>
                    <div style={{ color: '#999',fontSize:'9px' }}>
                        Threshold: {buy_threshold} %
                    </div>
                </td>
                <td>
                    <div>
                    <span className="benefits" style={{ fontSize: '14px', marginLeft: '5px' }}>
                        {result_buy} %
                    </span>
                    </div>
                </td>
                <td>
                    <div style={{ display: 'inline-block',color: '#f44336',fontSize:'13px' }}>
                        SELL T{number}
                    </div>
                    <div style={{ color: '#666',fontSize:'9px' }}>
                        Indice: {multiplier_sell}
                    </div>
                    <div style={{ color: '#999',fontSize:'9px' }}>
                        Threshold: {sell_threshold} %
                    </div>
                </td>
                <td>
                    <div>
                    <span className="benefits" style={{ fontSize: '14px', marginLeft: '5px' }}>
                        {result_sell} %
                    </span>
                    </div>
                </td>
            </tr>
        );
    }



    const exportToCSV = () => {
        const csvData = convertToCSV(data);
        const downloadLink = document.createElement("a");
        downloadLink.href = csvData;
        downloadLink.download = 'simulation_results.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 3 seconds
        }, 180000);

        return () => clearInterval(intervalId);
    }, []); // Run the effect only once on component mount

    return (
        <div>
            {data.map((simulator) => (
                <div key={simulator._id} style={{ margin: '20px' }}>

                    {simulator[`virtual_type`] === "Multiple Thresholds" ? (

                            <div>
                                {data.map((simulator) => (
                                    <div key={simulator._id}>

                                        <table id="simulator-table-result">
                                            <tbody>
                                            {createTransactionRow(1,simulator[`multiplier_buy_t1`],simulator[`buy_t1`],simulator[`ratio_buy_t1`],simulator[`multiplier_sell_t1`],simulator[`sell_t1`],simulator[`ratio_sell_t1`])}
                                            {createTransactionRow(2,simulator[`multiplier_buy_t2`],simulator[`buy_t2`],simulator[`ratio_buy_t2`],simulator[`multiplier_sell_t2`],simulator[`sell_t2`],simulator[`ratio_sell_t2`])}
                                            {createTransactionRow(3,simulator[`multiplier_buy_t3`],simulator[`buy_t3`],simulator[`ratio_buy_t3`],simulator[`multiplier_sell_t3`],simulator[`sell_t3`],simulator[`ratio_sell_t3`])}
                                            {createTransactionRow(4,simulator[`multiplier_buy_t4`],simulator[`buy_t4`],simulator[`ratio_buy_t4`],simulator[`multiplier_sell_t4`],simulator[`sell_t4`],simulator[`ratio_sell_t4`])}
                                            {createTransactionRow(5,simulator[`multiplier_buy_t5`],simulator[`buy_t5`],simulator[`ratio_buy_t5`],simulator[`multiplier_sell_t5`],simulator[`sell_t5`],simulator[`ratio_sell_t5`])}
                                            </tbody>
                                        </table>
                                        <p></p>
                                        <div className="s20" style={{
                                            color: ((simulator['sell'] - simulator['stoploss']) || simulator['sell'] > 0 ? '#5cb85c' : (simulator['sell'] - simulator['stoploss'] === 0 ? '#555' : '#f44336'))
                                        }}>
                                            {isNaN(simulator['sell']) || isNaN(simulator['stoploss']) ? (isNaN(simulator['sell']) ? simulator['stoploss'] : simulator['sell']) :
                                                (simulator['sell'] - simulator['stoploss']).toFixed(2)} $
                                        </div>
                                    </div>
                                ))}
                            </div>

                    ) : simulator[`virtual_type`] === "Basic" ? (
                        <div>
                            {data.map((simulator) => (
                                <div key={simulator._id}>
                                    <table id="simulator-table-result">
                                        <tbody>
                                        {createTransactionRow(1,0,0,0,100,simulator[`sell_t1`],simulator[`ratio_sell_t1`])}
                                        </tbody>
                                    </table>
                                    <p></p>
                                    <div className="s20" style={{
                                        color: ((simulator['investment']*(simulator[`ratio_sell_t1`]/100)*(simulator[`sell_t1`]/100)) > 0 ? '#5cb85c' : (simulator['investment']*(simulator[`ratio_sell_t1`]/100)*(simulator[`sell_t1`]/100) === 0 ? '#555' : '#f44336'))
                                    }}>
                                        {isNaN(simulator['sell']) || isNaN(simulator['stoploss']) ? (isNaN(simulator['sell']) ? simulator['stoploss'] : simulator['sell']) :
                                            (simulator['investment']*(simulator[`ratio_sell_t1`]/100)*(simulator[`sell_t1`]/100)).toFixed(2)} $
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default FixedResultSimulator;
