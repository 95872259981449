const IndicatorNameMappingSimulatorEssential = {
    // 15m Indicators
    "rsi6_15m_wf": "RSI (6) 15m",
    "rec_rsi6_bull_15m_wf": "RSI (6) Bullish 15m",
    "rsi14_15m_wf": "RSI (14) 15m",
    "rec_rsi14_bull_15m_wf": "RSI (14) Bullish 15m",
    "rsi30_15m_wf": "RSI (30) 15m",
    "rec_rsi30_bull_15m_wf": "RSI (30) Bullish 15m",
    "rec_sma510_15m_wf": "SMA 0510 15m",
    "rec_sma1020_15m_wf": "SMA 1020 15m",
    "rec_sma1550_15m_wf": "SMA 1550 15m",
    "rec_sma50200_15m_wf": "SMA 50200 15m",
    "rec_ema510_15m_wf": "EMA 0510 15m",
    "rec_ema1020_15m_wf": "EMA 1020 15m",
    "rec_ema1550_15m_wf": "EMA 1550 15m",
    "rec_ema50200_15m_wf": "EMA 50200 15m",


    // 1h Indicators
    "rsi6_1h_wf": "RSI (6) 1h",
    "rec_rsi6_bull_1h_wf": "RSI (6) Bullish 1h",
    "rsi14_1h_wf": "RSI (14) 1h",
    "rec_rsi14_bull_1h_wf": "RSI (14) Bullish 1h",
    "rsi30_1h_wf": "RSI (30) 1h",
    "rec_rsi30_bull_1h_wf": "RSI (30) Bullish 1h",
    "rec_sma510_1h_wf": "SMA 0510 1h",
    "rec_sma1020_1h_wf": "SMA 1020 1h",
    "rec_sma1550_1h_wf": "SMA 1550 1h",
    "rec_sma50200_1h_wf": "SMA 50200 1h",
    "rec_ema510_1h_wf": "EMA 0510 1h",
    "rec_ema1020_1h_wf": "EMA 1020 1h",
    "rec_ema1550_1h_wf": "EMA 1550 1h",
    "rec_ema50200_1h_wf": "EMA 50200 1h",

    // 1d Indicators
    "rsi6_1d_wf": "RSI (6) 1d",
    "rec_rsi6_bull_1d_wf": "RSI (6) Bullish 1d",
    "rsi14_1d_wf": "RSI (14) 1d",
    "rec_rsi14_bull_1d_wf": "RSI (14) Bullish 1d",
    "rsi30_1d_wf": "RSI (30) 1d",
    "rec_rsi30_bull_1d_wf": "RSI (30) Bullish 1d",
    "rec_sma510_1d_wf": "SMA 0510 1d",
    "rec_sma1020_1d_wf": "SMA 1020 1d",
    "rec_sma1550_1d_wf": "SMA 1550 1d",
    "rec_sma50200_1d_wf": "SMA 50200 1d",
    "rec_ema510_1d_wf": "EMA 0510 1d",
    "rec_ema1020_1d_wf": "EMA 1020 1d",
    "rec_ema1550_1d_wf": "EMA 1550 1d",
    "rec_ema50200_1d_wf": "EMA 50200 1d"

};
export default IndicatorNameMappingSimulatorEssential;