import React from 'react';
import binance_log_in from './images/binance_login.png';
import binance_label_api from './images/binance_label_api.png';
import binance_menu from './images/binance_menu.png';
import binance_verification from './images/binance_verification.png';
import binance_create_api from './images/binance_create_api.png';
import binance_api_configuration from './images/binance_api_configuration.png';
import binance_apikey_type from './images/binance_apikey_type.png';
import binance_api_secret_key from './images/binance_api_secret_key.png';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareMinus} from "@fortawesome/free-solid-svg-icons";
const BinanceComponent = () => {
    return (
        <div>
            <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                {/* Set overflowY to auto and maxHeight to limit the height of the overlay */}
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Create or Log in an Account on Binance
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_log_in} alt="Log in on Binance" style={{ maxWidth: '20%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Go to Account > API Management
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_menu} alt="API Management" style={{ maxWidth: '15%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Click on Create API
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_create_api} alt="Create API" style={{ maxWidth: '45%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Choose API Key type to System generated
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_apikey_type} alt="Select API key" style={{ maxWidth: '25%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Label API
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_label_api} alt="Label API" style={{ maxWidth: '20%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Phone Number and Email verification
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_verification} alt="Verification" style={{ maxWidth: '20%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Add trusted IPs and enable restrictions
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_api_configuration} alt="API parameters" style={{ maxWidth: '75%', height: 'auto' }} />
                    </div>
                </div>
                <div>
                    <h3 style={{ textAlign: 'left', paddingLeft: '100px', paddingTop: '50px', paddingBottom: '20px' }}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{color: "#000000",}} />&nbsp; Copy and paste these keys to fields
                    </h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={binance_api_secret_key} alt="API key and Secret key" style={{ maxWidth: '50%', height: 'auto' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BinanceComponent;
