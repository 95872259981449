import React, { useState } from 'react';
import IndicatorStrategieSell from "./IndicatorStrategieSell";

const SellStrategie = () => {
  const [consolidateSelection, setConsolidateSelection] = useState('NO');
  const [indicatorSelection, setIndicatorSelection] = useState('NO');

  return (
      <div className="card col-6 row-20 pad-top-10 border-radius">
        <div className="linechart col-12 row-20 pad-right-10 border-radius">
          <div className="dashboard_text_3 col-12 row-20 border-radius">

            {/* Main Line */}
            <div className="dashboard_title_2 col-12 s10-strategie-sell border-radius-top center">SELL</div>
            <div className="main-line-details">
              <div className="main-line-details">
                <div className="dashboard_title_2 col-4 s10b">Entry Price</div>
                <div className="dashboard_title_2 col-4 s10b">Amount</div>
                <div className="dashboard_title_2 col-4 s10b">Frequency</div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="0">0%</option>
                    <option value="1">1%</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="10">10 $</option>
                    <option value="100">100 $</option>
                  </select>
                </div>
                <div className="col-4">
                  <select className="custom-select">
                    <option value="1d">1d</option>
                    <option value="1w">1w</option>
                  </select>
                </div>
              </div>
              <div className="dashboard_title_2 col-8 s10b border-style-top">Take Profit</div>
              <div className="col-4 border-style-top border-style-top">
                <select className="custom-select" onChange={(e) => setConsolidateSelection(e.target.value)}>
                  <option value="NO">NO</option>
                  <option value="YES">YES</option>
                </select>
              </div>
            </div>

            {/* Subline for Consolidate */}
            {consolidateSelection === 'YES' && (
                <div className="subline col-12 row-20 border-radius">
                  <div className="dashboard_title_2 s10b3">TP #1:
                    <select>
                      <option value="-1">-1 %</option>
                      <option value="-2">-2 %</option>
                    </select>
                  </div>
                  <div className="dashboard_title_2 s10b3">TP #2:
                    <select>
                      <option value="-1">-1 %</option>
                      <option value="-2">-2 %</option>
                    </select>
                  </div>
                  <div className="dashboard_title_2 s10b3">TP #3:
                    <select>
                      <option value="-1">-1 %</option>
                      <option value="-2">-2 %</option>
                    </select>
                  </div>
                  <div className="dashboard_title_2 s10b3">TP #4:
                    <select>
                      <option value="-1">-1 %</option>
                      <option value="-2">-2 %</option>
                    </select>
                  </div>
                  <div className="dashboard_title_2 s10b3">TP #5:
                    <select>
                      <option value="-1">-1 %</option>
                      <option value="-2">-2 %</option>
                    </select>
                  </div>
                </div>
            )}

            {/* Indicator */}
            <div className="dashboard_title_2 col-8 s10b border-style-top">Indicators</div>
            <div className="col-4 border-style-top">
              <select className="custom-select" onChange={(e) => setIndicatorSelection(e.target.value)}>
                <option value="NO">NO</option>
                <option value="YES">YES</option>
              </select>
            </div>

            {/* Subline for Indicator */}
            {indicatorSelection === 'YES' && (
                <div className="subline col-12 row-20 border-radius">
                    <IndicatorStrategieSell/>
                </div>
            )}
          </div>
        </div>
      </div>
  );
};

export default SellStrategie;
