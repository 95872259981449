import React from 'react';
import {Checkbox, FormControlLabel, MenuItem, Stack, TextField, Typography} from '@mui/material';
import TermsModal from "./TermsConditions";
import {useTranslation} from "react-i18next";

function ExchangePlatformSection({ values, handleChange, errors }) {
    const { t } = useTranslation();
    return (
        <div>
            <Stack sx={{paddingBottom: '20px'}}>
                <TextField
                    select
                    label={t("profile.exchange")}
                    value={values.exchange || ''}
                    onChange={(event) => handleChange('exchange', event.target.value)}
                    variant="filled"
                >
                    <MenuItem value="binance">Binance</MenuItem>
                    {/*<MenuItem value="bitpanda">Bitpanda</MenuItem>
                    Add more platforms as needed */}
                </TextField>
            </Stack>
            <Stack direction="row" spacing={2} sx={{paddingBottom: '20px'}}>
                <TextField
                    variant='filled'
                    type='api_key'
                    label={t("profile.api_key")}
                    sx={{ width: "50%" }}
                    value={values.api_key || ''}
                    onChange={(event) => handleChange('api_key', event.target.value)}
                    error={errors.api_key}
                    helperText={errors.api_key && 'Please insert a correct api key'}
                />
                <TextField
                    variant='filled'
                    type="password"
                    label={t("profile.secret_key")}
                    sx={{ width: "50%" }}
                    value={values.secret_key || ''}
                    onChange={(event) => handleChange('secret_key', event.target.value)}
                    error={errors.secret_key}
                    helperText={errors.secret_key && 'Please insert a correct secret key'}
                />
            </Stack>
            <Stack>
                <TextField
                    variant='filled'
                    type='initial_fund'
                    label={t("profile.initial_fund")}
                    value={values.initial_fund || ''}
                    onChange={(event) => handleChange('initial_fund', event.target.value)}
                    error={errors.initial_fund}
                    helperText={errors.initial_fund && 'Please insert a correct number'}
                />
            </Stack>
        </div>
    );
}

export default ExchangePlatformSection;
