import React, {useEffect, useRef, useState, useCallback} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';
import { regexPassword } from '../utils';
import avatar from './images/botmight_logo.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faRectangleXmark} from "@fortawesome/free-solid-svg-icons";
import ExchangePlatformSection from './ExchangePlatformSection';
import BinanceComponent from './BinanceComponent';
import BitpandaComponent from './BitpandaComponent';

import {
    Paper, Container, Link, Stack, Button, Box, Divider, Select, MenuItem, Avatar, Typography, TextField, FilledInput,
    InputAdornment, IconButton, InputLabel, FormControl, FormHelperText, Checkbox, FormControlLabel, Card, CardContent, Grid, Tooltip} from '@mui/material';
import {Visibility, VisibilityOff,CheckCircle, Warning, Cancel,WorkspacePremium, AutoGraph, MilitaryTech
} from '@mui/icons-material';
import TermsModal from "./TermsConditions";
import {useTranslation} from "react-i18next";




function Signup() {
    const { t } = useTranslation();
    const getAvailabilityProps = (availability) => {
        switch (availability) {
            case "full":
                return { color: "#4CAF50", Icon: CheckCircle, legend: "Complet" };
            case "partial":
                return { color: "#FFA500", Icon: Warning, legend: "Partiel" };
            case "limited":
                return { color: "#D32F2F", Icon: Cancel, legend: "Limité" };
        }
    };
    const [planPeriod, setPlanPeriod] = useState("monthly");  // default to monthly
    const [exchange, setExchange] = useState('');
    const [values, setValues] = useState({
        country: '',
        email: '',
        username: '',
        phone: '',
        password: '',
        repeatPassword: '',
        showPassword: false,
        showRepeatPassword: false,
    });
    const [errors, setErrors] = useState({
        email: false,
        username: false,
        phone: false,
        password: false,
        repeatPassword: false,
        fetchError: false,
        fetchErrorMsg: '',
    });

    const [showTermsModal, setShowTermsModal] = useState(false);
    const termsModalRef = useRef(null);
    const [selectedPlan, setSelectedPlan] = useState('basic');
    const handleOpenTermsModal = () => setShowTermsModal(true);
    const handleCloseTermsModal = () => setShowTermsModal(false);
    const [isCGUApproved, setCGUApproved] = useState(false); // Track CGU approval
    const [platforms, setPlatforms] = useState([{ values: {exchange: ''}, errors: {} }]);
    const [showInfo, setShowInfo] = useState(false);
    const alertRef = useRef(null);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
    const [subscription_id, setSubscriptionId] = useState(null);
    const [plan_id, setPlanId] = useState(null);

    const plans = [
        {
            value: "basic",
            title: t("plans.basic.title"),
            price: "9.99€ / month",
            annualPrice: "99€ / year", // Added annual pricing
            services: [
                { name: t("plans.basic.services.automated_portfolio"), availability: t("plans.availability.full") },
                { name: t("plans.basic.services.market_overview"), availability: t("plans.availability.full") },
                { name: t("plans.basic.services.technical_analysis"), availability: t("plans.availability.full") },
                { name: t("plans.basic.services.long_position_only"), availability: t("plans.availability.partial") },
                { name: t("plans.basic.services.spot_only"), availability: t("plans.availability.limited") },
                { name: t("plans.basic.services.strategy_builder"), availability: t("plans.availability.limited") },
                { name: t("plans.basic.services.automated_self_service"), availability: t("plans.availability.limited") }
            ]
        },
        {
            value: "essential",
            title: t("plans.essential.title"),
            price: "19.99€ / month",
            annualPrice: "199€ / year", // Added annual pricing
            services: [
                { name: t("plans.essential.services.automated_portfolio"), availability: t("plans.availability.full") },
                { name: t("plans.essential.services.market_overview"), availability: t("plans.availability.full") },
                { name: t("plans.essential.services.technical_analysis"), availability: t("plans.availability.full") },
                { name: t("plans.essential.services.long_position_only"), availability: t("plans.availability.partial") },
                { name: t("plans.essential.services.spot_cross_margin"), availability: t("plans.availability.partial") },
                { name: t("plans.essential.services.strategy_builder"), availability: t("plans.availability.partial") },
                { name: t("plans.essential.services.automated_self_service"), availability: t("plans.availability.partial") }
            ]
        },
        {
            value: "premium",
            title: t("plans.premium.title"),
            price: "39.99€ / month",
            annualPrice: "399€ / year", // Added annual pricing
            services: [
                { name: t("plans.premium.services.automated_portfolio"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.market_overview"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.technical_analysis"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.long_short_position"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.spot_cross_isolated_margin"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.strategy_builder"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.automated_self_service"), availability: t("plans.availability.full") }
            ]
        },
        /*{
            value: "pro",
            title: t("plans.pro.title"),
            price: "99.99€ / month",
            annualPrice: "999€ / year", // Added annual pricing
            services: [
                { name: t("plans.premium.services.automated_portfolio"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.market_overview"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.technical_analysis"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.long_short_position"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.spot_cross_isolated_margin"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.strategy_builder"), availability: t("plans.availability.full") },
                { name: t("plans.premium.services.automated_self_service"), availability: t("plans.availability.full") },
                { name: t("plans.pro.services.api_access"), availability: t("plans.availability.full") },
                { name: t("plans.pro.services.vip_support"), availability: t("plans.availability.full") },
                { name: t("plans.pro.services.personal_coaching"), availability: t("plans.availability.full") }
            ]
        }*/
    ];

    const planIcons = {
        basic: <WorkspacePremium sx={{ color: "#1e88e5", fontSize: 40 }} />,
        essential: <AutoGraph sx={{ color: "#43a047", fontSize: 40 }} />,
        premium: <MilitaryTech sx={{ color: "#ffb300", fontSize: 40 }} />
    };

    const availabilityLegend = {
        full: "Fully Available",
        partial: "Partially Available",
        limited: "Limited Availability"
    };
    const handlePlanSelect = (planValue) => {
        handleChangePlan("selectedPlan", planValue);
    };

    const handleMollieSubscription = async (planId, planPrice, planPeriod, email, username) => {
        try {
            const response = await fetch("/api/create-subscription", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ planId, planPrice, planPeriod, email, username }),
            });

            const data = await response.json();
            if (data.paymentUrl) {
                window.location.href = data.paymentUrl; // Redirect to authorize payment
            } else {
                console.error("Subscription payment URL not received");
            }
        } catch (error) {
            console.error("Subscription request failed:", error);
        }
    };

    const handleChange = (index) => (fieldName, value) => {
        const updatedPlatforms = [...platforms];
        updatedPlatforms[index] = {
            ...updatedPlatforms[index],
            values: {
                ...updatedPlatforms[index].values,
                [fieldName]: value,
            },
        };
        setPlatforms(updatedPlatforms);
    };
    const handleChangePlatform = (field, value) => {
        // Update the state with the new value
        setExchange(value);
    };
    const handleChangePlan = (field, value) => {
        // Update the state with the new value
        setSelectedPlan(value);
    };
    const handleClose = () => {
        setShowInfo(false);
        setExchange("");
    };
    const handleHelpClick = () => {
        setShowInfo(true);
    };
    const handleChangeField = (fieldName) => (event) => {
        const currValue = event.target.value
        switch (fieldName) {
            case 'email':
                validator.isEmail(currValue)
                    ? setErrors({ ...errors, email: false })
                    : setErrors({ ...errors, email: true })
                break

            case 'password':
                regexPassword.test(currValue)
                    ? setErrors({ ...errors, password: false })
                    : setErrors({ ...errors, password: true })
                break

            case 'repeatPassword':
                currValue === values.password
                    ? setErrors({ ...errors, repeatPassword: false })
                    : setErrors({ ...errors, repeatPassword: true })
                break
        }
        setValues({ ...values, [fieldName]: event.target.value })
    }
    const handleShowPassword = (showPasswordField) => {
        setValues({
            ...values,
            [showPasswordField]: !values[showPasswordField],
        });
    };

    const handleAddPlatform = () => {
        setPlatforms([...platforms, { values: {exchange: ''}, errors: {} }]);
    };

    const handleRemovePlatform = (indexToRemove) => {
        setPlatforms(platforms.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if CGU approval and payment success status are valid
        if (!isCGUApproved) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: t("error.term_condition"),
            });
            return;
        }

        if (!isPaymentSuccessful) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: t("error.payment"),
            });
            return;
        }

        // Validate platform information
        const isValidPlatforms = platforms.every((platform) => {
            return (
                platform.values.exchange &&
                platform.values.initial_fund &&
                platform.values.api_key &&
                platform.values.secret_key
            );
        });

        if (!isValidPlatforms) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: t("error.all_platform"),
            });
            return;
        }

        // Construct request body including the new CGU and payment fields
        const requestBody = {
            email: values.email,
            password: values.password,
            username: values.username,
            country: values.country,
            phone: values.phone,
            isCGUApproved: isCGUApproved,
            isPaymentSuccessful: isPaymentSuccessful,
            subscription_id: subscription_id,
            plan_id: plan_id,
            platforms: platforms.map((platform) => ({
                exchange: platform.values.exchange,
                initial_fund: platform.values.initial_fund,
                api_key: platform.values.api_key,
                secret_key: platform.values.secret_key,
            })),
        };

        try {
            const res = await fetch('/api/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!res.ok) {
                const error = await res.json();
                setErrors({
                    ...errors,
                    fetchError: true,
                    fetchErrorMsg: error.msg,
                });
                return;
            }

            const data = await res.json();
            setErrors({
                ...errors,
                fetchError: false, // Clear previous errors
                fetchErrorMsg: data.msg,
            });

            // Reset form fields after successful registration
            setValues({
                email: '',
                username: '',
                country: '',
                phone: '',
                password: '',
                repeatPassword: '',
                showPassword: false,
                showRepeatPassword: false,
            });

            // Reset platform data
            const resetPlatforms = platforms.map(platform => ({
                values: { exchange: '', initial_fund: '', api_key: '', secret_key: '' },
                errors: {},
            }));
            setPlatforms(resetPlatforms);

            setPaymentSuccessful(false);
            setCGUApproved(false);
            setSubscriptionId('');
            setPlanId('');
            setSelectedPlan('');

        } catch (error) {
            setErrors({
                ...errors,
                fetchError: true,
                fetchErrorMsg: t("error.issue_server"),
            });
        }
    };


    return (
        <>
            <Container sx={{ marginTop: '70px',marginBottom: '70px' }} maxWidth='sm'>
                <Paper elevation={6}>
                    <Container
                        maxWidth='sm'
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: '20px',
                        }}>
                        <Avatar
                            alt="avatar"
                            src={avatar}
                            sx={{ width: 70, height: 70 }}
                        />
                        <h2>{t("profile.register_title")}</h2>
                    </Container>

                    <Stack
                        component='form'
                        onSubmit={handleSubmit}
                        noValidate
                        spacing={2}
                        sx={{ bgcolor: '#f5f5f6', padding: '20px', maxWidth: '100%' }}
                    >
                        <Container
                            maxWidth='sm'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '30px'
                            }}>
                            <h5>{t("profile.user_profile")}</h5>
                        </Container>
                        <Divider />
                        {/* Personal Information Section */}
                        <Stack direction="row" spacing={2}>
                            <TextField
                                variant='filled'
                                type='email'
                                label={t("profile.email")}
                                sx={{ width: "50%" }}
                                value={values.email || ''}
                                onChange={handleChangeField('email')}
                                error={errors.email}
                                helperText={errors.email && t("error.valid_email")}
                            />
                            <TextField
                                variant='filled'
                                type='username'
                                label={t("profile.username")}
                                sx={{ width: "50%" }}
                                value={values.username || ''}
                                onChange={handleChangeField('username')}
                                error={errors.username}
                                helperText={errors.username && t("error.valid_username")}
                            />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="flex-end">
                            <FormControl variant="filled" sx={{ width: "30%" }}>
                                <InputLabel id="country-label">{t("profile.country")}</InputLabel>
                                <Select
                                    labelId="country-label"
                                    value={values.country || ''}
                                    onChange={handleChangeField('country')}
                                >
                                    <MenuItem value="AT">{t("countries.AT")}</MenuItem>
                                    <MenuItem value="BE">{t("countries.BE")}</MenuItem>
                                    <MenuItem value="BG">{t("countries.BG")}</MenuItem>
                                    <MenuItem value="HR">{t("countries.HR")}</MenuItem>
                                    <MenuItem value="CY">{t("countries.CY")}</MenuItem>
                                    <MenuItem value="CZ">{t("countries.CZ")}</MenuItem>
                                    <MenuItem value="DK">{t("countries.DK")}</MenuItem>
                                    <MenuItem value="EE">{t("countries.EE")}</MenuItem>
                                    <MenuItem value="FI">{t("countries.FI")}</MenuItem>
                                    <MenuItem value="FR">{t("countries.FR")}</MenuItem>
                                    <MenuItem value="DE">{t("countries.DE")}</MenuItem>
                                    <MenuItem value="GR">{t("countries.GR")}</MenuItem>
                                    <MenuItem value="HU">{t("countries.HU")}</MenuItem>
                                    <MenuItem value="IE">{t("countries.IE")}</MenuItem>
                                    <MenuItem value="IT">{t("countries.IT")}</MenuItem>
                                    <MenuItem value="LV">{t("countries.LV")}</MenuItem>
                                    <MenuItem value="LT">{t("countries.LT")}</MenuItem>
                                    <MenuItem value="LU">{t("countries.LU")}</MenuItem>
                                    <MenuItem value="MT">{t("countries.MT")}</MenuItem>
                                    <MenuItem value="NL">{t("countries.NL")}</MenuItem>
                                    <MenuItem value="PL">{t("countries.PL")}</MenuItem>
                                    <MenuItem value="PT">{t("countries.PT")}</MenuItem>
                                    <MenuItem value="RO">{t("countries.RO")}</MenuItem>
                                    <MenuItem value="SK">{t("countries.SK")}</MenuItem>
                                    <MenuItem value="SI">{t("countries.SI")}</MenuItem>
                                    <MenuItem value="ES">{t("countries.ES")}</MenuItem>
                                    <MenuItem value="SE">{t("countries.SE")}</MenuItem>
                                    <MenuItem value="UK">{t("countries.UK")}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                variant='filled'
                                type='phone'
                                label={t("profile.phone")}
                                value={values.phone || ''}
                                onChange={handleChangeField('phone')}
                                error={errors.phone}
                                helperText={errors.phone && t("error.valid_phone")}
                                sx={{ width: "70%" }}
                            />
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-field'>{t("profile.password")}</InputLabel>
                                <FilledInput
                                    id='password-field'
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password || ''}
                                    onChange={handleChangeField('password')}
                                    error={errors.password}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showPassword')}
                                                edge='end'>
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                <FormHelperText error={errors.password}>
                                    {t("error.password_criteria")}
                                </FormHelperText>
                            </FormControl>
                        </Stack>
                        <Stack spacing={2}>
                            <FormControl variant='filled'>
                                <InputLabel htmlFor='password-repeat-field'>
                                    {t("profile.repeat_password")}
                                </InputLabel>
                                <FilledInput
                                    id='password-repeat-field'
                                    type={values.showRepeatPassword ? 'text' : 'password'}
                                    value={values.repeatPassword || ''}
                                    onChange={handleChangeField('repeatPassword')}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={() => handleShowPassword('showRepeatPassword')}
                                                edge='end'>
                                                {values.showRepeatPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {errors.repeatPassword && (
                                    <FormHelperText error={errors.repeatPassword}>
                                        {t("error.password_above")}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                        {/* Trading Information Section */}
                        <Container
                            maxWidth='sm'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '30px'
                            }}>
                            <h5>
                                {t("profile.api_management")}&nbsp;
                                <FontAwesomeIcon onClick={handleHelpClick} icon={faCircleQuestion} style={{ color: "#000000" }} />
                            </h5>
                            {showInfo && (
                                <div className="overlay" style={{ zIndex: '1000' }}>
                                    <div className="alert" ref={alertRef}>
                                                    <span className="close-icon" onClick={handleClose} style={{ position: 'absolute', top: '0', right: '0', padding: '2px' }}>
                                                        <FontAwesomeIcon icon={faRectangleXmark} size="xl" style={{ color: "#808080" }} />
                                                    </span>
                                        <div className="row-19 border-radius" style={{ position: 'relative' }}>
                                            <h2>{t("modal_title.support_center")}</h2>
                                            <Divider />
                                            <div className="col-12 row-20">
                                                <Stack>
                                                    <TextField
                                                        select
                                                        label={t("profile.exchange")}
                                                        value={exchange}
                                                        onChange={(event) => handleChangePlatform('exchange', event.target.value)}
                                                        variant="filled"
                                                    >
                                                        <MenuItem value="binance">Binance</MenuItem>
                                                        {/*<MenuItem value="bitpanda">Bitpanda</MenuItem>*/}
                                                    </TextField>
                                                </Stack>
                                                <Divider />
                                                {exchange === 'binance' && <BinanceComponent />}
                                                {/*{exchange === 'bitpanda' && <BitpandaComponent />}*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Container>
                        <Divider />
                        {/* Render existing platform sections */}
                        {platforms.map((platform, index) => (
                            <div key={index}>
                                <ExchangePlatformSection
                                    values={platform.values}
                                    errors={platform.errors}
                                    handleChange={(field, value) => handleChange(index)(field, value)}
                                />
                                {/* <Button
                                    onClick={() => handleRemovePlatform(index)}
                                    style={{ float: 'right', fontSize: '6px' }}
                                >
                                    Remove Platform
                                </Button>*/}
                            </div>
                        ))}
                        {/* Add Platform Button */}
                        {/* <Button onClick={handleAddPlatform} style={{ fontSize: '12px' }}>
                            Add Platform
                        </Button>*/}





                        {/* Plan and Payment Section */}
                        <Container
                            maxWidth="sm"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f6',
                                height: 10,
                                paddingTop: '40px',
                            }}
                        >
                            <h5>{t("profile.sub_and_payment")}</h5>
                        </Container>
                        <Divider />
                        {!isPaymentSuccessful ? (
                            <Stack alignItems="center" spacing={2}>
                                <div className="col-12 row-20">
                                    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                                        <Button
                                            onClick={() => setPlanPeriod("monthly")}
                                            variant={planPeriod === "monthly" ? "contained" : "outlined"}
                                            sx={{ mr: 2 }}
                                        >
                                            Monthly
                                        </Button>
                                        <Button
                                            onClick={() => setPlanPeriod("yearly")}
                                            variant={planPeriod === "yearly" ? "contained" : "outlined"}
                                        >
                                            Yearly
                                        </Button>
                                    </Box>

                                    <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                        {plans.map((plan) => (
                                            <Grid item xs={12} sm={6} md={4} key={plan.value} sx={{ display: "flex" }}>
                                                <Card
                                                    onClick={() => handlePlanSelect(plan.value)}
                                                    sx={{
                                                        cursor: "pointer",
                                                        border: selectedPlan === plan.value ? "1px solid #d9363e" : "1px solid #e0e0e0",
                                                        boxShadow: selectedPlan === plan.value ? "0px 2px 6px rgba(217, 54, 62, 0.5)" : "0px 2px 6px rgba(0, 0, 0, 1)",
                                                        transition: "0.3s",
                                                        borderRadius: "16px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        height: "100%",
                                                        width: "100%",
                                                        "&:hover": {
                                                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                                                            transform: "translateY(-3px)"
                                                        }
                                                    }}
                                                >
                                                    <CardContent
                                                        sx={{
                                                            flexGrow: 1,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "flex-start",
                                                            minHeight: "400px"
                                                        }}
                                                    >
                                                        {/* Icon and Title */}
                                                        <Box sx={{ textAlign: "center" }}>
                                                            <div>{planIcons[plan.value]}</div>
                                                            <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, fontSize: "1.5rem" }}>
                                                                {plan.title}
                                                            </Typography>
                                                            <Typography variant="body1" sx={{ color: "#d9363e", mt: 1, fontSize: "0.7rem" }}>
                                                                {planPeriod === "monthly" ? plan.price : plan.annualPrice}
                                                            </Typography>
                                                            {planPeriod === "yearly" && plan.annualPrice && (
                                                                <Typography variant="body2" sx={{ mt: 1, fontSize: "0.7rem", textDecoration: 'line-through', color: "#999" }}>
                                                                    {plan.price}
                                                                </Typography>
                                                            )}
                                                        </Box>

                                                        {/* Services List */}
                                                        <Box sx={{ mt: 2, flexGrow: 1, pt: 1 }}>
                                                            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                                                {plan.services.map((service, index) => {
                                                                    if (!service?.name || !service?.availability) return null;
                                                                    const { color, Icon, legend } = getAvailabilityProps(service.availability);
                                                                    return (
                                                                        <li key={index} style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                                                                            <Tooltip title={legend} arrow>
                                                                                <Icon sx={{ color, fontSize: "1rem", mr: 1 }} />
                                                                            </Tooltip>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {service.name}
                                                                            </Typography>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </Box>

                                                        {/* Subscribe Button (Mollie Payment) - Show only for selected plan */}
                                                        {selectedPlan === plan.value && (
                                                            <Button
                                                                variant="contained"
                                                                sx={{ mt: 2 }}
                                                                onClick={() => handleMollieSubscription(
                                                                    plan.value,
                                                                    planPeriod === "monthly" ? plan.price : plan.annualPrice,
                                                                    planPeriod,
                                                                    values.email,
                                                                    values.username
                                                                )}
                                                            >
                                                                Subscribe
                                                            </Button>
                                                        )}
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>

                                <Divider />
                                <Button
                                    variant='contained'
                                    size='large'
                                    type='submit'
                                    sx={{
                                        minWidth: '70%',
                                    }}
                                    disabled={true}
                                >
                                    {t("button.register")}
                                </Button>
                            </Stack>
                        ) : (
                            <Stack alignItems="center" spacing={2}>
                                <Typography color="green">
                                    {t("error.payment_success")}
                                </Typography>
                                {/* Register Button and Login Link */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        pt: 2,
                                    }}
                                >
                                    <Button
                                        variant='contained'
                                        size='large'
                                        type='submit'
                                        sx={{
                                            minWidth: '70%',
                                        }}
                                    >
                                        {t("button.register")}
                                    </Button>
                                </Box>
                            </Stack>
                        )}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isCGUApproved}
                                    onChange={() => {
                                        if (!isCGUApproved) {
                                            handleOpenTermsModal(); // Open the modal on first click
                                        } else {
                                            setCGUApproved(false); // Uncheck if already checked
                                        }
                                    }}
                                />
                            }
                            label={
                                <Typography variant="body2">
                                    {t("profile.term_condition")}

                                    {/* Modal for terms and conditions */}
                                    {showTermsModal && (
                                        <div className="modal" ref={termsModalRef}>
                                            <div className="modal-content">
                                                <button
                                                    onClick={() => {
                                                        handleCloseTermsModal();
                                                    }}
                                                    className="close-btn"
                                                >
                                                    X
                                                </button>
                                                <div className="terms-content">
                                                    <TermsModal />
                                                    <div className="terms-footer">
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation(); // Prevent event interference
                                                                setCGUApproved(true);
                                                                setTimeout(() => {
                                                                    handleCloseTermsModal(); // ✅ Close the modal after state updates
                                                                }, 50);
                                                            }}
                                                            className="update-btn"
                                                        >
                                                            {t("button.CGU")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Typography>
                            }
                        />


                        {errors.fetchError && (
                            <FormHelperText error>{errors.fetchErrorMsg}</FormHelperText>
                        )}
                        <Divider />
                        <Typography paragraph align='center'>
                            {t("profile.already_account")}{' '}
                            <Link component={RouterLink} to='/'>
                                {t("profile.login_here")}
                            </Link>
                        </Typography>
                    </Stack>
                </Paper>
            </Container>
        </>
    );
}

export default Signup;
