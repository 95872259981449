import React, { useState, useEffect, useRef, useCallback } from 'react';
import style from './stylesheets/content.css';
import LineChartTotal from "./LineChartTotal";
import TablePortfolio from "./TablePortfolio";

import ParentComponent from "./ParentComponent";
import Simulator_2 from "./Simulator_2";
import Symbols from "./Symbols";
import Market from "./Market";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faExchangeAlt  } from "@fortawesome/free-solid-svg-icons";
import Footer from "./footer";
import Bots from "./Bots";

const Content = () => {
    const [UsersInfo, setUsersInfo] = useState([]);
    const [itemsOpenOrderHistory, setItemsOpenOrderHistory] = useState([]);
    const [showOpenOrderHistoryModal, setShowOpenOrderHistoryModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const alertRef = useRef(null);

    // Memoize fetch function
    const fetchOpenOrderHistory = useCallback(async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await fetch(`/api/openorders`);
            const data = await response.json();
            setItemsOpenOrderHistory(data);
        } catch (error) {
            console.error('Error fetching order history:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    }, []);
// Fetch open order history at regular intervals or when dependencies change
    useEffect(() => {
        // Function to fetch the latest open order history
        const fetchOpenOrders = async () => {
            try {
                const response = await fetch(`/api/openorders`);
                const data = await response.json();
                setItemsOpenOrderHistory(data);
            } catch (error) {
                console.error('Error fetching open orders:', error);
            }
        };

        // Fetch orders initially
        fetchOpenOrders();

        // Optional: Set up a polling interval for periodic updates
        const interval = setInterval(fetchOpenOrders, 120000); // Fetch every 2 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);
    // Fetch users info once
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('/api/users');
                const data = await response.json();
                setUsersInfo(data);
            } catch (error) {
                console.error('Error fetching users info:', error);
            }
        };

        fetchUsers();
        // Optional: Set up a polling interval for periodic updates
        const interval = setInterval(fetchUsers, 120000); // Fetch every 2 minutes

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    // Modal handlers
    const handleCloseOpenOrderHistoryModal = () => {
        setShowOpenOrderHistoryModal(false);
    };

    const handleOpenHistory = () => {
        fetchOpenOrderHistory(); // Fetch data when icon is clicked
        setShowOpenOrderHistoryModal(true); // Show history modal
    };

    return (
        <div className="content col-12 border-style-left">
            {UsersInfo.map((item) => (
            <div className="col-12 row-20">
                <div className="container col-12 row-10" key={item.id}>

                        <div className="card col-5 row-20 pad-left-10  pad-bottom-10 pad-b-10-mobile_tablet" >
                            <div className="linechart_hidden col-12 row-7 pad-bottom-10 pad-right-10 border-radius">
                                <div className="card dashboard_text_3 col-12 row-20 border-radius">
                                    <div className="dashboard_title_2 col-12 s10 border-radius-top">Portfolio Overview</div>
                                    <div className="linechart col-12 row-15">
                                        <LineChartTotal
                                            total={item.total}
                                            benefits={item.benefits}
                                        />
                                        <div
                                            className="card-container"
                                            style={{
                                                display: 'flex',
                                                gap: '5px',
                                                padding: '5px',
                                                flexWrap: 'wrap',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {/* Invested Card */}
                                            <div
                                                className="s9-portfolio-overview card"
                                                style={{
                                                    flex: '1 1 calc(25% - 10px)',
                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                }}
                                            >
                                                <div style={{ fontSize: '9px', fontWeight: 'bold'}}>
                                                    $ {(item.invested).toFixed(2)}
                                                </div>
                                                <div style={{ fontSize: '7px', color: '#FFF' }}>Invested</div>
                                            </div>

                                            {/* Borrowed Card */}
                                            <div
                                                className="s9-portfolio-overview card"
                                                style={{
                                                    flex: '1 1 calc(25% - 10px)',
                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                }}
                                            >
                                                <div style={{ fontSize: '9px', fontWeight: 'bold'}}>
                                                    $ {(
                                                    (item.borrowed)
                                                ).toFixed(2)}
                                                </div>
                                                <div style={{ fontSize: '7px', color: '#FFF' }}>Borrowed</div>
                                            </div>

                                            {/* Remained Card */}
                                            <div
                                                className="s9-portfolio-overview card"
                                                style={{
                                                    flex: '1 1 calc(25% - 10px)',

                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                }}
                                            >
                                                <div style={{ fontSize: '9px', fontWeight: 'bold'}}>
                                                    $ {(item.remained).toFixed(2)}
                                                </div>
                                                <div style={{ fontSize: '7px', color: '#FFF' }}>Remained</div>
                                            </div>

                                            {/* Benefits Card */}
                                            <div
                                                className="s9-portfolio-overview card"
                                                style={{
                                                    flex: '1 1 calc(25% - 10px)',
                                                    borderRadius: '5px',
                                                    padding: '2px',
                                                    fontSize: '14px',
                                                    textAlign: 'center',
                                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                                    backgroundColor: 'rgba(244, 67, 54, 0.9)',
                                                }}
                                            >
                                                <div style={{ fontSize: '9px', fontWeight: 'bold'}}>
                                                    $ {(item.benefits).toFixed(2)}
                                                </div>
                                                <div style={{ fontSize: '7px', color: '#FFF' }}>Benefits</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="linechart col-12 row-13 pad-right-10 border-radius">
                                <div className="dashboard_text_3 col-12 row-20 border-radius">
                                    <div className="dashboard_title_2 col-12 s10 border-radius-top">
                                        Automated Portfolio
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <FontAwesomeIcon
                                                icon={faExchangeAlt}
                                                size="sm"
                                                style={{ color: '#FFF', cursor: 'pointer', marginLeft: '5px' }}
                                                onClick={handleOpenHistory}
                                            />
                                            {itemsOpenOrderHistory.length > 0 && (
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        right:"-10px",
                                                        top:"-2px",
                                                        background: 'red',
                                                        color: 'white',
                                                        borderRadius: '50%',
                                                        padding: '1px 3.5px',
                                                        fontSize: '6px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {itemsOpenOrderHistory.length}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <TablePortfolio />
                                </div>
                            </div>
                        </div>
                    <div className="card col-4 row-20 pad-right-5 pad-bottom-10 pad-b-10-mobile_tablet col-4-mobile">
                        <div className="card col-12 row-20 border-radius">
                            <div className="linechart col-12 row-20 border-radius">
                                <div className="dashboard_text_3 col-12 row-20 border-radius">
                                    <div className="dashboard_title_2 col-12 s10 border-radius-top">Market Overview</div>
                                    <div className="parentContainer">
                                        <div className="linechart col-12 row-3 pad-top-10 border-radius"><Market/></div>
                                        <div className="linechart col-12 row-15 pad-top-10 pad-bottom-10 border-radius"><Symbols/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-3 row-20 pad-left-5 pad-right-10 pad-bottom-10 pad-r-b-10-mobile_tablet col-4-mobile">
                        <div className="linechart col-12 row-20 border-radius">
                            <div className="dashboard_text_3 col-12 row-20 border-radius">
                                <div className="dashboard_title_2 col-12 s10 border-radius-top">Automated Self Services</div>
                                <Bots
                                    isFreeTrial={item.isFreeTrial}
                                    isEssential={item.isEssential}
                                    isPremium={item.isPremium}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container col-12 row-19 pad-top-10">
                    <div className="card col-3 width-tablet-phone row-19 pad-left-10 pad-r-b-10-mobile_tablet">
                        <div className="card dashboard_text_3 col-12 row-19 border-radius">
                            <div className="dashboard_title_2 col-12 s10 border-radius-top">Strategy Builder</div>
                            <Simulator_2
                                isFreeTrial={item.isFreeTrial}
                                isBasic={item.isBasic}
                                isEssential={item.isEssential}
                                isPremium={item.isPremium}
                                isAdmin={item.isAdmin}
                            />
                        </div>
                    </div>
                    <div className="card col-9 width-tablet-phone mobile_textsize row-19 pad-left-10 pad-right-10 pad-r-b-10-mobile_tablet">
                        <div className="dashboard_text_3 col-12 row-19 border-radius">
                            <div className="dashboard_title_2 col-12 s10 border-radius-top">Technical Analysis</div>
                            <ParentComponent />
                        </div>
                    </div>
                </div>
                {/* <div className="container col-12">
                   <Footer/>
                </div>*/}
            </div>
            ))}
            {showOpenOrderHistoryModal && (
                <div className="modal" ref={alertRef}>
                    <div className="modal-content">
                        <h2>Open Order</h2>
                        <button onClick={handleCloseOpenOrderHistoryModal} className="close-btn">
                            X
                        </button>
                        <div className="history-list">
                            {isLoading ? (
                                <div>
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                                    <p>Loading...</p>
                                </div>
                            ) : itemsOpenOrderHistory.length > 0 ? (
                                itemsOpenOrderHistory.map((order, index) => (
                                    <div className="order-item" key={index}>
                                        <span style={{fontWeight: 'bold', fontSize: '15px', color: '#999' }}>{order.cryptoname}</span>
                                        <div className="order-row">
                                            <div className="order-detail">
                                                <span>Price:</span> {order.price}
                                            </div>
                                            <div className="order-detail">
                                                <span>Side:</span> {order.side}
                                            </div>
                                        </div>
                                        <div className="order-row">
                                            <div className="order-detail">
                                                <span>Quantity:</span> {order.quantity}
                                            </div>
                                            <div className="order-detail">
                                                <span>Date:</span> {order.date}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No open order found.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Content;
