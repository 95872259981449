import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus } from "@fortawesome/free-solid-svg-icons";

import binance_log_in from './images/binance_login.png';
import binance_label_api from './images/binance_label_api.png';
import binance_menu from './images/binance_menu.png';
import binance_verification from './images/binance_verification.png';
import binance_create_api from './images/binance_create_api.png';
import binance_api_configuration from './images/binance_api_configuration.png';
import binance_apikey_type from './images/binance_apikey_type.png';
import binance_api_secret_key from './images/binance_api_secret_key.png';
import { useTranslation } from "react-i18next";

const stepStyle = {
    textAlign: 'left',
    paddingLeft: '6rem',
    paddingTop: '2rem',
    paddingBottom: '1rem'
};

const imageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};

const BinanceComponent = () => {
    const { t } = useTranslation();

    // Steps data based on the provided JSON
    const steps = [
        {
            title: t("help.binance_api_setup.step_1"),
            description: {
            start: t("help.binance_api_setup.description_1_start"),
            link_text: t("help.binance_api_setup.description_1_link_text"),
            end: t("help.binance_api_setup.description_1_end"),
            },
            image: binance_log_in,
            alt: "Log in on Binance",
            maxWidth: "20%"
        },
        {
            title: t("help.binance_api_setup.step_2"),
            description: t("help.binance_api_setup.description_2"),
            image: binance_menu,
            alt: "API Management",
            maxWidth: "15%"
        },
        {
            title: t("help.binance_api_setup.step_3"),
            description: t("help.binance_api_setup.description_3"),
            image: binance_create_api,
            alt: "Create API",
            maxWidth: "45%"
        },
        {
            title: t("help.binance_api_setup.step_4"),
            description: t("help.binance_api_setup.description_4"),
            image: binance_apikey_type,
            alt: "Select API key",
            maxWidth: "25%"
        },
        {
            title: t("help.binance_api_setup.step_5"),
            description: t("help.binance_api_setup.description_5"),
            image: binance_label_api,
            alt: "Label API",
            maxWidth: "20%"
        },
        {
            title: t("help.binance_api_setup.step_6"),
            description: t("help.binance_api_setup.description_6"),
            image: binance_verification,
            alt: "Verification",
            maxWidth: "20%"
        },
        {
            title: t("help.binance_api_setup.step_7"),
            description: t("help.binance_api_setup.description_7"),
            image: binance_api_configuration,
            alt: "API parameters",
            maxWidth: "75%"
        },
        {
            title: t("help.binance_api_setup.step_8"),
            description: t("help.binance_api_setup.description_8"),
            image: binance_api_secret_key,
            alt: "API key and Secret key",
            maxWidth: "50%"
        }
    ];

    return (
        <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>
            {steps.map((step, index) => (
                <div key={index}>
                    <h3 style={stepStyle}>
                        <FontAwesomeIcon icon={faSquareMinus} style={{ color: "#000" }} /> &nbsp;
                        {step.title}
                    </h3>
                    {step.description && typeof step.description === "object" ? (
                        // Check if description is an object and log it to console for debugging
                        <p style={{ textAlign: 'left', paddingLeft: '6rem', marginTop: '-10px', color: '#666' }}>
                            {console.log(step.description)} {/* Log to check the structure */}
                            {step.description.start}
                            <a href="https://www.binance.com/activity/referral-entry/CPA?ref=CPA_00X9G7Z77E" target="_blank" rel="noopener noreferrer">
                                {step.description.link_text}
                            </a>
                            {step.description.end}
                        </p>
                    ) : (
                        <p style={{ textAlign: 'left', paddingLeft: '6rem', marginTop: '-10px', color: '#666' }}>
                            {step.description}
                        </p>
                    )}
                    <div style={imageContainerStyle}>
                        <img src={step.image} alt={step.alt} style={{ maxWidth: step.maxWidth, height: 'auto' }} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BinanceComponent;
