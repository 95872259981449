import React, { useState, useEffect } from "react";
import CoinHistory from "./CoinHistory"; // Replace with the correct path to your component

const ParentComponent = () => {
	const [selectedCurrency, setSelectedCurrency] = useState("BTCUSDT");
	const [data, setData] = useState([]);

	useEffect(() => {
		// Fetch data for the selected currency
		fetchData();
	}, [selectedCurrency]);

	const fetchData = async () => {
		try {
			const response = await fetch(`/api/CoinHistory?currency=${selectedCurrency}`);
			const data = await response.json();

			console.log("Fetched data:", data);

			const formattedData = data[0]?.values?.map((d) => ({
				...d,
				date: new Date(d.date),
				value: +d.value,
			})) || [];

			console.log("Formatted data:", formattedData);

			setData(formattedData);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCurrencyChange = (currency) => {
		setSelectedCurrency(currency);
	};

	return (
		<div>
			<CoinHistory
				data={data}
				selectedCurrency={selectedCurrency}
				onCurrencyChange={handleCurrencyChange}
			/>
		</div>
	);
};

export default ParentComponent;