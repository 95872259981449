import React from 'react';

const TermsModal = () => {
    return (
        <div className="terms-modal">
            <h1 className="terms-title">Terms and Conditions</h1>
            <div className="terms-content">
                <section>
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to Botmight. By accessing the Application and using our services, you agree to these Terms and Conditions ("T&C"). If you do not accept these T&C, please do not use the Application.
                    </p>
                </section>
                <section>
                    <h2>2. Description of Services</h2>
                    <p>
                        The Application offers automated trading services, including:
                    </p>
                    <ul>
                        <li>Self-service robot creation (Scalping, DCA (Dollar-Cost Averaging), and more)</li>
                        <li>Strategy creation, virtual mode, and production (real purchase)</li>
                        <li>Market information</li>
                        <li>Financial indicators</li>
                    </ul>
                    <p>
                        These services rely on third-party exchange platforms such as Binance, Bitpanda, and other financial sources.
                    </p>
                </section>
                <section>
                    <h2>3. Registration and Use of APIs</h2>
                    <p>
                        When registering, you must provide accurate information, including your API keys and Secret Key from the exchange platforms you use. You are fully responsible for the confidentiality and security of these keys.
                    </p>
                </section>
                <section>
                    <h2>4. User Responsibilities</h2>
                    <p>
                        You acknowledge that the services provided are automated and depend on third-party platforms. You understand that financial markets involve risks, including the loss of your invested capital. You are responsible for monitoring your trading activities and setting up strategies in line with your goals.
                    </p>
                </section>
                <section>
                    <h2>5. Limitation of Liability</h2>
                    <p>
                        Botmight does not guarantee specific results or profits. Botmight disclaims any liability for financial loss, technical issues, or malfunctioning third-party exchange platforms. You use the services at your own risk.
                    </p>
                </section>
                <section>
                    <h2>6. Privacy</h2>
                    <p>
                        Your personal information and API keys are handled with the utmost discretion and in accordance with our Privacy Policy.
                    </p>
                </section>
                <section>
                    <h2>7. Dependence on Third-Party Platforms</h2>
                    <p>
                        Our services function through integration with platforms like Binance, Bitpanda, and others. We do not control or guarantee their functionality, data, or availability.
                    </p>
                </section>
                <section>
                    <h2>8. Changes to the T&C</h2>
                    <p>
                        We reserve the right to modify these T&C at any time. You will be notified of changes via email or through the Application. By continuing to use the Application, you agree to the new T&C.
                    </p>
                </section>
                <section>
                    <h2>9. Termination</h2>
                    <p>
                        We reserve the right to suspend or terminate your account in the event of a breach of these T&C or for any other reason deemed necessary.
                    </p>
                </section>
                <section>
                    <h2>10. Jurisdiction</h2>
                    <p>
                        These Terms and Conditions are governed by the laws applicable in the region where the user resides or where the application operates, unless otherwise stated. Any dispute arising from these terms will be resolved in accordance with the legal frameworks and jurisdictions agreed upon by the involved parties.
                    </p>
                </section>
                <section>
                    <h2>11. Contact</h2>
                    <p>
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us via email at <a href="mailto:support@botmight.net">support@botmight.net</a>. For additional assistance, you can also reach us through our integrated support system in the application or our official website.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsModal;